import {EDICOES} from "../edicoes";


const edicoesReducer = (state = EDICOES, action) => {

  return state;
}


export default edicoesReducer

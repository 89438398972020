import React, { Component } from "react";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Home from "./pages/Home";
import Edicoes from "./pages/Edicoes";
import NavBar from "./components/nav/NavBar";
import Footer from "./components/footer/Footer";
import Comissao from "./pages/Comissao";
import CorpoEditorial from "./pages/CorpoEditorial";
import Expediente from "./pages/Expediente";
import Edicao from "./pages/Edicao";
import { Link } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <ScrollToTop>
      <Switch>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-md col-nav">
              <NavBar />
            </div>

            <div data-comp-content className="col-md col-content">
              <div className="container-md m-0">
                <div className="row">
                  <div className="col-12">
                    <main className="main" role="main">
                      <Route exact path="/" component={Home} />
                      <Route path="/comissao" component={Comissao} />
                      <Route path="/edicoes" component={Edicoes} />
                      <Route
                        path="/corpo-editorial"
                        component={CorpoEditorial}
                      />
                      <Route path="/expediente" component={Expediente} />
                      <Route path="/edicao/:year" component={Edicao} />
                    </main>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Switch>
    </ScrollToTop>
  );
};

const mapStateToProps = (state) => {
  return {
    // tasks: state.task.tasks
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    // addTask: (task) => dispatch(addTask(task)),
    // delTask: (taskId) => dispatch(delTask(taskId))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));

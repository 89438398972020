import React, { useState } from "react";

import bandeiraBrasil from "./assets/pt-BR.png";
import bandeiraEua from "./assets/en-US.png";
import { i18n } from "../../translations/i18n";
import { useRouteMatch, useHistory } from "react-router-dom";

const I18N_STORAGE_KEY = "i18nextLng";

const LanguageSwitcher = () => {
  const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY));
  const router = useHistory();

  //conta acima é para dar um get no value setado
  const handleSelecteChange = (lang) => {

    localStorage.setItem(I18N_STORAGE_KEY, lang);

    i18n.changeLanguage(lang);
    router.go(0);

  };

  return (
    <div className="mt-2">
      <button
        onClick={() => handleSelecteChange("pt-BR")}
        value="pt-BR"
        className="mr-2 btn btn-light btn-sm"
      >
        <img src={bandeiraBrasil} width="20" height="14" />
      </button>
      <button
        onClick={() => handleSelecteChange("en-US")}
        value="en-US"
        className="btn btn-light btn-sm"
      >
        <img src={bandeiraEua} width="20" height="14" />
      </button>
    </div>
  );
};

export default LanguageSwitcher;

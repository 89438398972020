import i18n from 'i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

import { messages } from './languages'


i18n
    .use(LanguageDetector)
    //LanguageDetector serve para para detectar o idioma do navegador
    //porém não evita o usuário de trocar o idioma, caso queira
    .init({
        debug: false,
        defaultNS: ['translations'],
        fallbackLng: 'pt',
        ns: ['translations'],
        resources: messages
    })

export { i18n }

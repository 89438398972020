export const FOTOS_2014 = [
  {
    "src": "gallery/2014/intercorr2014_01.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_02.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_03.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_04.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_05.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_06.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_07.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_08.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_09.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_10.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_11.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_12.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_13.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_14.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_15.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_16.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_17.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_18.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_19.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_20.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_21.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_22.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_23.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_24.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_25.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_26.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_27.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_28.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_29.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_30.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_31.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_32.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_33.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_34.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_35.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_36.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_37.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_38.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_39.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2014/intercorr2014_40.jpg",
    "width": 1200,
    "height": 795
  }
];

export const FOTOS_2021 = [
  {
    "src": "gallery\/2021\/intercorr2021_01.jpg",
    "width": 1328,
    "height": 747
  },
  {
      "src": "gallery\/2021\/intercorr2021_02.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_03.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_04.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_06.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_07.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_08.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_09.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_10.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_11.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_12.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_13.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_14.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_15.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_16.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_17.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_18.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_19.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_20.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_21.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_22.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_23.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_24.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_25.jpg",
      "width": 1620,
      "height": 911
  },
  {
      "src": "gallery\/2021\/intercorr2021_26.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_27.jpg",
      "width": 1200,
      "height": 750
  },
  {
      "src": "gallery\/2021\/intercorr2021_28.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_29.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_30.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_31.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_32.jpg",
      "width": 1286,
      "height": 566
  },
  {
      "src": "gallery\/2021\/intercorr2021_33.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_34.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_35.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_36.jpg",
      "width": 1385,
      "height": 779
  },
  {
      "src": "gallery\/2021\/intercorr2021_37.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_38.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_39.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_40.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_41.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_42.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_43.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_44.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_45.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_46.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_47.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_48.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_49.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_50.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_51.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_52.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_53.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_54.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_55.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_56.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_57.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_58.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_59.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_60.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_61.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_62.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_63.jpg",
      "width": 1920,
      "height": 1080
  },
  {
      "src": "gallery\/2021\/intercorr2021_64.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_65.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_66.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_67.jpg",
      "width": 1286,
      "height": 723
  },
  {
      "src": "gallery\/2021\/intercorr2021_68.jpg",
      "width": 1286,
      "height": 723
  }
];

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import basicReduxStore from './store/basicReduxStore'
import App from './App';
/**
 * Theme
 */
import './theme/bootstrap/bootstrap.scss';
import './theme/index.scss';

/**
 * -------------------------------------
 * Store with Redux only
 */
const store = basicReduxStore();
ReactDOM.render(<Provider store={store}><BrowserRouter  basename={process.env.PUBLIC_URL}><App/></BrowserRouter></Provider>, document.getElementById('root'));

/**
 * -------------------------------------
 * Store with Redux + Firebase
 */
/*
const store = firebaseReduxStore();

const rrfProps = {
  firebase: fbConfig,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

ReactDOM.render(<Provider store={store}>
  <ReactReduxFirebaseProvider {...rrfProps}>
  <App/>
  </ReactReduxFirebaseProvider>
</Provider>, document.getElementById('root'));
*/


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

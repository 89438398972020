import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { i18n } from '../translations/i18n';
import './EditionList.scss'

const EditionList = (props) => {
  const { edicoes, compact, className } = props;
  const classes = compact ? className + ' compact' : className;
  return (
    <div data-comp-editionlist className={classes + ' list-group'}>

      {edicoes && (
        Object.keys(edicoes).sort().reverse().map((year) => {
          const edicao = edicoes[year];
          return (
            <Link to={'/edicao/' + year} key={edicao.year} className="list-group-item list-group-item-action flex-column align-items-start">
              <div className="d-flex align-items-center">
                <span className="arrow"><img src="./assets/arrow-r.svg" alt="Divisa" /></span>
                <h5 className="mb-1">{edicao.name} • <span dangerouslySetInnerHTML={{__html: edicao.date}}/> </h5>
                {edicao.anais.length ? (<small className="count">{edicao.anais.length} {i18n.t('edicoes.trabalhos')}</small>) : ''}
              </div>
            </Link>
          )
        })
      )}
    </div>
  );
}

// class EditionList extends Component {
//   render() {
//     const { edicoes, compact, className } = this.props;
//     const classes = compact ? className + ' compact' : className;

//     return (
//       <div data-comp-editionlist className={classes + ' list-group'}>

//         {edicoes && (
//           Object.keys(edicoes).sort().reverse().map((year) => {
//             const edicao = edicoes[year];
//             return (
//               <Link to={'/edicao/' + year} key={edicao.year} className="list-group-item list-group-item-action flex-column align-items-start">
//                 <div className="d-flex align-items-center">
//                   <span className="arrow"><img src="./assets/arrow-r.svg" alt="Divisa" /></span>
//                   <h5 className="mb-1">{edicao.name} • {edicao.date}</h5>
//                   {edicao.anais.length ? (<small className="count">{edicao.anais.length} trabalhos</small>) : ''}
//                 </div>
//               </Link>
//             )
//           })
//         )}
//       </div>
//     );
//   }
// }

const mapStateToProps = (state) => {
  return {
    edicoes: state.edicoes
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(EditionList);

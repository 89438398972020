import React, {Component} from 'react';
import {connect} from 'react-redux';
import EditionList from "../components/EditionList";
import '../theme/Home.scss';

import { i18n } from '../translations/i18n';

const Home = () => {

  return(

    <section data-comp-home className="home">
    <div className="bg-marrom">
      <img src="./assets/home-symbol.png" alt="" className="img-fluid"/>
      <div dangerouslySetInnerHTML={{__html: i18n.t("home.header")}}/>
    </div>
    <p className="mt-5">{i18n.t('home.subtitulo')}</p>
    <h2>Volumes</h2>
    <EditionList compact className="my-3"/>

    <h2 className="mb-3 mt-4">{i18n.t('home.tituloEdicao')}</h2>
    <p><a href="https://abraco.org.br/icc-congress2021/" target="_blank">{i18n.t('home.edicao')} 2021</a></p>
    <p><a href="http://www.abraco.org.br/intercorr2018/" target="_blank">{i18n.t('home.edicao')} 2018</a></p>
    <p><a href="http://www.abraco.org.br/intercorr2016/" target="_blank">{i18n.t('home.edicao')} 2016</a></p>
    <p><a href="http://www.abraco.org.br/intercorr2014/" target="_blank">{i18n.t('home.edicao')} 2014</a></p>
    <p><a href="http://www.abraco.org.br/intercorr2012/" target="_blank">{i18n.t('home.edicao')} 2012</a></p>
    <p><a href="http://www.abraco.org.br/intercorr2010/" target="_blank">{i18n.t('home.edicao')} 2010</a></p>
    <p><a href="http://www.abraco.org.br/intercorr2008/" target="_blank">{i18n.t('home.edicao')} 2008</a></p>
  </section>

  )

}

//export default Home;



/*class Home extends Component {
  state = {}

  render() {
    return (
      <section data-comp-home className="home">
        <div className="bg-marrom">
          <img src="./assets/home-symbol.png" alt="" className="img-fluid"/>
          <p>
            O INTERCORR é o maior evento internacional de corrosão realizado no Brasil que reúne a comunidade técnica e
            científica de universidades, institutos de pesquisas, empresas e profissionais da área de corrosão,
            proporcionando um maior intercâmbio de conhecimentos e experiências. Com o objetivo principal de incentivar
            o intercâmbio de informações e divulgar conhecimentos recentemente adquiridos com os estudos da corrosão e
            suas mais variadas formas de controle e prevenção, com um público que reúne: especialistas, gerentes,
            consultores, engenheiros, técnicos, pesquisadores e acadêmicos envolvidos em diversos setores ligados,
            direta ou indiretamente, ao combate da corrosão e suas medidas preventivas.</p>
          <p>O INTERCORR vem se destacando e tornando-se uma referência para o desenvolvimento do setor industrial,
            sendo um excelente cenário para empresas de diversos segmentos apresentarem suas tecnologias, divulgarem sua
            marca e darem visibilidade aos seus negócios, ampliando relacionamento e conhecimento.</p>
          <p>O conteúdo dos artigos aqui publicados é de inteira responsabilidade dos seus autores.</p>

        </div>
        <p className="mt-5">O conteúdo dos artigos aqui publicados são de inteira responsabilidade dos seus autores.</p>
        <h2>Volumes</h2>
        <EditionList compact className="my-3"/>

        <h2 className="mb-3 mt-4">Websites oficiais das edições</h2>
        <p><a href="https://abraco.org.br/icc-congress2021/" target="_blank">Edição 2021</a></p>
        <p><a href="http://www.abraco.org.br/intercorr2018/" target="_blank">Edição 2018</a></p>
        <p><a href="http://www.abraco.org.br/intercorr2016/" target="_blank">Edição 2016</a></p>
        <p><a href="http://www.abraco.org.br/intercorr2014/" target="_blank">Edição 2014</a></p>
        <p><a href="http://www.abraco.org.br/intercorr2012/" target="_blank">Edição 2012</a></p>
        <p><a href="http://www.abraco.org.br/intercorr2010/" target="_blank">Edição 2010</a></p>
        <p><a href="http://www.abraco.org.br/intercorr2008/" target="_blank">Edição 2008</a></p>
      </section>

    )
  }
}*/

const mapStateToProps = (store) => {
  return {
    // posts: store.post.posts
  }
}


export default connect(mapStateToProps)(Home);

export const EDICAO_2016 ={
  "message": '',
  "data": [
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_005.pdf",
      "name": "Proteção Anticorrosiva para estruturas de aço em atmosferas industriais e marinhas com sistema de tinta base aquosa acrílica tipo DTM (direct to metal).",
      "authors": "Paulo C. M. Tiano, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_008.pdf",
      "name": "Análise de falha em parafusos de válvula esfera - corrosão sob tensão por cloreto",
      "authors": "André da Silva Pelliccione, André Freitas Ribeiro, Angélica Dias Salvador, Orlandemberg Pereira da Silva, Patrícia Iris da Silva Peres, Leandro Guimarães de Oliveira, Rafael Silveira Barbosa"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_012.pdf",
      "name": "Estudos eletroquímicos sobre a deposição da liga ternária de Ni-Co-W",
      "authors": "Caio F. Baldessin, Ambrósio F. de Almeida Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_015.pdf",
      "name": "Degradação do Aço Carbono AISI 1020 Imersos em Diferentes Misturas de Óleo Diesel B6/B30 com Água do Mar",
      "authors": "Eraldo de J. Argolo, Rafael G. C. da Silva, Sara H. de Oliveira, Tayse C. da Silva, Leillane B. S. Medeiros, Francisca P. de França, Severino L. Urtiga Filho, Maria A. G. de A. Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_016.pdf",
      "name": "Avaliação da atividade anticorrosiva de tiossemicarbaz ida derivada do ácido esteárico via técnicas eletroquímicas",
      "authors": "Danilo Sousa-Pereira, Carla M. Goulart, Aurea Echevarria"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_018.pdf",
      "name": "Evolução de sistemas anticorrosivos em tinta em pó para ambientes marítimos, offshore e de refinarias",
      "authors": "Cláudio R. Martins, Marcos Roberto Segalla, Camila Aparecida Zimmermann"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_020.pdf",
      "name": "Aplicação de técnicas eletroquímicas na análise de corrosão de ligas amorfas do sistema Ni-Nb-B e Ni-Nb",
      "authors": "Emandro V. da Costa, Ivan N. Bastos, Walter J. Botta Filho, Luis C. R. Aliaga"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_021.pdf",
      "name": "Tanino da Acacia mearnsii De Wild como inibidor de corrosão para alumínio",
      "authors": "Silvia R.S. Rodrigues, Viviane Dalmor, João Henrique Z.dos Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_022.pdf",
      "name": "Estudo em solução salina do comportamento do Alumínio Anodizado e colorido",
      "authors": "Natal Nerímio Regone"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_023.pdf",
      "name": "Estudo eletroquímico de revestimentos orgânicos derivados de óleos vegetais: Eoc, LP, CP, EIS, LEIS e SVET",
      "authors": "Jéssica V. Nardeli, Cecilio S. Fugivara, Fatima Montemor, Assis V. Benedetti"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_025.pdf",
      "name": "Efeito do teor de nióbio e manganês sobre a permeabilidade de hidrogênio e comportamento eletroquímico de aços para tubos API 5L X65 sour em meio contendo H2S",
      "authors": "Marco A. P. Fiori, Viviam S.M. Pereira, Duberney Hincapié-Ladino, Neusa Alonso - Falleiros, Hélio Goldenstein, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_026.pdf",
      "name": "Estudo do comportamento eletroquímico das regiões (MB, ZTA e ZM) soldadas por FSW da liga AA2024/AA7475 utilizando microcélula eletroquímica",
      "authors": "Haroldo Marques Gonçalves, Assis Vicente Benedetti, Adriano Heleno Akita, Cecílio Sadao Fugivara"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_027.pdf",
      "name": "Determinação da oxidação da liga de cobre 144 imersa em gel despolarizante comumente usado em anodos, pela deconvolução da curva potenciodinâmica catódica seguida da construção de diagrama eletroquímico.",
      "authors": "José M . Silva, João H. Zancanella, Geraldo R. de Almeida, Carlos A. Sotille"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_028.pdf",
      "name": "ELETRODEPOSIÇÃO DE LIGAS METÁLICAS DE Zn-Ni PARA REVESTIMENTO ANTICORROSIVO",
      "authors": "Sara C. Silva, Thales A. Amarante, Ambrósio F. de Almeida Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_029.pdf",
      "name": "AVALIAÇÃO DE PRÁTICA EXPERIMENTAL NA ELUCIDAÇÃO DO CONCEITO DE PELÍCULA DE PASSIVAÇÃO",
      "authors": "Sara C. Silva, Ambrósio F. de Almeida Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_030.pdf",
      "name": "Avaliação da atividade anticorrosiva de derivados da classe 1,3,4-tiadiazólio -2- fenilaminas frente ao aço carbono em meio ácido",
      "authors": "Frauches-Santos, Cristiane, Echevarria Aurea"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_031.pdf",
      "name": "AVALIAÇÃO DA ATIVIDADE ANTICORROSIVA DE EXTRATOS AQUOSOS DAS CASCAS DE Theobroma cacao L.",
      "authors": "Mariana A. de Albuquerque, Márcia C. C. de Oliveira, Aurea Echevarria"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_033.pdf",
      "name": "Avaliação da Atividade Anticorro siva do Fenetil-enaminoéster em Meio Ácido frente ao Aço Carbono AISI 1020",
      "authors": "Mariana F. L. P. Carlos, Arthur Valbon, Ma rgareth Rose L. Santos, Aurea Echevarria"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_034.pdf",
      "name": "CODEPOSIÇÃO INDUZIDA USANDO COBALTO COMO METAL INDUTOR PARA FORMAÇÃO DE LIGAS DE TUNGSTÊNIO",
      "authors": "Rodolfo J. Amancio, Sara Cristina Silva, Ambrósio F. de Almeida Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_035.pdf",
      "name": "Evaluation of the corrosion resistance of WC-Co coatings obtained by Gas Cold Spray onto Al 7075-T6 in chloride solution",
      "authors": "Fernando Santos da Silva, Assis Vicente Benedetti, Sergi Dosta, Irene Garcia Cano, Josep Maria Guilemany"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_037.pdf",
      "name": "Investigação da efetividade do fosfato de zinco como pigmento inibidor de corrosão em tintas de base epóxi e alquídicas",
      "authors": "Eider Santos, Arariboia Martins"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_038.pdf",
      "name": "A utilização do microscópio eletrônico de varredura (MEV) para avaliação de diferentes revestimentos quando aplicados sobre preparo de superfície “pobre”",
      "authors": "Eider Santos, Arariboia Martins"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_040.pdf",
      "name": "Estudo do processo de biocorrosão na superfície do aço carbono ASTM A283, exposto em óleo diesel S10 e água doce",
      "authors": "Diana M. Frazão, Ivanilda R. de Melo, Severino L. U. Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_042.pdf",
      "name": "AVALIAÇÃO DE PARÂMETROS FÍSICO -QUÍMICOS SOBRE A ELET RODEPOSIÇÃO DAS LIGAS DE Co-Ni-M o E Co-Mo",
      "authors": "Luiz Felipe Ferraz, Flávio Cosenza Veiga Barbosa, Sara Cristina Silva, Ambrósio F. de Almeida Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_047.pdf",
      "name": "Estudo da eficiência do pó da torta de palmiste como inibidor de corrosão para o aço carbono em meio ácido",
      "authors": "André de M . Santos, Miriam S. Tokumoto, Isabel C. Guedes, Hercílio G. de Melo, Vera Rosa Capelossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_049.pdf",
      "name": "Avaliação de complexos de inclusão na inibição do aço carbono em meio ácido",
      "authors": "Thaís M. de Souza, Lilian F. de Senna, Eliane D`Elia, Luiz F. B. Malta"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_050.pdf",
      "name": "Estudo da formação de filmes na corrosão do aço carbono AISI 1020 por dióxido de carbono em ambientes termodinamicamente não favoráveis.",
      "authors": "Simone M. Klok, Haroldo de Araújo Ponte, Lílian R. M. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_051.pdf",
      "name": "Avaliação da corrosão de revestimentos de conversão para filme de alumina produzido por dip coating em sol-gel",
      "authors": "Antonio V. C. Braga, Dalva C. B. do Lago, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_057.pdf",
      "name": "Estimativa de probabilidade de falha por corrosão externa utilizando uma abordagem quantitativa de análise de risco para dutos",
      "authors": "Marcos Bartelotti"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_061.pdf",
      "name": "Formulações inibidoras verdes de compostos fenólicos para fluidos inorgânicos de acidificação",
      "authors": "Luana B. Furtado, Rafaela C. Nascimento, Maria J . O. C. Guimarães, Peter R . Seidl"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_062.pdf",
      "name": "Uso da SECM p ara estudo da corrosão da liga AA2024-T3 soldada por FSW",
      "authors": "Patrícia H. Suegama, Hercílio G. de Melo, Vincent Vivier"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_063.pdf",
      "name": "Estudo da influência da composição da água de poro sobre a corrosão do aço CA-50",
      "authors": "Renan E. Vieira, Marina M. Mennucci, Valdecir A. Quarcioni, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_065.pdf",
      "name": "CORROSÃO POR CO2 EM AÇO AO CARBONO EM MEIO SALINO A ALTA PRESSÃO E ALTAS TEMPERATURAS",
      "authors": "Natália Feijó Lopes, Eleani M. da Costa, João C. Salvador Fernandes"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_066.pdf",
      "name": "METHODOLOGY FOR GROUNDING ELECTRODE CORROSION DIAGNOSING",
      "authors": "Cleberson L. do Nascimento, Alysson N. Diógenes, Haroldo A. Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_067.pdf",
      "name": "PROTEÇÃO CATÓDICA GALVÂNICA – APLICAÇÕES – FACILIDADES E DIFICULDADES",
      "authors": "Laerce de Paula Nunes, Leo Santana Gomes de Matos, Anderson Teixeira Kriescher, Guilherme Vogel Satyro"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_070.pdf",
      "name": "Influência do teor de água na avaliação da corrosão de colunas de produção por meio da técnica de gaiola rotatória",
      "authors": "Bruno Diehl Neto, Jefferson Rodrigues de Oliveira, Carlos José Bandeira de Mello Joia, Ilson Palmieri Baptista, Tiago Hofman de Souza, Merlin Cristina Elaine Bandeira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_071.pdf",
      "name": "PROTEÇÃO CATÓDICA E REVESTIMENTOS - A IMPORTÂCIA DA COMPLEMENTARIDADE",
      "authors": "Laerce de Paula Nunes, Anderson Teixeira Kreischer, Guilherme Vogel Satyro, Leo Santana Gomes de Matos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_073.pdf",
      "name": "Biossurfactantes produzidos por isolados da ilha oceânica da Trindade mostram ação antimicrobiana contra bactéria redutora de sulfato",
      "authors": "Bruna A. Leão Ayupe, Péricles L. Fernandes, Pedro T. Valarelli, Paulo R. Cecon, Hilário C. Mantovani, Marcos R. Tótola"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_074.pdf",
      "name": "Resistência à corrosão de aços revestidos com zinco submetidos ao teste em câmara de névoa salina",
      "authors": "Egnalda Pereira da Silva Pimenta, Sandra Goulart-Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_077.pdf",
      "name": "ENSAIOS DE CAMPO DE SISTEMA DUPLEX (AÇO GALVANIZADO PINT ADO) PARA EMPRESAS DE ÓLEO &amp; G ÁS FIELD TESTS OF DUPLEX SYSTEM (COATED GALVA NIZED STEEL) AT OIL&amp;GAS COMPANY",
      "authors": "Ricardo Suplicy de Araujo Goes, Renata Yuriko Ogura, Paulo Cesar Maziero Tiano, Celso Gnecco, Marcelo Schultz da Rocha, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_079.pdf",
      "name": "Comportamento eletroquímico de uma solda a fricção do tipo alumínio-cobre tratada com 3-Glic ido xi-propil-trimetoxi silano",
      "authors": "Vitor G. Arrussul, Rafaela Spengler,Suelen W. Cendron, Aldoni G. Wiedenhöft, Denise S.Azambuja, Sílvia M. Tamborim"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_083.pdf",
      "name": "Avaliação de campo de revestimentos anti-corrosivos e anti-incrustantes para a indústria do petróleo",
      "authors": "Mônica de Oliveira Penna, Felipe Batista Alvim, Mohammad Mohsen Vazirian"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_084.pdf",
      "name": "Efeito da selagem hidrotérmica com adição de íons Ce (III) na proteção à corrosão da liga de alumínio AA7475 -T761 anodizada em banho ácido tartárico-sulfúrico",
      "authors": "Thallis L. Almeida, Fernanda M. Queiroz, Maysa Terada, Isolda Costa, Vera R. Capelossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_087.pdf",
      "name": "Aplicação Conjugada das Técnicas de Ruído Eletroquímico e de Processamento de Imagens na Detecção de Corrosão Localizada",
      "authors": "Gil Roberto V. Pinheiro, José Antônio da C. Ponciano Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_088.pdf",
      "name": "STRESS CORROSION CRACKING OF MODIFIED NITI ALLOYS IN CHLORIDE SOLUTIONS",
      "authors": "Camila D. R. Barros, Jose Antonio C. Ponciano Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_090.pdf",
      "name": "Analise comparativa da eficiência da Polianilina utilizada como pigmento em tinta para proteção contra corrosão",
      "authors": "A.L. Aravanis, A.F. Baldissera, C.A. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_092.pdf",
      "name": "ESTUDO COMPARATIVO DA RESISTÊNCIA À CORROSÃO DE AÇOS API GRAU X65 EM SOLUÇÃO B NACE TM 0284 SEM E COM SATURAÇÃO DE H2S",
      "authors": "Juan David Santos Martinez, Caroline Melo Andrade, Janeth Marlene Quispe Aviles, Duberney Hincapie-Ladino, Neusa Alonso Falleiros, Hercílio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_093.pdf",
      "name": "Avaliação da resistência à corrosão de filmes híbridos de diferentes oligômeros poliédricos de silsesquioxano (POSS) incorporados na resina epóxi aplicados em aço carbono 1010",
      "authors": "Marielen Longhi, Vinicios Pistor, Lucas Zini, Sandra Kunst, Ademir Zattera"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_094.pdf",
      "name": "Efeito do tratamento térmico na resistência a fragilização pelo hidrogênio em aço ASTM A333",
      "authors": "Jonas S. Sá, Rafael S. Gama, José A. C. P. Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_095.pdf",
      "name": "Mecanismo de corrosão proposto a partir das análises eletroquímicas e por SVET para a folha de Flandres revestida com filmes híbridos",
      "authors": "Marielen Longhi, Lucas Zini, Lilian Beltrami, Rosiana Boniatti, Célia Malfatti, Sandra Kunst"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_096.pdf",
      "name": "Influência das propriedades do aço sa e 1045 através de nitretação assistida a plasma e posterior aplicação de nitreto de titânio",
      "authors": "Éverson D. Panizzon, Patrícia Marcolin, Marielen Longhi, Lucas Zini, Cláudia Oliveira, Sandra Kunst, Agonir Wenginowicz, Denis Villarinho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_099.pdf",
      "name": "Electrodeposited TEOS (tetraethyl orthosilicate) as a pretreatment for organic coating on AA 1100 aluminum sheets",
      "authors": "Vitor Bonamigo Moreira, Álvaro Meneguzzi, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_101.pdf",
      "name": "Avaliação das propriedades ignífugas e de resistência à corrosão da tinta em pó epóxi/poliéster com adição de argilominerais",
      "authors": "Natanael Relosi, Oscar A. Neuwald, Ademir J. Zattera, Diego Piazza, Sandra R. Kunst, Eliena J. Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_102.pdf",
      "name": "A influência das concentrações dos precursores BTSE e do flexibilizante polietileno glicol na composição do sol e nas propriedades do filme híbrido",
      "authors": "Marielen Longhi, Leticia Caio, Estela Kerstner, Lilian V. R. Beltrami, Rosiana Boniatti, Tiago L. Menezes, Célia F. Malfatti, Sandra R. Kunst"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_103.pdf",
      "name": "Desempenho da passivação de Cr 3+ em substituição à passivação de Cr 6+ na resistência à corrosão em um processo de galvanoplastia",
      "authors": "Luane Carra, Marielen Longhi, Sandra Kunst, Roberto Mandelli"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_104.pdf",
      "name": "Influência da adição de óleo de soja epoxidado em à base de GPTMS para proteção contra a corrosão da liga de alumínio 7075",
      "authors": "Letícia Caio, Lílian Vanessa Rossa Beltrami, Célia de Fraga Malfatti, Sandra Raquel Kunst, Eliena Jonko Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_105.pdf",
      "name": "Caracterização microestrutural e corrosão de aços microligados com diferentes teores de Mn e Nb",
      "authors": "Janeth Quispe Avilés, Duberney Hincapié-Ladino, Juan S. Martines, Neusa AlonsoFalleiros, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_106.pdf",
      "name": "Influence of differe nt surface cleaning processes to obtaining coatings with silane and tannin for corrosion protection of galvanized steel.",
      "authors": "Bruno Pienis Garcia, Álvaro Meneguzzi, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_107.pdf",
      "name": "Avaliação de biocidas no controle da corrosão microbiologicamente induzida do aço carbono 1020 por bactérias redutoras de sulfato",
      "authors": "Priscila Santos da Silva, Lilian Ferreira de Senna, Márcia M.M Gonçalves, Dalva C. B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_108.pdf",
      "name": "Estudo da Eficácia da Usinagem Fotoquímica dos Aços Inoxidáveis 304 e 430",
      "authors": "Adair G. Souza, Carlos A. M. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_109.pdf",
      "name": "Cério como inibidor de corrosão para aço carbono",
      "authors": "Elber V. Bendinelli, Daniel S. V. Castro, Pedro H. M. Leal e Isabel C. P. Margarit-Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_110.pdf",
      "name": "Avaliação da corrosão por pites em um aço inoxidável supermartensítico em meio contendo íons cloreto e tiossulfato.",
      "authors": "Débora R. Lopes, Mônica M. A. M. Schvartzman, Wagner R . C. Campos, Letícia P. Alves"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_111.pdf",
      "name": "Suscetibilidade a variações de temperatura de revestimentos compósitos anticorrosivos",
      "authors": "Thiago Cordeiro da Silva, Isabel Cristina Pereira Margarit-Mattos, Aquila Vieira do Nascimento"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_115.pdf",
      "name": "Avaliação do extrato de chá verde liofilizado como inibidor de corrosão para bronze em água de chuva artificial",
      "authors": "Gustavo Klinsmann dos Santos Scholz, Mariana dos Reis Aboud, Dalva Cristina Baptista do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_116.pdf",
      "name": "Avaliação do extrato de chá verde liofilizado como inibidor de corrosão para bronze em água de chuva artificial",
      "authors": "Gustavo Klinsmann dos Santos Scholz, Mariana dos Reis Aboud, Dalva Cristina Baptista do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_118.pdf",
      "name": "ELETRODEPOSIÇÃO DE POLIPIRROL DOPADO COM ÂNIONS DODECILBENZENOSULFONATO E VANADA TO SOBRE A LIGA A A 1100",
      "authors": "Viviane Dalmoro, Suelen W. Cendron, Denise S. Azambuja, Kátia R.L. Castagno"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_121.pdf",
      "name": "Avaliação de extrato de folhas de cafeeiro como inibidor de corrosão para aço carbono em meio ácido",
      "authors": "Gustavo Klinsmann dos Santos Scholz, Maria de Lourdes Martins Magalhães, Renato Maciel Campos, Dalva Cristina Baptista do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_122.pdf",
      "name": "Avaliação dos Parâmetros de Deposição de Ligas Zn-Co Eletrodepositadas sobre Aço - carbono em Banhos Contendo Citrato de Sódio e Gelatina",
      "authors": "Felipe G. Nunes, Julyana R. Garcia, A ntonio V. C. Braga, D alva C. B. do Lago e Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_123.pdf",
      "name": "Produção e caracterização de revestimentos de ligas metálicas Cu-Sn em banho eletrolítico contendo glicina: ensaios preliminares",
      "authors": "Priscila Santos da Silva, Dalva C. B. do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_124.pdf",
      "name": "COR-TEN®: Uma história de 50 anos no Brasil",
      "authors": "S.J. Travassos, Célia R. Tomachuk, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_126.pdf",
      "name": "The influence of microstructure and heat treatment of API X 52 carbon steel on hydrogen diffusion",
      "authors": "Rhuãn C. Souza, Luciano R. Pereira, L ucas M. Starling, José A. C. Ponciano, Alysson H. S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_128.pdf",
      "name": "Possibilidade de prever a durabilidade de concreto dosado em central com uma célula de quatro eletrodos usando Espectroscopia de Impedância Eletroquímica.",
      "authors": "M. M. Mennucci, R. B. P. Bendezu, A. P. Alonso, E. Polesello, D. C. C. dal Molin, H. G. de Melo, X. R. Nóvoa"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_129.pdf",
      "name": "Caracterização de revestimento hidrofóbico obtidos por dip-coating em solução sol-gel",
      "authors": "Daiana Guerra Sacilotto, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_130.pdf",
      "name": "Análise de resistência à corrosão de revestimentos compósitos de Zinco e Zinco+ Bentonita com uso da técnica de espectroscopia de impedância eletroquímica",
      "authors": "Marielen Longhi, Ricardo Marpelli, Patrícia Marcolin, Lucas P. Zini,Sandra R. Kunst, William Sciacca Garcia, Célia F. Malfatti, Cláudia T. Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_131.pdf",
      "name": "EIS and Salt -Spray study of AA2524 anodized in tartaric -sulfuric acid bath and protected with hybrid sol–gel coating",
      "authors": "Hellen Costenaro, Fernanda M. Queiroz, Maysa Terada, Yoann Paint, Alexis Renaud, Marie -George Olivier, Isolda Costa, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_132.pdf",
      "name": "Efeito da marcação a laser por fibra óptica na resistência à corrosão de utensílios produzidos em aço inoxidável DIN 1.4110",
      "authors": "Fernando S. Fardo, Alvaro Meneguzzi, Jane Z. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_133.pdf",
      "name": "Caracterização eletroquímica da corrosão influenciada pelos micro -organismos no aço API 5L X80 em água produzida",
      "authors": "Edkarlla S. D. de Oliveira, Roseana F. da C. Pereira, Diogo L. A. e Silva, Alesson I. R. D. da Silva, Maria A. G. de A. Lima, Severino L. U. Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_134.pdf",
      "name": "Potencial corrosivo do óleo diesel B S -10 nos aços inoxidáveis 304, 316L e aço carbono SAE 1020",
      "authors": "Thiago Dalcumune, Aryadne Jesus Picoli, Roberto Balarini Junior, Edson S Nunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_136.pdf",
      "name": "Comportamento à chama e à corrosão do aço AISI 1006 revestido com tinta acrílica contendo montmorilonita e mica moscovita",
      "authors": "Gilmar A. Santa Catarina, Sandra R. Kunst, Diego Piazza, Lisete C. Scienza, Ademir J. Zattera"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_138.pdf",
      "name": "Estudo eletro químico da variação de concentração de nanopartículas de sílica na síntese de revestimentos híbridos siloxanos aplicados sobre aço estanhado.",
      "authors": "Geny F. T. M. Ferreira, Hercílio G. de Melo, Fabíola M. Di L. da Cruz, Patrícia H. Suegama"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_139.pdf",
      "name": "On the oxidation of copper to CuO in air",
      "authors": "Erik Salas, Claudio Méndez, David Jaramillo, Gabriel Plascencia"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_141.pdf",
      "name": "Avaliação de revestimento anticorrosivo orgânico aplicado em válvulas do tipo esfera para operações subsea",
      "authors": "Mateus Faccio, Eduardo Boff, Alexandre da Silva Rocha, Marcelo Favaro Borges, Telmo Roberto Strohaecker, João C. Brancher, Joseane O. B. Canterle, Luiz G. L. Rodrigues e Vagner Sartori"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_142.pdf",
      "name": "Avaliação da formação de biofilmes em aço duplex e super-duplex",
      "authors": "Walter B. Cravo Júnior, Diogo A. Coutinho, Ana C.C. de Albuquerque, Cynthia de A. Andrade, Maíra P. de Sousa, Claudia Groposo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_145.pdf",
      "name": "Influência da variação anual da resistividade elétrica do solo no desempenho de sistema de proteção catódica de dutos terrestres",
      "authors": "José Leonardo Resende"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_146.pdf",
      "name": "Estudo da imidazolina quaternária como inibidor para meios da produção de petróleo",
      "authors": "Alfredo Sahade Vespa, Adriana Cristina Gonçalves-Ricci, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_148.pdf",
      "name": "Avaliação de um aço microligado como material nos depósitos de Diesel Marítimo (DMA)",
      "authors": "Neyda C. O. Tapanes, Ana Isabel C. Santana, Amanda S.M. Oliveira, Euglacyo L. de Moura, Tamara S. Tavares, Yordanka R. Cruz, Rodolfo S. Perez, Donato A.G . Aranda"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_151.pdf",
      "name": "Corrosão em trocador de calor de Zr–702 na Unidade de Reconcentração de Ácido Sulfúrico de empresa petroquímica",
      "authors": "Josiane S. Costa, Victor G. da Silva, Jane Z. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_152.pdf",
      "name": "Superfícies condicionadas por biossurfactantes, avaliação das propriedades anticorrosivas, antiadesivas/anti biofilmes e antimicrobianas",
      "authors": "Lívia V. de Araujo, Carolina Reis Guimarães, Márcia Nitschke, Denise Maria Guimarães Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_153.pdf",
      "name": "Efeito de biocidas na corrosão induzida microbiologicamente do aço carbono em água salina",
      "authors": "Flávia G. D. Ferreira, Pulkra Silva, Pedro H. C. Moraes, Alliny S. S. Alves, Maria Alice G. A. Lima, Sara H. O. Maciel, Severino L.U. Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_154.pdf",
      "name": "Aluminium leaching during tribocorrosion in acidic, alkaline and neutral solutions: cooking simulation",
      "authors": "Giovanni U. Bruno, Álvaro Meneguzzi, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_155.pdf",
      "name": "Emprego do revestimento de conversão à base de zircônio sobre aço zincado por eletrodeposição e posterior tratamento do efluente gerado por nanofiltração",
      "authors": "Josiane S. Costa, Carolina de M. da Trindade, Jane Z. Ferreira, Andrea M. Bernardes"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_156.pdf",
      "name": "TRATAMENTO ANTICORROSIVO À BASE DE TEOS PARA A LIGA DE ALUMÍNIO 2024-T3",
      "authors": "Suelen Weimer Cendron, Cristina Pigozzo, Denise Schermann Azambuja"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_158.pdf",
      "name": "Corrosão do Aço Carbono Devido a Elevação da Concentração de CS 2 (Dissulfeto de Carbono)",
      "authors": "José Raimundo dos S. Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_159.pdf",
      "name": "Estudo do efeito da adição de ácido fórmico em depósitos de Zinco obtidos por eletrodeposição galvanostática",
      "authors": "Carolina S. Lopes, Michele D. Jesus, Carlos S. Caldas"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_161.pdf",
      "name": "Influência dos parâmetros de soldagem FSW sobre o comportamento de corrosão da liga AA2024 -T3",
      "authors": "Rocio del Pilar Bendezu Hernandez, Camila Molena de Assis, Diego Fabri Abrahão , Vincent Vivier, Hercílio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_162.pdf",
      "name": "THIRD GENERATION THPS TO CONTROL SOURING AND MIC IN SIMULATED RESERVOIR CONDITIONS",
      "authors": "Stephanie Edmunds, Chris Jones, Eder Torres"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_165.pdf",
      "name": "Estudo das condições de hidrólise para a obtenção do filme a base de silanos (3-Aminopropil)trietoxisilano (APTES) e (3-Glicidiloxipropil)trimetoxisilano (GPTMS) como pré-tratamento do aço galvannealed na proteção contra a corrosão",
      "authors": "Kleber Gustavo da Silva Souza, Franco Dani Rico Amado, Vera Rosa Capelossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_166.pdf",
      "name": "Implementação de um método eletroquímico para a determinação de quantificação de fases deletérias no aço inoxidável duplex.",
      "authors": "Hudison Loch Haskel, Luciana Schimidlin Sanches, Haroldo de Araújo Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_168.pdf",
      "name": "Influência da temperatura de cura e da dupla camada de um filme híbrido alcoóxido 3 - (trimetoxisililpropil) metacrilato (TMSM) e tetraetoxisilano (TEOS) aplicados sobre a liga de alumínio",
      "authors": "Gabriela Gobbi, Oscar Neuwald, Marielen Longhi, Lucas Zini, Sandra R. Kunst, Ademir J. Zattera"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_169.pdf",
      "name": "Avaliação do efeito da presença de inibidores de corrosão sobre a eficiência de inibidores de incrustação",
      "authors": "Felipe Batista Alvim, Cynthia de Azevedo Andrade, Davi Alves da Costa Ferreira, Mônica de Oliveira Penna"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_170.pdf",
      "name": "Influência de diferentes tratamentos de superfície em ligas de zinco para uso em metais sanitários na resistência a corrosão",
      "authors": "Marielen Longhi, Suzan Arnold, Patrícia Marcolin, Lucas Zini, Sandra Kunst, Claudia Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_171.pdf",
      "name": "Obtenção de superfícies super-hidrofóbicas em ligas de alumínio 5052 visando aumento da resistência à corrosão",
      "authors": "Rodrigo F. da Silva; Magda R. S. Vieira; Edval G. de Araújo; Luma R. R. B. de Vasconcelos, Maria I. C. Malta, Oscar O. de Araújo Filho; Severino Leopoldino Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_172.pdf",
      "name": "Impacto da presença de monocloramina na corrosividade de água de reúso de Refinaria de Petróleo sobre latão almirantado",
      "authors": "Eric Gripa Marques, Maliu Rosa A. da Silva, Vânia M. J. Santiago, Andréa A.Veiga, Sérgio Pagnin, Ladimir J. de Carvalho, Leila Y . Reznik"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_174.pdf",
      "name": "AVALIAÇÃO DA EFETIVIDADE DE PROCEDIMENTOS DE PASSIVAÇÃO UTILIZADOS EM TROCADORES DE CALOR OPERANDO EM SISTEMA DE ÁGUA DE REFRIGERAÇÃO",
      "authors": "Francisco E. Junior, Paulo M.B. Santana, Eduardo Cabral"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_175.pdf",
      "name": "Avaliação da degradação isotérmica no aço inoxidável superduplex UNS S32750 nas velocidades ultrassônicas volumétricas e potencial de corrosão por pite",
      "authors": "Larissa M. de Britto, Ygor T. B. dos Santos, Paulo M. B. de Santana, Ivan C. da Silva, Cláudia T. T, Farias"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_176.pdf",
      "name": "Influência da preparação da superfície química e mecânica em aço carbono para aplicação do revestimento poliuretano de alta resistência",
      "authors": "Andressa C. Xavier, Marielen Longhi, Patrícia Marcolin, Lucas P. Zini, Cláudia T. Oliveira, Sandra R. Kunst"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_179.pdf",
      "name": "PROTEÇÃO CATÓDICA EM FUNDO DE TANQUE DE ARMAZENAMENTO COM MANTA DE POLIETILENO COMO CONTENÇÃO DE VAZAMENTOS.",
      "authors": "Eduardo Chaves Barreto, Laerce de Paula Nunes, Anderson Teixeira Kreischer"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_181.pdf",
      "name": "Estudo de Mecanismo de Corrosão de Latão Almirantado revestido com Resina Epoxídica em Água de Reuso de Refinaria de Petróleo",
      "authors": "Arthur R. de Souza, Ladimir J. de Carvalho, Leila Y. Reznik, Vilma S. S. Motta"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_182.pdf",
      "name": "Influência da concentração de íons cério (III) e (IV) em filmes híbridos na proteção contra a corrosão da liga de magnésio AZ31",
      "authors": "Riberto N. Peres, Maria F . Montemor, Hercílio G. de Melo, Assis V . Benedetti, Patricia H. Suegama"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_183.pdf",
      "name": "Detecção de corrosão em tubos utilizando a técnica não destrutiva por ondas guiadas ultrassônicas.",
      "authors": "Vilander M. F. Kiminami, Ygor T. B. Santos, Ivan C. da Silva, Cláudia T. T. Farias"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_184.pdf",
      "name": "Influência do tempo de ataque ácido da liga de alumínio 5052 para obtenção de superfícies super-hidrofóbicas",
      "authors": "Luma R. R. B. de Vasconcelos; Magda R. S. Vieira; Edval G. de Araújo; Rodrigo F. da Silva; Maria I. C. Malta; Maxime Montoya; Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_185.pdf",
      "name": "Uma abordagem moderna à avaliação de sistemas de Proteção catódica em Tubulações Offshore",
      "authors": "Jim Britton – Presidente &amp; C.E.O. Deepwater Corrosion Services, Houston, TX. Tradução e atualização: Emílio Lana, Msc, PMP, Gerente de Projetos Deepwater do Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_187.pdf",
      "name": "Revestimentos anticorrosivos PMMA -sílica de alta performance",
      "authors": "Samarah Vargas Harb, Andressa de Lourdes Gasparini, Fábio Cesar dos Santos, Celso Valentim Santilli, Peter Hammer"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_188.pdf",
      "name": "Emprego da Técnica de PEC na Detecção de Descontinuidades em Equipamentos Sob Isolamento",
      "authors": "Caio V. S. Batista, Mateus C. R. Rebello, Carla B. F. do Carmo, Ygor T. B. Santos, Ivan C. da Silva, Cláudia T. T. Farias"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_190.pdf",
      "name": "Análise da resistência à corrosão mecânica em revestimentos depositadas pelo processo GMAW-CW, empregados na recuperação de pás de turbinas hidráulicas",
      "authors": "Tárcio dos S. Cabral, Lino A. S. Rodrigues, Sileno S. Dias, Eduardo de M. Braga"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_192.pdf",
      "name": "Resistência à corrosão do aço lean duplex UNS S82441 submetido à soldagem por atrito com pino não-consumível",
      "authors": "Antonio Marcos dos Santos Leite, Maysa Terada, Victor Ferrinho Pereira, Eduardo Bertoni da Fonseca, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_193.pdf",
      "name": "Observação do efeito da concentração de Cloreto e do PH do meio oxidante sobre os processos corrosivos nos aços AISI 304 e 316.",
      "authors": "Francielly Moura de Souza, Renato Santiago, Ana Isabel de Carvalho Santana"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_197.pdf",
      "name": "Influência do dióxido de carbono e cloretos no desempenho de inibidores de corrosão para concretos",
      "authors": "Cristiane Resende, Adriano Fernando Diniz, Patricia Benedini Martelli, Alysson Helton Santos Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_202.pdf",
      "name": "Interferência da exsudação na carbonatação de concretos.",
      "authors": "A. Catusso, M. M. Mennucci, J. J. O. Andrade, D. C. C. Dal Molin"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_203.pdf",
      "name": "Análise da Suscep tibilidade à Corrosão Intergranular de Soldas FSW do Aço Inoxidável Ferrítico AISI 410S Pela Técnica de Reativação Potenciocinética Cíclica (EPR -DL)",
      "authors": "Gerbson de Q. Caetano, Cleiton C. Silva, Marcelo F. Motta, Hélio C. Miranda, Jesualdo P. Farias, Luciano A. Bergmann, Jorge F. dos Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_204.pdf",
      "name": "O efeito da alta concentração de cloreto na corrosão pelo CO 2",
      "authors": "Tatiana C. Almeida, Paulo M. P. Cysne, Pedro V. G. Silva, Rafael F. Garcia, Merlin C. E. Bandeira, Oscar R. Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_206.pdf",
      "name": "Análise da corrosão do Aço API 5LX60 exposto a óleo cru e água do mar",
      "authors": "Felipe A. C. de Melo, Magda R. S. Vieira, Marcy V. C. Màrquez, Tiago V. C. Silva, Sara H. de Oliveira; Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_212.pdf",
      "name": "Influence of small amount of H 2S on the CO 2 corrosion of carbon steel at oil production conditions",
      "authors": "Merlin C. E. Bandeira, Danielle Spigarollo, Rogaciano M. Moreira, Oscar R. Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_215.pdf",
      "name": "Influência de filmes sol-gel com ácido fosfônico na ades ão e proteção contra corrosão de revestimento epóxi sobre liga de alumínio",
      "authors": "Viviane Dalmoro, João Henrique Z. dos Santos, Elaine Armelin, Carlos Alemán, Denise Schermann Azambuja"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_216.pdf",
      "name": "Caracterização e Resistência à Corrosão d e Ligas Fe -W e Fe -Co -W Obtidas por Eletrodeposição",
      "authors": "Francisco J. N. Santos, Gecílio P. Silva, Ricardo S. Cunha, Alessandro A. L. Araújo, Euclides F. C. Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_219.pdf",
      "name": "ESTUDO DA CORROSÃO EM JUNTAS DE SOLDA DO AÇO API 5L X80 EM AMBIENTE MARINHO",
      "authors": "Edivânia S. L. Vasconcelos, Maria A. G. A. Lima, Pulkra Silva, Sara H. Oliveira, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_220.pdf",
      "name": "Estudo da influência da corrosão em filmes a base de acetato de celulose/ TEOS na ação protetora de liga de alumínio",
      "authors": "Tatiana Inhaquite Wollmann, Silvio Luis Pereira Dias, Viviane Dalmoro"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_222.pdf",
      "name": "Visão geral dos inibidores bifuncionais a base de terras raras e suas aplicações",
      "authors": "Célia A. L. dos Santos, Fabiana Y. M. Vieira, Rafael A. Camargo, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_225.pdf",
      "name": "AVALIAÇÃO DO DESEMPENHO DE REVESTIMENTO ANTIADERENTE À BASE DE POLITETRAFLUOROETILENO APLICADO SOBRE SUBSTRATOS DE ALUMÍNIO",
      "authors": "André Adriano Versteg, Eliena Jonko Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_226.pdf",
      "name": "Desenvolvimento de metodologia para avaliação da taxa de corrosão por ácidos naftênicos através da técnica de ruído eletroquímico.",
      "authors": "Ana Carolina Tedeschi Gomes Abrantes, Haroldo de Araújo Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_229.pdf",
      "name": "Eletrodeposição de Filmes de Polipirrol em Meio Orgânico Contendo Ácido Fosfórico",
      "authors": "Andrea Santos Liu, Rodrigo Hilário, Liu Yao Cho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_230.pdf",
      "name": "Utilização da galvanização por imersão a quente em estruturas de Wind Fence",
      "authors": "Luiza Abdala, Daniele B. P. Albagli"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_231.pdf",
      "name": "Comportamento eletroquímico do alumínio AA6060 -T5 em presença de uma mistura de antocianinas extraídas da uva",
      "authors": "Rafaela Spengler, Amanda Petersen, Viviane Dalmoro, Sílvia M. Tamborim"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_232.pdf",
      "name": "AVALIAÇÃO DO GRAU DE CORROSÃO DE CHAPAS DE AÇO CARBONO POR CORRENTE ELÉTRICA",
      "authors": "Renato S. Pinheiro, Cristiano T. Malheiro"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_243.pdf",
      "name": "Análise crítica do ensaio de perda de massa para classificação do grau de agressividade atmosférica",
      "authors": "Caroline Barboza dos Reis, Elber Vidigal Bendinelli, Alberto Pires Ordine, Cristina da Costa Amorim, Yuri Hemerly Poyares Café"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_244.pdf",
      "name": "Avaliação de bórax como inibidor de corrosão para uso em água de hidrojateamento",
      "authors": "Jorge Felipe Ramos Pontes, Elber Vidigal Bendinelli, Cristina da Costa Amorim, Alberto Pires Ordine"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_245.pdf",
      "name": "Avaliação de sistemas de proteção anticorrosiva para manutenção de estruturas enterradas em aço galvanizado",
      "authors": "Marcos Martins de Sá, Alberto Pires Ordine, Wendell Porto de Oliveira, Cristina da Costa Amorim"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_246.pdf",
      "name": "Efeito da preparação de superfície de aço carbono na formação de produtos de corrosão sob tinta epóxi",
      "authors": "Cristina da Costa Amorim, Yuri Hemerly Poyares Café, Marcos Martins de Sá, Alberto Pires Ordine"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_251.pdf",
      "name": "Aplicação de polímero termoplástico como inibidor de corrosão em equipamentos de geometrias complexas",
      "authors": "Thomas Georg Fink, Renato Teixeira de Freitas, Maurélio Nascimento, Alex Herdy Piler"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_252.pdf",
      "name": "Influência do pH na suscetibilidade ao trincamento d e um aço -carbono submetido ao sulfeto de hidrogênio",
      "authors": "Leandro J. Paula, Bruna S. Mingotti, Eduardo N. Codaro, Heloisa A. Acciari"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_253.pdf",
      "name": "Limpeza e preservação de interno de tubulações durante a montagem através de jateamento abrasivo e utilização de inibidor de corrosão",
      "authors": "Fredson O. de Morais, Magda R. S. Vieira, Edval G. de Araújo, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_254.pdf",
      "name": "Corrosion-fatigue tests using strain gauges on the specimen to monitoring the load applied under specimen subject to high pressure.",
      "authors": "Rodrigo R. A. Garcia, Alessandro S. de Lima, Yuri Laet Ramalho, Felipe Cristaldi, Emannuel P. Q. Pereira, Sergio G. Tavares, Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_255.pdf",
      "name": "Sistema de alarem de produtos corrosivos acoplado a sondas de resistência elétrica e resistência de polarização linear – ensaios estáticos.",
      "authors": "Sidney Oswaldo Pagotto Júnior, Neusvaldo Lira de Almeida, Lorena Cristina de Oliveira Tiroel, José Álvaro de Carvalho Albertini, Paulo Roberto V. Moraes, André Luiz Burdin Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_257.pdf",
      "name": "Análise Laboratorial de Espuma Comercial de Poliuretano Usada em Revestimento Térmico de Duto sob Proteção Catódica",
      "authors": "Eduardo G.B. Leite, Denise S. de Freitas, Simone L.D.C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_258.pdf",
      "name": "Determinação da oxidação da liga de cobre 144 imersa em gel despolarizante comumente usado em anodos, pela deconvolução da curva potenciodinâmica catódica seguida da construção de diagrama eletroquímico.",
      "authors": "José M . Silva, João H. Zancanella, Geraldo R. de Almeida, Carlos A. Sotille"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_262.pdf",
      "name": "Influence of heat input and chemical dilution on Critical Pitting Temperature of coatings from Inconel 625 deposited by PTA-P",
      "authors": "Raphael A. Lorenzoni, Ricardo P. Gasparini, Marcelo C. S. Macêdo, Eduardo A. Ponzio"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_264.pdf",
      "name": "Simulações de diferentes configurações de anodos em sistema de proteção catódica por corrente impressa da área externa de fundo de tanques de armazenamento",
      "authors": "Juliana L. Cardoso, Neusvaldo L. Almeida, Fabiano R. Santos, Gutemberg S. Pimenta, Eduardo W. Laurino"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_265.pdf",
      "name": "Influência dos carbonatos e acetatos totais no caráter protetor dos produtos de corrosão do ferro em meios típicos de produção de óleo e gás: uma análise termodinâmica",
      "authors": "Bruno Diehl Neto, Carlos J. B. M. Joia, Ilson Palmieri, Flávia M. F. Guedes, Luciana Lima, Virginie Richoux"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_269.pdf",
      "name": "Study of Corrosion Inhibitor Selection for Amazon Pipelines",
      "authors": "Lyongjin Lee, Jiwon Jung, Suyeon Lee, and Cheolho Kang"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_270.pdf",
      "name": "Síntese e caracterização de microcápsulas estímulo-responsivas contendo óleo de linhaça e benzotriazol para aplicação em revestimentos inteligentes",
      "authors": "Débora Abrantes Leal, Cláudia Eliana Bruno Marino, Izabel Cristina Riegel Vidotti, Mário, Guerreiro Silva Ferreira, Bruno Campos da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_272.pdf",
      "name": "Estudo da corrosão em tubulações de aço carbono utilizadas n a distribuição de água potável em solução de hipoclorito de sódio.",
      "authors": "Reginaldo C. Silva e Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_274.pdf",
      "name": "Quebrando paradigmas: Aplicação de proteção catódica galvânica em um duto de médio porte",
      "authors": "Joao Paulo Klausing Gervasio, Daniel Alves Torres Filho, Alexandre Vicente da Fonseca"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_276.pdf",
      "name": "Avaliação da susceptibilidade à corrosão por pites do aço inoxidável supermartensítico submetido a diferentes tratamentos térmicos",
      "authors": "Camila M. Braga, Dayanna M. Duarte, Marília de M. Lima, Mônica M. de A. M. Schvartzman"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_278.pdf",
      "name": "Avaliação da efetividade de inibidores de corrosão em biodiesel",
      "authors": "Paulo Moura Bispo de Santana, Marilena Meira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_279.pdf",
      "name": "Influência da microestrutura na formação de pite em aço inoxidável supermartensítico",
      "authors": "Daniela C . S. Garcia, Luciana I. L. Lima, Marília M. Lima, Dayanna M. Duarte, Pedro H. B. O. Nogueira"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_280.pdf",
      "name": "Influência da Solução de Tamponamento nos Ensaios de Corrosão sob Tensão por Sulfeto",
      "authors": "Luciana Iglésias Lourenço Lima, Carlos J. B. M. Joia, Bruno Diehl Neto, Ilson Palmieri, Flávia M. F. Guedes, Daniela Garcia, Virginie Richoux"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_283.pdf",
      "name": "Avaliação d a influência da soldagem “Friction Spot Welding” (FSpW) nas propriedades da liga AA5754 na junta soldada AA5754/Ti -6AL-4V",
      "authors": "Guilherme S. Vacchi, Athos H. Plaine, Carlos A . D. Rovere, Sebastião E . Kuri, Nelson G. Alcântara, Jorge dos Santos, Luis F. S. Baroni"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_288.pdf",
      "name": "Electrochemical behavior of a CoCrMo biomedical alloy in simulated body fluids",
      "authors": "Daniel de Castro Girão, Eveline Carvalho de Brito, André Luiz Jardini, Miloslav Béreš, Hamilton Ferreira Gomes de Abreu, Walney Silva Araújo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_289.pdf",
      "name": "Influência do teor de enxofre e dos parâmetros de tratamento térmico na resistência à corrosão do aço 17-4PH",
      "authors": "Sérgio S.M. Tavares, Cássio L. C. Machado, Iara G. Oliveira, Pablo A. Lousada, Manoel R. da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_293.pdf",
      "name": "Caracterização eletroquímica do filme passivo formado em superfície de uma junta soldada por fricção radial de tubos de aço inoxidável supermartensítico",
      "authors": "Luis F. S. Baroni, Rodrigo Silva, Carlos A. D. Rovere, Sebastião E. Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_294.pdf",
      "name": "Avaliação do efeito do envelhecimento a 475 °C por 2000 h na s propriedades de aços inoxidáveis lean duplex",
      "authors": "Rodrigo da Silva, Luis F. S. Baroni, Camila Arana, Sebastião E. Kuri, Carlos A. D. Rovere"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_296.pdf",
      "name": "Avaliação da Resistência à Corrosão nos Aços Inoxidáveis AISI 304 e AISI 316, em Processos de Extração da Cana em Usinas de Açúcar e Álcool.",
      "authors": "Péricles Bosquetti, Luis Fernando Gardenghi, Murício Angeloni, Laura Alves Coelho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_297.pdf",
      "name": "ANÁLISE DA CORROSÃO DE AÇOS INOXIDÁVEIS AISI 430 E AISI 439 EM CORPOS DE PROVA COM DIFERENTES ACABAMENTOS SUPERFICIAIS NO SENTIDO TRANSVERSAL AO DE LAMINAÇÃO.",
      "authors": "Péricles Bosquetti, Ericson Rodrigues de Arruda, Murício Angeloni, José Roberto Garbin, Laura Alves Coelho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_300.pdf",
      "name": "Estudo das camadas calco-magnesianas formadas em superfícies metálicas sob proteção catódica",
      "authors": "Julianne Nezgoda, José Victor Goudar, Simone L. D. C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_301.pdf",
      "name": "Polyamide 12 Liner for Production Lines – Corrosion Protection, Efficient and Sustainable Revitalization of Mature Onshore Pipeline Infrastructures and New Offshore Pipelines",
      "authors": "Camila A. Farias, Daniel Demicoli"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_302.pdf",
      "name": "Polyamide 12 in Extruded Coating Systems for Steel Pipe Protection",
      "authors": "Camila A. Farias, Cristiano Poloni, Erik B. Nunes, Fernanda Mota, Juergen Franosch, Luiz Henrique Duarte, Rainer Goering"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_304.pdf",
      "name": "Comparative Study of Heavy Duty Paint Systems pigmented with different type of Zinc particles",
      "authors": "OtecRiera"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_305.pdf",
      "name": "Análise de falha de tubo de permutador de calor especificado em ASTM A179, pertencente a sistema de tratamento de petróleo",
      "authors": "Patricia Iris S. Peres, Angélica D. Salvador, André S. Pelliccione, Orlandemberg P. Silva, Éder Giuliano C. Vilas Boas, João Marcos O. Rangel, Ana Luiza S. Cesário"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_308.pdf",
      "name": "Corrosão galvânica do par aço-carbono/aço inoxidável em ácido sulfúrico concentrado",
      "authors": "Claudete Silva Barbosa, Neusvaldo Lira de Almeida, Gutemberg de S. Pimenta, Robson Rodrigues Moura, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_310.pdf",
      "name": "Avaliação eletroquímica por EIE de uma liga Al-Zn-Mg",
      "authors": "Emmanuelle S. Freitas, Talita Vida, Clarissa B. Cruz, Thiago Costa; Felipe Bertelli, Amauri Garcia"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_312.pdf",
      "name": "Analises de Parâmetros de Ensaio de Laboratório para Avaliação da Corrosividade de Fluídos de Estimulação Ácida a 110°C",
      "authors": "Javier A C Velasco, Hugo L. de Aquino Keide, Luiz M. F. N. Rodrigues, Rafael F. Barrozo, Flávia M. F. Guedes, Ilson Palmieri Baptista"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_313.pdf",
      "name": "Reposição de espessura por soldagem em tubos de caldeira de recuperação e seu efeito nas propriedades mecânicas, metalúrgicas e químicas",
      "authors": "Camila Pontes Pena, Paulo José Modenesi"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_315.pdf",
      "name": "Soluções sustentáveis para solubilização de resinas Epóxi em tintas de proteção industrial e marítima",
      "authors": "Caio C. S. Molinari, Suelbi Silva, Diana M. Tosto, Sergio Martins"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_317.pdf",
      "name": "Características das técnicas intrusivas e não intrusivas para monitoração de corrosão interna",
      "authors": "Heitor Ruschmann, Caio Pissolato, Anna Maria Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_319.pdf",
      "name": "Influência dos compostos de enxofre na corrosividade da nafta ao cobre e ao aço-carbono",
      "authors": "Vanessa Yumi Nagayassu Ferrari, Neusvaldo Lira de Almeida, Lorena Cristina de Oliveira Tiroel, André Luiz Castro Bonfim, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_321.pdf",
      "name": "Desenvolvimento de soluções sintéticas para simular diferentes tipos de solo para uso em laboratório",
      "authors": "Joseane M. Giarola, Aline Bonaccorsi, Bernardo A. F. Santos, José A. C. P. Gomes, Patricia B. Martelli, Alysson H. S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_324.pdf",
      "name": "Influência dos parâmetros de soldagem FSW sobre o comportamento de corrosão da liga AA2024-T3",
      "authors": "Rocio del Pilar Bendezu Hernandez, Camila Molena de Assis, Diego Fabri Abrahão, Vincent Vivier, Hercílio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2016/INTERCORR2016_325.pdf",
      "name": "Juntas de Isolamento Elétrico Monobloco projetadas para garantir a integridade dos dutos",
      "authors": "Mauro C. Barreto, Renata S.O. Ramos, Gabriela N. Bernardo"
    }
  ]
}

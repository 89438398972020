export const FOTOS_2012 = [
  {
    "src": "gallery/2012/intercorr2012_01.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_02.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_03.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_04.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_05.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_06.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_07.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_08.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_09.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_10.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_11.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_12.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_13.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_14.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_15.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_16.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_17.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_18.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_19.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_20.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_21.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_22.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_23.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_24.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_25.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_26.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_27.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_28.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_29.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2012/intercorr2012_30.jpg",
    "width": 1200,
    "height": 803
  }
];

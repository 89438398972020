export const EDICAO_2008 = {
  "message": '',
  "data": [
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_001.pdf",
      "name": "Efeito da Distribuição da Cementita e de Inclusões em Aços para Esmaltação Vítrea na Resistência ao Defeito “Escama de Peixe”",
      "authors": "Egnalda P. S. Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_002.pdf",
      "name": "Influência da Porosidade da Camada de Fosfato na Resistência à Corrosão de Aços Pintados",
      "authors": "C. H. S. B. Teixeira, E. A. Alvarenga, W. L. Vasconcelos"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_003.pdf",
      "name": "“RECUPERAÇÃO E REFORÇO ESTRUTURAL DE PONTES RODOVIÁRIAS NO RIO GRANDE DO NORTE”",
      "authors": "Fábio Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_004.pdf",
      "name": "“RECUPERAÇÃO E REFORÇO ESTRUTURAL DO COMPLEXO ESPORTIVO DO SESI-RN”",
      "authors": "Fábio Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_005.pdf",
      "name": "NANOTECNOLOGIA APLICADA A TRATAMENTOS SUPERFICIAIS PARA O AÇO CARBONO 1020 COMO ALTERNATIVA AO FOSFATO DE ZINCO",
      "authors": "Kelly Bossardi, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_007.pdf",
      "name": "ANÁLISE DE VARIÁVEIS CRÍTICAS PARA DESSALGAÇÃO DE PETRÓLEOS – DADOS DE CAMPO E TESTES EM LABORATÓRIO",
      "authors": "Daniel L. Cypriano N., Hermano C. M. Jambo, José A. C. P. Gomes, Jorge E. Paredes M. e Reinaldo V. Garcia"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_008.pdf",
      "name": "Avaliação da resistência a corrosão em água com detergente enzimático: Comparação entre o aço inoxidável supermartensítico com o martensítico convencional",
      "authors": "César Augusto Duarte Rodrígues, Tomaz Puga Leivas, Ivani Aparecida Nunes, João Manuel Domingos de Almeida Rollo, Germano Tremiliosi Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_009.pdf",
      "name": "CÊRA DE HIDROCARBONETO COMO REVESTIMENTO ANTI-CORROSIVO DE TUBOS E ACESSÓRIOS - APLICADA A FRIO COM TRATAMENTO DE SUPERFÍCIE CLASSE SP 2",
      "authors": "Ricardo Collela, Thomas J. Weber, Luiz Octávio Q. Fonseca Jr"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_010.pdf",
      "name": "Efeito da adição de íons Cu(II) na eficiência inibidora do benzotriazol (BTAH) para diferentes aços-carbono em meio de ácido sulfúrico",
      "authors": "Juliana V. Custódio, Isis. V. de S. Santos, Silvia M. L. Agostinho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_012.pdf",
      "name": "AVALIAÇÃO DA PROTEÇÃO DE FILMES DE INIBIDOR DE CORROSÃO ATRAVÉS DO ESTUDO COMPARATIVO ENTRE O SINERGISMO DE Zn(II) E Ca(II) COM O HEDP",
      "authors": "Uara Sarmenghi Cabral, Lúcio Sathler, Leila Yone Reznik"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_013.pdf",
      "name": "GERENCIAMENTO DA CORROSÃO INTERNA DOS DUTOS DA TRANSPETRO",
      "authors": "Carlos Alexandre Martins da Silva - Transpetro/SE/ENG/INTEG/DUTO(1)<br />\nEduardo Wlaudemir Laurino - Transpetro/SE/ENG/STSPCO/INSP(2)<br />\nLásaro Moreira de Andrade – Transpetro/SE/ENG/ST/NNE/INSP(3)<br />\nSergio Santos Twardowski Pinto – Transpetro/SE/ENG/STSUL/INSP(4)<br />\nVitor Manuel do Espírito Santo Silva - Transpetro/SE/ENG/SE/INSP(5)<br />\nGutemberg de Souza Pimenta – CENPES/TMEC(6)<br />\nÁlvaro Augusto Oliveira Magalhães – CENPES/TMEC(6)"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_015.pdf",
      "name": "Planejamento Estatístico fatorial como ferramenta para identificação de efeitos sinérgicos em uma mistura de inibidores de corrosão não tóxicos.",
      "authors": "Glorys Villarroel de Bueno, Maria E S Taqueda, Isabel C Guedes"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_016.pdf",
      "name": "Avaliação da Resistência à Corrosão de Aços IF, Revestidos com Zinco e Ligas de Zinco-ferro, Fosfatizados e Pintados na Indústria Automobilística",
      "authors": "Rodrigo J. A. Marques, Evandro de Azevedo Alvarenga, José Geraldo Moreira, G. Cristina D. de Godoy, Mônica M. A. M. Schvartzman"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_017.pdf",
      "name": "Estudo da viabilidade da adição de nióbio ao aço ISO 5832-9 aplicado em implantes ortopédicos",
      "authors": "Ruth Flavia Vera Villamil Jaimes, Mónica Luisa Chaves de Andrade Afonso, Alexandre Sokolowski, Sizue Ota Rogero, Silvia Maria Leite Agostinho e Celso Antonio Barbosa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_018.pdf",
      "name": "EXPERIÊNCIA COM A APLICAÇÃO DE TÉCNICA NÃO INTRUSIVA DE MONITORAÇÃO DA CORROSÃO INTERNA DE DUTOS",
      "authors": "Décio G. Peixoto, Gutemberg S. Pimenta, Alvaro A. O. Magalhães, Marcelo Araujo, Carlos Alexandre M. Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_019.pdf",
      "name": "Statistical Studies on the Distribution of Cathodic and Anodic Areas",
      "authors": "J.P.Torres, J.C.S. Fernandes, A.S.Castela"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_020.pdf",
      "name": "Avaliação da corrosão do aço ferrítico 444 tratados termicamente em meios ácido e neutro",
      "authors": "Régis L. Melo, Rafael R. Portela, Gustavo L. Vaz, Walney S. Araújo, Sérgio S. M. Tavares, Pedro de Lima-Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_021.pdf",
      "name": "Estabilidade do Óxido de Alumínio Sobre Al em Misturas Etilenoglicol / Líquido Iônico BMI.BF4",
      "authors": "Emilse Maria Agostini Martini, Fernanda Trombetta da Silva, Roberto Fernando de Souza, Michèle Oberson de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_022.pdf",
      "name": "Avaliação do efeito de particulados sólidos na eficiência de um inibidor de corrosão recomendado para meios salinos com CO2",
      "authors": "Michele P. Távora, Jardel D. Cunha, Allison A. R. Silva, Thiago R. Rocha, Eduardo P. M. Coelho, Djalma R. Silva, Pedro A. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_023.pdf",
      "name": "Avaliação de aminoácidos com grupo mercapto como inibidores de corrosão do aço carbono 1010 em meio ácido",
      "authors": "Roger A. Gomes, Pedro de L. Neto, Adriana N. Correia"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_024.pdf",
      "name": "Estudo de tintas epóxi à base d’água utilizando pigmentos anticorrosivo atóxicos.",
      "authors": "Francisco I. A. Fernandes, Gérson S. Estumano, Lucio W. Vasconcelos, Pedro de L. Neto, Roger A. Gomes, Walney S. Araújo, Adriana N. Correia"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_025.pdf",
      "name": "Estudo do efeito do benzotriazol e seus derivados contra a corrosão do cobre em meio de cloreto",
      "authors": "Nívea Boechat dos Santos Gloria, Maritza Angélica Paez Collio, Isabel Cristina Pereira Margarit-Mattos, Flávio Vieira Vasques de Souza, Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_026.pdf",
      "name": "SELEÇÃO DE INIBIDORES DE CORROSÃO PARA DUTO SUBMARINO DE GÁS ÚMIDO E NÃO ASSOCIADO EM CONDIÇÃO DE ALTA VELOCIDADE",
      "authors": "Álvaro A.O.Magalhães, Ricardo M.Guisso, Rosane F. Brito, Eva M. O. Paiva, Fabrício Torres, André L. C. Bonfin"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_028.pdf",
      "name": "Avaliação da corrosão e caracterização das ligas eletrodepositadas de Níquel e Cromo",
      "authors": "Glaydson L. F. Mendonça, Rafael R. Portela, Pedro de Lima Neto, Adriana Nunes Correia"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_029.pdf",
      "name": "O COMPORTAMENTO DO AÇO AISI 321 QUANDO SUBMETIDO A TRATAMENTOS TÉRMICOS DE SENSITIZAÇÃO PARA TEMPOS CURTOS",
      "authors": "Samuel F. Rodrigues, José C. Cardoso Filho, Auro A.Tanaka, Regina C. de Sousa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_030.pdf",
      "name": "CONTROLE DA CORROSÃO INTERNA EM PLANTAS PETROQUÍMICAS DE 1ª e 2ª GERAÇÕES UM MODELO TÉCNICO-GERENCIAL PARA A REALIDADE BRASILEIRA",
      "authors": "Reinaldo R. Nascimento, Sérgio. M. Moretti"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_032.pdf",
      "name": "Comportamento Electroquímico da Liga de NiTi",
      "authors": "N. Figueira, T. Moura e Silva, M. J. Carmezim, J.C.S. Fernandes"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_033.pdf",
      "name": "OCORRÊNCIA DA FRAGILIZAÇÃO INDUZIDA POR METAL LÍQUIDO EM AÇOS INOXIDÁVEIS AUSTENÍTICOS E FERRÍTICOS.",
      "authors": "Guilherme Marconi Silva, Alexandre Queiroz Bracarense"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_034.pdf",
      "name": "DESENVOLVIMENTO DE MÉTODO DE ENSAIO PARA AVALIAÇÃO DA RESISTÊNCIA À CORROSÃO DE FECHADURAS DE EMBUTIR COMERCIALIZADAS NO BRASIL",
      "authors": "FERNANDES-HACHICH, Vera, VAIDERGORIN, Evelyne, RIBEIRO, Maíse V., RIBEIRO, Edwiges"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_035.pdf",
      "name": "Investigação Eletroquímica do Aço AISI 347 Sensitizado Utilizando Técnicas de Polarização e Impedância Eletroquímica",
      "authors": "Renata S. da Conceição, Clairon L. Pinheiro, Regina C. de Sousa, José C. Cardoso Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_039.pdf",
      "name": "Estudo da Corrosão e Microdureza de Eletrodepósitos de Ni-Mo-P",
      "authors": "Régis Lopes Melo, Gustavo Leitão Vaz, Rafael Ribeiro Portela, Adriana Nunes Correia, Pedro de Lima- Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_040.pdf",
      "name": "Corrosão do Combustível do Reator Nuclear de Pesquisas IEA-R1 do IPEN",
      "authors": "Michelangelo Durazzo, Lalgudi V. Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_042.pdf",
      "name": "Corrosão Intergranular em Ligas de Alumínio de Fundição AlSiMg e AlCu",
      "authors": "Guilherme Ourique Verran, Ana Carolina de Moraes"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_043.pdf",
      "name": "Avaliação do Ácido Glicônico como Inibidor de Corrosão para Ligas de Alumínio 1200L",
      "authors": "Nelson Medeiros Lima Filho, Eliane Bezerra de Moraes Medeiros"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_044.pdf",
      "name": "AVALIAÇÃO DA CORROSÃO EM MATERIAIS USADOS EM SISTEMAS DE RESFRIAMENTO",
      "authors": "Alexandre Galvão B. de Mello, Simone Louise D. C. Brasil, Sérgio S. M.Tavares"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_046.pdf",
      "name": "Pigmentos Lamelares Naturais",
      "authors": "Christian Hainfellner, Alex A. S. Areas, Isac Castilho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_047.pdf",
      "name": "Influência do Teor de Cobre no Aço Carbono Quanto à Resistência à Corrosão dos Filmes Formados em uma Câmara de Corrosão Atmosférica",
      "authors": "Daniel Dalmau Jordà, Isabel Correia Guedes"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_048.pdf",
      "name": "Avaliação da corrosividade de um sistema de baixa pressão em unidade de hidrotratamento: um estudo de caso",
      "authors": "Gerardo Jesus Aracena Pérez, Cícero Roberto de Oliveira Moura"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_049.pdf",
      "name": "Priorização de Reparos em Falhas de Revestimento",
      "authors": "Daniel T. Filho, Osimar A. Simião, Carlos A. Peixoto, Alexandre Fonseca"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_051.pdf",
      "name": "Avaliação de desempenho de verniz acrílico e verniz poliuretano antipichação como revestimento de proteção às estruturas de concreto aparente",
      "authors": "Adriana Araujo, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_052.pdf",
      "name": "IDENTIFICAÇÃO DE CORRENTES DE INTERFERÊNCIA EM TORRES DE LINHAS DE TRANSMISSÃO DE ENERGIA ELÉTRICA",
      "authors": "José M. Silva, Luiz A.Lacerda, Roberta B.Boszczowski, Edson S. Dias, Johannes P. Friedrich"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_053.pdf",
      "name": "Avaliação Anticorrosiva de Tintas de Fundo Ricas em Zinco, Monocomponente, com Resina de Poliisocianato",
      "authors": "Fernando de Loureiro Fragata, Alberto Pires Ordine"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_054.pdf",
      "name": "Efeito do perfil de rugosidade de substratos de aço-carbono na medição de espessura de revestimentos anticorrosivos por pintura",
      "authors": "Alberto Pires Ordine, Fernando de Loureiro Fragata, Mauro Zanini Sebrão"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_055.pdf",
      "name": "AGRESSIVIDADE DO SOLO EM MATERIAIS METÁLICOS DEVIDO À REAÇÃO CATÓDICA DE REDUÇÃO DO OXIGÊNIO",
      "authors": "José M. Silva, Luiz A.Lacerda, Roberta B. Boszczowski, Rosane M. Ribas"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_056.pdf",
      "name": "Estudo para verificação da possibilidade de substituição do aço inoxidável martensítico fundido de componentes internos de válvulas tipo gaveta usadas em refinarias de petróleo por aço inoxidável dúplex fundido",
      "authors": "Carlos Alberto da Silva, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_057.pdf",
      "name": "Determinação da microestrutura e microtextura das camadas de óxido formadas no aço Fe-Cr-Mo em altas temperaturas.",
      "authors": "Luis Flávio Gaspar Herculano, Marcelo José Gomes da Silva, Hamilton Ferreira Gomes de Abreu e Pedro de Lima Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_058.pdf",
      "name": "Estudo Do Oxiânion Molibdato Como Inibidor De Corrosão Localizada Do Aço Inoxidável Austenítico 304l Em Água De Alta Pureza Contendo Cloretos Em Condições Hidrodinâmicas Controladas",
      "authors": "S. L. Castanheiro, P.H. Suegama, I. V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_059.pdf",
      "name": "APLICACIÓN DE LA TÉCNICA DE RUIDO ELECTROQUÍMICO AL ESTUDIO DE PINTURAS ANTICORROSIVAS",
      "authors": "Roberto Romagnoli, Marta Cecilia Deyá, G. Blustein, Beatriz del Amo"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_060.pdf",
      "name": "Estudo eletroquímico da influência das condições de hidrólise na obtenção de um filme polissilânico dopado com Ce (iv) na proteção contra corrosão do aço carbono “galvannealed”",
      "authors": "V. R. Capelossi, P.H.Suegama, I. V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_061.pdf",
      "name": "PERFORMANCE OF BENZOATE -BASED EPOXY COATINGS UNDER SIMULATED MARINE CORROSION CONDITIONS",
      "authors": "Guillermo Blustein, Roberto Romagnoli, Alejandro Di Sarli, Beatriz del Amo"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_062.pdf",
      "name": "ESTUDO DE POSICIONAMENTO DE AMOSTRAS DE APARELHOS HIDRÁULICOS EM ENSAIO DE RESISTÊNCIA À CORROSÃO",
      "authors": "FERNANDES-HACHICH, Vera., VAIDERGORIN, Evelyne, RIBEIRO, Edwiges"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_063.pdf",
      "name": "Caracterização da Corrosividade de Solos Através de Eletrólitos Produzidos a Partir de Amostras In Natura (B)",
      "authors": "Carlos Alberto Martins Ferreira, José Antônio da C. Ponciano Gomes, Denise S. de Freitas"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_064.pdf",
      "name": "PMCI – Plano de Monitoração da Corrosão Interna, Uma História de Sucesso na Unidade de Negócios da Bacia de Campos",
      "authors": "Orlandemberg Pereira Silva, Angélica Dias Salvador, Dilhermando Finamore, Edil do Patrocínio, Elcione Simor, Pablo Barreto, Patrícia Íris Peres, Sérgio Pagnin"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_066.pdf",
      "name": "Zinc/tin alloy coating for improved corrosion protection",
      "authors": "Marco Roesch, Camila Boin"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_068.pdf",
      "name": "LEITOS DE ÂNODOS COM ALTÍSSIMA RESISTÊNCIA DE CONTATO AO SOLO",
      "authors": "Francisco Müller Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_069.pdf",
      "name": "Contaminação superficial do aço-carbono zincado por imersão a quente cromatizado e não-cromatizado com íons cloreto",
      "authors": "Gislaine M. Bragagnolo, Jean V. Ferrari, Fernando de L. Fragata; Mário Carlos Andreoli, Márcio Bispo de Almeida, Zehbour Panossian, Neusvaldo Lira de Almeida"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_070.pdf",
      "name": "Pré-tratamento para pintura do aço-carbono zincado por imersão a quente",
      "authors": "Gislaine M. Bragagnolo, Jean V. Ferrari, Mário Carlos Andreoli, Fernando de L. Fragata, Márcio Bispo de Almeida, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_071.pdf",
      "name": "Revestimentos orgânicos para proteção anticorrosiva de estações certificadas pela ISO 14000",
      "authors": "Neusvaldo Lira de Almeida, Mirela Talarico, Francisco J.S. Siqueira, Mauro Kenji Mori"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_072.pdf",
      "name": "A comparative study of anticorrosive paints containing polyphosphates",
      "authors": "Beatriz del Amo, G. Blustein, Marta Cecilia Deyá, Roberto Romagnoli"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_073.pdf",
      "name": "Verificação da Ocorrência de HTHA em Tubulação A-106B",
      "authors": "Paulo Pio Alvisi, Helder de Souza Werneck"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_074.pdf",
      "name": "Avaliação de Corrosão em Baterias Chumbo-ácida",
      "authors": "Maria do Rosário F. Hurtado, Maria de Fátima N. C. Rosolem, Raul Fernando Beck, Maria Angela B. B. Lance, Regina Célia G. Comar"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_075.pdf",
      "name": "Efeito sinergístico entre o molibdato e a cisteína na inibição da corrosão do zinco em meio de cloreto.",
      "authors": "Regilany Paulo Colares, Régis Lopes Melo, Roger Almeida Gomes, Adriana Nunes Correia, Pedro de Lima- Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_076.pdf",
      "name": "AVALIAÇÃO DA RESISTÊNCIA À CORROSÃO DO AÇO AISI – 1020 REVESTIDO COM A MISTURA DE PÓS Ni-Cr-B-Si-C + ZrO3 + Y2O3: APLICADA POR ASPERSÃO TÉRMICA. ",
      "authors": "Carlos Alberto Picon, Luiz Carlos Casteletti, César Augusto Duarte Rodrigues, Germano Tremiliosi Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_077.pdf",
      "name": "Caracterização morfológica e eletroquímica dos fosfatos monobásico, dibásico e tribásico como inibidores da corrosão do Zn em meio de cloreto.",
      "authors": "Colares, R.P., Vaz, G.L., de Lima Neto, P., Correia, A.N., Nascente, P.A.P"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_078.pdf",
      "name": "Influências da Inversão da Molhabilidade óleo-água no processo corrosivo de um oleoduto de exportação em plataforma off-shore",
      "authors": "Patrícia I.S. Peres, Angélica D. Salvador,Orlandemberg P. Silva, Sérgio Pagnin, José A.S. Michelli Fh."
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_079.pdf",
      "name": "New heterocyclic compounds as corrosion inhibitors for carbon steel in 1 mol L -1 HCl",
      "authors": "Roberto S. Amado, Vanessa Vasconcelos Torres, Fernanda Baleixo Silva, Maurício Lanznaster, Marciela Scarpellini and Eliane D’Elia"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_080.pdf",
      "name": "Corrosão em Aços Inoxidáveis Austeníticos em Água de Refrigeração - Efeitos da Temperatura e da Adição de Inibidores",
      "authors": "Elizabete R. C. Leão, José Antônio da C. Ponciano Gomes, Jéssica G. Porto, Roane F. Davilla"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_081.pdf",
      "name": "CORROSÃO POR FADIGA EM TUBULAÇÃO DE CALDEIRA",
      "authors": "Sidney Oswaldo Pagotto Júnior, Zehbour Panossian, Rafael Barreto e Adriano Buhr"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_083.pdf",
      "name": "Applicability of Internal Corrosion Direct Assessment for Subsea Gas Pipelines",
      "authors": "Helio Alves, Tor Traeland, Oliver Moghissi"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_085.pdf",
      "name": "Field experiences with a high resolution corrosion monitoring system",
      "authors": "Elias Chirico"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_087.pdf",
      "name": "Foto-degradação do concreto betuminoso usinado a quente",
      "authors": "Maria de Fátima A S. Araújo, Vanessa F. C. Lins, Maria I. Yoshida, Vany P. Ferraz, Daniel M. Andrada, Fernando S. Lameiras"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_089.pdf",
      "name": "Efeito da concentração de cloretos na corrosão das ligas de alumínio 2024-T3 e 7050-T7451",
      "authors": "Jean V. Ferrari, Flávio de S. Costa, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_090.pdf",
      "name": "Monitoração da corrosão através de técnicas eletroquímicas",
      "authors": "Célia R. Tomachuk, Rolf Jansen"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_093.pdf",
      "name": "Utilização do Ensaio de Corrosão Fadiga na Avaliação de Inibidores de Corrosão",
      "authors": "Flávio Vieira Vasques de Sousa, Rafael Oliveira da Mota, Rafael da Silva Gama, Toseli de Farias Matos, Carlos José Bandeira de Melo Joia, Fabrício Pinheiro dos Santos, Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_095.pdf",
      "name": "Avaliação da corrosão do cobre puro quando imerso em água de chuva sintética de São Paulo e do Rio de Janeiro",
      "authors": "Rocio Del Pilar Bendezù Hernandez, Marina Martins Mennucci, Hercilio Gomes de Melo e Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_096.pdf",
      "name": "Busca de substitutos para o cádmio: ensaios de exposição natural",
      "authors": "Anna Ramus Moreira, Zehbour Panossian, Lisandro Maranho Rodrigues, Gutemberg de Souza Pimenta, Nara Guidacci Berry"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_097.pdf",
      "name": "Desenvolvimento de ligas Ti-Mo para aplicações biomédicas: Caracterização microestrutural e eletroquímica",
      "authors": "Nilson T. C. Oliveira, Antonio C. Guastaldi"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_100.pdf",
      "name": "Anticorrosive and flame resistant coatings",
      "authors": "Carlos. A. Giudice, Andrea M. Pereyra y Héctor. A. Videla"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_101.pdf",
      "name": "Avaliação de metodologias rápidas pa ra quantificação de APS redutase: Etapa I - Extração de Proteínas",
      "authors": "Fatima V. Pereira-Meirelles, Ivani, S. Bott, Lucas T. M. Silva, Maria Isabel P. da Silva, Mayara M.de Andrade Monica de O. Penna"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_102.pdf",
      "name": "UTILIZAÇÃO DE UM AMINO BISSILANO COMO PRÉ-TRATAMENTO DE AÇO CARBONO EM SISTEMA DE PINTURA ELETROFORÉTICA",
      "authors": "Paulo Renato de Souza, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_103.pdf",
      "name": "Hidrojateamento e nova geração de tinta sem solventes tolerante à humidade: o que há de novo e suas implicações para o estaleiro do futuro",
      "authors": "João Azevedo, Mario P. De Paiva"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_104.pdf",
      "name": "Estudo De Revestimentos Híbridos Via Processo Sol-Gel Como Pré-Tratamento Para Proteção Contra Corrosão Do Aço Carbono",
      "authors": "Cristiane R. Martins, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_105.pdf",
      "name": "Estudo das variações de pH nos ensaios de corrosão",
      "authors": "Sérgio E. A. Filho, Zehbour Panossian, Vanessa Y. Nagayassu, Neusvaldo L. de Almeida, Eduardo W. Laurino, Gutemberg de S. Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_106.pdf",
      "name": "Proposição de um mecanismo e de um critério de previsão de corrosão por corrente alternada em dutos enterrados",
      "authors": "Sérgio E. A. Filho, Zehbour Panossian, Neusvaldo L. de Almeida, Mário L. Pereira Filho, Diogo L. Silva , Eduardo W. Laurino, João Hipólito de L. Oliver, Gutemberg de S. Pimenta, José Álvaro de C. Albertini"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_107.pdf",
      "name": "Estudo de corrosão por corrente alternada em dutos instalados em corredores com linhas de transmissão elétrica",
      "authors": "Sérgio E. A. Filho, Zehbour Panossian, Neusvaldo L. de Almeida, Mário L. Pereira Filho, Eduardo W. Laurino, João Hipólito de L. Oliver, Gutemberg de S. Pimenta, José Álvaro de C. Albertini"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_108.pdf",
      "name": "Influence of the oxide interlayer on the electrochemical behavior of hydroxyapatite-coated titanium used as biomaterial",
      "authors": "Heloisa A. Acciari, Luci C. de O. Vercik, Márcio L. dos Santos, Antonio C. Guastaldi"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_109.pdf",
      "name": "Estudo Eletroquímico Da Influência Dos Parâmetros De Processamento Sol-Gel E A Proporção Inorgânica/Orgânica Na Formação De Um Filme Híbrido De Siloxano-Pmma Sobre Aço Carbono 1010",
      "authors": "P. H. Suegama, V. H. V. Sarmento, C. V. Santilli, I. V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_110.pdf",
      "name": "O uso de revestimentos tolerantes a baixos p erfis de rugosidade (surface tolerant) para prevenir corrosão externa, aplicados sobre superfícies quentes ou frias (molhadas), sem parada operacional",
      "authors": "Diego G. Hita, Pablo Hita"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_111.pdf",
      "name": "O uso de polímeros na recuperação estrutural de tubulações e vasos de pressão, atendendo as normas ASME PCC-2 e ISO TS 24817\"",
      "authors": "Pablo A. Hita, Diego G. Hita"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_112.pdf",
      "name": "INTERNAL CORROSION REMOTE MO NITORING ON PIPELINES",
      "authors": "Carlos Alexandre Martins da Silva, Álvaro Augusto Oliveira Magalhães, Marcelo Araújo, Kjell R Wold"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_113.pdf",
      "name": "Aplicação de Revestimento Poliuretânico Flexível em Condutos Forçados de Usinas Hidrelétricas",
      "authors": "Carlos Roberto Mário de Souza, Walter José de Miranda, João Camilo Cardoso"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_114.pdf",
      "name": "Oxidation kinetics of Fe-Cr-and Fe-Cr-Ni alloys for SOFC interconnect applications at emperatures from 600ºC to 800ºC (b)",
      "authors": "Vanessa F. C. Lins, Maria M.R. Castro, Alice G. Miranda, Matheus S. d’Assunção, Guilherme Martins, Thiago M. Gomes, Daniel M. Muzzi, Samuel T. de Paula Andrade, Túlio Matencio"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_115.pdf",
      "name": "Comportamiento electroquímico del acero 1018 en un concreto de activación alcalina parcialmente carbonatado",
      "authors": "W. Aperador, R. Mejía de Gutiérrez, E. Vera"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_120.pdf",
      "name": "Repeatability of corrosion parameters for titanium-molybdenum alloys in 0.9% NaCl solution",
      "authors": "Marisa V. Capela, Heloisa A. Acciari, Jorge Manuel V. Capela, Thaísa M. Carvalho and Maria Cecília S. Melin"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_121.pdf",
      "name": "Estudo comparativo da corrosão do aço-carbono na presença e ausência de microorganismos em diferentes condições de salinidade.",
      "authors": "Thiago Rocha dos S. Mathias, Mariana Machado Galvão, Patrícia Silva Guimarães, Eliana Flávia C. Sérvulo, Simone L.D.C.Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_122.pdf",
      "name": "O SEU DESAERADOR TEM A PERFORMANCE ESPERADA",
      "authors": "Antonio Sergio B. Neves, Francisco A. Passos"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_123.pdf",
      "name": "The Erosion-Oxidation Behavior of Commercial Steels AISI 1020, 410, 304 and 310 at High Temperatures",
      "authors": "Stela M.C.Fernandes, Olandir V. Correa, Lalgudi V. Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_124.pdf",
      "name": "Nanocrystalline rare earth oxide coatings fo r improved H.T. oxidation resistance of chromia forming alloys",
      "authors": "Stela Maria Cristina Fernandes, Lalgudi Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_125.pdf",
      "name": "Avaliação da Corrosão sob Tensão em Juntas Soldadas de Aço ASTM A-508 e AISI 316L em Ambiente de Reator Nuclear Tipo PWR",
      "authors": "Mônica M. A. M. Schvartzman, Wagner R. C. Campos, Marco Antônio D. Quinan, André César J. Castro, Raphael Gomes de Paula"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_129.pdf",
      "name": "TRIBOCORROSÃO DA LIGA TI 6AL4V EM SOLUÇÃO PBS",
      "authors": "Taíse M. Manhabosco, Iduvirges L. Muller"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_130.pdf",
      "name": "Avaliação da Tenacidade à Fratura de Dois Aços Inoxidáveis Super Duplex em Água do Mar Sintética com Aplicação de Proteção Catódica.",
      "authors": "Raphael Aragonês Leite, Charles Guilherme Kuhn, Jeffrey Lowe, Walmar Baptista, Rosane Fernandes de Brito, Fabrício Pinheiro do Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_132.pdf",
      "name": "Efeito do Potencial de Proteção Catódica na Corrosão Microbiologicamente Induzida",
      "authors": "Mariana M. Galvão, Eliana F.C. Sérvulo, Simone L.D.C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_133.pdf",
      "name": "Estudo de inibidores de corrosão para o aço inoxidável (martensítico) super 13% Cr em meio ácido: caracterização eletroquímica",
      "authors": "Mónica Luísa Chaves de Andrade Afonso, Pedro Alves Machado, João Crisósthomo de Queiroz Neto, Silvia Maria Leite Agostinho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_134.pdf",
      "name": "Estudo do comportamento corrosivo da liga de magnésio AZ91 revestida com metiltrietóxi silano dopado com íons cério (III) em meio de sulfato",
      "authors": "Denise S. Azambuja, Patrícia dos S. Correa, Gabriela E. Negruni"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_137.pdf",
      "name": "Efeito do Nb e do Mo na resistência à corrosão, e da corrosão na densidade de saturação magnética das ligas Fe-M-Zr- B-Cu(M = Nb,Mo) nanocristalinas.",
      "authors": "Carlos Alberto Caldas de Souza, Roberto Jorge de Câmara Cardoso, Luiz Rogério Pinho de Andrade Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_142.pdf",
      "name": "Caracterização eletroquímica e analítica de bicamadas Ce-silano modificadas com nanopartículas de sílica e /ou íons cério sobre a liga Al 2024-T3",
      "authors": "Luis M. Palomino, Patrícia H. Suegama, M. Fátima de Montemor, Idalina V. Aoki, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_144.pdf",
      "name": "Enxofre Elementar em Sistemas de Transporte de Gás Natural",
      "authors": "Milagros Guillen Núñez, Denise S. de Freitas, Caetano Moraes, Jussara de Mello Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_145.pdf",
      "name": "Melhoria de Desempenho em Ensaios de Descolamento Catódico com Revestimentos de Polietileno em Três Camadas",
      "authors": "João Riego Filho, Benedito Carlos Cavalheiro, Jorge Maurício da Veiga Taves"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_147.pdf",
      "name": "Estudo do Comportamento do Banho Eletrolítico para Eletrodeposição da Liga Co-Mo Resistente à Corrosão",
      "authors": "Otávia M. S. Ribeiro, Cristiane K.O. F. Vieira, Aldrighi L. M. Oliveira, Gecilio P. da Silva, Renato A. C. Santana, José J. N. Alves, Shiva Prasad"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_150.pdf",
      "name": "Avaliação da substituição do Ni em banhos de fosfatização por oxalato de nióbio e amônio e benzotriazol na proteção contra a corrosão do aço-carbono 1010",
      "authors": "E. P. Banczek, P. R. P. Rodrigues, I. Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_154.pdf",
      "name": "Estudo da Corrosão de Aço Carbono em Misturas de B5 e B20",
      "authors": "Denise S. Azambuja, Yara P. Silva, Clarisse M. S. Piatnicki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_155.pdf",
      "name": "Avaliação da Formação de Biofilmes e Biocorrosão em Cupons AISI 304L Imersos em Água Salina.",
      "authors": "Glória Mª Vinhas, Severino L. Urtiga F, Sara H. de Oliveira, Yêda M. B. de Almeida, Cristhiane M. de Andrade, Flávia R. do Nascimento, Jonatas B. Xavier, Lívia A.Santos Maria Alice G. de Andrade Lima, Francisca P.de França"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_156.pdf",
      "name": "Desempenho de tintas aplicadas sobre aço zincado por imersão a quente com diferentes níveis de contaminação",
      "authors": "Gislaine M. Bragagnolo, Jean V. Ferrari, Mário Andreoli, Fernando de L. Fragata, Márcio Bispo de Almeida, Zehbour Panossian, Neusvaldo Lira de Almeida"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_157.pdf",
      "name": "Estudo da Biocorrosão em Cupons Metálicos Expostos a Ambiente Marinho.",
      "authors": "Maria Alice G. de Andrade Lima, Sara H. de Oliveira, Maria de Los Angeles P. F. Palha, Glória Mª Vinhas, Severino L. Urtiga F, Alice Alexsandra S. Vieira, Lívia A. Santos, Mateus R, dos Santos, Francisca Pessoa de França"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_161.pdf",
      "name": "INTERFERÊNCIAS ELÉTRICAS EM CORRENTE ALTERNADA E SUAS INTERFACES COM A PROTEÇÃO ANTICORROSIVA",
      "authors": "Eduardo Chaves Barreto, Mauro Chaves Barreto, Laerce de Paula Nunes, Gilberto dos Santos Pires Jr."
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_162.pdf",
      "name": "Monitoramento “on line” da Corrosão em Dutos por Impedância Eletroquímica",
      "authors": "José R. P. Rodrigues, Célia Marina A. Freire, Margarita Ballester, Marcos B. Proença"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_163.pdf",
      "name": "DESEMPENHO DO FILME DE POLIPIRROL DOPADO COM TUNGSTATO E ÀCIDO OXÁLICO NA PROTEÇÃO À CORROSÃO DA LIGA AA-1100",
      "authors": "Kátia R.L. Castagno, Denise S. Azambuja, Viviane Dalmoro"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_165.pdf",
      "name": "Metodologia de avaliação da corrosividade de solos em campo",
      "authors": "José Maurílio da Silva, Victor Tadeu, Simone Louise D. C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_166.pdf",
      "name": "Susceptibilidade a Corrosão Intergranular de Juntas Soldadas do Aço AISI 317L",
      "authors": "Marlon Corrêa, Maurício Ferrapontoff Lemos, Raphael Leite"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_168.pdf",
      "name": "Avaliação, através de ensaios potenciodinâmicos, da resistência a corrosão de conjugados de WC-Co modificados estruturalmente.",
      "authors": "Maria M. R. Castro, Marília M.M. Lima,Cristina Godoy, Júnia C. Avelar-Batista. Vanessa F.C. Lins"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_169.pdf",
      "name": "Estudo da otimização do tempo de nucleação de monocamadas auto-organizáveis para ligas de alumínio AA – 3003",
      "authors": "Everton Carlos Gomes, Everson do P. Banczek, Isolda Costa, Maico Taras da Cunha e Paulo R. Pinto Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_170.pdf",
      "name": "Estudo da nucleação e repassivação de pites na liga de alumínio 2024-T3 por SVET",
      "authors": "Fernanda M. Queiroz, João P. Torres, Alda M. Simões Hercílio G. de Melo Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_173.pdf",
      "name": "Avaliação da corrosividade de biodiesel para os metais do circuito de combustíveis em motores de automóveis",
      "authors": "Isabella P. Aquino, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_174.pdf",
      "name": "Mapeamento da Corrosividade Atmosférica do Estado do Ceará",
      "authors": "Anadite M. de Luna, Iêda N. S. Montenegro, Antônio R. M. Filgueira, Narcélio A. Pereira, Waydson M. Ferreira, Jocilane A. Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_177.pdf",
      "name": "O encapsulamento de peças metálicas com polímero termoplástico para proteção contra corrosão",
      "authors": "Hugo L. Liguori Silva, Thomas G. Fink"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_178.pdf",
      "name": "A utilização de polímeros metálicos para reparos e reforço em tubulações e estruturas",
      "authors": "Thomas G. Fink , Hugo L. L. Silva, Ana Claudia Braga"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_179.pdf",
      "name": "Estudo das propriedades térmicas, mecânicas e anticorrosivas de tintas silicones reforçadas com carga mineral.",
      "authors": "Alice A. S. Vieira, Magda R. S. Vieira, Emanuela da S. Santos, Bruna N. P. da Silva, Diniz R. de Lima Jr, Sara H. de Oliveira, Glória M. Vinhas, Maria Alice G. de A. Lima; Cezar H. Gonzalez, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_181.pdf",
      "name": "Avaliação de ligas do sistema Ti-Nb-Fe para aplicações biomédicas",
      "authors": "Danielle Q. Martins, Maria E. P. Souza, Diego C. Andrade, Flávia F. Cardoso, Conrado. R. M.Afonso, Célia M. Freire, Rubens Caram"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_183.pdf",
      "name": "Avaliação do comportamento do aço inoxidável super duplex 25%Cr soldado e material de base tratado termicamente, com relação à tenacidade à fratura ao ar e em água do mar sintética com a utilização de proteção catódica",
      "authors": "Raphael Aragonês Leite, Marlon Brandi Correa, Rodrigo Miguel Borré, Charles Guilherme Kuhn"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_185.pdf",
      "name": "Proteção Catódica para Superfície Interna de Tubulações de Água de Formação",
      "authors": "Simone L.D.C. Brasil, Leandro B. S. Marques, Wilson G. Castinheiras Júnior, João P. K. Gervásio , Lincoln C. Vidal, José Antônio F. Santiago, José Claudio F.Telles"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_186.pdf",
      "name": "Revestimentos Anticorrosivos para Tanques de Armazenamento de Petroquímicos",
      "authors": "Jeferson L. Oliveira, Walter Souza, Cosmelina G. da Silva, Isabel C. P. Margarit-Mattos, Oscar R. Mattos, Joaquim P. Quintela, Victor Solymossy"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_187.pdf",
      "name": "Caracterização Eletroquímica de Solução Utilizada em Ensaios de Corrosão sob Esforços Mecânicos em Aço API X-80.",
      "authors": "Adriana Forero B, Walter Barreiro C., José Antônio Ponciano, Ivani de S. Bott"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_188.pdf",
      "name": "Obtenção de novos tensoativos epoxidados derivados de ácido ricinoleico e linoleico aplicados como inibidores de corrosão em oleodutos",
      "authors": "Alcides de Oliveira Wanderley Neto, Tereza Neuma de Castro Dantas, Everlane Ferreira Moura, Hélio Scatena Júnior e Afonso Avelino Dantas Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_189.pdf",
      "name": "Corrosão microbiológica do aço inoxidável 430 em meio de H 2SO 4 1 mol L -1",
      "authors": "Martha Tussolini, Priscila Anunziatto, Cynthia Beatriz Furstenberger , Isolda Costa, Paulo Rogério Pinto Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_192.pdf",
      "name": "Corrosão de aços baixo-carbono conformados pelo processo de trefilação",
      "authors": "Sonia Braunstein Faldini, Cleber Haruo Fukugauchi, Mauro César Terence, Lílian de Azevedo Maria"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_194.pdf",
      "name": "Comparação do efeito protetor do fosfato de zinco e de ferro contendo tolitriazol para o aço-carbono 1008",
      "authors": "C. Spagnol, M. F. de Oliveira; E. P. Banczek, P. R. P. Rodrigues, I. Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_195.pdf",
      "name": "Avaliação de correlações entre processos de corrosão e biocompatibilidade de ligas Níquel-Titânio.",
      "authors": "Leila Bucci dos Santos, José A.C Ponciano, Fernando Costa e Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_197.pdf",
      "name": "Aplicação da Microscopia Eletroquímica de Varredura no Estudo da Corrosão do Aço Inoxidável DIN W. Nr. 1.4460 com Alto Teor de Nitrogênio",
      "authors": "Maysa Terada, Bruno T. Ramasco, Alda M. P. Simões, Angelo F. Padilha, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_201.pdf",
      "name": "Estudo das Ligas Titânio – Zircônio Resultante s do Processo de Fundição Plasma – Skull para Aplicações como Biomateriais",
      "authors": "Iêda N. S. Montenegro, Antônio C. Guastaldi, Paulo Sérgio G. da Silva, Walney S. Araújo, Waydson M. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_202.pdf",
      "name": "EFEITO DO TENSOATIVO DODECILBEN ZENO SULFONATO DE SÓDIO EM SOLUÇÃO E EM SISTEMA MICROEMUL SIONADO NA INIBIÇÃO À CORROSÃO DO AÇO AISI 1020",
      "authors": "Cátia G. F. T. Rossi, Elaine C. M. de Moura, Ewerton R . F. Teixeira, Anne M. A. Bezerra, Tereza N. Castro Dantas, Maria A. M. Maciel"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_203.pdf",
      "name": "AVALIAÇÃO DA EFICIÊNCIA DO TENSOATIVO ÓLEO DE COCO SAPONIFICADO NA INIBIÇÃO À CORROSÃO, EM UMA CÉLULA ELETROQUÍMICA INSTRUMENTADA",
      "authors": "Thiago R. da Rocha, Cátia G. F. T. Rossi, Michele P. Távora, Maria A. M. Maciel, Tereza N. Castro Dantas, Djalma R. Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_206.pdf",
      "name": "Active protective coatings based on Active protective coatings based on “smart” nanocontainers nanocontainers",
      "authors": "M.L. Zheludkevich, M.G.S. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_207.pdf",
      "name": "Estudo preliminar da adesão de microrganismos em diferentes superfícies metálicas imersas em água de lavagem de usina de açúcar e álcool.",
      "authors": "Márcia Teresa Soares Lutterbach, Ana Lúcia Oliveira, Elisa Machado, Luciana Contador, Viviane de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_208.pdf",
      "name": "Determinação do Tempo de Umectação nas Estações de Estudo de Corrosão Atmosférica no Estado do Ceará",
      "authors": "Narcelio de Araújo Pereira, Anadite Maria de Luna, Iêda Nadja Silva Montenegro, Antônio Ribamar de Melo Filgueira"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_209.pdf",
      "name": "El Ánodo Polimérico Continuo Como Interceptor de Interferencias",
      "authors": "Osvaldo C. D’Albuquerque, Rubén O. Bracco"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_210.pdf",
      "name": "Manta Termocontrátil reforçada com fibra de vidro utilizada em juntas soldadas de dutos lançados pelo método de Perfuração Direcional",
      "authors": "José Eduardo V. dos Santos, Enrique Torres"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_211.pdf",
      "name": "Influência do tempo de imersão nas propriedades mecânicas e anticorrosivas de revestimento de zinco obtido por galvanização a quente.",
      "authors": "Bruna N. P. da Silva, Diniz R. de Lima Jr, Rodrigo L. Ribeiro, Magda R. S. Vieira, Alice A. S. Vieira, Sara H. de Oliveira, Cezar H. Gonzalez, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_212.pdf",
      "name": "Avaliação da proteção anticorrosiva de um sistema dúplex aplicado sobre aço ABNT 1010 imerso em água do mar da região de SUAPE.",
      "authors": "Magda R. S. Vieira, Alice A. S. Vieira, Bruna N. P. da Silva, Francisca P. de França, Glória M. Vinhas, Maria Alice G. de A. Lima, Yêda M. B. de Andrade, Cezar H. Gonzalez, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_215.pdf",
      "name": "Coatings systems for carbon steel protection against biocorrosion and biofouling in seawater. A Laboratory and field study",
      "authors": "Héctor A. Videla, Liz Karen Herrera, Andrea Pereyra, Carlos A.Giudice"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_216.pdf",
      "name": "Biocorrosion of carbon steel by iron reducing bacteria",
      "authors": "Héctor A. Videla, Sylvie Le Borgne, Juan M. Romero"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_226.pdf",
      "name": "Assessing Corrosion of Stainless Steel s Exposed to Seawater Containing Sulfate-Reducing Bacteria",
      "authors": "Letícia Alonso Bernardez, Luiz Rogério Pinho de Andrade Lima, Paulo Fernando Almeida"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_228.pdf",
      "name": "Avaliação de processos de corrosão em aços API 5L X70 em contato com o solo Neossolo Quartzarênico",
      "authors": "Sérgio Luís de Jesus, Vírglio Franco do Nascimento Filho, Isolda Costa, Ricardo Espíndola Romero, Antônio Carlos de Azevedo, Jesualdo Luiz Rossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_229.pdf",
      "name": "Estudo das alterações das Propriedades Eletroquímicas do Sistema Nb/Nb 2O5 em função da permeação de hidrogênio na estrutura metálica",
      "authors": "A.G.S.G da Silva, H.A. Ponte, A. Pashchuk, C. E. B Marino, L. M. Moya"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_232.pdf",
      "name": "Inspeção de Dutos Marítimos com Pig Instrumentado",
      "authors": "Rafael V. B. Licursi, Brunno S. L. Bezerra"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_233.pdf",
      "name": "Estudo da estabilidade da hidroxiapatita e de filmes de óxido de titânio por meio de técnicas eletroquímicas",
      "authors": "Cláudia E. B. Marino, Rafael S. Nakayama, Eduardo M. Szesz, Neide K. Kuromoto, Haroldo A. Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_235.pdf",
      "name": "Resistência à corrosão de aço baixo carbono com diferentes concentrações de cobre",
      "authors": "Alexandra S. Oliveira, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_236.pdf",
      "name": "Avaliação da Corrosividade em Gasoduto de Exportação",
      "authors": "Jussara de M. Silva, Edgar L. G. Lopes, Álvaro A. O. Magalhães"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_237.pdf",
      "name": "Avaliação da utilização do potencial de recuperação de metais de efluentes industriais através da técnica de remediação eletrocinética",
      "authors": "Adalberto Baptista, Maria J.J.S.Ponte, Haroldo A. Ponte, Vsevolod Mimryne, Nice M.S.Kaminari"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_238.pdf",
      "name": "Avaliação do Efeito Protetor de Resina Acrílica em Amostras de Bronze expostas a Ambiente Urbano",
      "authors": "Leandro Rosa dos Santos, Luiz R.M. de Miranda, Eliane D’Elia, Dalva C.B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_239.pdf",
      "name": "Avaliação do desempenho quanto a resistência a corrosão do filme de passivação isento de cromo hexavalente em aço galvanizado",
      "authors": "Bruna C. Ferreira, Priscila Maria Ladeira, Fábio Martinez Gudeliauskas, Alberto N. C. Costa, José Eduardo R. Carvalho, Marcelo P. Oliveira, João José de Moraes, Mari Marni Gonçalves Soares, Marcio Pedroso Bastos, Marcos Henrique Sequeira Nogueira; Andressa Boschetti"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_240.pdf",
      "name": "Avaliação da eficiência do Inibidor de corrosão de aço durante o processo de decapagem em ácido clorídrico",
      "authors": "Leonardo Pinez Fernandes, Bruna C. Ferreira, Fabio M. Gudeliauskas, Andressa Boschetti"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_241.pdf",
      "name": "EPÓXI PARA REPINTURA PROLONGADA",
      "authors": "Silvio Domingos da Silva, Ivonei Vavassori, Jorge Miguel Eleutério, Marilene Fiamoncini, Amelia Bublitz"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_243.pdf",
      "name": "Variação da velocidade de corrosão, resistência, morfologia e composição da camada de ferrugem ao longo do tempo de aços baixa- liga expostos em atmosfera industrial.",
      "authors": "L. Marina Ocampo C.; Isabel C.P. Margarit-Mattos, José D. Fabris"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_244.pdf",
      "name": "Correlação ao longo do tempo da velocidade de corrosão com a morfologia e composição da camada de ferrugem de aços baixa-liga expostos em atmosferas marinha de campo e de laboratório",
      "authors": "L. Marina Ocampo C.; Isabel C.P. Margarit-Mattos, José D. Fabris"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_249.pdf",
      "name": "Testes Comparativos entre Epóxi Tar Free x Coal-Tar Epóxi",
      "authors": "Celso Soldera, Pedro Almir Liza,Celso Gnecco"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_250.pdf",
      "name": "Medição Remota de Potencial Tubo-solo de Proteção Catódica na Amazônia",
      "authors": "João P. K. Gervásio, Leandro B. S. Marques, Wilson G. Castinheiras Junior, José Álvaro C. Albertini, Eduardo W. Laurino, Airton D. Moreno"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_251.pdf",
      "name": "NON-INTRUSIVE MONITORING OF IN TERNALCORROSION IN PIPELINES AND HIGH TEMPERATURE APPLICATIONS IN REFINERIES",
      "authors": "Kjell R. Wold, Marte A. Theodorsen, Hallgeir Jenssen, Anna Maria Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_252.pdf",
      "name": "The Use of Optically Activatede Pigmente d Epoxy Systems in Water Storage Tanks",
      "authors": "Dale Jones, Evandro R. Martin, Luis Manuel Mota"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_253.pdf",
      "name": "Utilização de óxido de alumínio sinterizado como preparação de superfície para aplicação de revestimentos por aspersão térmica",
      "authors": "Neusvaldo Lira de Almeida, Eduardo de Assis Faria, Marcos Prata, Rafael Tadeu Acconcia"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_254.pdf",
      "name": "Avaliação Eletroquímica do Potencial Anticorrosivo de Carga Mineral Lamelar em Tinta Epóxi",
      "authors": "Evandro Jose Lopes, Carlos Marcus Gomes da Silva Cruz, João Batista Floriano"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_255.pdf",
      "name": "Protection evaluation by electrochemical methods of galvanized steel",
      "authors": "Célia R. Tomachuk, Alejandro R. Di Sarli, Cecilia I. Elsner, Olga B. Ferraz"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_257.pdf",
      "name": "Estudo comparativo da resistência à corrosão e citotoxicidade in vitro de aços inoxidáveis para aplicações em próteses odontológicas sobre implante.",
      "authors": "Rogério Albuquerque Marques, Adonis Marcelo Saliba-Silva, Sizue Ota Rogero, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_259.pdf",
      "name": "A COMBINED USE OF ELECTROCHEMICAL, ANALYTICAL AND MICROSCOPIC TOOLS TO ASSESS TH E ANTI-CORROSION PERFORMANCE OF THIN ORGANIC COATINGS APPLIED ON GALVANISED STEEL",
      "authors": "R. Pinto, J. Flores, F. Hannour, M.F. Montemor"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_260.pdf",
      "name": "Corrosão por Descarga Atmosférica – É possível?",
      "authors": "João Hipolito L. Oliver, Daniel Alves Filho, Sérgio Eduardo Abud Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_261.pdf",
      "name": "Preparação De Filmes Finos De Sno 2-Tio 2 Para Utilização Na Contenção De Corrosão Em Aço Inoxidável",
      "authors": "Adriany S. Nascimento, Jomar S. Vasconcelos, Paulo R. Júnior, Wildney S. Pereira, Nazaré do Socorro L.S. Vasconcelos"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_264.pdf",
      "name": "Resistência à Oxidação da Liga Fe20Cr Revestida com Y 2O",
      "authors": "Marina F. Pillis, Edval G. de Araújo,Olandir V. Correa, Lalgudi V. Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_266.pdf",
      "name": "RESISTÊNCIA À CORROSÃO DE NANOCOMPÓSITOS DE POLIÉSTER COM MONTMORILONITA",
      "authors": "Eliena J. Birriel, Lisete C. Scienza, Ademir J. Zattera, Débora Secchi Silveira "
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_267.pdf",
      "name": "STRESS CORROSION CRACKING IN FUEL ETHANOL",
      "authors": "Dr. Russell D. Kane"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_268.pdf",
      "name": "Internal Corrosion Assessment for Dry Gas and Multiphase Pipelines using Corrosion Prediction Models",
      "authors": "Mr. Sridhar Srinivasan, Mr. Vishal Lagad, Dr. Russell D. Kane"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_269.pdf",
      "name": "Avaliação do desempenho de inibidores num sistema de resfriamento industrial baseado em medidas de potencial a circuito aberto.",
      "authors": " Eduardo A. de Souza, José A.C. Ponciano Gomes, Lúcio Sahtler, Leila Y. Reznik"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_271.pdf",
      "name": "Estudo da influência do acabamento interno na co rrosão de tubos de cobre",
      "authors": "A. C. N. de Jesus, R. Magnabosco, I. Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_272.pdf",
      "name": "Avaliação da citotoxicidade do benzotriazol como inibidor de corrosão",
      "authors": "S. O. Rogero, E. P. Banczek, I. Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_274.pdf",
      "name": "Fast, worry free pipeline installation with dual-layer FBE coatings",
      "authors": "Meghan L. Mallozzi, J. Alan Kehr"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_275.pdf",
      "name": "Predição da Corrosividade Atmosférica do Aço-carbono e Cobre utilizando Funções Dose-Resposta desenvolvidas para região de São Luís-MA",
      "authors": "Yuri C. Sica, Elaine D. Kenny, Luiz A. Lacerda; Kleber Frank Portella"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_276.pdf",
      "name": "SOLDAGEM DO AÇO HIPER-DUPLEX SAF 2707 HD®",
      "authors": "Carlos Faggiani, Peter Stenvall"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_277.pdf",
      "name": "SAF 2707 HD® (UNS S32707) – UM AÇO INOXIDÁVEL HIPER-DUPLEX PARA AMBIENTES SEVEROS CONTENDO CLORETOS",
      "authors": "Alan Souza, Marcelo Senatore, Eduardo Perea"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_278.pdf",
      "name": "Estudo dos filmes (Bi-1,2- [trimetoxisililetano]) e (Bi-3-[trimetoxisililpropil]tetrasulfito) sobre camadas intermediárias contendo Nitratos de Cério e Lantânio aplicadas sobre a superfície de aço eletrodepósitada com liga de ZnFe.",
      "authors": "M.C.G. Santos, M.Ballester, C.M.A. Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_279.pdf",
      "name": "Comparação do comportamento eletroquímico de ligas nacionais para implantes ortopédicos",
      "authors": "Maria E. P. Souza, Luis F. Spinardi, Cecília A. C. Zavaglia, Célia M. A. Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_281.pdf",
      "name": "Aplicação de Resinas Acrílicas Paraloid B-44 e B-72 em Amostras de Bronze Pré-tratadas com Benzotriazol",
      "authors": "Flávia J.R.de Oliveira, Luiz R.M. de Miranda, Eliane D’Elia, Dalva C.B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_282.pdf",
      "name": "Avaliação do desempenho do envernizamento interno de latas em folha-de-flandres por espectroscopia de impedância eletroquímica – resultados preliminares",
      "authors": "A.M.P.F Felipe; M.T.A.Freire; J.B.Gatti; M.E.P.Souza; C.M.A.Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_283.pdf",
      "name": "Predicting, Controlling and Monitoring MIC in Seawater Injection Systems",
      "authors": "Stephen Maxwell, Scott Campbell"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_284.pdf",
      "name": "The Initial Corrosion Behaviour of Galvanized Reinforcing Bar in Concrete",
      "authors": "Zuo Quan Tan, Carolyn M. Hansson"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_285.pdf",
      "name": "Influência do tratamento com moléculas auto-organizáveis no comportamento de corrosão da liga de alumínio 1050",
      "authors": "Margarida Szurkalo, Sérgio Luiz de Assis, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_287.pdf",
      "name": "Resistência à corrosão em água do mar dos revestimentos de CrN e AlCrN depositados sobre o aço ABNT 8550 nitretado a plasma",
      "authors": "Flávio José da Silva, Eduardo Braz Barbaresco, Sinésio D. Franco"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_288.pdf",
      "name": "Análise por técnica eletroquímica da influência do envelhecimento sobre a resistência a corrosão do revestimento com metal de adição ERNiCrMo-3",
      "authors": "Pedro de Lima Neto, Sitonio G. de Magalhães, Regilany P. Colares, Victor T. Araújo, Marcelo F. Motta"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_289.pdf",
      "name": "Tecnologias Antiincrustantes Isentas de Biocidas a Base de Elatômeros de Silicone e Fluorpolímeros",
      "authors": "Rosileia Mantovani"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_290.pdf",
      "name": "Plano de Gerenciamento de Integridade de dutos contra corrosão – Avaliação dos mecanismos de corrosão possíveis de ocorrer em dutos.",
      "authors": "Alysson H. Bueno, José A. C. Ponciano"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_291.pdf",
      "name": "NOVA TECNOLOGIA UTILIZADA PARA RE VESTIMENTO ANTICORROSIVO E ISOLAMENTO TÉRMICO DE JUNTAS DE CAMPO EM DUTOS SUBMARINOS",
      "authors": "Bruno R. M. Cunha, Fabio B. Azevedo, Alexsander C. Piske"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_292.pdf",
      "name": "Avaliação da estabilidade eletroquímica de barras de aço carbono em macrocélulas configuradas por concreto carbonatado e argamassas de reparo",
      "authors": "José Luis Serra Ribeiro, Sílvia M. S. Selmo"
    },
    {
      "url": "https://intercorr.com.br/anais/2008/INTERCORR2008_293.pdf",
      "name": "Avaliação da estabilidade eletroquímica de barras de aço carbono em concreto carbonatado",
      "authors": "José Luis Serra Ribeiro, Sílvia M. S. Selmo"
    }
  ]
}

export const FOTOS_2018 = [
  {
    "src": "gallery/2018/intercorr2018_01.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_02.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_03.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_04.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_05.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_06.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_07.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_08.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_09.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_10.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_11.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_12.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_13.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_14.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_15.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_16.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_17.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_18.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_19.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_20.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_21.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_22.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_23.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_24.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_25.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_26.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_27.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_28.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_29.jpg",
    "width": 954,
    "height": 1440
  },
  {
    "src": "gallery/2018/intercorr2018_30.jpg",
    "width": 1200,
    "height": 795
  },
  {
    "src": "gallery/2018/intercorr2018_31.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_32.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_33.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_34.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_35.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2018/intercorr2018_36.jpg",
    "width": 1200,
    "height": 800
  }
];

import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditionList from "../components/EditionList";
import { i18n } from '../translations/i18n';

const Edicoes = () => {
  return (
    <div data-comp-edicoes>
      <div className="pre-title" />
      <h1>{i18n.t('edicoes.titulo')}</h1>
      <p><span className="font-weight-bold">{i18n.t('edicoes.periodo')}</span> {i18n.t('edicoes.resPeriodo')}</p>
      <p><span className="font-weight-bold">{i18n.t('edicoes.area')}</span> {i18n.t('edicoes.resArea')}</p>
      <EditionList />
    </div>
  );
}

// class Edicoes extends Component {
//   render() {
//     return (
//       <div data-comp-edicoes>
//         <div className="pre-title" />
//         <h1>Edições</h1>
//         <p><span className="font-weight-bold">Periodicidade do evento:</span> a cada dois anos.</p>
//         <p><span className="font-weight-bold">Área de conhecimento:</span> Corrosão</p>
//         <EditionList />
//       </div>
//     );
//   }
// }

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
)(Edicoes);


import React from 'react';
import './Footer.scss';

function Footer(){
  const dt = new Date();
  let year = dt.getFullYear();
  const siteName = process.env.REACT_APP_SITE_NAME;
  const issn = process.env.REACT_APP_ISSN;
  return(
    <footer data-comp-footer>
      <img src="./assets/footer-symbol.svg" alt=" "/>
      <p>© {year} {siteName}. {issn && `ISSN ${issn}.`} Todos os direitos reservados.</p>
    </footer>
  )
}

export default Footer;

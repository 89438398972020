export const FOTOS_2016 = [
  {
    "src": "gallery/2016/intercorr2016_01.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_02.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_03.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_04.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_05.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_06.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_07.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_08.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_09.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_10.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_11.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_12.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_13.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_14.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_15.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_16.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_17.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_18.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_19.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_20.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_21.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_22.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_23.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_24.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_25.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_26.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_27.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_28.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_29.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_30.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_31.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_32.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_33.jpg",
    "width": 912,
    "height": 1368
  },
  {
    "src": "gallery/2016/intercorr2016_34.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_35.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_36.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_37.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_38.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_39.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_40.jpg",
    "width": 1200,
    "height": 800
  },
  {
    "src": "gallery/2016/intercorr2016_41.jpg",
    "width": 1200,
    "height": 800
  }
];

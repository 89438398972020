import React, { useState, useCallback } from 'react';
import Gallery from "react-photo-gallery";
import Carousel, { ModalGateway, Modal } from "react-images";

const CustomFooterCount = (props) => {
  const { currentIndex, views } = props;
  return (
    <div>
      <p>{currentIndex + 1} de {views.length}</p>
    </div>
  )
};

const AltText = ({ currentIndex }) => {
  return `Imagem ${currentIndex}`
}
const NextLabel = ({ nextIndex, totalCount }) => {
  return `Exibir imagem ${nextIndex} de ${totalCount}`
}
const PrevLabel = ({ prevIndex, totalCount }) => {
  return `Exibir imagem ${prevIndex} de ${totalCount}`
}
const NextTitle = (props) => {
  return `Próxima (seta direita)`
}
const PrevTitle = (props) => {
  return `Anterior (seta esquerda)`
}
const CloseLabel = (props) => {
  return `Fechar (esc)`
}
const FullscreenLabel = (props) => {
  return `[Enter | Sair] tela cheia (f)`
}

const Galeria = (props) => {
  const { photos } = props;
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const formatters = {
    getAltText: AltText,
    getNextLabel: NextLabel,
    getPrevLabel: PrevLabel,
    getNextTitle: NextTitle,
    getPrevTitle: PrevTitle,
    getCloseLabel: CloseLabel,
    getFullscreenLabel: FullscreenLabel,
  };

  const columns = (containerWidth) => {
    let columns = 2;
    if (containerWidth >= 700) columns = 3;
    return columns;
  }

  return (
    <div>
      {photos.length && (
        <>
          <Gallery photos={photos} direction="column" columns={columns} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  components={{ FooterCount: CustomFooterCount }}
                  formatters={formatters}
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </>
      )}



    </div>
  );
};

export default Galeria;

export const EDICAO_2012 = {
  "message": '',
"data": [
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_100.pdf",
      "name": "Estudo eletroquímico dos filmes formados sobre titânio em diferentes condições de anodização",
      "authors": "Dimas L. Torres, Eduardo N. Codaro, Heloisa A. Acciari"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_101.pdf",
      "name": "Comportamento eletroquímico de ligas dentárias Ni -Cr -Mo em meio fluoretado",
      "authors": "Eduardo N. Codaro, José W. J. Silva, Dimas L. Torres, Heloisa A. Acciari"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_102.pdf",
      "name": "Avaliação da resistência à corrosão da Liga Zn-Ni eletrodepositada em banho ácido, contendo glicerina ou ácido fórmico, através de ensaios eletroquímicos.",
      "authors": "Graziella A. G. Pedroza, Michele D. de Jesus, Jairo L . da Silva, Endric L. do Nascimento, Carlos A. C. de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_106.pdf",
      "name": "Comportamento eletroquímico do Zamac 5 utilizado em metal sanitário",
      "authors": "Luciane Taís Führ, Gustavo Alberto Ludwig, Felipe Dalla Vecchia, Célia Malfatti, Cláudia Trindade Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_107.pdf",
      "name": "PROVUS - Desenvolvimento de um Provador de Corrosão Ultrassônico",
      "authors": "Carlos A. P. Patusco, Claudio S. Camerini, Júlio C. Adamowski, Marcelo Y. Matuda, Flávio Buiochi, Alan T. Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_108.pdf",
      "name": "REVESTIMENTOS EPÓXIS APLICADOS NA PINTURA E MANUTENÇÃO COM REPINTURA PROLONGADA",
      "authors": "Silvio Domingos da Silva, Eder Dirceu Dela Justina"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_109.pdf",
      "name": "Automação nas Instalações de Mantas Termocontráteis em Juntas de Campo de Tubulações de Aço",
      "authors": "Andre Luis Lemuchi, Carl Christian Gudme"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_110.pdf",
      "name": "Determinação da fase sigma da zona termicamente afetada em soldagem de aço duplex por voltametria linear: uma técnica não destrutiva",
      "authors": "Haroldo de Araújo Ponte, Paulo Roberto Stocco Zempulski, Luciana Schmidlin Sanches, Alysson Nunes Diógenes, Nice M . S. Kaminari, Rubens Eduardo Hauser Novicki"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_113.pdf",
      "name": "Implantação de Sistema de Controle e de Monitoramento Remoto de Retificadores de Proteção Catódica",
      "authors": "Glaucio Antonio Weigert, Saul Renato da Silva,Felipe Lustosa Marcondes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_116.pdf",
      "name": "Macrosegregation and Tertiary Dendrite Arm Spacing Effects on the Corrosion Resistance of a Pb-Sb A lloy for Lead-Acid Battery Components",
      "authors": "Emmanuelle S. Freitas, Wislei R. Osório, Leandro C. Peixoto, José E. Spinelli, Amauri Garcia"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_117.pdf",
      "name": "Investigação do processo corrosivo do aço carbono SAE 1020 em água da Usina Hidroelétrica de Tucuruí/PA",
      "authors": "Marcos A.C. Berton, Regina S.M. Amaral, Fernando S.C. Azevedo, Leonardo H.L.S. Oliveira, Denis R.A. Fernandes, José C. Cardoso Filho, Patricia R. Dalzoto, Vânia A. Vicente, Ida C. Pimentel Paulo R.D. Marangoni"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_118.pdf",
      "name": "Corrosão da liga AISI 316L em junta tubo-espelho soldada por TIG autógena",
      "authors": "Luis Henrique Guilherme, Marcelo Falcão de Oliveira, Guilherme Beck, Arthur Mariano de Souza Malafaia"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_119.pdf",
      "name": "Estudo do desenvolvimento de micro-organismos utilizando a técnica de espectroscopia e impedância eletroquímica",
      "authors": "Marcos A.C. Berton, Regina S.M. Amaral, Fernando S.C. Azevedo, Leonardo H.L.S. Oliveira, Denis R.A. Fernandesb, José C. Cardoso Filho, Patricia R. Dalzotod, Vânia A. Vicented, Ida C. Pimenteld, Paulo R.D. Marangoni"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_12.pdf",
      "name": "CORROSÃO POR PITE UTILIZANDO O SISTEMA PONTUAL DE MEDIDA EM AÇOS SUPERMARTENSÍTICOS SOLDADOS",
      "authors": "César A. D. Rodrígues, Josias. F. Pagotto, Germano Tremiliosi Filho, Artur J. Motheo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_121.pdf",
      "name": "Accelerated Aging of Fusion Bonded Epoxy Coatings",
      "authors": "Juliana Francisco, Fabio Aguirre, Don Kirkpatrick"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_125.pdf",
      "name": "Estudo de extratos de origem vegetal como inibidores de corrosão para alguns dos metais presentes em um motor a diesel",
      "authors": "Rafael do Nascimento Carvas, Paulo Chanel de Freitas, PQ,Isabel Correa Guedes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_126.pdf",
      "name": "Anodização de aços inoxidáveis como tratamento superficial para obtenção de estruturas porosas",
      "authors": "Pier Alfredo Scheffel, Praticia Leite, Cesar Eduardo Scmitt, Angela Beatrice Dewes Moura, Célia de Fraga Malfatti, Claudia Trindade Oliveira, Luciane Taís Führ"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_13.pdf",
      "name": "Estudo da corrosão de eletrodepósitos de Zn e Zn-Ni obtidos a partir de banhos alcalinos",
      "authors": "Conceição Aparecida Matsumoto Dutra, Flávia Cristina Zenith Ferreira, Matheus Yukio de Arruda Itoyama, Roberto Zenhei Nakazato"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_131.pdf",
      "name": "Estudo do efeito de parâmetros do processo GTAW pulsado na Resistência à Corrosão intergranular e por pite do aço inoxidável duplex UNS S32304",
      "authors": "Felipe de Oliveira Palácio, Marcelo Camargo Severo de Macêdo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_132.pdf",
      "name": "PIG DE LIMPEZA X CORROSÃO MICROBIOLÓGICA: Uma técnica eficaz no controle da corrosão microbiológica em dutos de petróleo",
      "authors": "Giovanna Ferrazzo Naspolini; André Varvello Nunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_133.pdf",
      "name": "Estudo de corrosão localizada dos aços inoxidáveis em sistemas de resfriamento industrial",
      "authors": "Livia S. Mello, Eduardo A. Souza, José A. C. P. Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_134.pdf",
      "name": "Cryogenic spill protection and passive fire protection in the LNG industry",
      "authors": "Graham Boalera, Richard Perkins"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_137.pdf",
      "name": "Caracterização Mecano Tribológica e Comportamento em Corrosão de Revestimentos Depositados por Atrito.",
      "authors": "Marcela Gonçalves Ferreira, Sinésio Domingues Franco, Marcelo Camargo Severo de Macêdo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_139.pdf",
      "name": "Avaliação de um processo de cromatização trivalente para a liga de alumínio 5052 H34",
      "authors": "Mirela Oliveira Herzog Zunino, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_14.pdf",
      "name": "Bionanotecnologia Aplicada à Corrosão Microbiológica",
      "authors": "Ana Carla Cruz de Albuquerque Barbosa, Cynthia de Azevedo Andrade, Gina Vazquez Sebastian, Renato Rocha Valerio, Milton Luiz Barroso Anteiro,Vanessa Volaro Caminha Mota dos Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_140.pdf",
      "name": "Avaliação de Falhas em Revestimentos Anticorrosivos via Termografia Ativa",
      "authors": "Marcella Grosso, Marcos P. V. de Souza,João M. A. Rebello, Isabel C. P. Margarit-Mattos, Gabriela R. Pereira, Sérgio D. Soares"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_141.pdf",
      "name": "Silanização de superfícies de alumínio contra a corrosão – um processo alternativo à cromatização hexavalente",
      "authors": "Simone Fadanelli Giustia, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_142.pdf",
      "name": "Efeito da incorporação de MMT-Na+ Paula Tiboa Bertuoli e PANI-MMT em tinta líquida",
      "authors": "Juliana Zardo, Ademir José Zattera, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_143.pdf",
      "name": "Avaliação de inibidores de corrosão e da influência do tempo de pré-corrosão na prevenção de corrosão preferencial em juntas soldadas aplicadas a sistemas de produção de gás",
      "authors": "Juliana Lopes Cardoso, Loriene Fugazza de Almeida, Anna Ramus Moreira, Neusva ldo Lira de Almeida, Flávia Maciel Fernandes Guedes, Gustavo Leitão Vaz, Jussara de Mello Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_146.pdf",
      "name": "Estudo do efeito da tensão de cisalhamento de um fluido contendo CO 2 na tensão corrosão acelerada pelo fluxo do aço AISI 1020",
      "authors": "Lílian R. M. Ferreira, Haroldo A. Ponte, Luciana S. Sanches"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_148.pdf",
      "name": "40 ANOS DE PROTEÇÃO CATÓDICA DO TERMINAL SALINEIRO DE AREIA BRANCA – CODERN – RN",
      "authors": "Aldo Cordeiro Dutra"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_15.pdf",
      "name": "Understanding biocorrosion in practical cases of different industrial systems",
      "authors": "Patricia Guiamet, Paola Lavin, Mónica Zapponi, Sandra Gómez de Saravia"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_150.pdf",
      "name": "Estudo dos processos corrosivos do aço AISI 1020 em petróleo utilizando a técnica de ruído eletroquímico e análise de ondaletas",
      "authors": "Emerson C. Rios, Alexsandro M. Zimer, Paulo C.M. Dias, Marcos B.J. Freitas, Eustáquio V.C. Castro, Lucia H. Mascaro, Ernesto C. Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_151.pdf",
      "name": "Análise da corrosão puntiforme em aço carbono por série temporal de imagens acoplada a técnicas eletroquímicas",
      "authors": "Alexsandro M. Zimer, Emerson C. Rios, Matheus A. S. de Carra, Ernesto C. Pereira, Lucia H. Mascaro"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_155.pdf",
      "name": "Estudo da correlação entre corrosão sob tensão e permeação de hidrogênio em aço de baixa liga utilizando técnicas eletroquímicas para verificação da influência do meio H2S no aço utilizado",
      "authors": "Daniela C. S. Garcia, Vanessa F. C. Lins,Marília Lima, Luciana S. Ribeiro"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_156.pdf",
      "name": "Corrosão na solda de tubos com costura da liga AISI 316L usados em trocadores de calor",
      "authors": "Luis Henrique Guilherme, Marcelo Falcão de Oliveira, Guilherme Beck, Arthur Mariano de Souza Malafaia"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_157.pdf",
      "name": "Avaliação da incidência de trincas e sua influência na resistência a corrosão de aços revestidos com diferentes espessuras de cromo.",
      "authors": "D. L. M. F. Pinto, I. S. de Figueiredo, A. H. S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_16.pdf",
      "name": "Hydrotalcite and Boehmite coatings for increased protection of Aluminum clad spent nuclear fuel",
      "authors": "Stela .M.C.Fernandes, Olandir.V.Correa, José.A.DE Souza, Lalgudi.V.Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_160.pdf",
      "name": "Uso de Escórias de Cobre no Tratamento Anticorrosivo de Superfícies",
      "authors": "Marina dos R.M. Costa, Carlos A. M. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_162.pdf",
      "name": "RESISTÊNCIA À CORROSÃO POR PITES NA ADIÇÃO DE METAL DE SOLDA AUSTENÍTICO EM MATRIZ FERRÍTICA E ADIÇÃO DE METAL DE SOLDA FERRÍTICO EM MATRIZ AUSTENÍTICA",
      "authors": "Carlos A. Picone, Ricardo Gomes Pereira, Luiz C. Casteletti, Sérgio A. Spinola Machado, Germano Tremiliosi Filho, Cesar A. Duarte Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_163.pdf",
      "name": "Medidas de Aderência pelo Método Pull-Off Aspectos Práticos Não Abordados em Norma",
      "authors": "Rodolpho C. D. Pereira, Walter Andrade de Souza, Jeferson Leite Oliveira, Bruno Pacheco Silva, Isabel Cristina P. Margarit Mattos, Rodolpho C. D. Pereira, Walter Andrade de Souza, Jeferson Leite Oliveira, Bruno Pacheco Silva, Isabel Cristina P. Margarit Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_164.pdf",
      "name": "Estudo do e feito de deformação plástica por laminação a frio sobre a resistência à corrosão intergranular do aço inoxidável AISI 304",
      "authors": "Viviane C. De Paula, Mara C. L de Oliveira, Olandir V. Correa, Renato A. Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_166.pdf",
      "name": "IDENTIFICAÇÃO DE MICRO-ORGANISMOS NOS POTENCIAIS ELETROQUÍMICOS DO AÇO CARBONO, EM AMBIENTE MARINHO",
      "authors": "Dauton L. F. Menezes, Caio T. C. C. Rachid, Raquel S. Peixoto, Ricardo Coutinho, L. de Miranda, Selma G. F. Leite"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_168.pdf",
      "name": "Estudo da Aplicação do Processo de Soldagem Tig em Revestimentos Utilizando Liga Hastelloy X",
      "authors": "Hector R.M. Costa, Felix Wiliam Cortes, Ivan. N. Bastos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_170.pdf",
      "name": "Inibidores de Corrosão Utilizando a Tecnologia V-Active VCI: Conceito e Aplicação.",
      "authors": "Mário C. Genovez; Radim Hrdina; Marcelo Schultz"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_171.pdf",
      "name": "Análise da qualidade de instrumentais cirúrgicos disponíveis no mercado.",
      "authors": "Marcella Macedo de C. Lopes, Fernando B. Mainier, Sérgio S. M. Tavares"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_172.pdf",
      "name": "Cathodic protection of extended sheet piling. A practical case.",
      "authors": "U. Marinelli"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_173.pdf",
      "name": "Monitoring corrosion in concrete by means of Electrical Resistance Probes",
      "authors": "T. Bellezze, E. Barbaresi, R. Fratesi, U. Marinelli"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_174.pdf",
      "name": "Study of case to Internal Corrosion Monitoring using Non Intrusive and high resolution probes with Ultrassonic tecnology",
      "authors": "Elias J. Chirico, Eduardo de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_175.pdf",
      "name": "Avaliação de inibidores de corrosão para aços de baixo carbono com base em formulações à base de fosforamidatos",
      "authors": "Raphaela de M. Baêsso e Silva, Fernando B. Mainier, Marcos Costa de Souza, Leandro F. Pedrosa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_176.pdf",
      "name": "Desenvolvimento de sensor de permeação de hidrogênio de eletrólito polimérico sólido",
      "authors": "Otávio Carneiro Corrêa, José Antônio da Cunha Ponciano Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_177.pdf",
      "name": "Inibidor de corrosão por CO2 em meio de alta salinidade - Estudo comparativo entre metodologias.",
      "authors": "Aline Y. Kina, José A. C. P. Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_18.pdf",
      "name": "Use of Electrochemical Techniques to Evaluate the Effect of Red Mud Addition on the Corrosion of Reinforced Concrete",
      "authors": "Daniel.V. Ribeiro; Carlos A.D. Rovere; Sebastião E. Kuri; João A. Labrincha; João C.C. Abrantes; Márcio R. Morelli"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_180.pdf",
      "name": "Estudo da degradação por CO2 supercrítico da interface cimento-aço em poços injetores de CO2 Estudo da degradação por CO2 supercrítico da interface cimento-aço em poços injetores de CO2",
      "authors": "Felipe Dalla Vecchia, Eleani da Costa, Célia Malfatti, Juliane Marcolino, Elen da Silva, Sandra Raquel Kunst"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_181.pdf",
      "name": "Caracterização de Fases Precipitadas em Aços Superduplex",
      "authors": "Kioshy Santos de Assis, Flávio Vieira Vasques de Souza, Isabel Cristina P. Margarit Mattos e Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_182.pdf",
      "name": "Efeito da microestrutura na resistência à corrosão por frestas da superliga cw-2m",
      "authors": "José Henrique Alano, Carlos A. Della Rovere, Guilherme dos S. Vacchi, Sebastião E. Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_184.pdf",
      "name": "Avaliação do potencial de utilização do tanino vegetal da acácia negra como inibidor do processo de corrosão de aço carbono em presença de CO2 supercrítico",
      "authors": "Juliane B. Marcolino, Eleani M. da Costa, Felipe Dalla Vecchia"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_185.pdf",
      "name": "Análise do sinal de ruído eletroquímico no processo de passivação do aço inoxidável 316",
      "authors": "Alysson Diogenes, Luciana Schmidlin Sanches,Haroldo de Araújo Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_186.pdf",
      "name": "Avaliação da corrosão de aço ASTM-A36 em contato com biodiesel de soja e biodiesel de acrocomia acculeata e suas blendas com diesel",
      "authors": "Milene A. Luciano, Geralda C. Godoy, Vânya M. D. Pasa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_187.pdf",
      "name": "Avaliação da eficiência de sequestrantes de H2S em meios aquosos – Análise crítica de metodologias",
      "authors": "Vanessa M. Souza, José A. C. P. Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_19.pdf",
      "name": "Descrição matemática da síntese eletroquímica dos filmes de compostos oxi-hidroxi de cobalto (III) e durante a sintese dos compósitos deles com os polímeros condutores",
      "authors": "Volodymyr V. Tkach, Vasyl V.Nechyporuk"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_194.pdf",
      "name": "Estudo da Corrosão em Tubulações Enterradas na Região Carbonífera de Candiota, RS-Brasil",
      "authors": "Roberta M. Kleina, Nayana S. Vargas, Luciana M. Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_196.pdf",
      "name": "Monitoramento do Estado de Corrosão das G relhas Metálicas de Torres de Linhas de Transmissão de Energia Elétrica, Utilizando Técnicas Eletroquímicas",
      "authors": "Mauro Zanini Sebrao, Alberto Pires Ordine, Renato Antonio de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_197.pdf",
      "name": "Estudo das Propriedades do Filme Passivo em Ligas do Sistema Fe-Mn-Si com Efeito de Memória de Forma",
      "authors": "Silvia M. Higa, Carlos A. D. Rovere, Sebastião E. Kuri, Jorge Otubo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_198.pdf",
      "name": "Comparação da resistência à corrosão por pites do aço inoxidável austenítico 316L e do aço super austenítico AL-6XN PLUS&#x2122; em meio aquoso de H 2SO 4 e NaCl",
      "authors": "Pedro de Lima Neto, Jorge Luiz Cardoso, Alfredo Leão Silva Nunes Cavalcante, Hamilton Ferreira Gomes de Abreu, Marcelo José Gomes da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_199.pdf",
      "name": "Estudo da Corrosividade do Carvão de Candiota, RS-Brasil",
      "authors": "Roberta M. Kleina, Nayana S. Vargas, Luciana M. Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_20.pdf",
      "name": "Seleção de Liners de Fluorpolímeros em Aplicações de Proteção à Corrosão para a Indústria Química e Petroquímica",
      "authors": "Felipe A. Medeiros, Gilson C. Gama, André Koebsch, Erik B. Nunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_202.pdf",
      "name": "Utilização do hipoclorito de sódio e xantana como estratégia para controle da biocorrosão em looping com escoamento turbulento",
      "authors": "Sara H. Oliveira, Pulkra Silva, Diniz R. Lima Junior, Edvânia . S. Lima, Francisca P. França, Maria Alice G. A Lima, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_205.pdf",
      "name": "Estudo da associação do revestimento de conversão à base de óxido de zircônio com silano sobre aço carbono Diocleciano Junqueira",
      "authors": "Gustavo Zaro, Juliana dos Anjos, Tiago L. Menezes, Jane Z. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_206.pdf",
      "name": "AVALIAÇÃO DE EXTRATOS AQUOSOS DE VEGETAIS COMO INIBIDORES DA CORROSÃO DE METAIS EM MEIO ÁCIDO",
      "authors": "Pedro de Lima Neto, Moisés Albuquerque de Araújo, Amanda Cardoso, Paulo Naftali da Silva Casciano, Adriana Nunes Correia, Walney Silva Araújo, Franci sco Belmino Romero"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_209.pdf",
      "name": "Proteção catódica interna e externa de protótipo de tanque de armazenamento: um estudo em laboratório",
      "authors": "Eduardo G. B. Leite, Simone L. D. C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_211.pdf",
      "name": "Aplicação do THPS e xantana no controle da corrosão microbiológica em sistema dinâmico de escoamento turbulento.",
      "authors": "Sara H. Oliveira, Pulkra Silva, Diniz R. Lima Junior, Edkarlla S. D. Oliveira, Gloria M. Vinhas, Severino L. Urtiga Filho, Maria Alice G. A Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_212.pdf",
      "name": "Otimização do Sistema de Proteção Catódica de Terminal de Múltiplo Uso Através de Simulação Numérica",
      "authors": "Simone L.D.C. Brasil, José Claudio F.Telles, José Antônio F. Santiago, Leonardo R. Machado, Anderson T. Kreischer"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_213.pdf",
      "name": "EFEITO DA ADIÇÃO DE CAL NA RESISTÊNCIA À CORROSÃO DAS ARMADURAS DE AÇO CARBONO E GALVANIZADAS",
      "authors": "Michele C. C. Oliveira, Vanessa F. C. Lins, Cíntia G. F. Costa, Maria M. R.Castro, Bruno S. Santos, Carlos R. Araujo, Gelmo C.Costa, Elbia S. Pessin, Ana. R.Leite"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_214.pdf",
      "name": "Proteção catódica galvânica – passado, presente e futuro",
      "authors": "Laerce de P. Nunes, Antonio C. Caetano, Alexandre A. Araujo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_215.pdf",
      "name": "Influência do conteúdo de molibdênio e do tratamento térmico sobre o comportamento da corrosão em ligas Fe-Cr-Mo em meio ácido",
      "authors": "Pedro de Lima Neto, Adriana Nunes Correia, Walney Silva Araújo, Amanda Cardoso, Marcelo José Gomes da Silva, Luis Flávio Gaspar Herculano, Hamilton Ferreira Gomes de<br />\nAbreu, José Demontier Vieira de Souza Filho, Jardel de Souza Belo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_218.pdf",
      "name": "Avaliação da resistência à cor rosão de ligas inoxidáveis com efeito de memória de forma em ambientes contendo cloretos",
      "authors": "Carlos Alberto Della Rovere, Rodrigo da Silva, José H enrique Alano, Jorge Otubo, Sebastião E lias Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_22.pdf",
      "name": "Aplicação experimental da técnica de mapeamento de corrente de interferência SCM - Stray Current Mapper",
      "authors": "Marcelo Araújo; Neusvaldo L. Almeida; Gutemberg de S. Pimenta; Sérgio E. A. Filho; Eduardo W. Laurino; Jorge Dequech; Paulo M. de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_221.pdf",
      "name": "Anodização e Colorimento de uma liga de alumínio puro",
      "authors": "Natal Nerímio Regone"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_222.pdf",
      "name": "ESTUDO DA RESISTÊNCIA Á CORROSÃO DE LIGAS Fe -Cr -Mo EM MEIO ÁCIDO ÁGUA ARTIFICIAL DE TANQUE DE ARMAZENAMENTO DE PETRÓLEO SATURADA COM CO 2",
      "authors": "Pedro de Lima Neto, Adriana Nunes Correia, Amanda Cardoso, Walney Silva Araújo, Hamilton Ferreira Gomes de Abreu, Paulo Naftali da Silva Casciano"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_224.pdf",
      "name": "Aço P-110 exposto ao Fluído de Completação NaCl 9.8 ppg contaminado com CO2 em condição próxima à realidade.",
      "authors": "Susana Modiano, Sonia Coelho de Souza, Javier Alejandro Carreno Velasco, Ilson Palmieri Baptista"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_225.pdf",
      "name": "Avaliação da Corrosividade do Enxofre Elementar em Linhas de Produção e Transporte de Gás Natural.",
      "authors": "Denise Souza de Freitas, Simone Louise Delarue Cezar Brasil, Luana Barki, Gustavo Leitão Vaz, Jussara de Mello Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_226.pdf",
      "name": "MÉTODOS COMPUTACIONAIS CLÁSSICOS E QUÂNTICOS: NOVAS FERRAMENTAS PARA ESTUDAR INIBIDORES DE CORROSÃO",
      "authors": "Pedro de Lima Neto, Adriana Nunes Correia, Valder Nogueira Freire, Glaydson Leandro Farias Mendonça, Jefferson Melo do Nascimento, Paulo Naftali da Silva Casciano"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_227.pdf",
      "name": "Potencial de aplicação da tecnologia do Ultra- Violeta em fluidos utilizados em teste hidrostático",
      "authors": "Vanessa V. C. M. dos Santos, Jorge A. S. Lacerda, João L. B. da Silva, Cynthia A. Andrade, Renato R. Valério, Ana C. C. A. Barbosa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_228.pdf",
      "name": "Solving Corrosion and Wear Problems with Weld Metal Overlay",
      "authors": "Pedro E. Amador, George Y. Lai, Michael A. Welch"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_231.pdf",
      "name": "Avaliação Crítica do Ensaio de Reativação Eletroquímica Potenciodinâmica Aplicado a Aços Inoxidáveis Austeníticos",
      "authors": "Flávio V. Vasques de Sousa, Pedro Muri, Kioshy S. de Assis, Adriana da C. Rocha, Isabel C ristina P. Margarit-Mattos, Oscar R. Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_232.pdf",
      "name": "Influência do Aporte Termico de Soldagem sobre a Resistência à Corrosão por Pite do Aço Inoxidável Superduplex UNS 32750.",
      "authors": "Ana C. F. Forte, Walney S. Araújo, Cleiton C. Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_233.pdf",
      "name": "Post-weld heat treatment effect on the corrosion behavior of the weld joint AISI 316L with Inconel® 182 filler metal",
      "authors": "Célia de Araújo Figueiredo, Wagner Reis da Costa Campos, Tomás Moreira de Oliveira Rangel Teixeira Frade, Fernando Amorim Silva, Igraine Lydia P. S. Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_235.pdf",
      "name": "Influência da solubilização na resistência à corrosão de revestimento tipo “clad” de aço inoxidável AISI 904L",
      "authors": "Bruno A. R. S. Barbosa, Sérgio S. M. Tavares, Juan M. Pardal, Verônica A. Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_236.pdf",
      "name": "Análise da Susceptibilidade à Corrosão Localizada do Aço AISI 304 empregando a Técnica Eletroquímica de Reativação Cíclica (EPR-DL)",
      "authors": "Tathiane C. Andrade, Abraão D. G. Barreto,Gerbson Q. Caetano, Hélio C. de Miranda"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_237.pdf",
      "name": "Avaliação de parâmetros experimentais na formação de um filme de silano viniltrimetoxisilano sobre o aço carbono 1010 por meio de técnicas eletroquímicas",
      "authors": "Bruno Souza Fernandes, Kleber Gustavo da Silva Souza, Fábio Ferreira Santos, Franco Dani Rico Amado"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_238.pdf",
      "name": "RELAÇÃO DA UNIFORMIDADE E DA MASSA DO REVESTIMENTO DE ZINCO COM A RESISTÊNCIA A CORROSÃO DE PERFIS DE AÇO GALVANIZADO PARA SISTEMA DE DRYWALL COM CHAPAS DE GESSO",
      "authors": "Maíse Vasques Ribeiro, Evelyne Vaidergorin, Vera Fernandes Hachich"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_24.pdf",
      "name": "Influência de Bactérias Redutoras de Sulfato na corrosão do aço carbono AISI 1020",
      "authors": "Monick A. da C. Rola, Diana J. Maciel, Lilian F. de Senna, Mariana S. de Paula, Dalva C. B. do Lago, Márcia M. M. Gonçalves"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_242.pdf",
      "name": "Avaliação anticorrosiva do aço inoxidável duplex SAF 2205 modificado com cobre",
      "authors": "Úrsula C. Pereira, Luiz B. dos Santos Filho, Pedro de Lima Neto, Alex M. do Nascimento, Walney S. Araujo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_244.pdf",
      "name": "Avaliação do revestimento DLC em fluídos de estimulação ácida – ácido clorídrico 15% e ácido fórmico 7% / ácido acético 5% a 80°C.",
      "authors": "Susana Modiano, Sonia Maria Coelho de Souza, Javier Alejandro Carreno Velasco, André Ferreira Lazaro, Ilson Palmieri Baptista"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_246.pdf",
      "name": "Estudo via CFD do comportamento hidrodinâmico em geometrias sujeitas a corrosão-erosão.",
      "authors": "Bruno F. de Moura, Cecília M. M. Santos, Marcelo C. S. de Macêdo, Márcio F. Martins"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_247.pdf",
      "name": "O Estudo da utilização da técnica de fotocatálise e sua influência nos fenômenos de corrosão e incrustação em dutos de petróleo",
      "authors": "Geane da C.Santana, Sarah Maria D.Oliveira, Karina A.Kodel, Ana Carla S.Andrade, Divanízia N.Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_25.pdf",
      "name": "Influência do pH da solução à base de ácido hexafluorzircônio na formação do revestimento de conversão em aço galvanizado",
      "authors": "Gustavo Zaro, Tiago M. de Abreu, Jane Z. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_251.pdf",
      "name": "Resistência à corrosão de ligas amorfas à base de Ferro",
      "authors": "Claudemiro Bolfarini, José E. Berger, Ricardo Nogueira, Virginie Roche, Claudio S. Kiminami, Walter J. Botta"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_252.pdf",
      "name": "Study of corrosion inhibitors and synergisms for 304 stainless steel in 0.2% peraceticacid media",
      "authors": "Pedro A. Machado, Mario Duarte, Silvia Maria L. Agostinho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_253.pdf",
      "name": "Comportamento da Corrosão de Revestimentos de Superligas de Níquel em Água de Formação Petrolífera Saturada com CO2",
      "authors": "Wilman Eduardo Italiano Ferreira, Pedro de Lima Neto, Walney Silva Araujo, Amanda da Silva Cardoso, Hélio Cordeiro de Mirando"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_254.pdf",
      "name": "Efeito do ácido acético no comportamento eletroquímico de duas ligas CoCrMo e CoCrW em meio de NaCl 0,15 mol L-1",
      "authors": "Adriana Cristina Gonçalves, Klester dos Santos Souza, Silvia Maria Leite Agostinho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_255.pdf",
      "name": "Estudo da cisteína e metionina como inibidores de corrosão do aço carbono em meio ácido e neutro",
      "authors": "Pedro de Lima Neto, Adriana Nunes Correia, Glaydson Leandro Farias Mendonça, Roger Gomes de Almeida, Paulo Naftali da Silva Casciano"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_256.pdf",
      "name": "Erosão de Revestimentos Cr- N e Al–Cr–N associados à nitretação",
      "authors": "Sinésio D. Franco, Flávio J. da Silva, Marcelo C. S. de Macêdo, Ricardo R. Marinho, Marcelo T. P. Paes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_257.pdf",
      "name": "Estudo da resistência à corrosão em meio salino de revestimentos aspergidos termicamente sobre liga de alumínio 7475",
      "authors": "Letícia P. Lira, Carlos A. Picone, Frederico A. P. Fernandes, Germano Tremiliosi Filho, Luiz C. Casteletti"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_259.pdf",
      "name": "Análise da Susceptibilidade à Corrosão Localizada de um Tubo Soldado de Aço AISI 304 Pela Técnica Eletroquímica de Reativação Potenciocinética Cíclica (EPR -DL)",
      "authors": "Gerbson de Queiroz Caetano, Tathiane Caminha Andrade, Abraão Danilo Gomes Barreto, Hélio Cordeiro Miranda, Jesualdo Pereira Farias"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_26.pdf",
      "name": "A valiação da Corrosividade da Mistura Água de Formação/Petróleo em diferentes Materiais",
      "authors": "Vitor P. de Carvalho, Milene L. de Almeida Desmarais Osimar Antônio Simião, Leila Y. Reznik, Ladimir J. de Carvalho, Carlos Augusto Cardozo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_260.pdf",
      "name": "Resultados experimentais que ilustram diferentes modelos cinéticos aplicados em estudo de corrosão.",
      "authors": "Silvia Maria Leite Agostinho, Célia Aparecida Lino dos Santos, Mónica Luisa Chaves de Andrade Afonso, Klester dos Santos Souza, Augusto Agostinho Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_261.pdf",
      "name": "Estudo da eficiência da polianilina nanoparticulada como aditivo em revestimentos anticorrosivos.",
      "authors": "Eduarda M. C. Malafaia, Juan C. M. Llamas, Luma R. R. Barbosa, Nadège S. Bouchonneau da Silva, Magda R. S. Vieira, Kleber G. B. Alves Celso P. de Melo, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_263.pdf",
      "name": "Aspectos formais da descrição de modelos cinéticos aplicados em estudos de corrosão",
      "authors": "Augusto Agostinho Neto, Klester dos Santos Souza, Silvia Maria Leite Agostinho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_264.pdf",
      "name": "Avaliação de revestimentos orgânicos anticorrosivos através da técnica de espectroscopia de impedância eletroquímica.",
      "authors": "Luma Regina Rodrigues Barbosa, Eduarda Micaella Costa Malafaia, Vivaldo Moraes Pinto Júnior, Magda Rosângela Santos Vieira, Nadège Sophie Bouchonneau da Silva, Kleber<br />\nGonçalves Bezerra Alves, Severino Leopoldino Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_265.pdf",
      "name": "Análise da susceptibilidade à corrosão localizada do aço AISI 304 solubilizado pela técnica eletroquímica de reativação potenciocinética cíclica (EPR-DL)",
      "authors": "Gerbson de Queiroz Caetano, Tathiane Caminha Andrade, Abraão Danilo Gomes Barreto, Hélio Cordeiro Miranda, Jesualdo Pereira Farias"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_267.pdf",
      "name": "Tratamentos de modificação de superfície para aumentar a resistência à corrosão do alumínio AA1100",
      "authors": "Daniel S. Yoshikawa, Sérgio L. Assis, Maysa Teradac e Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_268.pdf",
      "name": "Estudo da aplicação da técnica de Ruído Eletroquímico no monitoramento da Corrosão por Ácidos Naftênicos",
      "authors": "Ana C. T. G. Abrantes, Fernanda Hass, Alysson N. Diógenes, Haroldo A. Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_27.pdf",
      "name": "Tratamento anticorrosivo à base de extrato do ácido pirolenhoso para ligas de alumínio 2024-T3",
      "authors": "Edvaldo Faour, Viviane Dalmoro, Denise Azambuja, Silvia Tamborim"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_272.pdf",
      "name": "Pré-revestimento de conversão à base de hexafluorzirconato",
      "authors": "Juliana dos A. Moraes, Jane Z. Ferreira, Alvaro Meneguzzi"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_273.pdf",
      "name": "Oxidação e redução de filmes de passivação. Um caso quase sem dirupção",
      "authors": "Carlos Ventura D´Alkaine, Heverson Renan de Freitas"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_275.pdf",
      "name": "CARACTERIZAÇÃO DE FASES EM AÇOS GALVANNEALED",
      "authors": "Cíntia Gonçalves Fonseca Costa, Rosângela Maria Vitor Paranhos, Vanessa de Freitas Cunha Lins, Evandro de Azevedo Alvarenga"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_276.pdf",
      "name": "Desempenho anticorrosivo de revestimentos em aço s carbono e super duplex",
      "authors": "Francisco Antônio da Rocha Agura, Isabel Cristina Pereira Margarit Mattos, Cosmelina Gonçalves da Silva, Walter Andrade de Souza, Victor Solymossy, Joaquim Pereira Quintela"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_278.pdf",
      "name": "REFEITO DA ADIÇÃO DE CAL NA RESISTÊNCIA À CORROSÃO DAS ARMADURAS DE AÇO CARBONO E GALVANIZADAS",
      "authors": "Michele C. C. Oliveira, Vanessa F. C. Lins, Cíntia G. F. Costa, Maria M. R.Castro, Bruno S. Santos, Carlos R. Araujo, Gelmo C.Costa, Elbia S. Pessin, Ana. R.Leite"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_279.pdf",
      "name": "Investigação de tratamentos alternativos à cromatização, sobre superfícies de aço eletrogalvanizado",
      "authors": "José Mário Ferreira Jr, Célia Regina Tomachuk, Walter Izaltino Alves Santos, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_280.pdf",
      "name": "ESTUDO DA INFLUÊNCIA DAS BACTÉRIAS OXIDANTES DE FERRO NA TAXA DE CORROSÃO DO AÇO CARBONO AISI 1010",
      "authors": "Sara H. de Oliveira, Edivânia S. Lima, Severino L. U. Filho, Maria Alice G. de A. Lima, Cezar H. Gonzalez"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_281.pdf",
      "name": "Caracterização do processo corrosivo em aço API 5LX60 como aplicação em poços injetores de água",
      "authors": "José César S. do Nascimento, Magda R. S. Vieira, Vivaldo M.Pinto Júnior, Janaína André Cirino, Maria Alice G. de Andrade Lima, Cezar H.Gonzalez, Severino L. Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_283.pdf",
      "name": "Biomateriais: Correlação entre as propriedades eletrônicas do filme passivo e a resistência à corrosão do aço inoxidável ABNT NBR ISO 5832-1",
      "authors": "Eurico F. Pieretti, Tomaz P. Leivas, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_284.pdf",
      "name": "Processo de corrosão do aço API5LX60 exposto a óleo cru e águas salinas",
      "authors": "Magda R.S. Vieira, Alice A. S. Vieira, Larissa C. Stefenoni, Vivaldo M. Pinto Júnior, Larissa S. Leite, Francisca Pessoa de França, Severino Leopoldino Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_285.pdf",
      "name": "Tratamento de água industrial Wellan ®2000",
      "authors": "Caio de Oliveira Cavalcante, D’artagnam Gomes Nascimento Filho, Luiz Antônio Magalhães Pontes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_289.pdf",
      "name": "Corrosão do Aço Baixo Carbono em Soluções Ricas de CO 2 e NaCl com e sem CaCO 3 a Alta Pressão e Alta Temperatura",
      "authors": "Lisiane Morfeo Tavares, Eleani Maria da Costa, Jairo José de Oliveira Andrade"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_292.pdf",
      "name": "Revestimento Interno de Tubos",
      "authors": "André Koebsch, Cristiano Poloni, Erik Barbosa Nunes, Fernanda Mota Gonçalves"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_293.pdf",
      "name": "Diversidade de Microrganismos Relacionados à Biocorrosão em Amostras de Etanol",
      "authors": "Viviane Oliveira, Mariana M. Galvão, Diogo A. Coutinho, Marcelo Araújo, Gutemberg S. Pimenta, Márcia T. S. Lutterbach"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_294.pdf",
      "name": "Qualificação de Revestimento Externo de Dutos em FBE High Temp",
      "authors": "André Koebsch, Bruna Portilho, Emerson Monteiro, Enio Oura, Erik Barbosa Nunes,Thiago Alexandre Boucas"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_297.pdf",
      "name": "Aplicação de luva de compósito pré-impregnada em reparo de tubulações de acordo com a norma ASME PCC2 PART4 e ISO TS24817 para reparos definitivos em tubulações",
      "authors": "Thomas Georg Fink"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_298.pdf",
      "name": "Sistema removível de proteção anti-corrosão em frestas tais como juntas flangeadas, válvulas e proteção de parafusos",
      "authors": "Thomas Georg Fink, Ed Hall"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_302.pdf",
      "name": "GESTÃO TECNOLÓGICA DO MONITORAMENTO DA CORROSÃO INTERNA DE DUTOS",
      "authors": "Ana Paula Erthal Moreira, Simone Louise D. C. Brasil, Estevão Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_303.pdf",
      "name": "Ligas de Magnésio para Implantes Reabsorvíveis",
      "authors": "A. Fernandes; A. Zomorodian; M.J. Carmezim; T. Moura e Silva, M.F. Montemor, J.C.S. Fernandes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_306.pdf",
      "name": "Perfilometria Óptica de Dutos Sujeitos à Corrosão - Princípios e Recentes Progressos",
      "authors": "Pedro de D. Buschinelli, Maryah E. Haertel, João R. C. Melo, Armando Albertazzi, João M. C. dos Santos, Cláudio S. Camerini"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_309.pdf",
      "name": "Efeito de filmes de TiO2 obtidos pelo processo de deposição química de organometálicos em fase vapor (MOCVD) sobre a resistência à corrosão do aço inoxidável AISI 304",
      "authors": "Marina Fuser Pillis, Mara Cristina Lopes de Oliveira, Olandir Vercino Correa, Renato Altobelli Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_31.pdf",
      "name": "Verificação da ocorrência de corrosão em frestas em etanol anidro por meio de ensaios de imersão",
      "authors": "Célia A. L. dos Santos, José Veríssimo S. dos Santos, Juliana P. Flor, Zehbour Panossian, Marcelo Araújo, Gutemberg de S. Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_312.pdf",
      "name": "Determinação do padrão de sensitização de diferentes aços inoxidáveis austeníticos utilizando ensaio EPR-DL convencional e ensaio EPR-DL de campo através de redes neurais",
      "authors": "Abraão D. G. Barreto, Gerbson C. Queiroz, Tathiane C. Andrade, Hélio C. de Miranda, Jesualdo P. Farias"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_313.pdf",
      "name": "Specifications for corrosion protection in the copper mining industry in Chile",
      "authors": "Christian Sánchez Villa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_315.pdf",
      "name": "Estudo dos efeitos de descargas atmosféricas em estruturas de aço-carbono com e sem revestimento",
      "authors": "Sidney Oswaldo Pagotto Júnior, Zehbour Panossian, Fabiano Raymundo dos Santos, Gutemberg de S. Pimenta, Eduardo W. Laurino, João Hipólito L. Oliver, João Paulo Klausing Gervásio, Marcelo Araújo, Maria Helena dos Santos Taqueda"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_319.pdf",
      "name": "Sucesso em caso de revestimento com polímero cerâmico em torre de destilação/fracionamento submetida à alta temperatura e a corrosão.",
      "authors": "Thomas Georg Fink, Oziel Jorge da Silva, Emílio César Castro, Maurelio do Nascimento"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_32.pdf",
      "name": "Estudo dos efeitos do tipo de composto de enxofre e da temperatura de processamento na corrosão do aço inoxidável AISI 304L em sistema simulado de retortagem de folhelhos pirobetuminosos.",
      "authors": "Marcio F. Oliveira, Lilian F. de Senna, Dalva C. B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_321.pdf",
      "name": "Revitalização de Sistemas de Proteção Catódica em Unidades Marítimas Semissubmersíveis.",
      "authors": "Edson Góis de Medeiros, Renato de Mello Brandão Horta"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_324.pdf",
      "name": "Pré-Modelagem de Iniciação por Corrosão Sob Tensão de Liga de Níquel Considerando os Efeitos do Hidrogênio, Lítio e Boro do Meio Aquoso",
      "authors": "Omar F. Aly, M . Mattar Neto, Mônica M. A. M. Schvartzman"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_327.pdf",
      "name": "Avaliação da resistência à corrosão de Ti6Al4V revestido com filme de carbono obtido por eletrodeposição a partir de líquidos orgânicos",
      "authors": "Tiago Falcade, Tobias Eduardo Schmitzhaus, Otávio Gomes dos Reis, Iduvirges Lourdes Müller, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_329.pdf",
      "name": "Avaliação de Processo Corrosivo em Oleoduto tratado com Inibidor de Corrosão e contaminado com Enxofre Elementar e Oxigênio oriundo de Equipamento de reaproveitamento de Resíduo Oleoso.",
      "authors": "Orlandemberg Pereira Silva, Petterson Elias, Douglas de Paula Santos, Rafael Gonçalves Rosa, Cynthia de Azevedo Andrade, Eduardo Gullo Muller Lopes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_33.pdf",
      "name": "Operating problems in secondary oil injection plants. Its impact on microbiological corrosion processes.",
      "authors": "Patricia Guiamet, Sandra Gómez de Saravia"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_331.pdf",
      "name": "Influência do binômio Rugosidade x Adesivo em reforços estruturais com material compósito.",
      "authors": "Eduardo Cesar Vilani"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_332.pdf",
      "name": "Ensaio de Aderência em Tintas e Esquemas de Pintura Pelo Método de Resistência à Tração – Valores Típicos de Tensão de Ruptura, Tipos de Falha e Comentários Técnicos",
      "authors": "Fernando de L. Fragata, Alberto P. Ordine, Cristina C. Amorim"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_333.pdf",
      "name": "Eletrodos compósitos de Ni-PAni para a produção de hidrogênio",
      "authors": "Camila dos Santos Torres, Tiago Falcade, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_334.pdf",
      "name": "Estudo do Comportamento do Aço Inoxidável AISI 316L em Presença de Salmoura de KCl",
      "authors": "Gabriele de A. Cunha, Diego N. Jacob, Francisco A. Barbosa, Gustavo F. Bastos, Leila Yone Reznik, Ladimir José de Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_335.pdf",
      "name": "Análise da Biocorrosão do Aço Carbono AISI 1020 em face a proteção de uma tinta pigmentada com Óxido de Nióbio.",
      "authors": "Lindomar C. A. de Araújo, Leila Y. Reznik, Márcia T.S. Luttebach, Ladimir J. de Carvalho, Eliana F. C. Servul"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_338.pdf",
      "name": "Proteção contra surtos em juntas isolantes de dutos com proteção catódica",
      "authors": "Fábio F. Barros, João P. K. Gervásio, Marco A. Chaves, Wagner A. Barbosa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_339.pdf",
      "name": "Avaliação de revestimento Epóxi Novolac Tipo II em Célula Atlas e outros meios agressivos conforme especificações da norma Petrobras N2912.",
      "authors": "Edno Bocardo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_34.pdf",
      "name": "Estudos de corrosão sob tensão do aço API 5L X70 e m meio de etanol sob atmosfera de nitrogênio e ar sintético",
      "authors": "Célia A. L. dos Santos, Antonio C. Joaquim, José Veríssimo S. dos Santos, Juliana P. Flor, Horácio O. Santos Jr, Zehbour Panossian, Marcelo Araújo, Gutemberg de S. Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_346.pdf",
      "name": "Efeito da temperatura de pré-aquecimento sobre a resistência à corrosão da liga Al-Mn (AA3003), em meio de HNO 3",
      "authors": "Raphael F. Rezende, João G. O. Júnior, Sérgio R. Barra"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_350.pdf",
      "name": "Oxidação cíclica e corrosão cíclica de ligas ferrosas com Si e Al como principais elementos de liga",
      "authors": "Artur M. de S. Malafaia, Marcelo T. Milan, Marcelo F. de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_356.pdf",
      "name": "Desempenho de Tintas de Poliuretano Alifático à Radiação Ultravioleta e Condensação de Umidade",
      "authors": "Fernando de L. Fragata; Cristina C Amorim; Alberto Ordine; Bartolomeu N. Cordeiro"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_358.pdf",
      "name": "Levantamento do processo de patenteamento na área de corrosão no Brasil",
      "authors": "Ladimir J.Carvalho, Izabel N. Ivancko, Mariana R. Silveira, Estevão Freire, Simone L.D.C.Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_359.pdf",
      "name": "Estudo da corrosividade de líquidos iônicos em aço carbono A 285",
      "authors": "Rogério Freitas dos Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_36.pdf",
      "name": "Avaliação da Influência da Temperatura de Revenido na Fragilização por Hidrogênio do Aço ABNT 10B22-Modificado",
      "authors": "Felipe Vanti Gonçalves, Cristiane de Souza Brandolt, Roberto Moreira Schroeder, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_360.pdf",
      "name": "Revestimentos de dutos: Análise crítica de requisitos do cliente",
      "authors": "André Koebsch, Cláudio Moreira, Normando Cunha"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_362.pdf",
      "name": "Influência da Cor das Tintas de Acabamento Poliuretano Alifático na Absorção e na Dissipação de calor",
      "authors": "Fernando L. Fragata, Márcio A. Sens, Mauro Z. Sebrão"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_363.pdf",
      "name": "Novo processo de eletrodeposição de cobre alcalino isento de cianeto",
      "authors": "Juliana V . Custódio, Bruna B . Abreu, Fernanda M . Queiroz, Célia R . Tomachuk"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_364.pdf",
      "name": "REVESTIMENTO DE POLIETILENO (3LPE), SUBMETIDO À ALTOS GRADIENTES DE TENSÃO AC (30,2 kV).",
      "authors": "João Paulo Klausing; João F. Ramos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_365.pdf",
      "name": "How Viscous Elastic Coatings (VEC) eliminate typical problems when rehabilitating coatings in the field",
      "authors": "Leo van Beugen"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_366.pdf",
      "name": "Estudo da corrosão metálica em biodiesel",
      "authors": "Rafaela C. Nascimento, Lucas P. N. Branco, Camilla F. S. Moura, Maria José O. C. Guimarães,Peter R. Seidl"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_367.pdf",
      "name": "Suscetibilidade à corrosão em âncoras de estaiamento de torres de transmissão de energia elétrica.",
      "authors": "Márcio A. Sens, Mauro Z. Sebrão"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_368.pdf",
      "name": "Estudo do efeito da densidade de corrente no processo de obtenção de superfícies nanoestruturadas de Ti",
      "authors": "Felipe Wasem Klein, Leonardo Marasca Antonini, Leonardo Simoni, Cláudia Trindade Oliveira, Iduvirges Lourdes Müller, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_369.pdf",
      "name": "Controle de corrosão em equipamentos da indústria, uma proposta de integração entre a Academia e a Indústria",
      "authors": "Ávila S. Ávila, Casais D. Casais, Kiperstok A. Kiperstok"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_370.pdf",
      "name": "Maximização da produtividade do tratamento de água de resfriamento em plantas petroquímicas - Metodologia e Resultados",
      "authors": "Ricardo A. Fernandes, Luiz Carlos Sivelli"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_373.pdf",
      "name": "Evaluation and proposed treatment of corrosion process in bauxite pulp pipeline",
      "authors": "Danilo Zim, Rafaela F. Carvalhal, Matheus P. Paschoalino, Otílio Pires, Rômulo Rufino"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_374.pdf",
      "name": "Avaliação de inibidores para controle da corrosão interna de equipamentos e tubulações de sistema de refrigeração",
      "authors": "Ladimir J. Carvalho, Leila Y. Reznik, Simone L.D.C. Brasil, Daniel Andrade, Eduardo G. B. Leite, Allana B. Simões"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_375.pdf",
      "name": "Avaliação da resistência à corrosão-fadiga do aço API 5CT P110 em meio aquoso salino contendo H2S.",
      "authors": "Thiago Luz Krauspenhar, Telmo Roberto Strohaecker, Charles Guilherme Kuhn, Márcio Ribeiro Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_377.pdf",
      "name": "Revestimento anticorrosivo de válvulas: A sua importância e a dinâmica da analise do seu desempenho",
      "authors": "André Koebsch, André Rasador, Douglas Gasparetto, Sandro Campos Amico"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_378.pdf",
      "name": "Nova Tecnologia de Tintas de Fundo Ricas em Zinco a Base de Silicato de Etila com Menor Intervalo de Tempo Entre Demãos",
      "authors": "Eider Fernando Alves dos Santos, Arariboia Martins"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_381.pdf",
      "name": "Avaliação da resistência à corrosão do aço inoxidável austenítico ASTM F -138 utilizado na fabricação de implantes ortopédicos",
      "authors": "Ferdinando M . R. Borges, Auro A. Tanaka, Silvio R. F. Batista"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_382.pdf",
      "name": "Aumento da Durabilidade das Estruturas de Concreto usando vergalhão de aço galvanizado",
      "authors": "Lucy Inês Olivan; Luércio Scandiuzzi; Olga Shizue Taki; Tarcísio Dias Ferreira; Daniele Beatriz Palmiro Albagli"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_385.pdf",
      "name": "Revestimento com Polimero Cerâmico de alto desempenho na proteção interna de tanques e vasos para hidrocarbonetos",
      "authors": "Fabio L. Barcia, Bluma G. Soares, Luis Miranda, Thomas G. Fink"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_386.pdf",
      "name": "Preparação Interna Sustentável de Superfícies de Tubulações – Tecnologia Sponge-Jet",
      "authors": "Eduardo Cardoso Garrido, Miguel Roca Calza, Nialdo Pereira dos Santos, Felipe Moreno Souza Martins, Fernando Manechini Calza"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_388.pdf",
      "name": "Fase Deletéria em Aço Inoxidável Superduplex: Comparação entre os Métodos de Detecção",
      "authors": "Rafael da Silva Gama, Flávio Vieira Vasques de Sousa, Kioshy Santos de Assis, Isabel Cristina Pereira Margarit Mattos, Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_389.pdf",
      "name": "Proteção Catódica de Aços Carbono em Ambientes Contendo BRS e Ferrobactérias",
      "authors": "Vinícius Giorgetti, Eduardo Wlaudemir Laurino, Sebastião Elias Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_39.pdf",
      "name": "Eletrodeposição de liga de cobre e estanho em substrato de aço-carbono, empregando eletrólito de citrato de sódio",
      "authors": "Rodrigo F. Kubrusly, Lilian F. de Senna, Dalva C.B. do Lago, Aderval S. Luna"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_40.pdf",
      "name": "Efeito da aplicação de campo elétrico pulsado para tratamento de fluido contaminado por bactérias. Um estudo preliminar.",
      "authors": "Walter Barreiro Cravo Junior, Luiz A. P. Gusmão, Ana Carla Cruz de Albuquerque Barbosa, Saulo Alves Monteiro, Ivani de Souza Bott"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_41.pdf",
      "name": "USO DE INIBIDORES DE CORROSÃO NA PROTEÇÃO CONTRA A CORROSÃO DE BRONZE",
      "authors": "Alice Peccini de Melo, Lilian Ferreira de Senna, Aderval Severino Luna, Eliane D’Elia, Dalva Cristina Baptista do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_43.pdf",
      "name": "Estudo do crescimento bacteriano e da aplicação de procedimentos de limpeza e desinfecção na corrosão do aço inoxidável 304L",
      "authors": "Yohandrina Ulloa, Hélène de Baynast, Geneviève Gaudet, Fabrice Audonnet, Márcia M. M. Gonçalves, Lilian F. de Senna, Dalva C. B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_45.pdf",
      "name": "Estudo da corrosão de cobre em meio de NaCl contendo BTAH",
      "authors": "Rafael C. C . Ribeiro, Eliane D’Elia, Dalva C. B. do Lago, Lílian F. de Senna c, Aderval S. Luna d,"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_5.pdf",
      "name": "Estudo da corrosão de aços utilizados em sede de válvulas gaveta e tubulações de refinaria em meio de águas acres de refinaria de petróleo",
      "authors": "Zehbour Panossian, Fabiana Yamasaki Martins Vieira, Hélio Sartori Jr., Gutemberg de Souza Pimenta, Leandro Peres Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_50.pdf",
      "name": "Avaliação de metodologia rápida para determinação de APS redutase: Etapa II – Quantificação",
      "authors": "Fatima V. Pereira-Meirelles, Ivani de Souza Bott, Monica de Oliveira Penna, Ana Carla Cruz de Albuquerque Barbosa"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_53.pdf",
      "name": "Uso da mecânica do dano em corrosão sob tensão",
      "authors": "Ivan N. Bastos, Heraldo S. Costa-Mattos, José A. C. P. Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_54.pdf",
      "name": "Nickel electrodepositing for uranium sealing",
      "authors": "Rafael Henrique Lazzari Garcia, Adonis Marcelo Saliba-Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_55.pdf",
      "name": "CINÉTICA DE LIBERAÇÃO DO INIBIDOR DODECILAMINA ENCAPSULADO SOBRE NANOPARTICULAS DE SÍLICA",
      "authors": "J. M. Falcón, I. V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_56.pdf",
      "name": "Corrosão do aço-carbono em ácido sulfúrico concentrado",
      "authors": "Zehbour Panossian; Claudete de Souza Silva; Mariliza Santos; Cesar Henrique Rufino; Gutemberg de S. Pimenta; Carlos Alexandre Martins da Silva; Jorge Fernando Pereira Coelho; Marcelo Araújo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_58.pdf",
      "name": "Caracterização de ligas de Zn-Co produzidas a partir de banhos de citrato de sódio",
      "authors": "Julyana R. Garcia, Dalva C. B do Lago, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_6.pdf",
      "name": "Corrosão sob tensão em meio cáustico de tubulação de unidade de processo",
      "authors": "André da Silva Pelliccione, Jorge Luiz Rezende Galvão, Milton Franco Moraes"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_60.pdf",
      "name": "Produção de revestimentos de liga Zn-Co em temperaturas acima da ambiente",
      "authors": "Renata F. Braga, Julyana R. Garcia,Lilian F. de Senna, Dalva C. B. do Lago, Aderval A. S. Luna"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_63.pdf",
      "name": "Estudo do comportamento eletroquímico de banhos para eletrodeposição de ligas de Cu-Zn empregando microbalança de cristal de quartzo",
      "authors": "Antonio V. de C. Braga, Lílian F. de Senna, Dalva C. B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_65.pdf",
      "name": "Eletrodeposição de revestimentos compósitos de Cu -Al 2O3, produzidos a partir de soluções de CuSO 4 e CuSO 4 + citrato de sódio – Resultados Preliminares.",
      "authors": "Thaís G. de Lima, Paulo Fernando M. do Valle, Dalva C. B. Lago, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_69.pdf",
      "name": "Eletroquímica de urânio para alvos de irradiação",
      "authors": "Adonis M. Saliba-Silva. Rafael H. L. Garcia, Eduardo H. Bertin, Elita F.U. Carvalho, Michelangelo Durazzo"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_7.pdf",
      "name": "Comportamento eletroquímico durante a síntese do revestimento polimérico condutor sobre aço nas misturas ACN-água no meio fortemente ácido.",
      "authors": "Volodymyr V.Tkach, Vasyl V. Nechyporuk"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_70.pdf",
      "name": "Avaliação da susceptibilidade à corrosão em frestas de ligas de alta resistência à corrosão por meio de ensaios eletroquímicos",
      "authors": "Zehbour Panossian, Cristiane Vargas Pecequilo, Renata Angelon Brunelli, Gutemberg de Souza Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_73.pdf",
      "name": "Estudo da variação de parâmetros de eletrodeposição na composição de ligas ZnFe obtidas a partir de soluções contendo citrato",
      "authors": "Flávia C. Z. Ferreira, Matheus Y.A. Itoyama, Conceição A.M. Dutra, Roberto Z. Nakazato"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_75.pdf",
      "name": "AUMENTO DA RESISTÊNCIA À CORROSÃO DAS FECHADURAS DE EMBUTIR COMERCIALIZADAS NO BRASIL",
      "authors": "Edwiges Souza Ribeiro, Evelyne Vaidergorin, Vera Fernandes Hachich"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_77.pdf",
      "name": "Avaliação dos revestimentos de zinco com incorporação de partículas poliméricas",
      "authors": "Célia R. Tomachuk, Cecília I. Elsner, Alejandro R. Di Sarli"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_78.pdf",
      "name": "Rugosidade superficial de revestimentos de Au: deposição eletroquímica e influência de aditivos",
      "authors": "Juliana Lopes Cardoso, Sebastião Gomes dos Santos Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_79.pdf",
      "name": "Cinética eletroquímica: comparação de curvas de polarização teóricas com as curvas experimentais",
      "authors": "Juliana Lopes Cardoso, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_81.pdf",
      "name": "Identificação das espessuras de parede dos tubos por meio de pigmentação do revestimento em PE3L",
      "authors": "André Koebsch, André Mutafi, Rogério Moraes da Silva, Normando Cunha, Erik Barbosa Nunes, Crisitiano Poloni"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_82.pdf",
      "name": "Uso promissor de nanopartículas em revestimentos anticorrosivos: uma abordagem da literatura",
      "authors": "Jean Vicente Ferrari, Zehbour Panossian, Joaquim P. Quintela, Victor Solimossy"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_84.pdf",
      "name": "Estudo da interface osso/ implante em Ti6Al4V e ISO 5832- 9 após tratamento eletroquímico",
      "authors": "Tatiana C. da Costa, Jayme Bordini, Haroldo de A. Ponte, Anna R. S. Gomes, Cláudia E.B. Marino, Letícia A. Taminato, Munif Hatem"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_85.pdf",
      "name": "A organização temporal durante a síntese catódica de compósitos resistentes à corrosão, baseados em polímeros condutores.",
      "authors": "Volodymyr V.Tkach, Vasyl V.Nechyporuk, Petro I. Yagodynets, Mykola M. Hryhoryak"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_86.pdf",
      "name": "Tinta inteligente na proteção anódica do aço carbono",
      "authors": "Rodrigo S. Silva, Jane Z. Ferreira, Carlos A. Ferreira, Álvaro Meneguzzi"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_87.pdf",
      "name": "ESTUDO ELETROQUÍMICO DE SOLUÇÕES SINTÉTICAS DE SOLO",
      "authors": "Ewerton Rodrigues da Costa, Igor Teixeira Fraga, José Antônio da Cunha Ponciano Gomes, Ivan Napoleão Bastos, Gustavo Mendes Platt, Alysson Helton Santos Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_89.pdf",
      "name": "Uso do sensor de oxigênio e do sensor de umidade durante análise de corrosividade de solos",
      "authors": "Silva J. Maurílio, Cabussú S. Mário, Portella F. Kleber, Pereira Ap. Príncia, Brambilla J.C.Kelly, Cerqueira P. Daiton, Rogério Salles"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_9.pdf",
      "name": "Seleção de materiais para plantas de produção de ureia",
      "authors": "Vanessa F. M. de Queiroz, Annelise Zeemann"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_91.pdf",
      "name": "Melhoramento de solo em sistema de aterramento de regulador de tensão em linha de distribuição, na região oeste da Bahia",
      "authors": "Silva J. Maurilio, Cabussú S. Mário, Portella F. Kleber, Pereira Ap. Príncia, Brambilla J.C.Kelly, Cerqueira P. Daiton, Rogério Salles, Sotille A. Carlos"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_93.pdf",
      "name": "Estudo da influência do molibdênio na resistência à corrosão de aços inoxidáveis ferrítico s, austenítico s e dúplex em solução simulada de água de poro e em concreto",
      "authors": "Thiago J. Mesquita, Adriana de Araujo, Zehbour Panossian, Ricardo P. Nogueira, Alberto S. Dias Junior, David R. Neves Filho, Sócrates C. S. Dias"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_94.pdf",
      "name": "Proteção superficial e proteção catódica de transformadores elétricos subterrâneos",
      "authors": "Adriana de Araujo, Clay M. Martins, Zehbour Panossian, Neusvaldo L. de Almeida, Alberto S. Dias Junior"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_95.pdf",
      "name": "Avaliação do efeito do benzotriazol como inibidor de corrosão para cobre em meio ácido",
      "authors": "Antonio Vitor de Castro Braga, Aline Júlio Magdaleno, Lílian F. de Senna, Aderval S. Luna, Eliane D’ Elia, Dalva C. B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_96.pdf",
      "name": "Elaboration and characterization of siloxane -PMMA hybrid coatings as pretreatment on the tinplate",
      "authors": "Sandra Raquel Kunst, Patrícia dos Santos Correa, Leonardo Marasca Antonini, Joseane de Andrade Santana, Victor Hugo Vitorino Sarmento, Céli a de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_97.pdf",
      "name": "Preparation and characterization of hybrid coating siloxane-PMMA on the galvanized steel",
      "authors": "Sandra Raquel Kunst, Cícero Inácio da Silva Filho, Leonardo Marasca Antonini, Elen Leal da Silva, Victor Hugo Vitorino Sarmento, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2012/INTERCORR2012_98.pdf",
      "name": "Poliuretano Sintático – Qualificação da Tecnologia para Utilização em Dutos Rígidos Submarinos",
      "authors": "Fabio B. Azevedo, Bruno M. Cunha, Guilherme E. Haverroth, Marcelo B. Teixeira"
    }
  ]
}

export const EDICAO_2018 ={
  "message":'',
  "data": [
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_003.pdf",
      "name": "Uso de polímero condutor na produção de Tinta Inteligente Poliuretana Anticorrosiva",
      "authors": "Rodrigo S. Silva, Alvaro Meneguzzi, Jane Z. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_006.pdf",
      "name": "Estudo da temperatura de tratamento térmico de revestimentos de conversão mistos produzidos por dip coating em sol-gel",
      "authors": "Antonio V. C. Braga, Dalva C. B. do Lago, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_007.pdf",
      "name": "Revestimentos de ligas Cu-Sn produzidos a partir de eletrólito ambientalmente não agressivo contendo tartarato de sódio: ensaios preliminares",
      "authors": "Priscila Santos da Silva, Dalva C. B. do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_008.pdf",
      "name": "Avaliação da propriedade anticorrosiva de revestimentos de ligas Cu-Sn obtidos a partir de eletrólitos contendo citrato ou tartarato de sódio: um estudo preliminar",
      "authors": "Priscila Santos da Silva, Nathalia Xiaohui Zhou, Dalva C. B. do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_011.pdf",
      "name": "Estudo do comportamento corrosivo de uma liga soldada de alumínio 7003",
      "authors": "Vinícius P. Furtado, Tábata A. Vieira, José H. Alano, Alice G. Osório, Fernando M. Machado"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_012.pdf",
      "name": "Morphological characterization and electrochemical behavior of Mg alloys in NaCl 0.1 mol/L",
      "authors": "Flávio de Souza Costa, Sviatlana Lamaka, Hercílio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_014.pdf",
      "name": "Eletrodeposição de ligas anticorrosivas de cobre e níquel a partir de banho de tartarato de sódio",
      "authors": "Thaís M. de Souza, Lilian F. de Senna, Dalva C. B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_020.pdf",
      "name": "Avaliação de um revestimento anticorrosivo com efeito de autorreparação com sistema bicomponente",
      "authors": "Fernando Cotting, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_021.pdf",
      "name": "Effect of the anodizing potential on the corrosion behavior of the AZ31B magnesium alloy",
      "authors": "Leandro A. Oliveira, Renato A. Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_022.pdf",
      "name": "Design de revestimentos amorfos/nanocristalinos a base de Fe resistentes à corrosão e ao desgaste",
      "authors": "Guilherme Y. Koga, Alberto M. Jorge Jr., Virginie Roche, Ricardo P. Nogueira, Robert Schulz, Sylvio Savoie, Claudemiro Bolfarini, Claudio S. Kiminami, Walter J. Botta"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_023.pdf",
      "name": "Passivação e corrosão de armaduras de aço carbono em matriz de cimento belíticosulfoaluminoso contendo cloretos",
      "authors": "Guilherme Y. Koga, Blandine Alberta, Virginie Roche, Ricardo P. Nogueira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_025.pdf",
      "name": "Estudo dos diagramas E -pH aplicados a revestimentos à base de Pentóxido de Nióbio",
      "authors": "Rodney Santandrea, Simone L.D.C.Brasil, Leila Y.Reznik, Ladimir J.de Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_027.pdf",
      "name": "Avaliação da morfologia micro -nanométrica e resistência à corrosão de revestimentos super-hidrofóbicos desenvolvidos em liga de alumínio 5052.",
      "authors": "Rafael G. C. da Silva, Maria I. C. Malta, Wagner D. O. De Araujo, Severino L. U. Filho, Sara H. de Oliveira, Magda R. S. Vieira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_028.pdf",
      "name": "Galvanização de estruturas de painéis fotovoltáicos",
      "authors": "Igor Filipi Rezende Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_029.pdf",
      "name": "Perspectivas e tendências da monitoração da corrosão na indústria de óleo e gás com a evolução dos sensores e a revolução digital.",
      "authors": "Pedro Altoé Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_032.pdf",
      "name": "O gerenciamento da corrosão interna de dutos e o uso de sistemas de Monitoração Não - Intrusivos: oportunidades e estratégias",
      "authors": "Pedro Altoé Ferreira, Victor Gomes da Silva, Gustavo Vaz Leitão"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_034.pdf",
      "name": "Avaliação da corrosividade do biodiesel usando microeletrodos",
      "authors": "Tiago Mendes Ferrer, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_035.pdf",
      "name": "Influência da frequência de pulso na eletrodeposição de ligas anticorrosivas de Cu-Co em aço carbono",
      "authors": "Thaís M. de Souza, Dalva C. B. do Lago, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_038.pdf",
      "name": "Monitoração e mitigação de produtos de corrosão interna em sistemas de transporte e distribuição de gás natural: visão geral, lacunas e oportunidades para pesquisa",
      "authors": "Pedro Altoé Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_040.pdf",
      "name": "Estudo da curva de polarização cíclica da liga de níquel Inconel 625 em solução de NaCl",
      "authors": "André S. Kravetz, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_042.pdf",
      "name": "Anodização de nióbio em licor pirolenhoso",
      "authors": "Italo Machado de Sá, Luã Tainachi Mueller, Angela Beatrice Dewes Moura, Luciane Thais Fuhr, Sandra Raquel Kunste, Cláudia Trindade Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_043.pdf",
      "name": "COLORED ANODIZING OF TITANIUM WITH PYROLIGNEOUS SOLUTIONS OF BLACK WATTLE",
      "authors": "Luciane Taís Fuhr, Sandra Raquel Kunst, Mariane Fernades, Fernando Dal Pont Morisso, Célia de Fraga Malfattie, Angela Beatrice Dewes de Moura, Lilian Vanessa Rossa Beltrami,Claudia Trindade Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_044.pdf",
      "name": "Ensaio de corrosão acelerado para avaliação do metal formador de tanques de armazenagem de biodiesel",
      "authors": "Renan Francisco Bohrer Klein, Sandra Raquel Kunst, Cláudia Trindade Oliveira, Luciane Tais Fuhr"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_045.pdf",
      "name": "Avaliação das camadas de cobre e níquel eletrodepositadas sobre o zamac",
      "authors": "Luciana Valandro, Carolina Dias da Costa, Sandra Raquel Kunst, Cláudia Trindade Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_046.pdf",
      "name": "EVOLUÇÃO NA RESISTÊNCIA À CORROSÃO DOS REVESTIMENTOS DAS FECHADURAS DE EMBUTIR COMERCIALIZADAS NO BRASIL",
      "authors": "Guilherme Pereira Gulman, Mariana Caro Barleta, Edwiges Ribeiro, Vera da Conceição Fernandes Hachich"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_048.pdf",
      "name": "Estudo de nanopartículas de sílica para encapsulamento de inibidor de corrosão",
      "authors": "Brunela P. Silva, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_049.pdf",
      "name": "Influência de montmorilonitas no desempenho anticorrosivo de tinta epoxi com óxido de ferro micáceo",
      "authors": "Aldavilma C. Lima, Araribóia Martins, Eider Santos, Oswaldo E. Barcia, Isabel C.P. Margarit-Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_051.pdf",
      "name": "Influência da preparação de superfície no desempenho de tintas de fundo aplicadas a estruturas marítimas sob proteção catódica",
      "authors": "Idalba Souza dos Santos, Ladimir José de Carvalho, Simone L . D. C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_052.pdf",
      "name": "Avaliação do efeito do nitrato em função da temperatura na geração biogênica de H2S em reservatórios de petróleo",
      "authors": "Vanessa Vólaro Caminha Mota dos Santos, Maíra Paula de Sousa, Paula Fernandes de Aguiar, Eliana Flávia Camporese Sérvulo, Magali Christe Cammarota"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_055.pdf",
      "name": "Avaliação do pó da casca da amêndoa de cacau como inibidor de corrosão para o aço carbono em meio ácido",
      "authors": "Mayara Cristina F. de Carvalho, Vera R. Capelossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_057.pdf",
      "name": "Avaliação da influência do revestimento de nanozircônio depositado com tetraetoxisilano (TEOS) em aço galvanizado na proteção contra corrosão",
      "authors": "Daniela Bertol, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_058.pdf",
      "name": "Proposta de estudo de técnicas não destrutivas para determinação do teor de umidade interna do concreto",
      "authors": "Lucas C. Nascimento, Adriana de Araújo, Kléber J. Oliveira, Valdecir A. Quarcioni"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_059.pdf",
      "name": "Simulação “ in vitro ” de condições típicas de peri-implantite no processo de corrosão de implantes dentários da liga de titânio (grau V).",
      "authors": "Larissa Oliveira Berbel, Everson do Prado Banczek, Mitiko Saiki, Rachel Black, Georgios A. Kotsakis, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_060.pdf",
      "name": "Proteção de ligas de alumínio por anodização sulfúrica e selagem com revestimento de conversão de Zr",
      "authors": "Jéssica Salles Pinheiro, Henrique R. Piaggio Cardoso, Jane Zoppas Ferreira "
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_061.pdf",
      "name": "Efeito de pós-tratamento em solução contendo íons Ce sobre a resistência à corrosão da liga de alumínio 2024-T3 anodizada em TSA",
      "authors": "Oscar Mauricio Prada Ramirez, Mayara Veloso Lima, Fernanda Martins Queiroz, Cleber Lima Rodrigues, Isolda Costa, Marie Georges Olivier, Hercilio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_062.pdf",
      "name": "Characterization of corrosion behavior of ISO 5832-1 austenitic stainless steel coating by polypyrrole",
      "authors": "Camila Boldrini Nascimento, Everaldo Carlos Venâncio, Renato Altobelli Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_063.pdf",
      "name": "Efeitos da soldagem por fricção e mistura com relação à resistência a corrosão nas ligas de alumínio 2050-T84 e 7050-T7451",
      "authors": "B. V. Gonçalves de Viveiros, M. Alencar, U. Donatus, I. Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_065.pdf",
      "name": "O Estudo das condições de obtenção do filme de silano TEOS como pré-tratamento do aço galvannealed na proteção contra a corrosão",
      "authors": "Iago M. F.C. R. e Silva, Vera R. Capelossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_066.pdf",
      "name": "ESTUDO DA INFLUÊNCIA DOS TRATAMENTOS TERMOMECÂNICOS T8 E T851 NA MICROESTRUTURA E NO COMPORTAMENTO DE CORROSÃO DA LIGA AA2198",
      "authors": "João Victor de Sousa Araujo, M ariana Milagre Xavier, Caruline Souza Carvalho Machado, Fernanda Martins Queiroz, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_067.pdf",
      "name": "Caracterização microestrutural e eletroquímica da liga de alumínio AA2050 em meios de cloreto e sulfato",
      "authors": "Wander C .Junior, Jean V . Ferrari, Cristiane R . Martins, Hercílio G . de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_068.pdf",
      "name": "Estudo do efeito de tratamentos térmicos de recozimento sobre a resistência à corrosão do aço inoxidável ASTM F139",
      "authors": "Rodrigo Kenji de Oliveira, Renato Altobelli Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_069.pdf",
      "name": "Estudo da resistência à corrosão de dois aços API 5L X65 com diferentes teores de Mn e de Nb",
      "authors": "Janeth Quispe Avilés, Hercílio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_071.pdf",
      "name": "Avaliação de Desempenho Anticorrosivo e Custo de Esquemas de Pintura com Tintas em Pó, após 10 Anos de Exposição em Atmosfera de Elevada Agressividade",
      "authors": "Filipe Batista Fontes, Alberto Pires Ordine, Marcos Martins de Sá"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_073.pdf",
      "name": "Técnica de injeção de corrente como ferramenta de inspeção de estruturas enterradas do setor elétrico",
      "authors": "Juliana Victorino Dias, Elber Vidigal Bendinelli"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_077.pdf",
      "name": "Avaliação do comportamento anticorrosivo do aço galvanizado por imersão a quente em diferentes atmosferas",
      "authors": "Felipe Garcia Nunes, Elber Vidigal Bendinelli, Alberto Pires Ordine, Luiz Alberto Ferreira da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_078.pdf",
      "name": "Avaliação de custo e benefício de esquemas de pintura de base aquosa, contendo tintas de fundo pigmentadas com zinco, em comparação a esquemas tradicionais",
      "authors": "Alberto Pires Ordine, Cristina da Costa Amorim, Filipe Batista Fonte, Marcos Martins de Sá, Wendell Porto de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_079.pdf",
      "name": "Desempenho anticorrosivo de pintura em aço galvanizado envelhecido: avaliação de laboratório e de campo, após sete anos de exposição atmosférica",
      "authors": "Cristina Amorim, Alberto Ordine, Marcos Martins de Sá"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_080.pdf",
      "name": "Bringing new functionalities to Rotating Cage Autoclave Assembly",
      "authors": "Danielle C. F. S. Spigarollo, Merlin C. E. Bandeira, Isadora V. Nogueira, Rogaciano M. Moreira, Oscar R. Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_083.pdf",
      "name": "Avaliação de um silanol como inibidor de corrosão para o aço galvannealed através das técnicas eletroquímicas de EIE e SVET",
      "authors": "Sergio Henrique Cavallaro, Fernando Cotting, Neusa Alonso Falleiros, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_084.pdf",
      "name": "Effect of post weld heat treatment on corrosion resistance of austenitic stainless steel weldment joint by nickel filler metal",
      "authors": "João Henrique Nery Garcia, Thaís Rachid Netto, Luiza Esteves, Wagner Reis da Costa Campos, Emerson Giovani Rabelo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_087.pdf",
      "name": "Avaliação Eletroquímica na Formação dos Produtos de Corrosão no Aço API X65 em Meios Contendo CO 2 e H2S em Diferentes Temperaturas e Concentrações de Cloreto.",
      "authors": "Gonçalves, M. C., Santos, B. A. F., Souza, R. C., Júnior, E. M., Simões, T. A., Oliveira , J. R., Vaz, G. L., Caldeira, L., Gomes, J. A. C. P., Bueno, A. H. S."
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_088.pdf",
      "name": "Evaluation of cold wire addition on pitting corrosion behavior of SAW duplex stainless steel welds",
      "authors": "Neice F. Santos, Ronaldo C. Júnior, Paulo J. Modenesi, Vanessa F. C. Lins, Emerson Giovani Rabello, Luiza Esteves"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_089.pdf",
      "name": "Hydrogen Evolution Reaction Evaluation in Aqueous Solutions Containing H2S at Different Pressures",
      "authors": "Pedro R. P. Viana, Flávio V. V. de Souza, Oswaldo E. Barcia, Oscar R. Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_091.pdf",
      "name": "Estudo da formação de par galvânico entre armaduras de aço-carbono e aço inoxidável de uma mesma estrutura de concreto",
      "authors": "Juliana Lopes Cardoso, Mayara Stecanella Pacheco, Renata Angelon Brunelli, Adriana de Araujo, Jose Luis Serra Ribeiro, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_093.pdf",
      "name": "Inibição da corrosão do aço carbono empregando extrato de semente de mamão formosa",
      "authors": "Beatriz P. Ferreira, Brenda M. A. Marinho, Carla P. Vieira, Flávia C. R. Costa, Sarah G. Marquesa, Mônica Maria de Abreu Mendonça Schvartzman"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_095.pdf",
      "name": "Resultados da exposição atmosférica por dois anos de aço carbono e aço galvanizado pintados (sistema duplex) em duas estações da Petrobrás",
      "authors": "Ricardo Suplicy Goes, Renata Yuriko Ogura, Paulo Cesar Maziero Tiano, Celso Gnecco, Genaro Zanon, Marcelo Schultz, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_096.pdf",
      "name": "Efeito do meio de exposição atmosférica acelerada na avaliação da performance anticorrosiva de diferentes revestimentos aplicados nas ligas Al-Mn e Al-Mg",
      "authors": "Monica Scarabotto, Eliena J. Birriel, Lisete C. Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_098.pdf",
      "name": "Corrosion resistance and adhesion evaluation of press hardened steel 22MnB5 coated with AlSi and ZnNi in cyclic corrosion testing",
      "authors": "Camila P. Couto, Isolda Costa, Zehbour Panossian, Marco A. Colosio, Luiz G. F. P. de Campos, Jesualdo L. Rossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_102.pdf",
      "name": "Influência da Concentração de Cloreto de Sódio na Formação dos Filmes de Sulfeto de Ferro e Carbonato de Ferro no Aço API X65 em meios contendo CO 2 e H2S.",
      "authors": "Gonçalves, M. C., Santos, B. A. F., Souza, R. C., Júnior, E. M., Simões, T. A., Oliveira, J. R., Vaz, G. L., Caldeira, L., Gomes, J. A. C. P., Bueno, A. H. S."
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_103.pdf",
      "name": "Caracterização da corrosão do aço ABNT 1020 em ensaios de intemperismo atmosférico e salt spray",
      "authors": "Emílio J. Silva, Gabriel C. Santos, Allan M. L. Souza, Gonçalo Siqueira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_104.pdf",
      "name": "Avaliação da utilização de inibidores de flash rusting sob pintura anticorrosiva em condição de imersão a elevadas temperaturas",
      "authors": "Leonardo Mukim de Moraes, Arariboia Martins da Cruz Lobo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_105.pdf",
      "name": "AVALIAÇÃO ELETROQUÍMICA DOS PRODUTOS DE CORROSÃO DO AÇO CARBONO API X65 EM MEIOS CONTENDO CO2/H2S EM ELEVADAS PRESSÕES E TEMPERATURAS.",
      "authors": "Souza, R. C., Santos, B. A. F., Gonçalves, M. C., Júnior, E. M., Simões, T. A., Oliveira, J. R., Vaz, G. L., Caldeira, L., Gomes, J. A. C. P., Bueno, A. H. S"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_106.pdf",
      "name": "TESTES DE IMERSÃO E PERDA DE MASSA NO ESTUDO DO COMPORTAMENTO DO AÇO CARBONO API X65 EM AMBIENTE COM CO2/H2S EM ELEVADAS PRESSÕES E TEMPERATURAS.",
      "authors": "Souza, R. C., Santos, B. A. F., Gonçalves, M. C., Júnior, E. M., Simões, T. A., Oliveira, J. R., Vaz, G. L., Caldeira, L., Gomes, J. A. C. P., Bueno, A. H. S."
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_108.pdf",
      "name": "Avaliação da Corrosão Interna Utilizando Técnicas Intrusivas e Não Intrusivas em Condições Controladas em Laboratório",
      "authors": "Victor G. Silva, Gustavo L. Vaz, Pedro A. Ferreira, Anna Ramus, Neusvaldo L. de Almeida"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_109.pdf",
      "name": "Efeito do inibidor orgânico AMT na resistência à corrosão do aço carbono recoberto com filmes de TEOS em meio salino",
      "authors": "Lucas V. de A. Kornaenski, João Carlos S. S. Júnior, Dalva C. B. do Lago e Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_110.pdf",
      "name": "Validação de simuladores termodinâmicos para definição do meio corrosivo na produção de petróleo",
      "authors": "Bruno Diehl Neto, Ilson Palmieri Baptista, Flávio V. V. de Sousa, Luciana I. L. Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_111.pdf",
      "name": "Estudo das condições de produção de revestimentos AMT/TEOS para proteção à corrosão do aço carbono em meio salino",
      "authors": "João Carlos S. S. Júnior, Lucas V. de A. Kornaenski, Dalva C. B. do Lago e Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_113.pdf",
      "name": "Identificação do parâmetro chave na definição da severidade corrosiva do meio para CSTS no Aço Inoxidável Supermartensítico",
      "authors": "Bruno Diehl Neto, Ilson Palmieri Baptista, Flávio V. V. de Sousa, Luciana I. L. Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_114.pdf",
      "name": "Análise de corrosão no revestimento de produção de poços de petróleo em caso de falha do primeiro conjunto solidário de barreiras",
      "authors": "Bruno Diehl Neto, Ilson Palmieri Baptista, Jefferson Rodrigues de Oliveira, Rogaciano Maia Moreira, Merlin C. E. Bandeira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_116.pdf",
      "name": "Efeito da adição de íons cloretos na resistência à corrosão da liga AA1050 em soluções contendo sulfato",
      "authors": "Guilherme dos Santos Vacchi, Guilherme Koga, Carlos Alberto Della Rovere"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_118.pdf",
      "name": "Evaluation of pH stability and localized corrosion influence of various buffer solutions for simulated well condition",
      "authors": "Luciana I. L. Lima, Bruno Diehl Neto, Ilson Palmieri Baptista, Flávio V. V de Sousa, Paulo M. Cysne"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_122.pdf",
      "name": "Avaliação preliminar de inibidores verdes no controle da corrosão de aço carbono em meio ácido",
      "authors": "Ariane Varella da Silva, Amanda Lacerda das Chagas, Dalva Cristina Baptista do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_123.pdf",
      "name": "PROTEÇÃO CATÓDICA x INSCRUSTAÇÃO",
      "authors": "Hugo F. Goulart, José C. M. Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_124.pdf",
      "name": "Extrato de folhas de cafeeiro como inibidor de corrosão para aço carbono 1020 em meio ácido",
      "authors": "Amanda Lacerda das Chagas, Ariane Varella da Silva, Lilian Ferreira de Senna, Aderval Severino Luna, Dalva Cristina Baptista do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_126.pdf",
      "name": "Influência da concentração e tempo de imersão de tanino sobre revestimento nanocerâmico à base de Zr",
      "authors": "Pedro Lewgoy Martini; Josiane Costa; Henrique Ribeiro Piaggio Cardoso; Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_128.pdf",
      "name": "Dispositivos alternativos contra surtos de tensão em retificadores utilizados no sistema de proteção catódica",
      "authors": "Vitor ugo R. Souza, Alexandre V. Fonseca"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_129.pdf",
      "name": "Considerações sobre os possíveis mecanismos de corrosão no espaço anular de dutos flexíveis",
      "authors": "Helio Alves"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_132.pdf",
      "name": "Proteção anticorrosiva de partícula inteligente contendo inibidor de corrosão em meio agressivo",
      "authors": "Elber V. Bendinelli, Idalina V. Aoki, Fernando Cotting, Oswaldo E. Barcia, Isabel C. P. Margarit-Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_133.pdf",
      "name": "Influência da Renovação de Meio Corrosivo no Comportamento do Aço X65 quanto a Corrosão em Meios de CO2 e H2S",
      "authors": "Rogaciano M. Moreira, Cleison F. C. Nascimento, Merlin C. E. Bandeira, Oscar R. Mattos LNDC/COPPE/UFRJ, Flávia M. F. Guedes, Ilson P. Baptista CENPES/PETROBRAS"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_135.pdf",
      "name": "Avaliação Eletroquímica da Formação de Filmes de FeCO 3 na Corrosão do Aço API X65 em Meios Contendo CO2 e Ácido Acético.",
      "authors": "Mendes Júnior, E. P., Santos, B. A. F., Souza, R. C., Gonçalves, M. C., Simões, T. A., Oliveira, J. R., Vaz , G. L., Caldeira, L., Gomes, J. A. C. P., Bueno, A. H. S."
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_136.pdf",
      "name": "Testes de Imersão e Perda de Massa na Formação de Filmes de na Corrosão do Aço API X65 em Meios Contendo e Ácido Acético.",
      "authors": "Mendes Júnior, E. P., Santos, B. A. F., Souza, R. C., Gonçalves, M. C., Simões, T. A., Oliveira, J. R., Vaz, G. L., Caldeira, L., Gomes, J. A. C. P., Bueno, A. H. S."
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_137.pdf",
      "name": "Influência do revestimento super-hidrofóbico na resistência à corrosão em liga de Alumínio 5052",
      "authors": "Daiana Guerra Sacilotto, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_139.pdf",
      "name": "Recuperação de Zinco de Sucatas de Aço Galvanizado",
      "authors": "Larissa A. Carvalho, Victor Hugo C. A. Rego, Ladimir José de Carvalho, Simone L. D. C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_140.pdf",
      "name": "Avaliação de biocidas naturais no controle da biocorrosão em aço carbono 1020 por Desulfovibrio alaskensis",
      "authors": "Rodrigo S. Fonseca, Victoria de C. M. Ferreira, Dalva C. B. Lago, Lilian F. de Senna, Marcia M. M. Gonçalves"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_141.pdf",
      "name": "Avaliação d e Inibidores de Corrosão em meios com CO2 e alta temperatura",
      "authors": "Jefferson R. de Oliveira, Victor G. Silva, Alvaro A . O. Magalhães, Rosane F . de Brito, Tiago H. de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_142.pdf",
      "name": "Otimização das variáveis do processo de conversão de revestimento nanocerâmico à base de Titânio sobre aço galvanizado.",
      "authors": "Julia B. Saldanha; Henrique Ribeiro Piaggio Cardoso; Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_145.pdf",
      "name": "AVALIAÇÃO NO DESEMPENHO À CORROSÃO DA CORRENTE DE SOLDAGEM DNO REVESTIMENTO À PLASMA DA LIGA INCONEL ® 625 SOBRE O AÇO SAE 4130",
      "authors": "Letícia Caio, Davi Manhães e Eliena Jonko Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_146.pdf",
      "name": "Filosofia para seleção de metalurgia de poços produtores de óleo e gás",
      "authors": "Aline L.S. Gomes, Ilson Palmieri Baptista"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_147.pdf",
      "name": "Microstructural and E lectrochemical behavior of Welded Pipe A106 Gr . B and 316L with nickel alloys 82 and 182 in 3.5 wt. % NaCl solution",
      "authors": "Alisson H. Freire Vilela, Wagner R. Costa Campos, Luiza Esteves,Camila R. Oliveira Almeida, Bárbara A. Cardoso, Emerson G. Rabelo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_149.pdf",
      "name": "Denominações dos defeitos encontrados em superfícies pintadas e galvanizadas submetidas a ensaios acelerados de corrosão",
      "authors": "Taís Sabedot Pertile, Tanara Dariva Beux, Eliena Jonko Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_152.pdf",
      "name": "Avaliação das propriedades de adesão e de autorreparação de uma tinta aditivada com microcápsulas poliméricas contendo um formador de filme",
      "authors": "Fernando Cotting, Sérgio Henrique Cavallaro, Jorge Fernando Perreira Coelho, Mônica Marroig, André Koebsch e Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_155.pdf",
      "name": "Avaliação do desempenho de dois tipos de revestimentos antiaderentes e dois pré-tratamentos em substratos de alumínio Matéria",
      "authors": "Taíse Andreoli, Eliena Jonko Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_156.pdf",
      "name": "Efeito autorreparador em primer carregado com inibidor de corrosão encapsulado em haloisita combinado com esmalte base água",
      "authors": "Gabriela S. F. Carvas, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_158.pdf",
      "name": "Investigation on the effect of a bis-1,2-(triethoxysilyl) ethane film on the corrosion protection of AA2198-T8 aluminum-lithium alloy",
      "authors": "Rafael Emil Klumpp, Caruline de Souza Carvalho Machado, Mariana Xavier Milagre, João Victor de Sousa Araújo, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_159.pdf",
      "name": "Avaliação do tratamento de lixiviado de aterro sanitário pelo processo de eletrocoagulação utilizando eletrodos de ferro e alumínio",
      "authors": "Júlia Cesa Pagnusati, Taís Sabedot Pertile, Eliena Jonko Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_160.pdf",
      "name": "Avaliação da corrosão em tubos da fornalha baixa de caldeiras de recuperação química",
      "authors": "Patrícia A. Saliba, Renata B. Soares, Camila P. Pena, Vanessa F. C. Lins"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_161.pdf",
      "name": "Extrato de casca de banana (Musa AAB subgrupo Prata) como ini bidor verde de corrosão do aço carbono 1020 em ácido clorídrico 1M",
      "authors": "Ana Luiza G. Eurides, Aysla C. S. Mayrink, Bruno C. de Andrade, Igor M. Alves, Isabella L. de Souza, Matheus B. Barrouin, Pedro F. de Andrade"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_162.pdf",
      "name": "Influência da concentração de ácido fosfórico na anodização de uma chapa de alumínio",
      "authors": "Mirian P. dos Santos, Natal N. Regone"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_163.pdf",
      "name": "Influência da soldagem RFSSW na resistência à corrosão localizada no Al da junta dissimilar AA6016-DP600",
      "authors": "Willian S. de Carvalho, Guilherme S. Vacchi, Uceu Suhuddin, Jorge F. dos Santos, Carlos A. D. Rovere"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_164.pdf",
      "name": "Investigação da resistência à corrosão das ligas de alumínio 2024-T3 e 7475-T651 soldadas por fricção e mistura (FSW)",
      "authors": "Aline F. S. Bugarin, Maysa Terada, Fernanda M. Queiroz, Hercílio G.de Melo, Rubens N. F. Júnior e Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_166.pdf",
      "name": "Avaliação das características morfológicas da camada porosa produzida pela anodização da liga de Al 2024 em ácido sulfúrico-tartárico",
      "authors": "Israel L. M. Gonçalves, Simone L. D. C. Brasil, Denise S. de Freitas"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_167.pdf",
      "name": "Estudos de atividade antimicrobiana de produtos químicos de acordo com sua finalidade de uso, com enfoque na inibição de Bactérias Redutoras de Sulfato (m -BRS).",
      "authors": "Henrique M. Simon, Caroline F. da Silva, Antônio F. Montemor, Jonas G. dos Santos, Maria F. de A. Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_169.pdf",
      "name": "Avaliação dos revestimentos orgânicos de verniz, curados através de cura térmica e UV, aplicados sobre os revestimentos metálicos de alumínio (magnetron sputtering) e prata (eletrolítico) em amostras de zamac",
      "authors": "Josiele Davanzo, Taís Sabedot Pertile, Eliena Jonko Birriel"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_170.pdf",
      "name": "Influência dos graus de limpeza de superfície no desempenho anticorrosivo de tinta rica em zinco",
      "authors": "Isabela M. A. Silva, Idalina Vieira Aoki, Fernando Cotting, Elber V. Bendinelli, Marcos M. de Sá, Maria Cindra Fonseca, Isabel Cristina P. Margarit-Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_172.pdf",
      "name": "Avaliação do potencial biocida e da ecotoxicidade de proantocianidinas extraídas da fibra da casca de coco (Cocos nucifera)",
      "authors": "Douglas Guedes, Gabriel Martins, Daniela S. Alviano, Marcia T. S. Lutterbach, Leila Y. Resnik, Eliana F. C. Sérvulo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_174.pdf",
      "name": "Effect of surface preparation procedure on corrosion protection of electrodeposited silane films",
      "authors": "Gabriel B. Leoni, Carolina dos Santos Pereira, Denise Souza de Freitas, José Antônio da Cunha Ponciano Gomes, Simone Louise Delarue Cezar Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_177.pdf",
      "name": "ESTUDO COMPARATIVO DA CORROSÃO DOS AÇOS INOXIDÁVEIS DUPLEX E SUPERMARTENSÍTICO EM MEIO CONTENDO CO2",
      "authors": "Karina Rocha Fonseca Souza, Verônica Alves Veloso, Lucas Henrique de Oliveira Souza, Mônica Maria de Abreu Mendonça Schvartzman"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_178.pdf",
      "name": "Reparos em sistemas de pintura externa com Revestimentos de Alta Espessura – Que ações dão mais eficiência e durabilidade aos serviços de campo",
      "authors": "Diego G. Hita, Fabrício Mello Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_181.pdf",
      "name": "INFLUÊNCIA DO TEOR DE TITÂNIO NA CORROSÃO POR PITE DO AÇO INOXIDÁVEL SUPERMARTENSÍTICO 13 Cr",
      "authors": "Lucas Henrique de Oliveira Souza, Luiza Esteves, Wagner Reis da Costa Campos, Mônica Maria de Abreu Mendonça Schvartzman"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_183.pdf",
      "name": "Avaliação do Impacto da Dosagem de THPS na Corrosividade da Água do Mar Dessulfatada",
      "authors": "Flavia Maciel Fernandes Guedes, Eva Maria de Oliveira Paiva, Eduardo Gullo Muller"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_188.pdf",
      "name": "Corrosão de materiais metálicos em diferentes misturas diesel:biodiesel",
      "authors": "Cristie Luis Kugelmeier, Rodrigo da Silva, Marcos Roberto Monteiro, Sebastião Elias Kuri, Carlos Alberto Della Rovere"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_194.pdf",
      "name": "Desempenho de revestimentos anticorrosivo epóxi-fosfato de zinco de alta espessura com adição de argilas montmorilonitas em uma usina Termelétrica a carvão",
      "authors": "Daniel de Castro Girão, Úrsula Cid Pereira, Adriana de Oliveira Sousa Leite, Walney Silva Araújo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_195.pdf",
      "name": "Caracterização e Resistência à Corrosão da Metalização Direta de Níquel Sobre Aço 1020 Via Processo Autocatalítico.",
      "authors": "Dayanna D. R. Nascimento, Ricardo S. Cunha, Francisco J. N. Santos, Gecilio P. Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_196.pdf",
      "name": "Desenvolvimento e caracterização d e revestimentos de conversão à base de terras raras sobre liga de magnésio AZ91D",
      "authors": "Eloana Patrícia Ribeiro, Renato Altobelli Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_197.pdf",
      "name": "Análise da resistência à corrosão em aços de aços para estampagem a quente revestidos com camada protetiva de alumínio-silício",
      "authors": "Marilia F. Bolsanello, Marco A. Colosio, Isolda Costa, Jesualdo L. Rossi"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_198.pdf",
      "name": "Influence of the Ce sealing bath temperature on the corrosion resistance of TSA AA2524",
      "authors": "Maysa Terada, Fernanda M. Queiroz, Victor Hugo Ayusso, Hellen Costenaro, Hercílio G. de Melo, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_199.pdf",
      "name": "Monitoramento e estudo comparativo da corrosão do aço galvanizado e do aço carbono em diferentes soluções contendo fosfato com variação de temperatura",
      "authors": "Karina T. Pereira, Dalila M. da Silveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_200.pdf",
      "name": "Distintas formas de corrosão das armaduras de estruturas em concreto armado: uma revisão",
      "authors": "Tatiana Conceição Machado Barretto, Igor Machado da Silva Parente"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_201.pdf",
      "name": "Avaliação da taxa de corrosão no revestimento de produção de poços submarinos produtores de petróleo que operam com gas-lift",
      "authors": "Bruno Diehl Neto, Ilson Palmieri Baptista, Júlio Endress Ramos, Eduardo Gullo Muller Lopes, Javier A. C. Velasco"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_203.pdf",
      "name": "INFLUÊNCIA DOS TRATAMENTOS TÉRMICOS NAS PROPRIEDADES MECÂNICAS E RESISTÊNCIA À CORROSÃO DO AÇO INOXIDÁVEL AISI 420",
      "authors": "Péricles Bosquetti , Oscar Q. Grinhal , Otávio G. Contarta, Maurício Angelonia, Diógenes Bosquettia"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_204.pdf",
      "name": "Revestimentos Elastoméricos para Reabilitação de Dutos",
      "authors": "Erik B. Nunes, Jeferson L. Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_206.pdf",
      "name": "Sistema de Junta de Campo Soldados por Eletrofusão para Dutos Isolados Termicamente",
      "authors": "Erik B. Nunes, Vinicius Giorgetti"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_207.pdf",
      "name": "O ESTADO DA ARTE DA CORROSÃO PELO ETANOL COMBUSTÍVEL",
      "authors": "Célia Aparecida Lino dos Santos; Lorena Cristina de Oliveira Tiroel; Elcio Cruz de Oliveira; Neusvaldo Lira de Almeida"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_209.pdf",
      "name": "Monitoramento da corrosão de aço galvanizado em soluções de fosfato em meio ácido",
      "authors": "Thalyta Fonseca Silva, Dalila Moreira da Silveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_211.pdf",
      "name": "Fatores que influenciam a corrosão em pontes metálicas na presença de meio aquoso com alta concentração de NaCl",
      "authors": "Ana Laura Alves Oliveira, Daniel Oliveira Alves, Jhonata Moreira Leite, Nathannyel Araújo Costa, Nelson Alexandre Ruas, Priscila Oliveira Chaves, Érica Karine Ramos Queiroz"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_212.pdf",
      "name": "Efeitos do CO2 e ciclos de pressão em revestimentos compósitos anticorrosivos",
      "authors": "Áquila V . Nascimento, Thiago C. da Silva, Larissa A. Gouvêa, Rafael A . Amaral, Rogaciano M. Moreira, Merlin C. E. Bandeira, Isabel C . P. Margarit-Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_213.pdf",
      "name": "Corrosão sob tensão de aço inoxidável austenítico soldado em ambiente evaporativo de gotejamento de água do mar sintética",
      "authors": "Henrique B. Pereira, Marcelo F. Moreira, Neusvaldo L. de Almeida, Hélio C. Miranda, Ilson Palmieri Baptista"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_214.pdf",
      "name": "Comportamento de oxidação em elevada temperatura de uma liga FeMnSiCrNiCo com efeito de memória de forma",
      "authors": "Rodrigo Silva, Camila Arana, Artur M. S. Malafaia, Anibal Mendes, Carlos A. Della Rover"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_215.pdf",
      "name": "Estudo de atmosferas agressivas em usinas termelétricas a carvão com FGD semi-seco: desenvolvimento de metodologia de diagnóstico e proteção.",
      "authors": "Adriana de O. S. Leite, Sílvia B. F. de Souza, Priscilla F. de O. Ferreira, Darley da S. Lima, Úrsula C. Pereira e Walney S. Araújo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_216.pdf",
      "name": "Estudo da corrosão atmosférica dos aços patináveis, carbono e inoxidáveis expostos em site de uma termoelétrica no Ceará.",
      "authors": "Úrsula C. Pereira, Valeria S. dos Santos, Sílvia B. F. de Souza, Priscilla F. de O. Ferreira, Darley da S. Lima, Egnalda P. S . Pimenta, Adriana de Oliveira S. Leite e Walney S. Araújo"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_218.pdf",
      "name": "Utilização de um processo sol-gel baseado no método de Pechini como pré-tratamento alternativo para aço carbono",
      "authors": "Yuri H. P. Café; Emerson S. Ribeiro; Eliane D’Elia; Elber V. Bendinelli; Cristina C. Amorim; Alberto P. Ordine "
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_219.pdf",
      "name": "Influência do tratamento químico da haloisita no seu carregamento com inibidor de corrosão dodecilamina para obtenção de tintas autorreparadoras",
      "authors": "Nayarit Mata, Fernando Cotting, Sergio Cavallaro, Nathalie Ochoa, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_222.pdf",
      "name": "Análise da resistência à corrosão em revestimentos a base de cobalto depositadas pelo processo GMAW-CW.",
      "authors": "Tárcio dos S. Cabral, Lino A. S. Rodrigues, Ademir A. Castro Filho, Francisco F. Barbosa Junior , Eduardo de M. Braga"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_223.pdf",
      "name": "CORROSÃO DAS ESTRUTURAS PÓS RECUPERAÇÃO DA CATEDRAL METROPOLITANA DE NATAL: um estudo de caso",
      "authors": "Daniela Maria Arruda Rocha, Victor de Paiva Maia, Fábio Sérgio da Costa Pereira Carlindo, Avelino Bezerra Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_225.pdf",
      "name": "Comportamento frente à corrosão de uma liga fundida Al - 3%Zn - 1%Mg em solução de NaCl",
      "authors": "Freitas. Emmanuelle Sá, Cruz. Clarissa Barros, Bertelli  Felipe, Garcia. Amauri"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_226.pdf",
      "name": "Corrosão em soldas aéreas revestidas com massa epoxi para instalação de dispositivos de proteção catódica.",
      "authors": "Valéria Martins, Marcus Fischer Nunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_228.pdf",
      "name": "Considerações gerais sobre proteção catódica por corrente impressa em navios",
      "authors": "Aldo Cordeiro Dutra, Laerce de Paula Nunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_229.pdf",
      "name": "Termografia Aplicada na Manutenção Preditiva de Retificadores de Proteção Catódica – Estudo de Caso",
      "authors": "Rodrigo M.S. Soares, André L.O. Silva, Mário C.M.F. Souza, Henrique K. Miyamoto, Márcio Z. Fortes, Vitor H. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_230.pdf",
      "name": "Influência do pré-aquecimento no desempenho corrosivo do aço carbono revestido com alumínio (Al) por aspersão térmica imerso em solução de cloreto.",
      "authors": "Silva J. Maurilio, Vanat K. Juliana, Pukasiewicz A. G. Marenda, Paredes R. S . Cortes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_231.pdf",
      "name": "Rede Neural Aplicada em Sistemas de Proteção Catódica – Estudo de Caso",
      "authors": "Rodrigo M.S. Soares, Vitor H. Ferreira, Márcio Z. Fortes"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_232.pdf",
      "name": "Self-Healing Hydrotalcite Coatings for Corrosion Protection of Aluminum-Clad Spent Nuclear Fuels During Long Term Wet Storage",
      "authors": "Stela Maria de Carvalho Fernandes, Olandir Vercino Correa, José Antonio Batista de Souza, Renato Altobelli Antunes, Nels on Batista de Lima, Lalgudi Venkataraman Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_233.pdf",
      "name": "Influência da variação anual da resistividade elétrica do solo no desempenho de sistema de proteção catódica de dutos terrestres",
      "authors": "José Leonardo Resende"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_235.pdf",
      "name": "Avaliação Preliminar das Taxas de Corrosão e Deposição em Meio Atmosférico na Região Metropolitana de São Luís - MA.",
      "authors": "Fernando Célio Monte Freire Filho, Edson Hiroharo Vieira Togawa, Gabriel Alves Cantanhede, Vallena Maria Macêdo Rezende, Claudemir Gomes de Santana, Rafael Feres Moreira Lima, Renata Medeiros Lobo Muller"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_236.pdf",
      "name": "GROUNDING ELECTRODE CORROSION DIAGNOSING - SIGNAL ANALYSIS",
      "authors": "Cleberson L. do Nascimento, Alysson N. Diógenes, Haroldo A. Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_237.pdf",
      "name": "Ação de Biocidas como Sequestrantes de H2S",
      "authors": "André Luiz Castro Bonfim, Claudia Groposo, Maíra Paula de Sousa, Alcimar Cardoso"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_239.pdf",
      "name": "Avaliação de Sequestrantes de H2S para aplicação em Offloading",
      "authors": "André Luiz C. Bonfim, Alvaro O. Magalhães, Alcimar Cardoso, Warlley L. Antunes, Guilherme C. Menezes, Eduardo C. Bastos, Luiz S. C. Junior, Hercilio Honorato"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_246.pdf",
      "name": "Revestimento Epóxi Novolac de Alto Desempenho à Corrosão para Aplicação em Superfícies Ferrosas Preparadas por Meio de Ferramentas Mecânicas, nos Serviços de Manutenção Industrial",
      "authors": " Eder Dirceu Dela Justina, Thiago Elias Bastos"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_247.pdf",
      "name": "Uso de compostos elastoméricos emulsionados em água e aplicados a frio para proteção anticorrosiva de frestas e da interface aço e concreto",
      "authors": "Marcelo Tinôco, Bárbara Bravo, Renata Ramos, Gerson Vieira"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_248.pdf",
      "name": "Monitoramento Remoto de Retificadores de Proteção Catódica",
      "authors": "Magno C. da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_249.pdf",
      "name": "Isolamento Térmico para equipamentos em operação em ambientes que necessitem de proteção contra fogo",
      "authors": "Priscila F. P. Tavares, Celso Gnecco, Felipe Naciuk"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_253.pdf",
      "name": "Estudo do Comportamento do aço API 5l X70 em contato com solos urbanos de Palmas - TO.",
      "authors": "Alexon Braga Dantas, Isolda Costa,Sérgio Luís de Jesus"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_254.pdf",
      "name": "Avaliação de camada de galvaniza ção sobre imãs de Nd-Fe-B usados para levitação magnética",
      "authors": "Victor Hugo Cardozo Americano Rego, Larissa Araújo Carvalho, Ladimir José de Carvalho, Simone Louise Delarue C. Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_256.pdf",
      "name": "Silanes and tannin coatings: com parison between single and double layers",
      "authors": "Pedro H. P. de Moraes, Álvaro Meneguzzi, Henrique Ribeiro Piaggio Cardoso"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_259.pdf",
      "name": "O DESAFIO DA AVALIAÇÃO DO CORROSIVIDADE EM DUTOS DE OPERAÇÃO INTERMITENTE",
      "authors": "Zehbour Panossian, Lorena Cristina de Oliveira Tiroel, André Luiz Castro Bonfim, Vanessa Yumi Nagayassu Ferrari"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_263.pdf",
      "name": "Conformabilidade e resistência à corrosão de eletrodepósitos de zinco",
      "authors": "Fabiano R. Santos, Zehbour Pannosian"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_264.pdf",
      "name": "Sulfide Stress Cracking propagation threshold behavior of a low alloy steel at low and high bubble pressure.",
      "authors": "Javier A. C. Velasco, Bruno Diehl Neto, Ilson Palmieri Batista, Rodrigo V. Landim"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_266.pdf",
      "name": "Estudo da Cinética de formação de H2S in situ a partir de soluções contendo ânion tiossulfato",
      "authors": "Felippe S. Kerpen, Ettore Pilla,Tiago Renck, Tiago Falcade"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_267.pdf",
      "name": "Estudo das propriedades da tinta epóxi-Nb2O5 aplicada na Indústria Química",
      "authors": "Maria E. D. Serenário, Fernando T. Abreu, Rhuan C. Souza, Bernardo A. F. Santos, Luiz R. M. Miranda, Alysson H. S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_268.pdf",
      "name": "EFEITO DO TEOR DE FERRO NAS PROPRIEDADES ELETROQUÍMICAS DA LIGA INCONEL 625 UTILIZADA COMO REVESTIMENTO EM TUBULAÇÕES PARA CAPTAÇÃO DE PETRÓLEO",
      "authors": "Raisa Rodrigues, Julio Cesar Lourenço, Maria Ismenia Sodero Toledo Faria, Alain Laurent Marie Robin, Carlos Angelo Nunes, Christian Egidio da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_269.pdf",
      "name": "Esquemas de pintura de proteção anticorrosiva para condições especiais de exposição",
      "authors": "Neusvaldo Lira de Almeida; Márcio Bispo de Almeida; Taline Diir Lima; Bernardo Batista Rocha; Vitor Ribeiro Orrico"
    },
    {
      "url": "https://intercorr.com.br/anais/2018/INTERCORR2018_271.pdf",
      "name": "Compatibilidade de Aço Carbono em Etanol Combustível com Teores Crescentes de Água",
      "authors": "Mona A. Abdel Rehim, Lílian Ferreira de Senna, Dalva C. B. do Lago, Eduardo Homem de Siqueira Cavalcanti"
    }
  ]
}

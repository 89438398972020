export const EDICAO_2010 = {
  "message": '',
  "data": [
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_006.pdf",
      "name": "Corrosão nas armaduras de concreto armado",
      "authors": "Felipe Elder Cruz Lopes, Juscelino Chaves Sales"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_009.pdf",
      "name": "Utilização De Resinas De Poliuretano Para Impermeabilização De Estruturas De Concreto Armado",
      "authors": "Fábio Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_011.pdf",
      "name": "Revestimento orgânico interno de válvula esfera: Caso prático.",
      "authors": "André Koebsch, Erik Barbosa Nunes, Fabrizio Cruz de Almeida"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_018.pdf",
      "name": "Corrosão do núcleo de placas combustíveis a base de dispersões U 3O8-Al",
      "authors": "Michelangelo Durazzo, Lalgudi V. Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_021.pdf",
      "name": "Acompanhamento da Aplicação dos Revestimentos Anti -Corrosivos na Indústria de Construção e Montagem Offshore",
      "authors": "Ronaldo Rollin Pinheiro, Fernando Benedicto Mainier, Miguel Luiz Ribeiro Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_023.pdf",
      "name": "Sistema Sintático para Revestimento Anticorrosivo e Isolamento Térmico de Duto Submarino em Marlim Sul",
      "authors": "Fabio B. de Azevedo, Guilherme E . Haverroth, Bruno R. M. da Cunha, R odrigo T. B. de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_024.pdf",
      "name": "Análise Estrutural De Cortina Atirantada Em Iminência De Colapso Devido À Corrosão De Armaduras",
      "authors": "Fábio Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_025.pdf",
      "name": "Electrochemical impedance spectroscopy as a technique for characterization of the oxide film formed on nickel alloy 52 at 325 °C",
      "authors": "Célia A. Figueiredo, Rik -Wouter Bosch"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_026.pdf",
      "name": "Inovação Tecnológica: Processo de Autodeposição na substituição do E-coat",
      "authors": "Kelly Bossardi, Pelayo Munhoz Olea"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_027.pdf",
      "name": "Influência da condutividade do solo na resposta do eletrodo de referência permanente com cupom",
      "authors": "Telma Regina Villela, Vânia Mori, Hosam Abdel -Rehim, Maurício Guerrante, João Hipólito Oliver"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_028.pdf",
      "name": "Estudo da sensitização de aço inoxidável supermartensítico através da técnica de polarização eletroquímica de reativação cíclica (PERC ou DL -EPR)",
      "authors": "Gustavo F. da Silva, Sérgio S.M. Tavares, Fernando B. Mainier"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_030.pdf",
      "name": "Caracterização eletroquímica e superficial de revestimentos metálicos à base de pentóxido de nióbio aspergidos em aço carbono.",
      "authors": "José Maurílio Silva, Oscar Regis Junior, Ramon S.C. Paredes, Alfredo José Zara, Kleber F. Portella, Princia Ap. M. Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_031.pdf",
      "name": "Comportamento eletroquímico do Ti -cp e das ligas Ti -Ta em soluções fisiológicas",
      "authors": "Rodrigo R . A. Garcia 1 , Alain Robin 2 , Jorge L . Rosa 3"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_032.pdf",
      "name": "RECUPERAÇÃO, REFORÇO E IMPERMEABILIZAÇÃO DA CATEDRAL METROPOLITANA DE NATAL",
      "authors": "Fábio Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_034.pdf",
      "name": "Mitigação da corrosão de estruturas enterradas por modificação do solo",
      "authors": "Catalina Córdoba, Mario A. Mejía, Jorge A. Calderón, Félix Echeverría, Marcela Morales"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_035.pdf",
      "name": "Avaliação eletroquímica da corrosão de materiais metálicos usados em redes de distribuição de água potável",
      "authors": "John Fredy Rios, Jorge A. Calderón"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_036.pdf",
      "name": "Corrosividade do etanol anidro , do etanol hidratado e da mistura combustível E25 (25% etanol/75% gasolina)",
      "authors": "Anna Ramus Moreira; Zehbour Panossian; Gislaine Maria Bragagnolo; Célia A. Lino dos Santos; Marcelo C. Gandur; Emerson Monteiro de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_038.pdf",
      "name": "Otimização do processo de fabricação de peças para a indústria aeronáutica",
      "authors": "Faria, M.I.S.T., Robin A., Prisco, L.P., Puccini, M.C., Go nçalves, D.C., Lourenço, J.C., Silva, G."
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_040.pdf",
      "name": "Revestimentos nanoestruturados para proteção anticorrosiva.",
      "authors": "Cláudia B. Pelizaro, Daniel T. Minozzi, Luiz Gustavo P. Simões, André L. Araujo, Mauricio R. B. Delmonte"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_041.pdf",
      "name": "TRAT AMENTOS SUPERFICIAIS PARA O AÇO -CARBONO: NANOTECNOLOGIA COMO ALTERNATIVA AO FOSFATO DE ZINCO",
      "authors": "Kelly Bossardi, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_043.pdf",
      "name": "Melhoramento de solos não corrosivos para uso em aterramento elétrico visando casamento de impedância com o cabo de descida de postes de distribuição.",
      "authors": "José Maurílio Silva, Edson da Silva Dias, Célio de Souza Brandão Filho, Rodolfo Cesar Bathke, Carlos Barros Rodrigues, Dailton P. Cerqueira, Flávio C. Andrade, Rogério Salles, Princia Ap. M. Pereira, Rosane M. Ribas, Mário S. Cabussú, Kleber F. Portella, Carlos A. Sotille"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_046.pdf",
      "name": "Estudo do Comportamento Eletroquímico do aço -carbono, em meio de bicarbonato de sódio e gás carbônico, por técnica de Ruído Eletroquímico",
      "authors": "Hellen C. N. Domingues, Haroldo A. Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_048.pdf",
      "name": "Pinturas Anticorrosivas Nanoestructuradas",
      "authors": "Andrea M. Pereyra y Carlos A. Giudice"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_049.pdf",
      "name": "Influencia Del Tipo De Fibra De Refuerzo Y Del Material Formador De Película En Pinturas De Terminación Aplicadas Sobre Fondos Anticorrosivos Basados En Zinc Metálico",
      "authors": "Andrea M. Pereyra, Paula Alfieri, Carlos A. Giudice"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_052.pdf",
      "name": "Estudo eletroquímico da influência da microstrutura e do tempo de permanência do substrato na solução de hidrólise de um sulfossilano em diferentes phs na proteção contra corrosão do aço carbono “galvannealed”",
      "authors": "era R. Capelossi, Paulo R. de Souza, Idali na V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_055.pdf",
      "name": "Efeito da adição de níquel nos espaçamentos dendríticos secundários e na resistencia à c orrosão de ligas hipoeutéticas Al -Ni.",
      "authors": "Giselle B. Costa, Fernando A. de Sá,Célia M. A. Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_058.pdf",
      "name": "Eliminação de Processo Corrosivo em Linhas de Topo de Torres Extratoras de Oxigênio de Unidades HDS",
      "authors": "Hezio Rosa da Silva, Wagner Batista de Oliveira, José Lana Cardoso"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_059.pdf",
      "name": "Formulação de uma mistura de inibidores de corrosão para aço carbono ABNT 1010 em meio de ácido clorídrico 2,0 M.",
      "authors": "Victor C. Moreira, Maria E. S. Taqueda, Isabel C. Guedes"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_061.pdf",
      "name": "Influência do tratamento térmico na microestrutura e susceptibilidade à sensitização do aço inoxidável AISI 430",
      "authors": "Vanessa M . de Souza, Aline Y . Kina, Sérgio S . M . Tavares, B runo A. R. Souza Barbosa, Fernando B . Mainier, Talles Montenegro"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_062.pdf",
      "name": "Influência da microestrutura na resistência à corrosão por pites do aço AISI 904L 1",
      "authors": "Bruno Alberto Ramos Souza Barbosa, Sérgio Souto Maior Tavares, Vitor Correia da Costa, Lorena Menezes, Juan Manuel Pardal, Victor Moita Pinheiro"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_065.pdf",
      "name": "Estudo das Propriedades de Ligas Fe -Cr usadas como Revestimentos Metálicos Aspergidos Termicamente para aplicações em meios corrosivos",
      "authors": "Hector Reynaldo Meneses Costa, Vinícius Ribeiro dos Santos de Sá Brito, Ivan Napoleão Bastos, Thiago Daflon dos Santos, Yasmin Kronemberger"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_066.pdf",
      "name": "Efeito dos parâmetros do processo de selagem sobre a coloração e a resistência à corrosão de peças de alumínio anodizadas por anodização crômica",
      "authors": "Prisco, L.P., Puccini, M.C.,Gomes J, Faria, M.I.S.T., Robin A."
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_067.pdf",
      "name": "Uma Metodologia Alternativa para Determinar a Agressividade Atmosférica",
      "authors": "Mauro Zanini Sebrao, Alberto Pires Ordine"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_070.pdf",
      "name": "Efeito de parâmetros de soldagem na resistência à corrosão intergranular de aço inoxidável com 11 %Cr avaliada pelo método de reativação eletroquímica",
      "authors": "Gueder Alves Assumpção, Sergio Souto Maior Tavares, Temístocles de Sousa Luz e Marcelo Camargo Sever o de Macedo"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_071.pdf",
      "name": "Caracterização eletroquímica da liga Ti – 6Al – 4V revestida por hidroxiapatita através dos processos sol-gel e eletroforese",
      "authors": "Sil F. dos S. Quaresma, José C. C. Filho, Ivan N. Bastos, Gloria D. de A. Soares, Eduardo P. Avés"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_076.pdf",
      "name": "Influência do mecanismo de dano por carburização na fratura de tubos de fornos de pirólise em serviço",
      "authors": "Paulo Moura Bispo de Santana, Sergio Rodrigues Barra"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_081.pdf",
      "name": "A influência do CS 2 sobre a corrosividade em sistemas de destilação extrativa que usam acetonitrila como solvente de extração.",
      "authors": "Reinaldo Ribeiro do Nascimento"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_086.pdf",
      "name": "Estudo do processo de osseointegração de implantes à base de Ti via eletroquímica",
      "authors": "Bruno S. Gugelmin, Haroldo de A. Ponte, Claudia E.B. Marino"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_087.pdf",
      "name": "Avaliação da proteção contra corrosão fornecida por filme de passivação isento de cromo hexavalente em chapas galvanizadas aplicado em linha de galvanização contínua",
      "authors": "Alberto Nei C. Costa, Roberto T . Renó, Leonardo C. Machado, Marcelo P . de Oliveira, M arcio P . Bastos, José Eduardo R. Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_090.pdf",
      "name": "Desempenho De Tintas De Acabamento Frente Às Radiações Ultravioleta, U VA e UVB , E Condensação De Umidade",
      "authors": "Fernando Fragata, Cristina C. Amorim, Alberto P. Ordine"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_091.pdf",
      "name": "Ensaios De Aderência Em Tintas E Esquemas De Pintura - Análise De Resultados Obtidos Por Diferentes Métodos",
      "authors": "Fernando Fragata, Cristina C. Amorim, Alberto P. Ordine"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_092.pdf",
      "name": "Desempenho De Esquemas De Pintura Com Intervalos Curtos Entre Demãos - Alternativas Para A Redução Do Tempo De Execução Da Pintura",
      "authors": "Fernando Fragata, Cristina C. Amorim, Alberto P. Ordine"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_093.pdf",
      "name": "Monitoração Da Corrosão Em Dutos E Tubulações Industriais Através De Sensores Permanentes De Ondas Guiadas - PIMS",
      "authors": "Francisco C. R. Marques, Rafael W. F. Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_094.pdf",
      "name": "Corrosion Protection Of Flanges, Valves And Welded Joints Application Experience In Petrobras Facilities",
      "authors": "M. Schultz, R. Singh, E. Lyublinski, Y. Vaks, M. Posner, T. Natale"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_095.pdf",
      "name": "Corrosion Protection of Oil Storage Tank Tops",
      "authors": "Efim Lyublinski, Yefim Vaks, Marcelo Schultz, Joelma Damasceno, Ronnie Singh"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_097.pdf",
      "name": "Ti-Mo: Caracterização E Estudo De Resistência À Corrosão Em Meio Fisiológico",
      "authors": "Nilson T. C. Oliveira, Antonio C. Guastaldi"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_099.pdf",
      "name": "Caracterization of corrosion process in Bauxite Slurry Pipeline",
      "authors": "Otilio Othon, Gerson Melo, Romulo Rufino, Marco Veisac, Fabio Mendes, Geraldo Brittes, Francisco Edvan, Luis C. Mascarenhas, Arquimedes Andreda, José A. Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_102.pdf",
      "name": "Corrosão sob tensão em pH próximo ao neutro em gasoduto",
      "authors": "André S. Pelliccione, Arthur E. de Lima, Jorge L . R. Galvão, Hervandil M. Santanna, Milton F. Moraes, Murilo F . Leal, Wallace S. Carmona"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_103.pdf",
      "name": "Comparação da resistência à corrosão por pite em água do mar nos aços inoxidáveis superaustenítico, superduplex e supermartensítico",
      "authors": "César Augusto Duarte Rodrígues, Germano Tremiliosi Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_105.pdf",
      "name": "Integridade Dos Dutos: Pig De Limpeza X Corrosão Interna Em Dutos – Eficiência Aliada A Baixo Custo",
      "authors": "Milene L. A. Desmarais, Carlos Augusto S. M. Cardozo"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_106.pdf",
      "name": "Obtenção de revestimentos de nióbio depositados por aspersão térmica para proteção a corrosão marinha",
      "authors": "Carolina Mariano, Ramón S. C. Paredes"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_114.pdf",
      "name": "Gerenciamento de Pintura Industrial",
      "authors": "Laerce de Paula Nunes, Gedeon Oliveira, Anderson T. Kreischer"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_116.pdf",
      "name": "Development of coatings to dental implants applications",
      "authors": "Peterson de M. Batista, Henrique R. P. Cardoso, Luciana M. Rodrigues, Roberto M. Schroeder, Alexandre F. Galio, Iduvirges Lourdes Müller"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_119.pdf",
      "name": "Avaliação da Proteção Catódica de Tubulações em Furos Direcionais",
      "authors": "Simone L. D. C. Brasil, J.Fernando P. Coelho, José Cláudio F. Telles, José Antonio F. Santiago"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_123.pdf",
      "name": "ESTUDO DO EFEITO DE MOLÉCULAS AUTO-ORGANIZÁVElS E DO CRESCIMENTO DE ÓXIDO COM PARTÍCULAS CERÂMICAS DE ZIRCÔNIO NA SUBSTITUIÇÃO DA CROMATIZAÇÃO PARA A PROTEÇÃO CONTRA A CORROSÃO DO ALUMÍNIO 1050",
      "authors": "Wagner Izaltino Alves dos Santos, Solange de Souza, Marcelo de Oliveira, Daniel Sierra Yoshikawa, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_124.pdf",
      "name": "INFLUÊNCIA DOS COMPONENTES E DA COMBINAÇÃO SUBSTRATO/REVESTIMENTO NA RESISTÊNCIA À CORROSÃO DAS FECHADURAS DE EMBUTIR COMERCIALIZADAS NO BRASIL",
      "authors": "Vera Fernandes Hachich, Edwiges Souza Ribeiro, Evelyne Vaidergorin"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_125.pdf",
      "name": "Corrosão de grafite em eletrolisador tipo PEM",
      "authors": "Marco A. O liveira da Silva, Marcelo Linardi, Adonis M. Saliba -Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_126.pdf",
      "name": "Análise de biofilmes em superfície metálica submetida a fluido oleoso com baixo BSW",
      "authors": "Walter Barreiro Cravo Júnior, Karla de Avellar Mota, Ivani de S. Bott, Maria Isabel Pais da Silva, Mônica de Oliveira Penna"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_129.pdf",
      "name": "ESTUDO DAS OSCIL AÇÕES DO AÇO UNS S30400 COM SOLUÇÕ ES ÁCIDAS ATRAVÉS DE ENSAIOS DL -EPR E CRONOAMPEROMETRIA",
      "authors": "Marcos Paulo Moura de Carvalho, Ivan Napoleão Bastos, Thiago José Mesquita, Ricardo Pereira Nogueira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_134.pdf",
      "name": "Estudo comparativo do comportamento eletroquímico de uma liga Co-Cr em meio de NaCl 0,15 mol/L e Saliva artificial",
      "authors": "Klester S. Souza, Daniela C. Bataglioli, Ruth F.V.V. Jaimes, Silvia M.L. Agostinho"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_136.pdf",
      "name": "Lanthanide based conver sion coatings to protect alumin um alloys",
      "authors": "S. M. Fernandes, O. V. Correa, J. A. de Souza, L. V. Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_137.pdf",
      "name": "Optimization of rare earth additions to increase high temperature oxidation resistance of chromia and alumina forming alloys",
      "authors": "Stela M. Fernandes, Olandir V. Correa, Lalgudi V.Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_138.pdf",
      "name": "Evaluation of the healing action of cerium as corrosion inhibitor silanes films obtained on the AA2024 -T3 alloy",
      "authors": "Marlla V. da Costa, Julien Esteban, Florence Ansart, Jean -Pierre Bonino, Tiago L. Menezes, Iduvirges L. Müller and Célia F. Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_139.pdf",
      "name": "Efeito do Potencia l de Proteção Catódica Sobre a Biocorrosão de Aço -Carbono em Solo Contendo BRS.",
      "authors": "Tatiana de Campos Rodrigues, Eliana Flávia Camporese Sérvulo, Simone Louise Delarue Cezar Brasil"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_140.pdf",
      "name": "Anodização de aços inoxidáveis como tratamento superficial para aplicação em temperaturas elevadas",
      "authors": "Tiago Falcade, Tiago Lemos Menezes, Célia F. Malfatti, Cláudia T. Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_141.pdf",
      "name": "Investigação De Oxidação Seletiva Em Aço Dual Phase",
      "authors": "Laureanny Madeira, Vanessa C. F. Lins, Maria das Mercês R. Castro, Guilherme A. Farias, Vicente T. L. Buono, Juliana P. Guimarães, Evandro A. Alvarenga"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_142.pdf",
      "name": "Estudo da cinética de oxida ção em alta temperatura de uma liga à base de Níquel em temperaturas entre 660 e 900 °C",
      "authors": "José Henrique Alano, Carlos Alberto Della Rovere, Sebastião Elias Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_146.pdf",
      "name": "Construção De Procedimentos Experimentais Para Curso Técnico Da Área Naval A Partir Da Adaptação De Normas ASTM",
      "authors": "Marcos Paulo Moura de Carvalho, Ivan Napoleão Bastos, Alex Sander Duarte da Matta, Andrew Martins Araújo, Egberto Temperini, Thaís Mal heiros, Thiago Barcelos"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_147.pdf",
      "name": "Controle Microbiológico em Águas de Reúso em Torres de Resfriamento",
      "authors": "Andréa Azevedo Veiga, Lídia Yokoyama, Juacyara Carbonelli Campos, Luiz Alberto Cesar Teixeira, Vânia Maria Junqueira Santiago"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_148.pdf",
      "name": "Fluoropolymers for the Chemical Processing Industry Applications",
      "authors": "J.Karol Argazinski, José Alex P. Sant’Anna, Marcos R. Tristante"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_149.pdf",
      "name": "A grandeza potencial elétrico e os diferentes conceitos envolvidos em eletroquímica e corrosão",
      "authors": "Augusto A. Neto, Silvia M.L. Agostinho, Luís G.N. Barbosa, Ruth F.V.V. Jaimes, Klester S. Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_150.pdf",
      "name": "Aço carbono e e stabilidade oxidativa do biodiesel de soja : efeito do tempo, temperatura, agitação e concentração de antioxidante",
      "authors": "Yara P. da Silva, Edilene S. Auzani, Denise S. Azambuja, Clarisse M. S. Piatnicki"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_153.pdf",
      "name": "Estudo dos eletrodos a serem utilizados na técnica de remediação eletrocinética",
      "authors": "Nice M.S.Kaminari, Renata B.G. Valt, Michele Maidel, Maria José J.S. Ponte, Haroldo A. Ponte"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_154.pdf",
      "name": "Densidade De Depósito Uma Técnica Para Avaliação Da Limpeza Interna De Caldeira",
      "authors": "Antonio Sergio Barbosa Neves, Francisco Antonio Passos"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_155.pdf",
      "name": "Formação De Biofilmes E Consequências Sobre O Aço Carbono AISI - 1020 Exposto Em Sistema Dinâmico Contendo Como Fluido Água + 10% Da Mistura Petrodiesel/Biodiesel (B3) Comercial",
      "authors": "Francisca Pessoa de França, Ivanilda Ramos de Melo, Severino Urtiga Filho, Fernando Jorge S. Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_159.pdf",
      "name": "Avaliação da espécie vegetal Croton cajucara Be nth como inibidor de corrosão de aço AISI 1020 em meio salino",
      "authors": "Maria B . M. C. Felipe, Gineide C . dos Anjos, Djalma R . da Silva, Sílvia R . B. de Medeiros, Maria A . M . Maciel"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_160.pdf",
      "name": "Evaluación de la resistencia a la corrosión de sistemas de recubrimientos con altos sólidos por medio de espectroscopia de impedancia electroquimica.",
      "authors": "Andrés Guzmán Rosas, Luz Marina Ocampo"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_163.pdf",
      "name": "O que se pode aprender a partir de medidas de potencial de circuito aberto?",
      "authors": "Silvia Maria L. Agostinho (PQ), Ruth F.V.V. Jaimes (PQ), Luís Gustavo N. Barbosa (IC)"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_164.pdf",
      "name": "Comparação entre Taxas de Corrosão calculadas a partir de Trocas de Cupons de Perda de Massa e de Inspeção Interna com Pig Instrumentado em Oleoduto Rígido Submarino.",
      "authors": "Pedro Nogueira Addor, Jane Zoppas Ferreira, Érika Santana Mota Nicoletti, Angélica D ias Salvador, Aldo Renato Franzoi, Eline Terezinha Antunes de Souza Paes, Sérgio Pagnin"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_167.pdf",
      "name": "Taxa de corrosão de corpos-de-prova expostos no Ceará em atmosfera marinha de muito alta agressividade e em condições aceleradas no laboratório",
      "authors": "Iêda N. S. Montenegro, Anadite M. de Luna, Ana L. Maia, Antônio R. de M. Filgueira, Narcelio de A. Pereira, Jailson S. Rodrigues, Jocilane de A. Rodrigues"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_170.pdf",
      "name": "Análise eletroquímica e de superfície do efeito do benzotriazol na corrosão do aço -carbono 1005 em meio de cloreto de sódio",
      "authors": "Juliana V. Custódio, Silvia M. L. Agostinho, Paola Corio, Jean C . S. Costa , Alda M. P. Simões"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_171.pdf",
      "name": "Estudo eletroquímico do efe ito da concentração de íons de Ce(IV ) na formação de um filme protetor a base de polissilano sobre aço carbono",
      "authors": "Paulo R. de Souza, Patrícia H. Suegama, Assis V. Benedetti, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_172.pdf",
      "name": "Avaliação da atividade do óleo essencial de Lippia gracilis Schauer sobre biofilm es formados em sistema dinâmico",
      "authors": "Marcelino Gevilbergue Viana, Márcia Teresa Soares de Lutterbach, Cynthia Cavalcanti de Albuquerque, Djalma Ribeiro da Silva, Tereza Neuma de Castro Dantas"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_174.pdf",
      "name": "The Integration of Intrusive and Non-Intrusive Systems for Corrosion and Sand/Erosion Monitoring Using New Generation Data Management Software",
      "authors": "Kjell Wold, Sean Hopkins, Tommy Jakobsen, Svein Erik Lilleland"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_175.pdf",
      "name": "Estudo da corrosão do cobre na presença do brometo de cetilpiridínio em HC l 1M",
      "authors": "Felipe Diógenes Abreu, Walysson Gomes Pereira, Eve rardo Paulo de Oliveira Júnior, Samuel Victor Lima Araújo, Carlla Lorena Façanha Silva, Rui Carlos Barros da Silva, Carlos Emanuel de Carvalho Magalhães"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_179.pdf",
      "name": "Emprego de metionina como inibidor de corrosão para cobre em meio de suor artificial",
      "authors": "Lourdes A. Etshindo, Dalva C. B. do Lago, Lilian F. de Senna, Aderval S. Luna, Eliane D’Elia"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_183.pdf",
      "name": "Adequação e cuidados de qualidade no reúso de águas como reposição em sistemas de resfriamento abertos com recirculação",
      "authors": "César Augusto Pereira, Sérgio Machado Correa"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_190.pdf",
      "name": "Influência de Tratamentos Termoquímicos por Difusão a Plasma no Desempenho do Aço Inoxidável Austenítico AISI 316 Frente ao Desgaste e Corrosão.",
      "authors": "Reinaldo B. de Oliveira Jr., Stephano P. T. Piva, Mônica M. A. M. Schvartzman, G. Cristina D. Godoy"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_191.pdf",
      "name": "Emprego de BTAH como inibidor de corrosão para cobre em meio ácido",
      "authors": "Aline J.Magdaleno, Dalva C. B. do Lago, Lilian F. de Senna, Aderval S. Luna, Eliane D’Elia"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_192.pdf",
      "name": "Efeito dos parâmetros de deposição na eletrodeposição de ligas Cu-Zn a partir de banhos contendo glicina",
      "authors": "Julyana R. Garcia, Aderval S. Luna, Dalva C. B do Lago, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_194.pdf",
      "name": "Estudo das propriedades do sistema platina-polianilina-paládio para prevenção e controle da corrosão por hidrogênio permeado",
      "authors": "Lilian M. Moya, Jeferson A. Moreto, Aleksandra G. S. G. da Silva, Haroldo de A . Ponte, Carlos M. G. da S. Cruz"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_196.pdf",
      "name": "Eletrodeposição de ligas Cu -Zn em eletrólitos de citrato de sódio: efeito da densidade de corrente, da velocidade de agitação e da temperatura de deposição",
      "authors": "Braulio C. C. de A. Rocha, Aderval S. Luna, Juliane A. Cardoso, Dalva C. B. do Lago, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_197.pdf",
      "name": "Avaliação de aminoácidos como inibidores de corrosão para aço carbono",
      "authors": "Aline J. Magdaleno, Bruna Liliam F. da Silva, Crisiele M. Souza, Eliane D’Elia, Lílian F. de Senna, Dalva C. B. do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_198.pdf",
      "name": "Avaliação crítica dos critérios de proteção catódica apresentados pela norma ISO 15 589-1",
      "authors": "Alysson H. Bueno, José A. C. Ponciano"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_199.pdf",
      "name": "Avaliação da corrosão de materiais metálicos a base de titânio em soluções de saliva artificial",
      "authors": "Arianne Madureira Barcelos, Nancy Assis Ferreira, Hélio Sampaio Filho, Dalva Cristina Baptista do Lago, Lilian Ferreira de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_202.pdf",
      "name": "Produção de Revestimentos de Liga Co -Cu sobre Substratos de Aço ao Carbono Empregando Banhos a Base de Glicina.",
      "authors": "Thaís G. Lima, Dalva C.B. do Lago, Aderval S. Luna, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_208.pdf",
      "name": "Avaliação dos extratos de mate verde e carqueja como inibidores da corrosão para o aço -carbono 1020",
      "authors": "Eliane D’Elia, Camila Faia de Sá Roberto Salgado Amado"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_209.pdf",
      "name": "Avaliação da biocorrosão de aço carbono AISI 1020 em sistema tratado com nitrato para o controle de acidificação biogênica",
      "authors": "Kally Alves de Sousa, Eliana Flávia Camporese Sérvulo, Magali Christe Cammarota"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_212.pdf",
      "name": "Estudo teórico e eletroquímico da inibição da corrosão do aço carbono por meio aminoácidos em ácido sulfúrico.",
      "authors": "Glaydson Leandro Farias Mendonça, Roger Almeida Gomes, José R. C. Júnior, Valder N. Freire, André F. de Moura, David L. Azevedo, Pedro de Lima Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_213.pdf",
      "name": "Extrato aquoso de chá -verde como inibidor da corrosão do aço-carbono 1020",
      "authors": "Eliane D’Elia, Felipe Santiago Soares, Camila Faia de Sá, Roberto Salgado Amado"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_215.pdf",
      "name": "Microscopia óptica in situ usada para análise da corrosão do aço ao carbono em meio de sulfeto acoplada a técnicas eletroquímicas",
      "authors": "Alexsandro M. Zimer, Emerson C. Rios, Paulo C.D. Mendes, Ernesto C. Pereira, Lucia H. Mascaro"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_218.pdf",
      "name": "Preparação e caracterização de xerogel híbrido para proteção anticorrosiva do cobre",
      "authors": "Denise S. Azambuja, Geraldo B.Machado, Débora F.Gay, Sílvia M. Tamborim, Tania M.H.Costa5<br />\n, Edilson V. Benvenutti</p>\n<p>&nbsp;"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_220.pdf",
      "name": "Electrochemical characterization of silane films consisting of TEOS and TMSPMA on electrodeposited zinc",
      "authors": "Marlla V. da Costa, Cláudia T. de Oliveira, Tiago L. Menezes, Eduardo L. Schneider, Iduvirges L. Muller and Célia F. Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_222.pdf",
      "name": "Eficiência de inibição dos sistemas microemulsionados contendo o tensoativo óleo de coco saponificado e o heterociclo isatina",
      "authors": "Djalma R. da Silva, Ádna D. N. de Souza, Cátia G. F. T. Rossi, Maria Aparecida M. Maciel, Tereza N. C. Dantas"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_223.pdf",
      "name": "Preparation and characterization of silane films obtained from a sol consisting of 3 – (trimethoxysilylpropyl) methacrylate and tetraethoxysilane On AA2024T3 aluminum alloy",
      "authors": "Sandra Raquel Kunst, Marlla Vallerius Da Costa, Patrícia dos Santos Correa, Jean Pierre Bonino, Elodie Xuereb, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_224.pdf",
      "name": "Variação do valor da classificação da corrosividade atmosférica em diferentes ambientes",
      "authors": "Iêda N. S. Montenegro, Anadite M. de Luna, Ana L. Maia, Antônio R. de M. Filgueira, Narcelio de A. Pereira, Jailson S. Rodrigues, Jocilane de A. Rodrigues, Solange M. B. Girão"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_225.pdf",
      "name": "Potencial anticorrosivo de Alcalóides Isoquinolínicos Microemulsionados na Inibição da Corrosão de Aço Carbono AISI 1020",
      "authors": "Cássia Carvalho de Almeida, Gilvani Gomes de Carvalho, Catia Guaraciara Fernandes Teixeira, Carlos Alberto Martinez Huitle, Djalma Ribeiro da Silva, Maria Aparecida Medeiros Maciel"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_227.pdf",
      "name": "La Corrosión del Concreto en construcciones portuarias, subestaciones portuarias y otros ambientes, Un enfoque para Colombia",
      "authors": "Juan Diego Montoya Serra"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_229.pdf",
      "name": "Desenvolvimento de Nanocompósitos à Base de Argilas Organofilizadas e Polimetacrilato de Metila para a Utilização em Revestimentos Anti-corrosivos.",
      "authors": "Ariosvaldo A. B. Sobrinho, Edjânio B. Araújo, Danielly Vieira de Lucena, Daniel Baracuy da Cunha Campos"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_230.pdf",
      "name": "Estudo Da Influência Do Tratamento De Superfície Do Aço Carbono Na Obtenção De Revestimentos Híbridos Protetores",
      "authors": "Priscila Segura, Cristiane Reis Martins, Idalina Vieira Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_233.pdf",
      "name": "Estudo Da Eletrodeposição Da Liga Z NCO Sobre Aço-Carbono",
      "authors": "J. M. Falcón, A. C. Neiva, I. V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_234.pdf",
      "name": "Avaliação Da Suscetibilidade À Corrosão Sob Tensão Da Junta Soldada De Liga De Níquel 182 Em Ambiente De Reator Nuclear",
      "authors": "Luciana Iglésias Lourenço Lima, Mônica Maria de Abreu Mendonça Schvartzman, Alexandre Queiroz Bracarense, Marco Antônio Dutra Quinan, Wagner Reis da Costa Campos, Tiago Moreira Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_235.pdf",
      "name": "Aplicação de inibidores de corrosão em sistemas de produção de campos de gás não associado para evitar corrosão preferencial em juntas soldadas",
      "authors": "Flávia Maciel Fernandes Guedes, Jussara de Mello Silva, Eva Maria de Oliveira Paiva, Rosane Fernandes de Brito, Helga Stefania Maranhão Bodstein, Denise Souza de Freitas"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_236.pdf",
      "name": "Avaliação da compatibilidade da glicerina frente a materiais Metálicos",
      "authors": "André L. C. Bonfim, Luiz S. C. Júnior, Gutemberg S. Pimenta, Sonia M. C. Menezes, Helga S. M. Bodstein, Denise S. Freitas"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_237.pdf",
      "name": "Elaboração e caracterização de filme silanos aplicados sobre aço CA50",
      "authors": "Tamara Francisca Baggio, Sandra Raquel Kunst, 3Marlla Vallerius da Costa, Iduvirges Lourdes Muller, Luiz Carlos Pinto da Silva Filho, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_239.pdf",
      "name": "Resistência à corrosão de uma camada compósita matriz metálica aço inoxidável duplex/WCp preparada por laser",
      "authors": "Maria C. F. Ierardi, Marcelo J. G. da Silva, Alex M. do Nascimento, Walney S. Araujo, Hamilton F. G. de Abreu"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_240.pdf",
      "name": "Corrosão do cobre em meio citrato em diferentes pH",
      "authors": "Rui Carlos Barros da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_241.pdf",
      "name": "Avaliação do efeito da formação da fase sigma no processo de corrosão do aço inoxidável duplex UNS S31803.",
      "authors": "Sanderlir Silva Dias, Roger Almeida Gomes, Regilany Paulo Colares, Pedro de LimaNeto, Adriana Nunes Correia, Walney Silva Araújo"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_243.pdf",
      "name": "Estudo da Corrosão por Solos Amazônicos através de Técnicas Eletroquímicas",
      "authors": "Clairon L. Pinheiro, Marcelo C. Santos, José C. Cardoso Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_247.pdf",
      "name": "Desenvolvimento de sensores eletroquímicos a base de ní quel eletrodepositado para detecção de fragilização por hidrogênio",
      "authors": "Guilherme A . Santos, Aleksandra G . S. G. da Silva, Luciana S . Sanches, Haroldo de A . Ponte, Cláudia E . B. Marino"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_248.pdf",
      "name": "Efeito dos ácidos orgânicos e do etanol na corrosão pelo CO 2",
      "authors": "Lize Mirela Lopes de Almeida, Jussara de Mello Silva, Denise Souza de Freitas, Mario Sergio Ruas Martins"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_250.pdf",
      "name": "Desenvolvimento e caracterização de revestimentos híbridonano estruturados para a proteção contra a corrosão de substratos metálicos",
      "authors": "Tiago L. Menezes, Renan Araújo, Álvaro Meneguzzi, Jane Z. Ferreira, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_251.pdf",
      "name": "Avaliação do Potencial Anticorrosivo do Extrato de Phyllantus amarus Solubilizado em um Sistema Microemulsionado",
      "authors": "Gineide C. dos Anjos, Cássia C. Almeida, Ciro J. F. Rodrigues, Cátia G. F. T. Rossi, Djalma R. da Silva, Maria A. M. Maciel"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_252.pdf",
      "name": "Estudo da interferência da armadura nas leituras de resistividade elétrica superficial do concreto – I. Medições aos 28 dias.",
      "authors": "Julia W. Lencioni, Maryangela G. de Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_253.pdf",
      "name": "ESTUDO ELE TROQUÍMICO DA ESTABILIDADE DE PÁ TINAS DE COBRE USANDO UM MICROELETRODO COM CAVIDADE.",
      "authors": "M. M. Mennucci, I. V. Aoki, S. Joiret, V. Vivier, H. G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_254.pdf",
      "name": "Influência da microbiota marinha na corrosão de cupons de aço carbono SAE 1008",
      "authors": "Lívia Almeida Santos Dantas, Glória Maria Vinhas, Francisca Pessoa de França, Virgínia Carmem Rocha Bezerra, Edivânia Souza de Lima, Maria Alice Gomes de Andrade Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_255.pdf",
      "name": "Avaliação do Desempenho da Trietanolamina como Inibidor de Corrosão para o Aço 304L em Águas de Produção da Indústria Petrolífera",
      "authors": "Gabriela O. Pinon, Patrícia G. Corradini, Nickson Perini, Eustáquio V. R. de Castro, Marcos B. J. G. de Freitas"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_257.pdf",
      "name": "The effect of zinc addition in stress corrosion cracking initiation in nickel alloy 600 in simulated PWR primary water",
      "authors": "Raphael Gomes de Paula, Célia de Araújo Figueiredo, Gastón Alvial Moraga"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_259.pdf",
      "name": "Influência de concentradores geométricos de tensão na corrosão sob tensão do aço AISI 304 em meios contendo cloretos",
      "authors": "Thiago Martins Teixeira Braga, Luiz Cláudio Cândido"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_261.pdf",
      "name": "Especificações e ensaios para fornecimento de tubos sem costura em aços inoxidáveis duplex",
      "authors": "Antonio Carlos Tomaselli, Fausto C. Camargo, Rodrigo Faveret Signorelli"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_262.pdf",
      "name": "Pré-qualificação de Sequestrantes de H2S para Injeção em Poços de Petróleo",
      "authors": " Alvaro A. O. Magalhães; André L. C. Bonfim"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_263.pdf",
      "name": "Dissolução Química do Chumbo em Meio Citrato-Ácido",
      "authors": "Walysson Gomes Pereira, Gledson Vieira Lima, Jhonyson Arruda Carvalho Guedes, Samuel Victor Lima Araújo, Felipe Diógenes Abreu, Everardo Paulo de Oliveira Júnior, Carlos Emanuel de Carvalho Magalhães, Rui Carlos Barros da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_264.pdf",
      "name": "Estudo da Técnica de “Weld Overlay” para Mitigação da Corrosão Sob Tensão em Soldas à Base de Ligas de Níquel Submetidas às Condições de Reator PWR",
      "authors": "Marco Antônio Dutra Quinan, Mônica Maria de Abreu Mendonça Schvartzman, Luciana Iglésias Lourenço Lima, Jose Eduardo de Almeida Maneschy, Emerson Giovani Rabello, Paulo de Tarso Vida Gomes, Cartergiane Júnio de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_265.pdf",
      "name": "Influência do manganês na corrosão do aço inoxidável AISI 297 na presença de íons sulfeto",
      "authors": "Patricia Gon Corradini, Marcos Benedito José Geraldo de Freitas, Nickson Perini, Gabriela Oliveira Pinon, Vinícius Guilherme Celante, Eustáquio Vinícius Ribeiro Castro"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_267.pdf",
      "name": "CAUSAS DA OCORRÊNCIA DE PITES EM CUPONS DE AÇO CARBONO",
      "authors": "Neusvaldo Lira de Almeida; Lorena Cristina de Oliveira Tiroel; Adriano Garcia Bernal; Eduardo Wlaudemir Laurino; Vanessa Yumi Nagayassu; Vinícius de Ávila Jorge"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_269.pdf",
      "name": "COMPORTAMENTO À CORROSÃO E À CORROSÃO SOB TENSÃO DO AÇO INOXIDÁVEL DUPLEX AISI 318 EM MEIO CONTENDO CLORETO, CO 2 E pH4",
      "authors": "Maiquel A. da Rosa, Taíse M . Manhabosco, Roberto M. Schroeder, Iduvirges L . Muller "
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_270.pdf",
      "name": "Efeito da temperatura de nitretação na corrosão em água do mar de um aço superaustenítico",
      "authors": "Frederico A.P. Fernandes, Stênio C. Heck, Luiz C. Casteletti, Carlos A. Picon, Germano Tremiliosi Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_272.pdf",
      "name": "External Corrosion Growth on an Ageing Pipeline: a Case Study",
      "authors": "Érika S.M. Nicoletti, Ricardo D. de Souza, João Hipólito de L. Olivier"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_274.pdf",
      "name": "Determinação do Grau de Sensitização do Metal de Adição Inconel 182 depositado em diferentes condições de Aporte Térmico de Soldagem.",
      "authors": "Guilherme M Silva, Alexandre Q Bracarense, Mônica M A M Schvartzman, Ana L B C Costa,Tiago M. Pereira, Marcos S. Chaim"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_275.pdf",
      "name": "Comparative study of Ferritic Stainless Steels applied at High Temperatures",
      "authors": "Moreto, J. A, Gamboni, O. C, Ponte, H. A, Moya, L. M, Bueno, L. O, S. Fofano"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_278.pdf",
      "name": "Estudo da corrosão de aço carbono 1020 em meio de petróleo.",
      "authors": "Emerson C. Rios, Alexsandro M. Zimer, Marcos B.J.G. de Freitas, Lúcia H. Mascaro, Ernesto C. Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_283.pdf",
      "name": "Estudo da corrosão atmosférica em perfis que compõem a estrutura galvanizada de sustentação de cabos de transmissão de energia elétrica",
      "authors": "J. A. Moreto, D. B. V. Castro, L. S. Rossino, O. Maluf, D. Spinelli, J. R. Tarpani"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_287.pdf",
      "name": "Eletrosíntese de Nanocompósitos de Polianilina e Montmorilonita (PANI-MMT)",
      "authors": "Paula Tibola Bertuoli, Juliana Zardo, Eliena Jonko Birriel, Ademir Jose Zattera, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_288.pdf",
      "name": "Avaliação Comparativa da Resistência à Corrosão de Filmes de DLC",
      "authors": "Ricardo S. Bonelli, Ricardo P.O.S. Nery, Sergio. S, Camargo Jr."
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_291.pdf",
      "name": "Estudo das alterações das propriedades eletroquímicas do Tântalo com carregamentos cíclicos de hidrogênio",
      "authors": "Aleksandra G.S.G da Silva, Haroldo A. Ponte, Lilian M. Moya, Cláudia E. B. Marino, Guilherme Arruda"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_292.pdf",
      "name": "Estudos Iniciais dos Extratos das Plantas Pectis oligocephala, Pectis apodocephala e Talisia esculenta na Corrosão do Cobre em Solução de HNO3 1M",
      "authors": "Denis Valony Martins Paiva, Everardo Paulo de Oliveira Júnior, Carlla Lorena Façanha Silva, Samuel Victor Lima Araújo, Felipe Diógenes Abreu, Rui Carlos Barros da Silva, Marcos Aurélio Nunes da Silva Filho, Sônia Maria Oliveira Costa, Eduardo Bedé Barros, Carlos Emanuel de Carvalho Magalhães"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_296.pdf",
      "name": "Monitoração de Corrosão Interna em Dutos de Petróleo e Derivados",
      "authors": "Anna Maria C. Carvalho, Lorena Cristina de Oliveira Tiroel, Ricardo Mouro, Vinícius de Ávila Jorge"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_297.pdf",
      "name": "Biocompatibilidade e comportamento eletroquímico de titânio eletropolido com e sem filmes de DLC ( Diamond-Like Carbon )",
      "authors": "Taíse Manhabosco (PQ ), Sílvia M. Tamborim (PQ), André Jarenkow (IC) , Moema Q.Vieira (PG), Mariana M.Ilha (IC), Léo Anderson M. Martins (PQ) , Iduvirges Lourdes Muller (PQ), Fátima T.C.R.Guma ( PQ)"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_300.pdf",
      "name": "AVALIAÇAO DO EFEITO CORROSIVO DO BIODIESEL DE SOJA EM MATERIAL METALICO EMPREGADO EM MOTORES DO CICLO DIESEL",
      "authors": "Cristiane C. Italiano, Lídia Santos P . Martins, José Roberto P. Rodrigues, Eliane Rodrigues de Souza, Antonio Francisco F. de Vasconcelos, Margarita Ballester, Celia Marina A . Freire"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_302.pdf",
      "name": "Desempenho de cupons de aço carbono 1020 na região metropolitana de Salvador - BA.",
      "authors": "Kelly Jacqueline Campos Brambilla, Kleber Franke Portella, Danilo Damasceno Silva, Mário Seixas Cabussú, Daílton Pedreira Cerqueira e Rogério Nascimento Salles"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_303.pdf",
      "name": "Resistência à corrosão de filmes de carbono obtidos por eletrodeposição: influência do substrato",
      "authors": "Tiago Falcade, Taíse Matte Manhabosco, Iduvirges Lourdes Müller"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_304.pdf",
      "name": "Influência dos parâmetros de tratamento na resistência à corrosão em água do mar do aço AISI H13 nitretado ionicamente",
      "authors": "Stênio C. Heck, Frederico A.P. Fernandes, Carlos A. Picon, Luiz C. Casteletti, Artur de Jesus Motheo"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_305.pdf",
      "name": "Efeito Do Extrato Hidroalcóolico De Senna Reticulata Sobre A Corrosão Do Aço 1010 Em Meio De Ácido Clorídrico",
      "authors": "Andressa M. de Oliveira; Paulo N. S. Casciano; Pedro L. Neto.; Adriana N. Correia.; Francisco B. Romero."
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_307.pdf",
      "name": "Reducing the risk of Hydrogen Induced Stress Cracking (HISC) on Duplex Stainless Steels with Hot Isostatic Pressed (HIP) Materials.",
      "authors": "Luiz H. D. Crestana, Martin Bjurström, Alan C. A. Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_309.pdf",
      "name": "Resultados de inspeção in-line com PIG de detecção de corrosão (MFL) e análise de integridade baseada na Norma ASME B31.G do Mineroduto de Bauxita de Paragominas-Pa",
      "authors": "Gerson Melo, Romulo Rufino, Otilio Pires, Marco Veisac, Orlando Favacho, Marco Leão, Geraldo Britte, Francisco Edvan, Guilherme Porto"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_310.pdf",
      "name": "Análise da corrosão naftênica utilizando a técnica do ruído eletroquímico",
      "authors": "Patrícia S. da Silva, Haroldo A. Ponte, Nice M. S. Kaminari, Luciana S. Sanches"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_311.pdf",
      "name": "Avaliação microestrutural da liga FeCr após exposição a alta temperatura em atmosfera N 2-4%H 2",
      "authors": "Marina F. Pillis, Lalgudi V. Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_312.pdf",
      "name": "Estudo do Re vestimento Nanocerâmico Zircônio/ Tit ânio em Aço Carbono na Proteção Contra Corrosão.",
      "authors": "Juliana dos A. Moraes, Guilherme Adams, Álvaro Meneguzzi, Jane Z. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_314.pdf",
      "name": "Desenvolvimento de Equipamento para Ensaio de Corrosão Fadiga por Controle de Deslocamento.",
      "authors": "Márcio Ribeiro Antunes, Charles Kuhn, Raphael Aragones Leite, Maurício Ferrapontoff Lemos, Telmo R. Strohaecker"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_315.pdf",
      "name": "Tests with reagents for inhibition of corrosion in Bauxite Slurry Pipeline",
      "authors": "Otilio Othon Pires, Gerson Melo, Romulo Rufino, Marco Veisac, Mendes, Geraldo Brittes, Francisco Edvan, Charleston Dias, Orlando Favacho"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_317.pdf",
      "name": "Prediction and Prevention: The development of test methods to predict and corrosion inhibitors to prevent localized corrosion in extreme environments",
      "authors": "J. Caleb Clark, Joshua F. Addis, Maximilian A. Silvestri"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_327.pdf",
      "name": "Avaliação das Características Físicas das Tintas Epoxi Bicomponentes Após Processo de Envelhecimento",
      "authors": "Kirlene Salgado Fernandes Penna, Luiz Henrique Duarte"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_328.pdf",
      "name": "Revestimento a base de TEOS/ácido fosfônico como proteção anticorrosiva para a liga AA2024",
      "authors": "Viviane Dalmoro, João Henrique Z. dos Santos,Denise Schermann Azambuja"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_329.pdf",
      "name": "ESTUDO DA RESISTÊNCIA À CORROSÃO DE LIGAS INOXIDÁVEIS COM EFEITO DE MEMÓRIA DE FORMA",
      "authors": "Carlos Alberto Della Rovere, José Henrique Alano, Jorge Otubo, Sebastião Elias Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_331.pdf",
      "name": "Otimização do banho eletroquímico para eletrodeposição de filmes de Ni-W-Fe resistentes à corrosão",
      "authors": "Josiane Dantas Costa, Mikarla Baia de Sousa, Aldrighi Luiz Marques de Oliveira, Ana Regina Nascimento Campos, Gecilio Pereira da Silva, Rafael Ribeiro Portela, Renato Alexandre Costa de Santana, Shiva Prasad"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_332.pdf",
      "name": "Estudo Comparativo da Corrosão Eletroquímica Provocada por Águas de Produção de Petróleo no Aço Inoxidável Austenítico AISI 316L.",
      "authors": "Gabriela O. Pinon, Patrícia G. Corradini, Nickson Perini, Marcos B. J. G. de Freitas"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_333.pdf",
      "name": "Obtenção e caracterização da liga Co-W-Fe obtida por eletrodeposição",
      "authors": "Mikarla Baia de Sousa, Josiane Dantas Costa, Paulo Naftali da Silva Casciano, Paulo Sérgio Gomes da Silva, Ana Regina Nascimento Campos, Pedro de Lima Neto, Renato Alexandre Costa de Santan, Shiva Prasad"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_334.pdf",
      "name": "Aplicação do método de análise de mistura de componentes para desenvolvimento de novas rotas de tecnologias limpas direcionadas aos inibidores de corrosão",
      "authors": "Elizandra C. S. Elias, Erika C. A. N. Chrisman"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_336.pdf",
      "name": "Avaliação do comportamento corrosivo dos revestimentos de níquel (Inconel 625) depositados por soldagem MIG sobre substrato API 5L Gr. B.",
      "authors": "Francisco de Assis Souza Neto, Marcelo César Dias da Cunha, Paulo Naftali da Silva Casciano, Renato Alexandre Costa de Santana, Marco Antonio dos Santos, Theophilo Moura Maciel"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_339.pdf",
      "name": "Corrosão de grades de liga de Pb/Ca/Sn de placas positivas de Baterias de Chumbo-Ácido",
      "authors": "Gilberto A. de O. Brito, Carlos V. D’Alkaine"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_342.pdf",
      "name": "Avaliação da resistência à corrosão de aços inoxidáveis ferríticos em solução aquosa com adição de álcool e de sais contendo cloreto",
      "authors": "Marcela R. Menezes, Margareth S. Andrade, Rosa M. R. Junqueira"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_343.pdf",
      "name": "Revestimento Protetor Contra Corrosão Contendo Nanopartículas de ZnO",
      "authors": "Marcos Vinicius Sulzbach Rauber, Ester Schmidt Rieder, Fernando Rodrigues Eisele, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_349.pdf",
      "name": "Durabilidade de estruturas de concreto em ambiente marinho: estudo de caso",
      "authors": "Adriana Araujo, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_350.pdf",
      "name": "Estudo do mecanismo de eletrodeposição do cobre a partir do HEDP por meio da técnica eletroquímica de medição do potencial de circuito aberto",
      "authors": "Cristiane Vargas Pecequilo, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_352.pdf",
      "name": "Tintas Epóxi - NOVOLACAS",
      "authors": "Celso Gnecco"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_354.pdf",
      "name": "Efeitos da temperatura na morfologia e porosidade da camada de fosfato tricatiônico de Zn, Nb e Mn, em aço carbono (SAE) 1020.",
      "authors": "Rosele Correia de Lima, Antonio Carlos de Oliveira Sobrinho, Luiz Antonio Rossi Jazbinsek, Danilo Coladetti Curtolo, Everson do Prado Banczek, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_360.pdf",
      "name": "Análise Comparativa entre o Fosfato Tricatiônico Comercial de Zn, Ni e Mn e o Fosfato Tricatiônico Composto por Zn, Nb e Mn, Aplicados em Aço-carbono SAE 1005.",
      "authors": "Rosele Correia de Lima, Everson do Prado Banczek, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_363.pdf",
      "name": "Comportamento de camadas fosfatizadas frente à corrosão e ao atrito",
      "authors": "élia A. L. dos Santos, Maria Cristina M. Farias, Edwilson Leite, Antenor F. Filho, Zehbour Panossian, Amílton Sinátora"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_364.pdf",
      "name": "Uso de extrato de repolho roxo como inibidor de corrosão para aço-carbono",
      "authors": "Ladimir J. Carvalho, Simone Louise D. C. Brasil, Gabriel M. Rocha, Miguel Bustamante"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_365.pdf",
      "name": "Poly aspartic Coatings That Perform as Well a s Polyurethane Coatings",
      "authors": "Andrew Smith, Beth Eng"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_366.pdf",
      "name": "Estudo da corrosividade de pastas de cimento com adição de lodo produzido em ETES da indústria têxtil",
      "authors": "Ladimir Jose de Carvalho, Valéria Castro de Almeida"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_368.pdf",
      "name": "Zinc Loading vs Performance Requirements for Zinc Rich Primers",
      "authors": "Andrew Smith, Keith Ketheeswaran, Steven Crowley"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_369.pdf",
      "name": "Estudo E Caracterização Do Resíduo De Chumbo Como Revestimento Aplicado Pelo Processo De Aspersão Térmica A Chama",
      "authors": "Frieda S. Barros, Ramón S. C. Paredes"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_370.pdf",
      "name": "Análise de Cupons de Corrosão/Incrustação Instalados em Coluna de Produção",
      "authors": "Thiego C. B. A. da Silva, Fernando N. da Silva, Jardel D. da Cunha, Djalma R. da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_372.pdf",
      "name": "Corrosão em torno do cordão de solda em um aço inoxidável austenítico 304L",
      "authors": "Carlos A. Picon, Tarcísio A. M orando, Stênio C. Heck, Frederico A.P. Fernandes, Luiz C. Casteletti, Artur de J. Motheo"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_374.pdf",
      "name": "Efeito da laminação a frio na corrosão em água do mar de uma liga do sistema Fe-Mn- Al",
      "authors": "Luiz C. Casteletti, Frederico A.P. Fernandes, Ricardo G. Pereira, Stênio C. Heck, Carlos A. Picon"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_377.pdf",
      "name": "Ensaios para Avaliação da Corrosividade de Solos",
      "authors": "Denise S. Freitas, Simone L. D. C. Brasil, J. Fernando P. Coelho, João Hipólito L. Oliver, Felicle del Valle L. Araujo, Lisiane G. Lima"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_384.pdf",
      "name": "Resistência à Corrosão de Aços para Edificações Residencia is e Comerciais",
      "authors": "Evandro de Azevedo Alvarenga"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_387.pdf",
      "name": "Fotodegradação de corantes naturais em fotoeletrodos de dióxido de Titânio - TiO 2",
      "authors": "Ana Beatriz G . de Farias, José F. Julião, Antinous de Souza Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_388.pdf",
      "name": "Análise de Falha de Arruelas Cônicas Utilizadas em Trilhos de Fixação de Bancos Automotivos",
      "authors": "Moreto, J. A, Bose Filho, W. W, Spinelli, D, Ruchert, C. O. F. T"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_389.pdf",
      "name": "Análise de Falha de Tubos de Aço Carbono ASTM A – 178 Gr Utilizados em Feixes Tubulares",
      "authors": "Moreto, J. A, Bose Filho, W. W, Spinelli, D, Ruchert, C. O. F. T"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_391.pdf",
      "name": "Controle de Corrosão e Redução do Consumo de Água de Resfriamento",
      "authors": "Osvaldo Benedito Dib Melo, Damián Serra, Domingos Savio"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_393.pdf",
      "name": "Estudo preliminar de trincamento sob tensão de aços inoxidáveis duplex em água do mar sintética.",
      "authors": "José A.P.Gomes, Jacqueline M. de Farias"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_394.pdf",
      "name": "Avaliação da Ação Corrosiva de Águas Salinas em Estruturas e Equipamentos de Termelétricas",
      "authors": "Simone L. D. C. Brasil, José Claudio F. Telles, José Antonio F. Santiago, Ralf Lehtola"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_395.pdf",
      "name": "Estudo teórico da corrosão por macrocélula em armaduras de aço-carbono expostas a reparos localizados em estruturas de concreto",
      "authors": "José Luis Serra Ribeiro, Silvia Maria de Souza Selmo, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_396.pdf",
      "name": "O uso das espectroscopias de impedância eletroquímica e Raman na avaliação da qualidade de biodiesel",
      "authors": "Isabella P. Aquino, Rocio B. Hernandez, Dennis L. Chicoma, Reinaldo Giudici, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_397.pdf",
      "name": "A new thermodynamic criterion and a new field methodology to verify the probability of AC corrosion in buried pipelines ",
      "authors": "Zehbour Panossian, Sérgio E.A. Filho, Neusvaldo L. de Almeida, Diogo de L Silva, Mário L. Pereira Filho, Eduardo W. Laurino, João Hipólito L. Oliver, José A. C. Albertini, Gutemberg S. Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_398.pdf",
      "name": "Estudos de corrosão em meio de etanol",
      "authors": "Célia A. L. dos Santos, Zehbour Panossian, Gutemberg de Souza Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_399.pdf",
      "name": "Evaluation Of Anticorrosive Coatings For Tanker Walls To Transport Oil In A High Salinity Environment, In The Presence Of Tension, Temperature And Co2",
      "authors": "Neusvaldo Lira de Almeida; Adriano Garcia Bernal; Victor Solymossy; Flávio Augusto S. Serra; Joaquim Pereira Quintela"
    },
    {
      "url": "https://intercorr.com.br/anais/2010/INTERCORR2010_400.pdf",
      "name": "Cálculo Automatizado de Proteção Catódica.",
      "authors": "Renato de Mello Brandão Horta"
    }
  ]
};

export const EDICAO_2014 ={
  "message": '',
  "data": [
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_005.pdf",
      "name": "Uso de técnicas eletroquímicas no diagnóstico corrosivo das grelhas de torres instaladas em solos de Imperatriz e Rondonópolis",
      "authors": "José M. Silva, Fernando S. C. Azevedo, Torricelli S.Gomes, Renato A. Oliveira, Djalma R. Garcia, Wilson S. Malheiros, Francisco F. S. Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_007.pdf",
      "name": "Comparação entre os Métodos de Microbalança de Cristal de Quartzo e Eficiência Estática e Dinâmica para a Avaliação de Inibidores de Incrustação (CaCO3 e BaSO4)",
      "authors": "Mônica de O. Penna, Felipe B. Alvim, Gabrielle G. P. Nunes, Ana Carolina H. Gonçalves"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_008.pdf",
      "name": "Seleção de Revestimentos Orgânicos Anti-incrustantes para Poços de Petróleo",
      "authors": "Mônica de Oliveira Penna, Felipe Batista Alvim, Ana Carolina Helena Gonçalves, Alexander Hiroshi Kasama, André Ferreira Lazaro"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_009.pdf",
      "name": "Avaliação da atividade de biocidas comerciais no controle da biocorrosão em águas salinas.",
      "authors": "Lindomar Cordeiro A. de Araújo, Ladimir José de Carvalho, Márcia Teresa S. Lutterbach, Leila Yone Reznik, Eliana Flávia Camporese Sérvulo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_012.pdf",
      "name": "Pintores Industriales Certificados, Un camino Hacia La Profesionalización del Oficio.",
      "authors": "Damian Banega, Karina Bisciotti, Andres Ceriotti, Mónica Pinto, Isabel Tiscornia"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_013.pdf",
      "name": "IMPLEMENTACIÓN EN ARGENTINA DE LA CERTIFICACIÓN DE PREPARADORES DE SUPERFICIE. CUIDANDO EL TALÓN DE AQUILES DEL PROCESO DE PINTADO.",
      "authors": "D. Banega, K. Bisciotti, A. Ceriotti, M. Pinto, I. Tiscornia"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_014.pdf",
      "name": "Biofilms: an approach in different industrial systems",
      "authors": "Patricia Guiamet, Sandra Gómez de Saravia"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_017.pdf",
      "name": "Revitalização de Sistemas de Proteção Catódica Instalados em Plataformas Marítimas Fixas de Produção de Óleo &amp; Gás",
      "authors": "Renato de Mello Brandão Horta, Edson Góis de Medeiros, Álvaro Antônio Terra Martins da Silva, Rodrigo Leite Quaresma"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_020.pdf",
      "name": "Estudo exploratório do sistema aço AISI 1020/NaCl 0,6 mol L-1 envolvendo potencial de circuito aberto e observações por lupa e microscópio.",
      "authors": "Cynthia M. Carvalho, Larissa R. Demuner, Eduardo Amorim, Geissy Mendes, Angélica Zaneze, Renecris Lovate, Vanderli L. Junior, Marcelo Chagas, Rayara Brambilla, Rayane Nichetti, David R. Souza, Christiano J. G. Pinheiro, Gilberto A. O. Brito"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_021.pdf",
      "name": "Influência do envelhecimento a 475°C por curtos períodos de tempo nas propriedades mecânicas e de corrosão do aço UNS S32750",
      "authors": "Marcelo Camargo Severo de Macêdo, Sérgio Souto Maior Tavares, Warlen Alves Monfardini, Filipe Luppi Moreira, Artur Pratti de Barros, Bruno Lopes Valentim"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_022.pdf",
      "name": "Avaliação de um inibidor de corrosão utilizado na indústria do petróleo através de técnicas eletroquímicas e analíticas",
      "authors": "Adriana C. Gonçalves, Klester S. Souza, Marcia L.A. Temperini, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_023.pdf",
      "name": "Análise crítica da técnica de reativação eletroquímica potenciocinética em duplo-loop (DL-EPR) aplicada a aços inoxidáveis duplex: limitações e potencialidades",
      "authors": "Pedro H. M. Leal, Kioshy S. de Assis, Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_025.pdf",
      "name": "Aproveitamento de resíduos da indústria do papel como carga em um verniz epóxi bicomponente aplicado sobre aço carbono",
      "authors": "Glaiton Tuzzin, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_027.pdf",
      "name": "Performance Evaluation of Epoxy Anticorrosive Coatings and Polyurethane Protective Tape by Salt Spray Fog Test",
      "authors": "Marcos Moraes, Sérgio Abud Filho, Artur Graeff, Emerson Monteiro, Hector Medeiros, Edmilson Cavalcanti, André Koebsch"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_028.pdf",
      "name": "Avaliação da resistência à corrosão de juntas de aço lean duplex soldadas por diferentes processos (SMAW, GMAW E FCAW), em meio corrosivo de glicerina ácida, utilizando a espectroscopia de impedância eletroquímica.",
      "authors": "Dalila C. Sicupira, Gabriela R. Niquini, Cintia G. F. Costa, Vanessa de F. C. Lins"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_029.pdf",
      "name": "EFEITO DO RESFRIAMENTO EM ÁGUA E ÓLEO NA CURVA DE CORROSÃO NO AÇO INOXIDÁVEL SUPERMARTENSÍTICO COM NIÓBIO",
      "authors": "César A. D. Rodrígues, Rafael M. Bandeira, Germano T. Filho, Alberto M. Jorge Jr"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_030.pdf",
      "name": "Efeito do fósforo na resistência a corrosão no aço inoxidável supermartensítico",
      "authors": "Rafael M. Bandeira, Germano Tremiliosi Filho, Alberto M. Jorge Jr, César A. D. Rodrígues"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_031.pdf",
      "name": "EFEITO DA ADIÇÃO DE PET PÓS-CONSUMO EM TINTAS COMERCIAIS",
      "authors": "Oscar de Almeida Neuwald, Julia Cesa Pagnusati, Diego Piazza, Ademir José Zattera, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_034.pdf",
      "name": "Microscopia Óptica e Microscopia Confocal como Técnicas Comparativas para Caracterização de Pites em Cupons de Corrosão",
      "authors": "Geanni B. Silveira, Elaine C. Pereira, Angelus G. Pereira, Ronaldo P. Rocha, Marcello Filgueira, Dilhermando Finamore, Angélica D. Salvador"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_036.pdf",
      "name": "Avaliação Preliminar da Corrosividade de Efluente de Refinaria em Sistema de Resfriamento",
      "authors": "Sergio Pagnin, Andrea Azevedo Veiga, Vânia Maria Junqueira Santiago"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_037.pdf",
      "name": "USO DE TINTA INTELIGENTE NA PROTEÇÃO ANÓDICA DE AÇO AISI 1010",
      "authors": "Rodrigo S. Silva, Vitor Maggi, Jane F. Zoppas, Álvaro Meneguzzi"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_038.pdf",
      "name": "Aderência de micro-organismos em membranas de nanofiltração de fibra oca",
      "authors": "Francisca P. de França, Renata O. R. Calixto, Priscila M. Rocha, Ana C. M. Costa, Jane H. Fujiyama‐Novak, Cristiano P. Borges"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_040.pdf",
      "name": "Influência de Inibidores de Corrosão na Dissolução de Incrustações Carbonáticas",
      "authors": "Bruno B. Castro, Stefan S. Ferreira, José Antônio C. P. Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_041.pdf",
      "name": "Influência da Geometria dos Cupons de Corrosão na Determinação das Taxas de Corrosão Uniforme e Puntiforme",
      "authors": "Elaine C. Pereira, Angelus G. P. Silva, Ronaldo P. Rocha, Marcello Filgueira, Dilhermando J. Finamore, Alcirley S. J. Filho, Angélica D. Salvador"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_042.pdf",
      "name": "Avaliação das propriedades mecânicas e de proteção à corrosão de um revestimento base poliéster contendo montmorilonita funcionalizada com silano",
      "authors": "Paula T. Bertuoli, Veronica P. Frizzo, Lisete C. Scienza, Ademir J. Zattera"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_043.pdf",
      "name": "Ação de inibidores voláteis de corrosão em aço carbono AISI 1020",
      "authors": "Cássia Luara Costella Acauan, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_045.pdf",
      "name": "Avaliação de 2-amino-5-mercapto-1,3,4-tiodiazol como inibidor de corrosão para bronze em água de chuva.",
      "authors": "Dalva Cristina Baptista do Lago, Lilian Ferreira de Senna, Aderval Severino Luna, Eliane D’Elia, Barbara Teixeira de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_046.pdf",
      "name": "Comportamento de cisteína como inibidor de corrosão para cobre em meio ácido",
      "authors": "Tuanny Rodrigues Coqui, Lilian Ferreira de Senna, Aderval Severino Luna, Eliane D’Elia, Dalva Cristina Baptista do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_047.pdf",
      "name": "Polarização cíclica de aços inoxidáveis em soluções eletrolíticas do processo Clean In Place",
      "authors": "Renata B. Soares, Giselle A. dos Santos, Cíntia G. F. Costa, Maria das Mercês R. de Castro, Vanessa de F. C. Lins"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_048.pdf",
      "name": "Tratamento e caracterização de hidróxido duplo lamelar para armazenamento de inibidor de corrosão",
      "authors": "Elber V. Bendinelli, Idalina Vieira Aoki, Isabel Cristina P. Margarit-Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_049.pdf",
      "name": "Avaliação da resistência à corrosão de aços inoxidáveis austeníticos utilizados em planta de produção de biodiesel através de testes de imersão",
      "authors": "Layane I. Silva, Cíntia G. F. Costa, Maria M. R. Castro, Vanessa F. C. Lins"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_052.pdf",
      "name": "Eletrodeposição de ligas de Zn-Co produzidas em meio de citrato a partir de corrente pulsada simples",
      "authors": "Julyana R. Garcia, Dalva C. B. do Lago, Lilian F. Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_053.pdf",
      "name": "Superfície de Folhas de Flandres Tratadas com Sais de Cério: Morfologia e Comportamento Eletroquímico",
      "authors": "Márcia Dalle Grave, Débora Guerra Salvador, Vinicius Soares Teixeira, Ivana Greice Sandri, Lisete Cristine Scienza"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_055.pdf",
      "name": "Uma visão critica de alguns conceitos utilizados na polarização dos metais.",
      "authors": "Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_059.pdf",
      "name": "Comportamento em relação à corrosão das ligas amorfas Fe-Co-Cr-M-B-Y (M=Mo, Nb, Si)",
      "authors": "Carlos Alberto Caldas de Sousa, Daniel Veras Ribeiro, Michele David de Jesus, Cleiton Araujo, Graziella Aguiar Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_060.pdf",
      "name": "Cálculo da Impedância Eletro -Hidrodinâmica Levando em Conta o Acoplamento do Transporte de Momento com o Transporte de Massa",
      "authors": "Nathália A. Leite, Oswaldo Barcia, Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_062.pdf",
      "name": "Síntese e Avaliação de Inibidores de Corrosão Triazínicos em Solução de HCl 1.0 M",
      "authors": "Mariana Lima da Cunha e Rodrigo de Siqueira Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_064.pdf",
      "name": "Influence of aging time on the corrosion behavior of 6061 Al alloy in alkaline solution",
      "authors": "Olandir V. Correa, José A.B. de Souza, Mara C.L. de Oliveira, Renato A. Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_065.pdf",
      "name": "Pitting susceptibility of AISI 304 stainless steel after cold rolling",
      "authors": "Olandir V. Correa, Mara C.L. de Oliveira, Renato A. Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_066.pdf",
      "name": "Revestimento Interno de Dutos Submarinos – Aplicação e Utilização em Gasodutos da Bacia de Santos.",
      "authors": "Fabio B. Azevedo, Bruno R. M. Cunha, Guilherme E. Haverroth"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_067.pdf",
      "name": "Efeito da concentração de polietilenoglicol na formulação de filmes híbridos aplicados em folhas de flandres",
      "authors": "Sandra R. Kunst, LilianV.R. Beltrami, Henrique R.P. Cardoso, Maria R. O. Vega, Tiago L. Menezes, Célia F. Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_068.pdf",
      "name": "Efeito da temperatura de cura e da dupla camada em filmes híbridos modificados com plastificante aplicados em folhas de flandres",
      "authors": "Sandra R. Kunst, Lilian V. R. Beltrami, Maria R. O. Vega, Henrique R.P. Cardoso, Célia F. Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_069.pdf",
      "name": "Estudo Comparativo entre as Técnicas do Número Mais Provável e PCR em Tempo Real para Quantificação de BRS (Bactérias Redutoras de Sulfato) em Amostras no Setor de Óleo e Gás.",
      "authors": "Diogo Azevedo Coutinho; Luana T. Albuquerque Guerreiro; Walter Barreiro Cravo Junior; Miriam Salete de Santana; Vitor Cordeiro Pereira; Gutemberg Pimenta; Marcelo Araujo e Marcia<br />\nT. S Lutterbach"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_070.pdf",
      "name": "Uso de Tecnologias de Proteção Anticorrosiva para Pontos Críticos na Preservação da Integridade de Instalações Marítimas de Produção.",
      "authors": "Álvaro Antônio Terra Martins da Silva, Renato de Mello Brandão Horta, Foluke Salgado de Assis"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_071.pdf",
      "name": "Implantação de programa de manutenção itinerante em plataformas satélites para atendimento de gestão de SGSO e requisitos da ANP",
      "authors": "Roberto Viana Dantas, Eudes José de Medeiros Cunha"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_072.pdf",
      "name": "Oxidação cíclica, caracterização das camadas oxidadas e cinéticas de oxidação em ligas Fe-5Si-5Cr ensaiadas a 800, 900 e 1000 oC.",
      "authors": "Artur Mariano de Sousa Malafaia, Marcelo Falcão de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_073.pdf",
      "name": "Avaliação de extratos de chás verde e branco como inibidores de corrosão para o aço -carbono 1020 em solução de HCl 1mol/L",
      "authors": "Viviane M. Teixeira, Lilian F. de Senna, Dalva C. B. do Lago, Aderval S. Luna"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_075.pdf",
      "name": "Estudo de corrosão por correntes alternadas (AC) em uma linha de transporte de gás Amauri G. Martins Jr.",
      "authors": "João Paulo Klausing Gervásio, Daniel Alves Torres Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_077.pdf",
      "name": "Análise da resistência à corrosão e comportamento eletroquímico dos revestimentos de Zn e Zn-Fe-Co em cloreto",
      "authors": "Conceição Aparecida Matsumoto Dutra, Flávia Cristina Zenith Ferreira, Roberto Zenhei Nakazato"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_078.pdf",
      "name": "Efeito do ácido fórmico na eficiência de deposição da liga Zn-Ni, obtidas no mesmo pH.",
      "authors": "Graziella A. G. Pedroza, Michele D. de Jesus, Carlos A. C. de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_079.pdf",
      "name": "Influência do teor de ferro na estrutura e na resistência à corrosão de revestimentos de ligas Zn-Fe e Zn-Fe-Co obtidos por eletrodeposição",
      "authors": "Roberto Zenhei Nakazato, Conceição Aparecida Matsumoto Dutra, Flávia Cristina Zenith Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_081.pdf",
      "name": "Avaliação de modelos computacionais para simulação do sistema de aterramento de torres de transmissão e cálculo da tensão de stress imposta ao revestimento isolante de tubulações enterradas",
      "authors": "Amauri G. Martins Jr., Antônio Carlos R. Valente, Paulo César de O. Teixeira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_082.pdf",
      "name": "Estudo da resistência à corrosão da liga Zn-Ni na presença do ácido fórmico, obtidas no mesmo pH",
      "authors": "Graziella A. G. Pedroza, Michele D. de Jesus, Carlos A. C. de Souza"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_083.pdf",
      "name": "Processo corrosivo de um trocador de calor na unidade de Hidrotratamento de uma Planta de Lubrificantes Naftênicos",
      "authors": "Emanuel Santana da Costa, Cícero Roberto de Oliveira Moura, Gerardo Jesus Aracena Perez, Antenor Barra Dias Filho, José Augusto Ribeiro Ferraz"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_084.pdf",
      "name": "Determinação das Taxas de Corrosão Uniforme e Puntiforme dos Cupons produzidos por diferentes Fornecedores",
      "authors": "Dilhermando J. Finamore; Elaine C. Pereira, Angelus G. P. Silva, Ronaldo P. Rocha, Marcello Filgueira, Angélica D. Salvador"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_085.pdf",
      "name": "Caracterização Microestrutural e Avaliação da Resistência à Corrosão de Aços Inoxidáveis Austeníticos utilizados em aplicações como Biomateriais",
      "authors": "Ely Wagner Ferreira Sabará, Alysson Helton Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_086.pdf",
      "name": "Estudo da resistência à corrosão do aço inoxidável duplex UNS S31803 tratado termicamente com diferentes velocidades de resfriamento",
      "authors": "Davi P. Garcia, Marcelo C. S. de Macedo, Sérgio S. M. Tavares, Artur P. de Barros"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_088.pdf",
      "name": "Avaliação do desempenho de inibidores de corrosão para concretos em ambientes contaminados com dióxido de carbono e cloretos",
      "authors": "Cristiane Resende, Adriano F. Diniz, Patricia B. Martelli, Alysson H. S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_089.pdf",
      "name": "Efeito da adição da glicerina na resistência à corrosão e na estrutura de um depósito de zinco obtido através de eletrodeposição.",
      "authors": "Michele D. Jesus, Carlos A. C. Souza, Graziella A. G. Pedroza, Daniel V. Ribeiro"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_093.pdf",
      "name": "Avaliação de corrosão em chapas de fundo de tanques de armazenamento de petróleo através da técnica de MFL - Magnetic Flux Leakage",
      "authors": "Emanuel Santana da Costa, Luiz Carlos Lima de Medeiros, Cícero Roberto de Oliveira Moura, Fábio Campos Morais, Marcus Vinícius Diamantino S. Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_094.pdf",
      "name": "THE INFLUENCE OF MOLYBDENUM AND NICKEL CONCENTRATION IN THE CORROSION SUSCEPTIBILITY OF STAINLESS STEEL OF THE THIRD GENERATION MARTENSITIC AND SUPERMARTENSITIC WIHT DIFFERENT NaCl SOLUTIONS.",
      "authors": "Philipe A. P. Pacheco; J. R. de Oliveira; J. A. C. P. Gomes and A. H. S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_096.pdf",
      "name": "Revestimento Microestruturado com Celulose para Proteção à Corrosão",
      "authors": "Cleide Borsoi, Lisete C. Scienza,Carlos A. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_101.pdf",
      "name": "Proteção catódica de juntas soldadas de dutos com revestimento de alumínio e de zinco",
      "authors": "Anna Ramus Moreira, Neusvaldo Lira de Almeida, Harold Rivas León, João Paulo Klausing Gervásio"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_102.pdf",
      "name": "Necessidade do uso de técnicas transientes para interpretar resultados estacionários em corrosão",
      "authors": "Oscar Rosa Mattos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_103.pdf",
      "name": "TRIBOCORROSÃO DO AÇO INOXIDÁVEL SUPERDUPLEX UNS S32750 EM SOLUÇÃO SBF",
      "authors": "Danillo P. Silva, José M. Sánchez-Amaya, Ivan N. Bastos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_104.pdf",
      "name": "Avaliação do efeito da rugosidade na formação do filme passivo em ligas de titânio utilizadas como Biomateriais",
      "authors": "Sergio Luiz Moni R. Filho, Lucas Starling Moura, Ivan Napoleão Bastos, Lincoln Cardoso Brandão e Alysson Helton Santos Bueno</p>\n<p>&nbsp;"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_105.pdf",
      "name": "Seleçao de material de tubos de permutador de calor baseada em ensaios de polarização",
      "authors": "André S. Pelliccione, Eduardo A. de Souza, Gerardo J. A. Perez, Jefferson R. de Oliveira, José A. C. P. Gomes, Murilo F. Leal, Otávio C. Corrêa"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_106.pdf",
      "name": "The corrosion susceptibility of 13cr and S13cr martensitic steels in a NaCl 5% solution with different surface preparation and aeration conditions",
      "authors": "Carolina M.P. Andrade, Jefferson R. Oliveira, José A.C. P. Gomes, Alysson H.S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_107.pdf",
      "name": "Estudo Comparativo entre as ligas de alumínio 3003 e 1060: Uma aplicação em tubovias de HNO 3",
      "authors": "Raphael Rezende, Guilherme Oliveira,Welton Azevedo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_109.pdf",
      "name": "Corrosão do aço induzida por íons cloreto: Avaliação da macrocélula de corrosão em concretos convencionais",
      "authors": "Joseanne M. R. Dotto, Iduvirges L. Müller"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_110.pdf",
      "name": "Agrietamiento de la fundición gris en el servicio de Ácido Sulfúrico",
      "authors": "David F. Mardero Iturralde, Martín Duarte Guigou"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_112.pdf",
      "name": "Estudo das condições de obtenção de revestimentos a base de TEOS/GPTMS aplicados na proteção do aço carbono.",
      "authors": "Fernando S. da Silva, Bruna S. Dos R. Aranha, Eduardo Dos S. Freitas Cardoso, Patrícia H. Suegama, Assis V. Benedetti, Hercílio G. de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_113.pdf",
      "name": "Avaliação da espessura, dureza e resistência a corrosão de uma camada anódica colorida",
      "authors": "Natal N. Regone"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_114.pdf",
      "name": "Influência da Natureza do EDTA na Corrosão do Aço em Fluidos de Estimulação de Poços de Petróleo",
      "authors": "Jorge A. Calderón, Ferley A. Vásquez, Javier A. Carreño"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_115.pdf",
      "name": "Eletrodeposição de revestimentos compósitos de Cu – AlO(OH).",
      "authors": "Thaís G. de Lima, Dalva C.B do Lago, Deborah V. Cesar, Lilian F. de Senna"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_116.pdf",
      "name": "The eletrochemical evaluation of the influence of titanium and molybdenum alloy elements in 13cr supermartensitic and 13cr martensitic stainless steels in different concentrations of chloride solutions",
      "authors": "Josemar C. Josemar, Jefferson O. Jefferson, José P. José, Alysson. B."
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_118.pdf",
      "name": "Corrosion study of API X70 in three different protic ionic liquids",
      "authors": "Maria R. Ortega-Vega, Sandra R. Kunst, Silvana Mattedi, Miguel Iglesias, Célia F. Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_119.pdf",
      "name": "Inhibición de la corrosión del acero por “tanatos” metálicos. Estudio comparativo de pinturas epoxídicas formuladas con tanatos metálicos en ensayos de corrosión acelerada",
      "authors": "Santiago Flores, Juan José Caprari, Luis Figueroa, Roberto Romagnoli"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_120.pdf",
      "name": "LIMPEZA MECÂNICA PARA REMOÇÃO DOS RESÍDUOS SÓLIDOS, ORIUNDOS DE CORROSÃO E EROSÃO DO SISTEMA SECUNDÁRIO, NOS ESPELHOS E SUPERFÍCIES EXTERNAS DOS TUBOS DOS GERADORES DE VAPOR DA USINA NUCLEAR DE ANGRA 1 PARA EVITAR CORROSÃO SOB TENSÃO",
      "authors": "Paulo Artur Pimentel Tavares da Silva, Diogo Petronilio de Jesus, Oséas Mello de Almeida, Lauro Roberto Lopes, Rodrigo Vieira da Fonseca, Ricardo Albuquerque Soares, Einar Sobral de Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_122.pdf",
      "name": "Investigação do perfil de rugosidade no desempenho anticorrosivo de esquemas de pintura",
      "authors": "Eider Santos, Arariboia Martins"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_124.pdf",
      "name": "Detecting MCI in Concrete Using UV-Spectroscopy-Migration Depth and Dosage Detections",
      "authors": "Ming Shen, Josh Hicks"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_125.pdf",
      "name": "Influência na Resistência de Contato Anodo/Eletrólito dos Parâmetros Utilizados no Dimensionamento de Sistemas de Proteção Catódica",
      "authors": "Laerce de Paula Nunes, Anderson Teixeira Kreischer, Luciano Gabriel de Oliveira Coelho, Antônio Pires Caetano, Léo Santana Gomes de Matos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_126.pdf",
      "name": "Utilização de alumínio recoberto por aspersão térmica em tubulação de condensadores evaporativos",
      "authors": "Baroni, L.F.S, Rovere, C.A, Kuri, S.E"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_127.pdf",
      "name": "Proteção Catódica de Estruturas Enterradas Em Áreas Urbanas de Grandes Cidades",
      "authors": "Ieda Nadja Silva Montenegro, Luiz Paulo Gomes, Laerce de Paula Nunes, Anderson Teixeira Kreischer"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_130.pdf",
      "name": "Effect of nanocrystalline mixed rare earth oxide coatings on the high temperature oxidation behavior of Fe20Cr5Al alloy",
      "authors": "Stela M.C.Fernandes, Olandir V.Correa, Lalgudi V.Ramanathan"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_131.pdf",
      "name": "Resistência à corrosão de latas de alumínio em contato com água tônica",
      "authors": "Luiza Esteves, Vanessa F. C. Lins, Eric M. Garcia"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_132.pdf",
      "name": "AVALIAÇÃO DA ATIVIDADE ANTICORROSIVA DE FORMULAÇÕES DE 4-HIDROXIBENZALDEÍDO TIOSSEMICARBAZONA E EXTRATO VEGETAL DE Talinum triangulare",
      "authors": "Mariana Almeida de Albuquerque , Márcia C. C. de Oliveira e Aurea Echevarria"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_133.pdf",
      "name": "Comportamento em Corrosão Do Aço Inoxidável Dúplex ASTM A890 Gr5A Solubilizado, Laminado A Quente E Conformado Por Spray",
      "authors": "Silvio R. F. Batista, Antônio Itanajé C. Silva, Valdemar S. Leal, Gedeom S. Reis, Rubens S. Gonçalves, Raimundo N. B. Oliveira, Maria P. S. S. Teixeira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_134.pdf",
      "name": "Considerações Sobre PREN (Pitting Resistance Equivalent Number) E Fase Sigma Na Resistência À Corrosão De Um Aço Inoxidável Dúplex",
      "authors": "Silvio Rogério F. Batista, Sebastião E. Kuri, Rubens S. Gonçalves"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_139.pdf",
      "name": "CONTROLE QUÍMICO DO SISTEMA SECUNDÁRIO DE ANGRA",
      "authors": "Diogo P. Jesus, Paulo A. P. T. Silva, Oséas M. Almeida, Einar S. Carvalho, Lauro R. Lopes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_140.pdf",
      "name": "Materiais para Vasos de Pressão em Meio com H 2S/H 2 Aplicáveis a Unidades de Hidrotratamento em Refinarias",
      "authors": "Aline Raquel Vieira Silva, Ana Emília Diniz Silva Guedes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_141.pdf",
      "name": "PROTEÇÃO CONTRA CORROSÃO DE FLANGES E CONEXÃO DE TUBOS",
      "authors": "Prof. E. Lyublinski, M. Schultz, R. Singh"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_143.pdf",
      "name": "HIBERNAÇÃO DE EQUIPAMENTOS CONTRA CORROSÃO",
      "authors": "E. Lyublinski, R. Singh, E. Tanabe, H. Siriaco"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_144.pdf",
      "name": "Estudo sobre sistemas de proteção catódica em meios contendo bactérias e alta salinidade",
      "authors": "Neusvaldo L. Almeida; Gutemberg de S. Pimenta; Vanessa Y. N. Ferrari; Marcelo Araújo; Márcia T. S. Lutterbach; Viviane de Oliveira; Eduardo W. Laurino; Johny H. Oliveira; Mariliza dos Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_146.pdf",
      "name": "Susceptibilidad a la Corrosión Localizada de Aleaciones Al-Ag Procesadas por Extrusión de Canal Angular Constante",
      "authors": "Martha A. Lomelí, Luis S. Hernández, Alberto Torres, Sandra L. Rodríguez, Laura A. Hernández"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_149.pdf",
      "name": "Análise do processo de aspersão térmica do zinco e suas qualidades de proteção para estruturas de aço, com foco em condições de parques eólicos.",
      "authors": "William. Marques, Frank E. Goodwin"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_150.pdf",
      "name": "Controle Químico da Corrosão do Sistema Primário (refrigeração do reator) da Central Nuclear de Angra 1",
      "authors": "Einar S. Carvalho, Diogo P. de Jesus, Paulo Artur P. T. Silva, Lauro Roberto Lopes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_151.pdf",
      "name": "Influência do potencial catódico sobre as propriedades mecânicas do aço API 5L X80",
      "authors": "Diniz Ramos de Lima Júnior, Magda Rosângela Santos Vieira, Bruna Nayara Pereira da Silva, Ivanilda Ramos de Melo, Cezar Henrique Gonzales, Severino Leopoldino Urtiga Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_152.pdf",
      "name": "Estudo comparativo de custos para estruturas de concreto armado usando vergalhão comum ou de aço galvanizado",
      "authors": "Luiza Abdala, Daniele Albagli, Lucy Inês Olivan, Luércio Scandiuzzi"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_155.pdf",
      "name": "Estudo da Corrosão por Cloretos e seus Efeitos em Aços Inoxidáveis AISI 430 e AISI 439 em Diferentes Acabamentos Superficiais",
      "authors": "Péricles Bosquetti, Maurício Angeloni, Alexandre Guizelini da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_160.pdf",
      "name": "Estudo preliminar da formação de biofilmes em superfícies de aço inox 304L",
      "authors": "Suleima E B Pereira, Dalva C. B. do Lago, Lilian F. de Senna, Márcia M. M. Gonçalves"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_161.pdf",
      "name": "Desenvolvimento de sensor para determinação de pH interfacial durante a corrosão puntiforme observada in situ por serie temporal de imagens",
      "authors": "Alexsandro M. Zimer, Marina M. da Silva, Lucia H. Mascaro, Ernesto C. Pereira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_162.pdf",
      "name": "Análise da resistência à corrosão de juntas soldadas por FCAW e FCAW-CW do aço naval",
      "authors": "Lino Alberto S Rodrigues, Danyella Crystyane S. Cardoso, Sand Queiroz de Oliveira, Eduardo de M. Braga"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_165.pdf",
      "name": "Estudo da Corrosão do aço 1020 e do cobre em biodiesel, produzido a partir de óleo de canola usado, desenvolvido por projeto de iniciação científica júnior na Escola SESC de Ensino Médio.",
      "authors": "Marcos Paulo M. Carvalho, Caio Couto Batatinha, Daniela Rosset, Guilherme Tarini, Matheus Torres, Sara Raquel Laurentino"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_166.pdf",
      "name": "Estudo da corrosão em ligas de Al-2,0%Fe tratadas por refusão a laser em meio de ácido sulfúrico",
      "authors": "Micene T. Katieli, Pariona M. Meza, Zara J. Alfredo, Guimarães Amanda, Domingues, Rodrigo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_167.pdf",
      "name": "Ocorrência do “Metal Dusting” nos Tubos de Caldeira Flamotubular de uma Unidade de Geração de Hidrogênio",
      "authors": "Paulo Pio Alvisi, Leandro de Souza Schiara"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_170.pdf",
      "name": "Revestimento a base tanino para substituição de cromatização em aço galvanizado",
      "authors": "Rafael Scheer Trindade, Aline d'Avila Gabbardo, Lorenzo Liguori Bastos, Álvaro Meneguzzi, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_171.pdf",
      "name": "Corrosão em tubos de condensadores air cooler do efluente de reatores de reforma catalítica",
      "authors": "Camila Pontes Pena, Eduardo Massayuki Suenaga, Gabriela Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_172.pdf",
      "name": "Estudo eletroquímico do recobrimento de ligas de alumínio com revestimentos orgânicos",
      "authors": "Jéssica V. Nardeli, Patricia H. Suegama, Assis V. Benedetti, Cecílio Sadao Fugivara, Younès Messaddec, Sidney J. L. Ribeiro, Elaine R. P. Pinto"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_173.pdf",
      "name": "Estudo comparativo entre a galvanização por imersão a quente – processo contínuo e por batelada – e a galvanização contínua",
      "authors": "Luiza Abdala, Daniele Albagli"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_174.pdf",
      "name": "Estudo voltamétrico dos sistemas Fe/NaOH 0,10 mol L -1 e Fe/NaOH 1,0 mol L -1",
      "authors": "Gilberto A. de O. Brito, Janaína G. Giori"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_175.pdf",
      "name": "OBTENÇÃO E CARACTERIZAÇÃO DE REVESTIMENTOS DE CONVERSÃO NANOCERÂMICOS À BASE DE TITÂNIO E ZIRCÔNIO",
      "authors": "Maíra P. P. Lucena, Gabriela J. Fávero, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_176.pdf",
      "name": "Influência do envelhecimento da solução precursora nas propriedades de proteção contra corrosão do revestimento silano BTSE com inibidor cério aplicado sobre aço galvanizado",
      "authors": "Aline D. Gabbardo, Caroline de Barros, Jane Zoppas Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_177.pdf",
      "name": "Influência da adição de corante nos revestimentos de conversão à base de zircônio em peças de aço zincado por eletrodeposição",
      "authors": "Josiane S. Costa, Tamyres C. Rosa, Jane Z. Ferreira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_178.pdf",
      "name": "Efeito da adição da glicerina na resistência a corrosão e na eficiência de deposição de depósitos de Zn-Ni contendo diferentes teores de Ni, obtidos através de eletrodeposição",
      "authors": "Carlos Alberto Caldas de Sousa, Evaristo Thiesney Barreto Lemos, Michel e David de Jesde Jesus, Taiane Ferreira de Melo, Graziella Aguiar Gomes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_179.pdf",
      "name": "Efeito da adição da glicerina na resistência a corrosão e na eficiência de deposição de depósitos de Zn-Ni contendo diferentes teores de Ni, obtidos através de eletrodeposição",
      "authors": "Carlos Alberto Caldas de Sousa, Evaristo Thiesney Barreto Lemos, Michele David de Jesus, Taiane Ferreira de Melo e Joeliton Evangelista Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_180.pdf",
      "name": "Resistência à corrosão do aço 316L em água artificial de poço de petróleo saturada com CO 2",
      "authors": "Diego Henrique Fonteles Dias, Jorge Luiz Cardoso, Hamilton Ferreira Gomes de Abreu, Pedro de Lima Neto, Marcelo José Gomes da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_181.pdf",
      "name": "Estudo da proteção contra a corrosão da liga de alumínio clad 2024-T3 anodizada em ácido tartárico-sulfúrico e protegida com híbrido aplicado por sol-gel",
      "authors": "V.R. Capelossi, R. P. B. Hernandez,I. Recloux, H. G de Melo, M.-G. Olivier"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_183.pdf",
      "name": "PROTEÇÃO CONTRA CORROSÃO DE TETOS DE TANQUES ATMOSFÉRICOS",
      "authors": "Prof. E. Lyublinski, R. Singh, M. Schultz, E. Leal"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_184.pdf",
      "name": "PROTEÇÃO CONTRA CORROSÃO DE FUNDOS DE TANQUES ATMOSFÉRICOS ",
      "authors": "E. Lyublinski, Y. Vaks, T.Natale, M. Posner, R. Singh, M. Schultz"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_190.pdf",
      "name": "Avaliação do comportamento eletroquímico do Nitinol em diferentes soluções fisiológicas",
      "authors": "Alana Witt Hansen, Luciane Taís Führ, Leonardo Marasca Antonini, Lílian Vanessa Rossa Beltrami, Cláudia Eliana Bruno Marino, Célia de Fraga Malfatti"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_191.pdf",
      "name": "Influência de parâmetros físico-químicos e químicos de água de reúso na corrosão de latão almirantado",
      "authors": "Rafael F. de Oliveira, Izabel N. Ivancko, Luiz H. C. Nogueira, Vânia M. J. Santiago, Andréa A.Veiga, Sérgio Pagnin, Ladimir J. de Carvalho, Leila Y. Reznik"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_193.pdf",
      "name": "Propriedades de corrosão de aços inoxidáveis supermartensíticos soldados por fricção radial",
      "authors": "Carlos A. Della Rovere, Celso R. Ribeiro, Rodrigo da Silva,Sebastião E. Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_194.pdf",
      "name": "Estudo do comportamento de corrosão da liga AA 2024-T3 soldada por fricção (FSW) utilizando técnicas eletroquímicas globais e locais",
      "authors": "Camila Molena de Assis, Tiago Sawczen, Idalina Vieira Aoki, Vincent Vivier, Hercílio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_197.pdf",
      "name": "Corrosion behavior of hydrotalcite coated AA 6061 alloy",
      "authors": "Stela M Fernandes, Olandir V. Correa, José A. Souza, Lalgudi V.Ramanathan, Renato A. Antunes, Mara C. de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_199.pdf",
      "name": "Análise c rítica dos ens aios de corrosividade de derivado s de petróleo ao cobre e ao aço - carbono",
      "authors": "Lorena Cristina de Oliveira Tiroel, Vanessa Yumi Nagayassu, Ze hbour Panossian, Johny Hernandes de Oliveira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_204.pdf",
      "name": "Elaboração e caracterização de revestimentos de carbono eletrodepositado a partir de dimetilformamida (DMF)",
      "authors": "Ribeiro P. C. Henrique, Maria Rita Ortega Vega, Falcade Tiago, Kunst R.Sandra, Malfatti F. Céliae"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_207.pdf",
      "name": "Estudo do comportamento elétrico pela técnica de espectroscopia de impedância e simulação numérica em ligas aerosespaciais Al-2,0%Fe tratadas por refusão a laser em ácido sulfúrico.",
      "authors": "Micene T. Katieli, Pariona M. Meza, Zara J. Alfredo, Guimarães Amanda, Bosetti S. Pedro"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_210.pdf",
      "name": "Estudo da ação inibidora do extrato de Camellia sinesis na corrosão do aço-carbono 1020 em HCl 1 mol/L",
      "authors": "Elaine Cesar do Carmo Assumpção de Souza, Kelly Cristina Ribeiro Ferreira, Michelle André da Silva, Alex de Melo Monteiro, Mariana Magalhães Marques, Dalva Cristina Baptista do Lago, Lilian Ferreira de Senna, Eliane D'Elia"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_211.pdf",
      "name": "Mapas de impedância eletroquímica aplicada à corrosão",
      "authors": "Jorge Morvan Luz, Marco André Abud Kappel, Ricardo Fabbri, Roberto Pinheiro Domingos, Ivan Napoleão Bastos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_214.pdf",
      "name": "Influência da fase sigma na resistência à corrosão do aço inoxidável ASTM A890 grau 1C após tratamento isotérmico",
      "authors": "Daniel de Castro Girão, Maria Filomena Leitão Vasconcelos, Walney Silva Araújo, Pedro de Lima Neto, Hamilton Ferreira Gomes de Abreu"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_216.pdf",
      "name": "Estudo eletroquímico empregado em diferentes polimentos químicos na liga de alumínio",
      "authors": "B. S. D. R. Aranha, C. S. Fugivara, A. V. Benedetti, P. H. Suegama"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_218.pdf",
      "name": "Estudo de performance de inibidores de corrosão comerciais aplicados em águas de reúso de refinaria",
      "authors": "Raphael da C. Pinto, Rafael F. de Oliveira, Izabel N. Ivancko, Ladimir J. de Carvelho, Leila Y. Reznik"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_221.pdf",
      "name": "Comportamento eletroquímico da liga AA2024-T3 anodizada em ácido sulfúrico e tartárico e protegida com híbrido Sol-Gel",
      "authors": "Rocio del Pilar Bendezu Hernandez, Vera Rosa Capelossi, Anne-Pascale Romano, MarieGeorges Olivier, Hercílio Gomes de Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_222.pdf",
      "name": "Utilização da liga Zn-Al-Mg como uma inovação na Galvanização",
      "authors": "Renato Cavalcanti de A. Tozin"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_226.pdf",
      "name": "Estudo do Comportamento Eletroquímico da Liga Ti-12Si em NaCl 3,5 % e 40 % de Etilenoglicol em NaCl 3,5 %",
      "authors": "Haroldo M. Gonçalves; Ivan Ramirese Geziel R. de Andrade"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_227.pdf",
      "name": "Frações polares obtidas de vegetal sul-americano aplicadas no combate à corrosão na indústria petrolífera",
      "authors": "Nadja M. C. Alves, Djalma R. da Silva, Cátia G. F. T. Rossi, Rosélia de S. Leal, Tereza N. C. Dantas, Afonso A. D. Neto"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_228.pdf",
      "name": "Avaliação do uso de abrasivo micro encapsulado em esponja de poliuretano para tratamento de superfícies sob o enfoque das tecnologias limpas e da legislação ambiental brasileira.",
      "authors": "Eduardo Cardoso Garrido, Miguel Roca Calza, Nialdo Pereira dos Santos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_233.pdf",
      "name": "Avaliação da resistência à corrosão e propriedades mecânicas do aço inoxidável duplex LDX 2404 envelhecido termicamente em baixa temperatura",
      "authors": "Rodrigo da Silva, Carlos Alberto Della Rovere, Sebastião Elias Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_236.pdf",
      "name": "Investigação do comportamento passivo de aços supermartensíticos em meios com elevado teor de íons cloreto",
      "authors": "Mário José Bueno de Souza Freitas, Monica Maria de Abreu Mendonça Schvartzman, Wagner Reis da Costa Campos"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_237.pdf",
      "name": "Influência do tratamento térmico na microestrutura e no grau de sensitização de um aço inoxidável supermartensítico 15%Cr",
      "authors": "Bruno A.R.S. Barbosa, Sérgio S.M. Tavares, Ivan N. Bastos, Juan M. Pardal"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_238.pdf",
      "name": "Efeito da corrente pulsada na composição e estrutura da liga ZnCo eletrodepositada",
      "authors": "Célia R. Tomachuk, Isolda Costa"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_239.pdf",
      "name": "Utilização de sulfato como inibidor de corrosão localizada para a liga 800GN em água pressurizada em alta temperatura contendo íons cloreto",
      "authors": "Fábio A. Mansur, Mônica M. A. M. Schvartzman, Pedro H. B. O. Nogueira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_240.pdf",
      "name": "Efeito inibidor dos ânions carboxilatos e benzoato na corrosão de armaduras de concreto armado",
      "authors": "Almeida, P. ;Silva, T.M.V, Silva, E.F.C,Tamborim, S.M.M."
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_242.pdf",
      "name": "Formulação e Estudo Comparativo da Eficiência do Molibdato de Sódio e Tungstato de Sódio como Inibidor de Corrosão para aço 1010 em meio ácido e meio neutro.",
      "authors": "Victor C. Moreira, Glorys V. Bueno, Isabel C. Guedes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_243.pdf",
      "name": "Electrochemical evaluation of hydroxyapatite and titanium oxide coating made by HVOF in Kokubo's solution.",
      "authors": "Rafael T. Sakai, Patricia H. Suegama, Assis V. Benedetti, Hortensia Melero, Javier Fernandez"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_244.pdf",
      "name": "Influência da adição de nanopartículas de SiO 2 em um filme híbrido na liga AZ31 na proteção contra a corrosão",
      "authors": "Riberto N . Peres, Maria F . Montemor, Hercílio G . de Melo, Patricia H. Suegama"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_245.pdf",
      "name": "Caracterização e aplicação do tensoativo natural extraído da IPC como inibidor de corrosão no aço carbono 1020",
      "authors": "Jussara C. Cardozo, Cátia Guaraciara F. T. Rossi, Keila Rejane de O. Melo, Rosélia A. de Sousa"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_247.pdf",
      "name": "Avaliação eletroquímica e microscópica do aço carbono em biodiesel na presença e ausência de antioxidante sintético",
      "authors": "Yara P.da Silva, Viviane Dalmoro, Denise S.Azambuja, Clarisse M. S. Piatnicki"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_249.pdf",
      "name": "Obtenção, via eletroquímica, de nanotubos auto-organizados sobre Ti cp Nilson T.C.",
      "authors": "Oliveira, Julia F. Vedério, Claudemiro Bolfarini"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_250.pdf",
      "name": "Desempenho de esquemas de pintura em câmara de condensação de umidade, em comparação a condições de imersão e de atmosfera úmida",
      "authors": "Yuri H. P. Café, Tatiane S. Moura, Cristina C. Amorim, Marcos M. Sá, Alberto P. Ordine, Fernando L. Fragata"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_251.pdf",
      "name": "EFEITO DE TINTAS DE FUNDO RICAS EM ZINCO NO DESEMPENHO ANTICORROSIVO DE ESQUEMAS DE PINTURA APLICADOS EM CHAPAS DE AÇO- CARBONO OXIDADAS PREPARADAS POR FERRAMENTAS MECÂNICAS",
      "authors": "C. C. Amorim, F. L. Fragata, M. M. de Sá, A. P. Ordine, Y. H. P. Café"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_253.pdf",
      "name": "Estudo de caso de corrosão metálica em equipamentos elétricos da subestação",
      "authors": "Angelim, PE Alberto Pires Ordine, Fernando de Loureiro Fragata, Mauro Zanini Sebrão, Francisco Alexandre Filho, Luiz Raimundo Barbosa"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_254.pdf",
      "name": "Descolamento de revestimento de polietileno tripla camada: influência do tipo de solo e potencial aplicado",
      "authors": "Denise S. de Freitas, Simone L. D. C. Brasil, Gustavo Xanchão, Eduardo G. B. Leite, Jorge F. P. Coelho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_255.pdf",
      "name": "Mapeamento de casos de corrosão em usinas de produção de açúcar e álcool",
      "authors": "Alisson H. dos S. Marinho, José G. da Silva Jr, Jorge F. da S. Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_257.pdf",
      "name": "Mitigação da Corrosão do Aço-Carbono 1020 em meio ácido na presença de diferentes extratos de Café (Coffea canephora)",
      "authors": "Elaine Cesar do Carmo Assumpção de Souza, Beatriz de Andrade Ripper, Daniel Perrone Moreira, Eliane D’Elia"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_260.pdf",
      "name": "AVALIAÇÃO DO EFEITO DO ÁCIDO ACÉTICO NA CORROSÃO DO AÇO CARBONO POR CO 2 EM CONDIÇÕES DE NÃO FORMAÇÃO DE FILMES PROTETORES",
      "authors": "Ricardo Marcondes Guisso, Rosane Fernandes Brito, Alvaro Augusto Oliveira Magalhães, Marcelo Camargo Severo Macêdo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_261.pdf",
      "name": "Estudo do extrato da semente de mamão papaia (Carica papaya) como inibidor de corrosão para o aço-carbono 1020 em meio ácido.",
      "authors": "Vanessa Vasconcelos Torres, Eliane D’Elia, Gabrielle Borges Cabral, Kelly Cristina Ribeiro Ferreira, Ana Carolina Gomes da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_262.pdf",
      "name": "Efeito de filmes finos de óxido de nióbio obtidos por magnetron sputtering sobre a resistência à corrosão do aço inoxidável AISI 316",
      "authors": "Guilherme A. Geríbola, Guilherme Scheidt, Oswaldo P. V. da Silva Junior, Renato A. Antunes, Mara C.L.Oliveira, Marina F. Pillis"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_263.pdf",
      "name": "Avaliação do grau de sensitização dos aços inox UNS S32750",
      "authors": "Josemar L. Costa, Jefferson R. Oliveira, José A. P. Gomes, Alysson. H. S. Bueno"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_266.pdf",
      "name": "Efeito do potencial nos parâmetros de circuito equivalente de diagramas de impedância eletroquímica",
      "authors": "Glauber de Souza Melengate, Iuri Bezerra de Barros, Ivan Napoleão Bastos, João da Cruz Payão Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_267.pdf",
      "name": "Revestimento de equipamentos de processo operando em alta temperatura. Cases comprovam bom desempenho",
      "authors": "Diego G. Hita"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_270.pdf",
      "name": "Nova Tecnologia de Revestimentos Internos Resistentes à Erosão e Pulverizáveis para Tubulações e Equipamentos de Processo",
      "authors": "Ivan Ordaz, Diego Gonzalo Hita, Pedro Ruiz, Cassia Sanada"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_271.pdf",
      "name": "Estudo da Corrosividade do Enxofre Elementar em E&amp; P",
      "authors": "Denise Souza de Freitas, Luana Barki e Gustavo Leitão Vaz"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_273.pdf",
      "name": "Efeito do Revenido no Potencial de Pite em Aços Inoxidáveis Martensíticos",
      "authors": "Sandra Nakamatsu, Renato de Mendonça, Juliete N. Pereira, Pedro V. Marcos, Ana C. F. de Paiva, Bruno R. B. de Souza, Neide A. Mariano"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_276.pdf",
      "name": "A Influência do Recobrimento Biomimético em Titânio c.p. e Aço Inoxidável Austenítico nas Propriedades de Corrosão",
      "authors": "Mariny Fabiéle Cabral Coelho, Talita Pezzo Carvalhal, Sandra Nakamatsu, Eliana C. da S. Rigo, Neide Aparecida Mariano"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_277.pdf",
      "name": "Desenvolvimento de procedimento prático de campo para identificação de tintas de acabamento, visando auxiliar na especificação de esquemas de pintura de manutenção",
      "authors": "Fernando L. Fragata, Marcos M. Sá, Celso Gnecco e Luiz Alberto F. da Silva"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_279.pdf",
      "name": "Susceptibility of M5 (ZrNbO) alloy to pitting corrosion at different temperatures",
      "authors": "Olandir V. Correa, Mara C.L. de Oliveira, Elisabete J. Pessine, José A.B. de Souza, Renato A. Antunes"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_282.pdf",
      "name": "Antifoulant compounds with copper for preventing bioadhesion. A case study.",
      "authors": "Patricia Sandra Guiameta, Sandra Gabriela Gómez de Saravia"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_283.pdf",
      "name": "Dispositivo contínuo de funcionamento de retificador de proteção catódica",
      "authors": "João Paulo Klausing Gervásio, Daniel Alves Torres Filho, Alexandre Vicente da Fonseca, José Luiz de Araújo Silva Filho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_286.pdf",
      "name": "Aplicação de ensaios eletroquímicos na avaliação da resistência à corrosão do aço duplex SAF 2205 solubilizado",
      "authors": "Maria E. R. Cronemberger, Sandra Nakamatsu, Renato de Mendonça, Juliete Pereira, Neide A. Mariano, Carlos A. Della Rovere, Sebastião E. Kuri"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_287.pdf",
      "name": "Verifcação da Susceptibilidade a Corrosão do Aço API 5L X70 em Diferentes Meios Etanólicos.",
      "authors": "Pedro M. Moraes, Joseane O. Breitenbach, Carlos Eduardo F. Kwietniewski, Telmo R. Strohaecker, Gutemberg S. Pimenta, Ilson Palmieri Baptista and Marcelo Araujo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_288.pdf",
      "name": "Efecto de BSR, CO2, crudo y un tratamiento químico en la agresividad corrosiva de un agua de producción sintética",
      "authors": "Antonio de Turris, Matilde Romero, Sankara Papavinasam, Lisseth Ocando"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_289.pdf",
      "name": "ESTUDO DE ELETRODEPOSIÇÃO DE LIGAS Cu-Sn EM MEIO DE CITRATO DE SÓDIO",
      "authors": "João Carlos S. S. Júnior, Lilian Ferreira de Senna, Dalva Cristina Baptista do Lago"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_291.pdf",
      "name": "Technological Development of Anti-fouling Paints with EPS as a Natural Biocide",
      "authors": "Rodrigo de S. Melo, Simone L. D. C. Brazil, Aricelso M. Limaverde Filho, Ladimir J. De Carvalho e Odara R. Baptista Melo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_292.pdf",
      "name": "Cinética da Cura de Filmes de Tinta Industrial pela Técnica de Espectroscopia de Difusão de Onda",
      "authors": "Marcos A.C. Berton, Paulo R.D. Marangoni, Nério Vicente Jr."
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_293.pdf",
      "name": "Estudo comparativo das taxas de corrosão do aço-carbono em água do mar sintética e águas de produção de pós-sal e pré-sal",
      "authors": "Rodrigo Leite Quaresma, Rodrigo de Siqueira Melo, Simone D. C. Brasil, Ladimir J. de Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_299.pdf",
      "name": "Metodologia para controle de qualidade de peças de latão e zamac banhadas em ouro",
      "authors": "José Antonio da C. P. Gomes, Adriana B. da Silva, Márcia Fosco, Jorge Fosco, Gaetano Vaccariello, Paulo Borges"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_300.pdf",
      "name": "Desempeño de sonda basada en electrodo de referencia de cobre sulfato de cobre en sistema de monitoreo de corrosión in situ desarrollado para estructuras de concreto reforzado",
      "authors": "G. Roa-Rodriguez, W. Aperador, A. Delgado"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_301.pdf",
      "name": "A Non-Phosphorous Chemical Treatment Program for Cooling Water Systems",
      "authors": "Damian Serra, Osvaldo Dib"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_303.pdf",
      "name": "Corrosão de armadura de estruturas de concreto leve com ar incorporado",
      "authors": "Adriana de Araujo; Zehbour Panossian, Mírian Cruxên B. de Oliveira, David R. das Neves Filho, Alberto D. Siqueira Junior"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_304.pdf",
      "name": "Extensão da vida útil das estruturas de concreto com uso de armaduras de aço-carbono revestidas ou de aço inoxidável",
      "authors": "Adriana de Araujo, Anna Ramus Moreira, Zehbour Panossian"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_306.pdf",
      "name": "The Uses of Polyvinylidene Fluoride based resins in Chemical Handling Systems",
      "authors": "Denis K. de Almeida, Fabio L. F. Paganini, David Seiler"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_309.pdf",
      "name": "Interpretação de curvas de polarização",
      "authors": "Zehbour Panossian, Célia Aparecida Lino dos Santos, Juliana Lopes Cardoso; André Luiz Nunis da Silva, Rafael Augusto Camargo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_310.pdf",
      "name": "Influência da rugosidade e da camada de ligante sobre a adesão de revestimento de pentóxido de nióbio aplicado por aspersão térmica a chama",
      "authors": "Eduardo S. A. Conde, Simone L. D. C. Brasil, Ladimir J. Carvalho"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_312.pdf",
      "name": "Corrosion protection and the cost of failure",
      "authors": "Lasse Isaksena"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_313.pdf",
      "name": "Combinação sinérgica de TEOS e ácido fosfônico para proteção da liga de alumínio AA1100",
      "authors": "Viviane Dalmoro, Yara P. da Silva, João Henrique Z. dos Santos, Elaine Armelin, Carlos Alemán, Denise S. Azambuja"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_314.pdf",
      "name": "Avaliação da corrosão por frestas de diferentes ligas nobres para aplicação em equipamentos de poços injetores",
      "authors": "Guedes, Flávia Maciel Fernandes; Paiva, Eva Maria de Oliveira; Lopes, Eduardo Gullo Muller; Andrade, Cynthia de Azevedo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_315.pdf",
      "name": "Perfil de Rugosidade de Superfícies de Aço-carbono x Espessura de Tintas e de Esquemas de Pintura - Um Tema Importante para Ser Debatido",
      "authors": "Celso Gnecco e Fernando Fragata"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_316.pdf",
      "name": "Reparo de Pintura Interna de Tanques de Armazenamento",
      "authors": "Fábio Moraes de Oliveira, Marcelo Gimenez, Gerson Vianna Vieira"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_317.pdf",
      "name": "Intergranular corrosion in 350-grade maraging steel",
      "authors": "Nadia Khaled Zurba, Andrea Paesano Jr."
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_318.pdf",
      "name": "Avaliação do desempenho de materiais para seleção da metalurgia adequada para tubos de aquecedores de óleo",
      "authors": "Victor G. Silva, Cynthia A. Andrade, Flavia M. F. Guedes, Rosane F. de Brito, Neusvaldo L. de Almeida, Gislaine M. Bragagnolo"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_319.pdf",
      "name": "Avaliação da eficiência de inibidores voláteis de corrosão para proteção externa de fundo de tanque de armazenamento por meio de ensaios de imersão",
      "authors": "José Luís S. Ribeiro, Adriana de Araujo, Zehbour Panossian, Neusvaldo L. de Almeida, Helena L. A. Gloria, Thales G. Rosa, Gutemberg de Souza Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_320.pdf",
      "name": "Avaliação da eficiência de inibidores voláteis de corrosão para proteção externa de fundo de tanque de armazenamento por meio de técnicas eletroquímicas",
      "authors": "José Luís S. Ribeiro, Zehbour Panossian, Adriana de Araujo, Neusvaldo L. de Almeida, Renata A. Nelli, Guternberg de Souza Pimenta"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_321.pdf",
      "name": "Efeito autorreparador em uma resina a base de epóxi pela aditivação de microcápsulas contendo inibidores de corrosão",
      "authors": "Fernando Cotting, Idalina V. Aoki"
    },
    {
      "url": "https://intercorr.com.br/anais/2014/INTERCORR2014_322.pdf",
      "name": "Estudo sobre corrosão preferencial em tubos com solda helicoidal",
      "authors": "Neusvaldo L. Almeida; André Luis C. Bonfim; Jussara Melo; Márcio Bispo de Almeida"
    }
  ]
}

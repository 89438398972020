export const FOTOS_2010 = [
  {
    "src": "gallery/2010/intercorr2010_01.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_02.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_03.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_04.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_05.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_06.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_07.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_08.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_09.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_10.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_11.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_12.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_13.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_14.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_15.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_16.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_17.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_18.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_19.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_20.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_21.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_22.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_23.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_24.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_25.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_26.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_27.jpg",
    "width": 1200,
    "height": 803
  },
  {
    "src": "gallery/2010/intercorr2010_28.jpg",
    "width": 1200,
    "height": 803
  }
];

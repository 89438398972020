import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Button} from 'react-bootstrap';
import { i18n } from '../translations/i18n';

const Expediente = () => {
  return (
    <div data-comp-corpoeditorial>
      <div className="pre-title"/>
      <h1>{i18n.t("expediente.titulo")}</h1>

      {/*<p>ABRACO - Associação Brasileira de Corrosão</p>*/}

      <h4>{i18n.t("expediente.comissaoOrganizadora")}</h4>

      <p><span className="font-weight-bold text-capitalize">{i18n.t("expediente.presidente")}:</span> Olga Baptista Ferraz (INT – {i18n.t('expediente.int')})</p>
      <p><span className="font-weight-bold">Vice-{i18n.t("expediente.presidente")}:</span> Zehbour Panossian (IPT – {i18n.t('expediente.ipt')})</p>

      <h4>{i18n.t("expediente.periodicidade")}</h4>
      <p>{i18n.t("expediente.resPeriodicidade")}</p>

      <h4>{i18n.t("expediente.idioma")}</h4>
      <p>{i18n.t("expediente.inglesEportugues")}</p>


      <h4>{i18n.t("expediente.dadosEditora")}</h4>
      <p><strong>{i18n.t("expediente.nome")}:</strong> Associação Brasileira de Corrosão – ABRACO</p>
      <p><strong>{i18n.t("expediente.endereco")}:</strong> Avenida Venezuela, nº 27 – Sala 412 – Centro / Rio de Janeiro – CEP: 20081-311</p>
      <p><strong>{i18n.t("expediente.emailEtelefone")}:</strong> <a href="mailto:marketing@abraco.org.br">marketing@abraco.org.br</a> / <a href="tel:2125161962">(21) 2516-1962</a></p>

      <Button className="btn btn-primary btn-sm" target="_blank" href="https://www.abraco.org.br">{i18n.t("expediente.siteDaEditora")}</Button>

    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
)(Expediente);

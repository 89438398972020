export const FOTOS_2008 = [
  {
    "src": "gallery/2008/intercorr2008_01.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_02.jpg",
    "width": 1200,
    "height": 900
  },
  {
    "src": "gallery/2008/intercorr2008_03.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_04.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_05.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_06.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_07.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_08.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_09.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_10.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_11.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_12.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_13.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_14.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_15.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_16.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_17.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_18.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_19.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_20.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_21.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_22.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_23.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_24.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_25.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_26.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_27.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_28.jpg",
    "width": 1200,
    "height": 798
  },
  {
    "src": "gallery/2008/intercorr2008_29.jpg",
    "width": 1200,
    "height": 798
  }
];

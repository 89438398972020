import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Navbar, Nav} from "react-bootstrap";
import * as $ from "jquery";

import './NavBar.scss';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { i18n } from '../../translations/i18n';

function onSelect(e) {
  $('.navbar-toggler').click();
}

function NavBar() {
  const issn = process.env.REACT_APP_ISSN;

  return (
    <Navbar data-comp-navbar className="align-items-md-end flex-md-column sticky-top" expand="md" bg="">

      <Navbar.Toggle aria-controls="navbarSupportedContent"/>


      <Navbar.Brand as="h2">
        <Link to="/">
          <small>Anais</small>
          Intercorr</Link>
        {issn && (
          <div className="issn">ISSN {issn}</div>
        )}
      </Navbar.Brand>
      <LanguageSwitcher />
      <Navbar.Collapse id="navbarSupportedContent">
        <Nav className="mr-auto  nav flex-column">
          <li className="nav-item ">
            <NavLink onClick={onSelect} exact to="/" className="d-flex align-items-center nav-link">
              <span className="nav-item-line"/><span>Home</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink onClick={onSelect} to="/edicoes" className="d-flex align-items-center nav-link text-white text-right"><span
              className="nav-item-line"/><span>{i18n.t('edicoes.titulo')}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink onClick={onSelect} to="/comissao" className="d-flex align-items-center nav-link text-white text-right">
              <span className="nav-item-line"/><span>{i18n.t('comissao.titulo')}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink onClick={onSelect} to="/corpo-editorial" className="d-flex align-items-center nav-link text-white text-right">
              <span className="nav-item-line"/><span>{i18n.t('editorial.titulo')}</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink onClick={onSelect} to="/expediente" className="d-flex align-items-center nav-link text-white text-right">
              <span className="nav-item-line" /><span>{i18n.t('expediente.titulo')}</span>
            </NavLink>
          </li>
        </Nav>
        <div className="logos d-flex justify-content-end">
          <div className="logo">
            <img src="./assets/abraco.png" alt="ABRACO" className="img-fluid"/>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavBar;

export const EDICAO_2021 = {
  "data": [
    {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_101.pdf",
      "name": "Comparative thermodynamic stability of the stainless steels UNS S41003, UNS S30400, and carbon steel SAE 1010 in diluted sodium sulfate solution",
      "authors": "Adolfo K. N. Viana, Zehbour Panossian, Tarcisio R. de Oliveira, Celia Aparecida L. dos Santos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_102.pdf",
      "name": "Inspection of the exposed concrete façades of Vilanova Artigas, modern Brazilian heritage building with 50 years of service life",
      "authors": "Adriana de Araujo, Claudia de Andrade Oliveira, Tatiana R. da Silva Simão, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_103.pdf",
      "name": "Evaluation of galvanic anode for patch repair of concrete structures deteriorated by chloride ion-induced corrosion",
      "authors": "Adriana de Araujo, José L. S. Ribeiro, Thales G. Rosa, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_105.pdf",
      "name": "Uncertainty Estimation in Paint Quality Control to Improve Paint Standards of the Brazilian Electrical Sector",
      "authors": "Alberto P. Ordine, Paulo R. G. Couto, Cristina C. Amorim, Jailton C. Damasceno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_106.pdf",
      "name": "Stainless Steel Alloys as Technical Alternatives to Industrial Coatings in Corrosive Environments of the Electric Sector",
      "authors": "Alberto P. Ordine, Victor F. Jesus, Elber V. Bendinellia, Bruno R. Cardoso, Carlos F. T. Matt, Adolfo K. N. Viana, Ronildson G. Monteiro, Geraldo R. R. Filho, Vinícius O. Rodrigues"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_107.pdf",
      "name": "The effect of wet abrasive blasting parameters on the anticorrosive performance of a coating system used in buried structures",
      "authors": "Marcos M. de Sá, Alberto P. Ordine, Cristina C. Amorim, Elber V. Bendinelli"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_109.pdf",
      "name": "Incorporation of niobate ions into oxide layers on aluminum by a two-step anodizing process",
      "authors": "Alessandra Konrath, Luís F. P. Dick"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_112.pdf",
      "name": "Case Study – Resort Corais de Búzios (RN)",
      "authors": "Alexandre M. N. Cobra, Flávio U. Morais Jr, Valdecir A. Quarcioni, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_113.pdf",
      "name": "2D pit growth in Mg thin films",
      "authors": "Aline D. Gabbardo, G. S. Frankel"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_114.pdf",
      "name": "Corrosion inhibition of carbon steel in 0,1 mol.L-1 H2SO4 solution by aqueous extract of Morinda Citrifolia L.",
      "authors": "Aline S. Lima, Marcelo F. A. Neto, Úrsula Cid Pereira, Walney Silva Araujo, Men S. M. S. Filho, Marcelo M. V. Parente"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_115.pdf",
      "name": "Microemulsified systems based on passion fruit oil as potential green corrosion inhibitors for carbon steel P110 in saline medium saturated with CO2",
      "authors": "Aline V.Souza, Janaína. C. da Rocha, J.A.C Ponciano Gomes, Luiz C.M. Palermo, Claúdia R.E. Mansur"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_116.pdf",
      "name": "Study of sodium nitrite effect as corrosion inhibitor of AISI 1010 carbon steel expose in aggressive environment",
      "authors": "Allan R. Silva, Anelize Seniski, Kleber F. Portella, Gilberto T. Carrera, Mariana d’Orey G. P. Bragança"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_117.pdf",
      "name": "Corrosion behavior of weathering steel in marine environments",
      "authors": "Amanda P. Silva, Raphael F. Assumpção, Rogério A. Carneiro, Vanessa F. C. Lins, Dalila C. Sicupira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_119.pdf",
      "name": "Study of the hydrophobicity of coatings based on niobium pentoxide (Nb2O5)",
      "authors": "Ana Laura D.M.Campista, Ladimir J.de Carvalho, Simone Louise D.C. Brasil"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_124.pdf",
      "name": "Thermal Aging Effects on Mechanical and Corrosion Resistances of Super-Austenitic Stainless Steel exposed at 600˚C",
      "authors": "Juan M. Pardal, Andréia S. M. Cardoso, Sérgio S.M. Tavares, Alex P. Souza, Cauê S.C. Nogueira, Jorge L. Cardoso, Marcelo J. G. da Silva, Javier A. C. Velasco"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_125.pdf",
      "name": "Influence of Backing Gas Protection on the Corrosion Resistance in Superduplex Stainless Steel Root Welds Performed by GTAW Process",
      "authors": "Tobias C.C. Paiva, Juan M. Pardal, Andreia S.M. Cardoso, Tabatta R.B. Martins, Pedro S.P. Garcia, Sérgio S.M. Tavares, Ismael Cardote Filho"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_126.pdf",
      "name": "Comparative evaluation of electrical resistance probes (SRE) using dynamic tests",
      "authors": "Anna Ramus Moreira, Victor Gomes Silva, Luis Fernando Fiuza Chaves, Neusvaldo Lira de Almeida, Álvaro Augusto Oliveira Magalhães"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_129.pdf",
      "name": "The role of roughness of inorganic conversion coatings on the stability of alumina film in saline medium",
      "authors": "Antonio V. C. Braga, Dalva C. B. Lago, Lilian F. Senna"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_130.pdf",
      "name": "Production and characterization of anticorrosive alumina film/inorganic conversion coatings/304L stainless steel systems for biomedical application",
      "authors": "Camila S. Silva, Antonio V. C. Braga, Dalva C. B. do Lago, Lilian F. de Senna"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_131.pdf",
      "name": "Analysis of the influence of the corrosive environment of air-sea on the fatigue failure of a stainless steel threaded screw rod used in the automobile industry as structural part.",
      "authors": "José G.S. Bezerra and Armando H. Shinohara"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_132.pdf",
      "name": "Flexible pipes - Corrosion of Armour Wires in Annulus",
      "authors": "Arne Dugstad, Simona Palencsár"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_133.pdf",
      "name": "Influence of Tempering Heat Treatment on Corrosion Susceptibility of Supermartensitic Stainless Steels 13Cr.",
      "authors": "Pâmela T. L. Santos, Arthur F. Sene, Carlos E. V. Masalla , Gustavo L. Vaz, Jefferson R. Oliveira, Lecino Caldeira, Alysson H. S. Bueno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_135.pdf",
      "name": "The corrosion behavior on AA2050-T84 and AA7050-T7451 welded and non-welded by FSW",
      "authors": "Bárbara V. G. de Viveiros, Larissa O. Berbel, Uyime Donatus, Isolda Costa"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_137.pdf",
      "name": "ELECTROCHEMICAL INVESTIGATION OF THE ELECTROLYTE RENEWAL EFFECT ON API X65 CORROSION IN SWEET AND SOUR ENVIRONMENTS",
      "authors": "Bernardo A. F. Santos, Maria E. D. Serenario, Rhuan C. Souza, Jefferson R. Oliveira, Gustavo L. Vaz, Jose A. C. P. Gomes, Alysson H. S. Bueno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_138.pdf",
      "name": "THE ELECTROLYTE RENEWAL EFFECT ON CORROSION AND SCALES FORMATION OF API X65 CARBON STEEL IMMERSED IN CONDENSED WATER ENVIRONMENTS",
      "authors": "Bernardo A. F. Santos, Maria E. D. Serenario, Rhuan C. Souza, Luan C. Santos, Jefferson R. Oliveira, Gustavo L. Vaz, Jose A. C. P. Gomes, Alysson H. S. Bueno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_139.pdf",
      "name": "Development and Application of New Technologies for Prevention of Corrosion in Steel Structures",
      "authors": "Bernardo Rocha, Fabiano Gaeede, Taline Lima, Marcelo Strieder, Marcelo Muniz, Rey Pereira, Neusvaldo L. Almeida, Márcio B. Almeida"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_140.pdf",
      "name": "Application of a biosurfactant produced from vinasse to control the biocorrosion process",
      "authors": "Bianca Ferrazzo Naspolini, Diogo Azevedo Coutinho, Luiz André Lucas Teixeira Pinto Marcia Teresa Soares Luterbach, Magali Christe Cammarota, Walter Barreiro Cravo Júnior"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_141.pdf",
      "name": "Synergistic corrosion inhibition mechanism of Calcium lignosulfonate (CLS) and inorganic inhibitors in alkaline environment containing Cl-",
      "authors": "Bing Lin, Junlei Tang, Yingying Wang"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_145.pdf",
      "name": "Synthesis of titania nanocontainers with encapsulated dodecylamine as corrosion inhibitor to develop self-healing coatings",
      "authors": "Brunela Pereira da Silva, Idalina Vieira Aoki"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_146.pdf",
      "name": "Electrochemical Impedance Spectroscopy analysis of the expanded austenite phase produced by plasma nitriding",
      "authors": "Bruno B. Ramos, Francisco A. Vicente Sônia M. H. Probst, Aloísio N. Klein, Cristiano Binder"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_147.pdf",
      "name": "H2S partitioning in gas-water-oil systems",
      "authors": "André Luiz Castro Bonfim, Bruno Diehl Neto, Antônio C. S. M. Carvalho, Guilherme P. M. da Silva"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_150.pdf",
      "name": "High-temperature oxidation behavior of Fe-Mn-Si-Cr-Ni-(Ce) shape memory stainless steels",
      "authors": "Caique A. T. Alberto, Rodrigo da Silva, Carlos A. D. Rovere"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_153.pdf",
      "name": "Characterization of the corrosion behavior of sintered parts obtained by powder metallurgy with different manganese sulfide concentrations",
      "authors": "Camila B. Nascimento, Bruno S. Calabraro, Danilo F. Dos Anjos, Rafael Evangelista, Sabrina S. Pirielli, Marco A. Colosio, Jesualdo Luiz Rossi"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_154.pdf",
      "name": "Characterization of the corrosion behavior of sintered parts obtained by powder metallurgy with different manganese sulfide concentrations",
      "authors": "Camila B. Nascimento, Bruno S. Calabraro, Danilo F. Dos Anjos, Rafael Evangelista, Sabrina S. Pirielli, Marco A. Colosio, Jesualdo Luiz Rossi"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_155.pdf",
      "name": "Influence of hot-stamping process on the corrosion initiation and properties of corrosion products at the surface of 22MnB5 steel metallic coated with hot-dip Al-Si",
      "authors": "Camila Pucci Couto, Kitty Baert, Isolda Costa, Zehbour Panossian, Iris De Graeve, Reynier I. Revilla, Herman Terryn, Jesualdo L. Rossi"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_157.pdf",
      "name": "Evaluation of the impact of crude oil emulsion on pipeline corrosion",
      "authors": "C.A. Silva, D.R.N. Filho, M.H.A. Zanin, Z. Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_158.pdf",
      "name": "Corrosion rate prediction for mild steel pipelines in multiphase flow",
      "authors": "C.A. Silva, H.B. Pereira, M.E.S. Taqueda, Z. Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_160.pdf",
      "name": "Study of the effects of solution pH in the corrosion resistance of Endur 300 and UNS S41003alloys",
      "authors": "Carlos H. B. Queiroz, Walney S. Araújo, Davi A. Marques, Adolfo K. do N. Viana"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_161.pdf",
      "name": "Study of the corrosion resistance of Endur 300 and UNS S41003 alloys in different pH electrolytes",
      "authors": "Carlos H. B. Queiroz, Davi A. Marques, Adolfo K. do N. Viana, Walney S. Araújo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_163.pdf",
      "name": "Carbon steel corrosion controlled by imidazoline-based in medium containing chloride: effects O2 and CO2.",
      "authors": "Carlos V.R. P. Queiroz, Roberta B.Vasques, Gustavo L. Vaz, Álvaro A. O. Magalhães, Walney S. Araújo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_164.pdf",
      "name": "Evaluation of different galvanization methods",
      "authors": "Caroline C. Rodine, Larissa A. Carvalho, Victor Hugo C. A. Rego, Simone L. D. C. Brasil, Ladimir J. Carvalho"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_165.pdf",
      "name": "Corrosion behavior of NdFeB permanent magnet in two neutral environments",
      "authors": "Daniel S. Yoshikawa, Célia A. L. Santos, Ramon V. Martin, Neusvaldo L. de Almeida, Rubens N. Faria, Matheus A. Carvalho, Paulo A. Wendhausen, Fernando J. Landgraf"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_166.pdf",
      "name": "SCC occurrence in anhydrous ethanol samples from dedicated and multi-product pipelines",
      "authors": "Célia A. L. dos Santos, Neusvaldo L. de Almeida, Lorena C. de O. Tiroel, Elcio C. de Oliveira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_167.pdf",
      "name": "Niobium oxide thin layers characterized by Raman spectrocopy and SEM-FIB",
      "authors": "Célia A. L. dos Santos, Cátia Fredericci, Juliana P. Flor; Neusvaldo L. de Almeida; Hamilton da C. Carnaval, Gilberto de S. Freitas."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_168.pdf",
      "name": "The effect of high Fe concentrations on the microstructure and electrochemical behavior of as-cast modified Inconel 625®",
      "authors": "Lorenzo P. Souza; Julio C. Lourenço, Maria Ismênia S. T. Faria, Carlos A. Nunesd, Mark Baker, Célia R. Tomachuk"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_169.pdf",
      "name": "Influence of High Nickel Content in a Supermartensitic Stainless Steel on Pitting Corrosion Behavior in Standard and Welded Conditions",
      "authors": "César A. D. Rodrígues, Rafael M. Bandeira, Bárbara B. Duarte, Germano T. Filho, Alberto M. Jorge Jr"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_171.pdf",
      "name": "Ethanol Corrosivity Evaluation by Eletrochemical Impedance Spectroscopy (EIS) in Gasoline Direct Injection (GDI) Flex Fuel Engine Oil",
      "authors": "Charles L.B. Assunção, Erika C.A.N. Chrisman, Leila Y. Reznik"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_172.pdf",
      "name": "Evaluation of the behavior of the hot dip galvanizing coating in a cellulose plant",
      "authors": "Christian Sánchez Villa, Oscar Bustos Castillo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_174.pdf",
      "name": "Comparative corrosion resistance between stainless steels UNS S41008 and UNS S43000",
      "authors": "Cintia P. Amaro, Adolfo K. N. Viana, Lucio A. Bailo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_178.pdf",
      "name": "Importance of assessing how aggressive the environment is for installing transmission line towers",
      "authors": "Cristina da Costa Amorim, Bartolomeu Neves Cordeiro"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_180.pdf",
      "name": "Corrosion resistance evaluation of super hydrophobic coating on stainless steel AISI 304",
      "authors": "Daiana G. Sacilotto, Jane Zoppas Ferreira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_181.pdf",
      "name": "Improving Zinc-rich Epoxy primer performance with conductive polymer incorporation",
      "authors": "Danae Lopes Francisco, Marília Santos Menossi, Paloma Vieira dos Santos, Alessandra Nery Goncalves Motta, Kleber Lanigra Guimarães, Adriano Marim de Oliveira, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_183.pdf",
      "name": "Surface modification of titanium and cobalt-base alloys fabricated by selective laser melting for biomedical applications.",
      "authors": "Daniel de Castro Girão, Jorge Luiz Cardoso, Guilherme Arthur Longhitano, Luis Paulo Mourão Santos, Úrsula Cid Pereira, Miloslav Béreš, André Luiz Jardini, Carlos Salles Lambert, Hamilton Ferreira Gomes de Abreu"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_184.pdf",
      "name": "Integrity of reinforced concrete structures of old buildings located in the coastal region of Fortaleza city exposed to the chloride ion action",
      "authors": "Daniel de Castro Girão, Davi Valente Santos, Iêda Nadja Silva Montenegro, Francisco Belmino Romero"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_185.pdf",
      "name": "Atmospheric corrosion of weathering steel in the ICP Materials project",
      "authors": "Daniel de la Fuente, Jenifer Alcántara, Katerina Kreislova, Johan Tidblad"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_186.pdf",
      "name": "Inline real-time corrosion monitoring in steel mill flue gases for efficient waste heat recovery",
      "authors": "Daniel de la Fuente, Jenifer Alcántara, Pavel Ivashechkin"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_187.pdf",
      "name": "High temperature oxidation of iron aluminide coatings",
      "authors": "Daniel D.M.R. Ferreira, Frederico A.P. Fernandes"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_189.pdf",
      "name": "Mechanisms for Anaerobic Microbiological Influenced Corrosion by Non-Sulphide Producing Organisms.",
      "authors": "Daniel J. Blackwood"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_190.pdf",
      "name": "Anticorrosion and Antifouling performance of advanced polymer-graphene based nanocomposite coatings.",
      "authors": "Daniel J. Blackwood, W.X. Hou, Y. Gao, J. Wang"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_191.pdf",
      "name": "Microencapsulation of methylmethacrylate to reinforced concrete addition for achieving self-healing and anticorrosive properties.",
      "authors": "Valério, D., Aoki, I. V."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_192.pdf",
      "name": "Oxidation behavior of ultra-high-strength 13Ni15Co10Mo maraging steel",
      "authors": "Daniela Passarelo Moura da Fonseca, Nelson Batista de Lima, Angelo Fernando Padilha"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_194.pdf",
      "name": "Microbiological Corrosion in Fire Pipe Protection Systems (sprinkler) in the Presence of Sulfate Reducing Bacteria",
      "authors": "Danielle Samira F. Abdalla, Ana Carolina A. Laurindo, Dalva C. B. do Lago Lilian F. de Senna e Márcia M. Machado Gonçalves"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_195.pdf",
      "name": "Electrochemical behavior of UNS S41003 and Endur 300 in different concentrations of Brine",
      "authors": "Davi A. Marques, Carlos H. B. Queiroz, Adolfo K. do N. Vianao, Walney S. Araújo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_197.pdf",
      "name": "Synergistic effect of a combination of layered hydroxide salt and layered double hydroxide containing different corrosion inhibitors in an organic coating for active corrosion protection of carbon steel",
      "authors": "Débora A. Leal, João Tedim, Fernando Wypych, Cláudia E. B. Marino"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_198.pdf",
      "name": "Evaluation of Plastic Deformation Influence on High Strength Casing Steel Stress Corrosion Cracking",
      "authors": "Luciana Lima, Debora Molter, Bruno Diehl Neto, Ilson Palmieri, Ana Carolina Vilas Bôas, Marília Lima, Ravel Pinheiro, Dilson S. Santos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_200.pdf",
      "name": "Microstructure characterization and CO2 corrosion behavior analysis of a carbon steel used for tensile armors of flexible pipes",
      "authors": "Dimas J. Resende Jr., Sergio S. S. M. Tavares, Ilson P. Baptista, Adriana C. M. Barrios, Camila Finamore"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_201.pdf",
      "name": "Stress corrosion cracking analysis of a carbon steel used for tensile armors of flexible pipes in confined environment containing dissolved CO2",
      "authors": "Dimas J. Resende Jr., Sérgio S. S. M. Tavares, Ilson P. Baptista, Rodrigo V. Landim, Javier A. C. Velasco"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_202.pdf",
      "name": "Application of Bipolar Electrochemistry for Corrosion Research",
      "authors": "Dirk L Engelberg, Yiqi Zhou"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_206.pdf",
      "name": "Low environmental impact coatings - Polyaspartic",
      "authors": "Eder Dirceu D. Justina, Thiago E. Bastos, Diovana M. Ockner"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_208.pdf",
      "name": "A COMPARATIVE STUDY OF CARBON STEEL COMPATIBILITY WITH E27 AND E25 GASOLINE-ETANOL FUEL BLENDS UNDER STANDARDIZED TESTING CONDITIONS",
      "authors": "E.H.S. Cavalcanti, V.R.G. Santos, M.A. Abdel-Rehim,V. L. D. Resende, V. Mori"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_216.pdf",
      "name": "Guanine as an Environmentally Friendly Corrosion Inhibitor for Copper and X65 Steel Surfaces in HCl Solution – An Electrochemical and Raman Investigation",
      "authors": "Elaine F. Silva, Julio S. Wysard, Maria J. M. Andrade, Tatiana C. Almeidaa, Merlin C. E. Bandeiraa, Oscar R. Mattos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_218.pdf",
      "name": "Real-time potential monitoring of 304 stainless steel under cathodic protection.",
      "authors": "Elismar S. Elismar, Eudésio O. Eudésio, Harlan C. Harlan, Jerry A. Jerry, Moacy P. Moacy, Márcia S. Márcia"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_220.pdf",
      "name": "Structural Covering with Self-healing Cement",
      "authors": "Erica M. Magnago"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_221.pdf",
      "name": "Failure of 304 Austenitic Stainless-Steel Pipes",
      "authors": "Faraj Hammad Omer"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_222.pdf",
      "name": "Sanicro 35, a new PRE 52 alloy for corrosive environments",
      "authors": "Ivy Frazão, Felipe Beluche Lima, Robert Mattsson Öhnfeldt"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_226.pdf",
      "name": "Self-healing properties and anticorrosive performance of a shape-memory epoxy coating",
      "authors": "Fernando C. Melges, Brunela Pereira da Silva, Idalina V. Aoki"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_227.pdf",
      "name": "Corrosion & low-carbon energies",
      "authors": "Damien Férona"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_231.pdf",
      "name": "Corrosion of Nitrided and DLC-coated SAE 1020, using artificial sweat and 0.6 mol/l NaCl as Corrosive Media",
      "authors": "Francisco A. Vicente, Bruno B. Ramos, Sônia M. H. Probst, Aloísio N. Klein, Cristiano Binder"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_232.pdf",
      "name": "Evaluation of structure, heterogeneities, thickness and corrosion protection of electrodeposited sol-gel superhydrophobic coatings",
      "authors": "Gabriel B. Leoni, Denise S.de Freitas, José A. P. da C. Gomes, Simone L. D. C. Brasil"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_235.pdf",
      "name": "Influence of the salt on CO2 corrosion of Martensitic Carbon Steel in packer fluid",
      "authors": "Gabriela G.P.Souza, Rachel V. Rodrigues, Javier A.V.Carreno, Ilson. P. Baptista, Eduardo A. Ponzio"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_237.pdf",
      "name": "Metastable pitting characteristics of aluminium alloys investigated from the in-situ mapping of hydrogen evolution at metastable pit locations",
      "authors": "Gayathri Sridhara, Nick Birbilisa, V.S. Raja"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_238.pdf",
      "name": "Influence of pH and concentration on zirconium-based coating on T6-7075 aluminum alloy",
      "authors": "Germano C. Rosa, Jéssica Salles Pinheiro, Jane Zoppas Ferreira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_242.pdf",
      "name": "Influence of H2S Content on CO2 Corrosion Behavior of API 5L X80",
      "authors": "Gislaine M. B. Nunes, Paloma V. dos Santos, Tarcísio H. C. Pimentel, David R. das Neves Filho, Gabriel Soares Bassani, Neusvaldo Lira de Almeida"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_243.pdf",
      "name": "On the risk of hydrogen embrittlement of steels in a microbiological methanation plant",
      "authors": "Gregor Mori, Anton Trautmann, Andreas Keplinger, Markus Oberndorfer, Stefan Bauer"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_244.pdf",
      "name": "Corrosion Resistant Multi-Principal Element CrCoNi Alloys for Marine Applications",
      "authors": "Guilherme Y. Koga, Nick Birbilis, Guilherme Zepon, Claudio S. Kiminami, Walter J. Botta, Michael Kaufman, Amy Clarke, Francisco G. Coury"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_248.pdf",
      "name": "Comparison between weathering steels and hot-dip galvanized steels as alternatives to corrosion in construction sector",
      "authors": "Héctor A. Muñoz, Mariana T. Souza"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_249.pdf",
      "name": "Characterization of patinas on recent bronze sculptures in Zagreb city centre",
      "authors": "Helena Otmačić, Dajana Mikić, Angela Kapitanović"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_250.pdf",
      "name": "Influence of Welding Energy on Intergranular and Pitting Corrosion Susceptibility of UNS S32205 Duplex Stainless-Steel Joints",
      "authors": "Henrique Boschetti Pereira, Tarcisio Henrique Carvalhaes Pimentel, Carlos Alberto da Silva, Zehbour Panossian, Cesar Roberto Farias de Azevedo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_251.pdf",
      "name": "Evaluation of the efficiency of biocidal products for the Oil and Gas industry by using microscale cultivations",
      "authors": "Simon, H. M., Rodrigues, M. F. A., Silva, W. C.b, Rodrigues, A. A. P."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_252.pdf",
      "name": "Effect of copper addition on the passivation behavior of cast duplex stainless steel ASTM A890 1B and 3A in critical environment conditions",
      "authors": "Hillane M. L. F. de Lima, Sérgio S. M. Tavares, Matheus S. Rodrigues, Walney S. Araújo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_254.pdf",
      "name": "Hydrogen embrittlement behavior of high-entropy alloys",
      "authors": "H. Luo1, C.W. Du, X.G. Li1, D.Raabe"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_260.pdf",
      "name": "Carbon nanotubes in zinc rich weldable shop primer",
      "authors": "Alexandre A. Marinho, Douglas da S. Fortunato, Kioshy S. de Assis, Sebastião G. Alves, Isabel C. P. Margarit-Mattos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_262.pdf",
      "name": "Issues with thermal insulation painting",
      "authors": "Pedro R. P. Viana, Flávio V. V. de Souza, Thiago R. de Almeida, Victor H. D. M. Santos, Wang Liangzhuang, Oswaldo E. Barcia, Isabel C. P. Margarit-Mattos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_263.pdf",
      "name": "A homemade electrochemical hanging droplet cell to evaluate the corrosion resistance of friction stir weld zones of the AA2198-T8 Al-Cu-Li alloy",
      "authors": "Caruline de Souza Carvalho Machado, José Wilmar Calderón Hernández, Mariana Xavier Milagre, João Victor de Souza Araujo, Uyime Donatus, Isolda Costa"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_264.pdf",
      "name": "Influence of anodizing parameters on roughness of aluminum alloy 2024",
      "authors": "Israel L.M.Gonçalves, Priscila O. Gonçalves, Simone L. D. C. Brasil, Denise S. Freitas"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_266.pdf",
      "name": "Advantages in using remote monitoring to assess effectiveness of cathodic protection in stray current affected areas",
      "authors": "Ivano Magnifico"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_270.pdf",
      "name": "Corrosion behavior of API 5L X65 steel in CO2 saturated media in the absence and presence of contaminant H2SO4",
      "authors": "Janeth Marlene Quispe-Avilés, José Wilmar Calderón-Hernández, Caruline de Souza Carvalho Machado, Helio Goldenstein, Hercílio Gomes de Melo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_272.pdf",
      "name": "Effect of flash rust preventer applied after hydroblasting surface preparation on steel under epoxy paint schemes",
      "authors": "Jeferson L. Oliveira1, Bluma Guenther Soares, Ruan Roberto Henriques, Joaquim P. Quintela, Anders W. B. Skilbred, Andreas LØken"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_273.pdf",
      "name": "Effect of pulsed and spray multipass MAG welding on the corrosion resistance of 316L stainless steel.",
      "authors": "Jefferson Silva, Suellen Reis, Eloá Lopes Maia, Maysa Teradab, Aline Bugarin, Larissa Berbel, Rayanne Araujo Andrade, Luis Henrique Guilherme, Isolda Costa"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_277.pdf",
      "name": "Effect of Elastic Stress on Pitting Behavior of 304L Stainless Steel in NaCl Solution",
      "authors": "Jiadong Li, Junlei Tang, Bing Lin"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_278.pdf",
      "name": "Finite element simulation of pitting of 304L stainless steel under elastic tensile stress",
      "authors": "Jiadong Li, Junlei Tang, Bing Lin"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_281.pdf",
      "name": "Corrosion and Protection of Pipe in Carbon Capture and Storage Operation and Oil & Gas Industry",
      "authors": "Chong Sun, Jing Liu, Jing-Li Luo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_282.pdf",
      "name": "Lithium salts loaded in epoxy paint on galvanized steel to improve corrosion resistance",
      "authors": "Jivago Santana de Sá Reis, Brunela Pereira da Silva, Idalina Vieira Aoki"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_283.pdf",
      "name": "Avaliação da corrosão em dutos recém enterrados revestidos e com proteção catódica",
      "authors": "João P. K. Gervásio"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_286.pdf",
      "name": "Enhance of the corrosion resistance of Niobium by PEO coating",
      "authors": "David Quintero, Walney S. Araujo, Jorge A. Calderón"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_288.pdf",
      "name": "Study of the corrosion of non-noble metal-based catalysts during oxygen evolution reaction under on/off operation",
      "authors": "Santiago Cartagena, Jorge A. Calderón"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_289.pdf",
      "name": "Cases of atmospheric corrosion in some urban structures of the city of Maceió",
      "authors": "Jorge F. da S. Filho, Willidez M. da S. Lopes"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_290.pdf",
      "name": "Influence of the δ phase and the water vapor in the initial stage of the high temperature oxidation process on the VAT46® alloy.",
      "authors": "Jorge Meyrelles Jr, Fernando Rizzo, Mauricio de Jesus Monteiro, Alexandre Farina, Axel Kranzmann"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_291.pdf",
      "name": "Inspection and repair of corroded pipeline: a case study",
      "authors": "Jose J. M. Oliveira, Emmanuelle S. Freitas"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_292.pdf",
      "name": "Use of alternative backfill in the galvanic cathodic protection about anchor rods of guided transmission tower",
      "authors": "José Maurílio da Silva, Ramon Sigifredo Cortes Paredes, Rullian Ferreira Pinheiro, Márcio Tonetti, Marcelo Buras, Amanda Jarek, Betina Lepretti Medeiros, Juliane de Melo Rodrigues, Alexandre Tachibana dos Santos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_294.pdf",
      "name": "Study of cathode superficial pH during cathodic protection",
      "authors": "Jose Victor de O. Goudar, Denise Souza de Freitas, Simone Louise D. C. Brasil"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_296.pdf",
      "name": "Comparison of high temperature oxidation resistance of AISI 303, 304 and 310 steels",
      "authors": "Júlia N. Pereira, Igor M. R. Candido, Artur de S. Malafaia"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_297.pdf",
      "name": "High temperature cyclic oxidation resistance of AISI 303 steel",
      "authors": "Júlia N. Pereira, Igor M. R. Candido, Artur de S. Malafaia"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_298.pdf",
      "name": "Study of calcium carbonate scaling and corrosion on steel using a high salinity NaCl and CaCl2 brine",
      "authors": "Juliana F. de Angelo, Jean Vicente Ferrari"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_299.pdf",
      "name": "Corrosion control of reinforced concrete by acrylic resin injection",
      "authors": "Diogo Nicoletti, Juliana L. Cardoso"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_300.pdf",
      "name": "Interpretation of potential measurements of three embedded rebars in prismatic concrete test specimen",
      "authors": "Juliana L. Cardoso, Adriana de Araujo, José L. S. Ribeiro, Renata A. Brunelli, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_301.pdf",
      "name": "Is open circuit potential a suitable parameter to assess corrosion behaviour of galvanized rebars in marine environment?",
      "authors": "Rafael Ribeiro Urano Alves, Juliana Lopes Cardoso, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_302.pdf",
      "name": "Test methods for identification of preferential weld corrosion susceptibility on welded joints of subsea pipelines",
      "authors": "Juliana L. Cardoso, Zehbour Panossian, Bruno de Barros Andrade, Marcos Luiz Henrique, Petronio Zumpano Jr., Ilson P. Baptista"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_303.pdf",
      "name": "Corrosion behaviour of Dual-Coated (Zinc/Epoxy) Rebars in Pore-Simulated Solution with and without Chloride",
      "authors": "Mayara Stecanella Pacheco, Juliana Lopes Cardoso, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_306.pdf",
      "name": "Study of the potentiality of Ginkgo Biloba based extract as a corrosion inhibitor",
      "authors": "Karine D. de A. Freitas, Amanda A. G. Silva, Lídia B. O. Souza, Sérgio R. Barra"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_309.pdf",
      "name": "Corrosion Characteristics of Secondary Phases in WE43 Magnesium Alloys Under Various Exposure Conditions",
      "authors": "KX. Kuah, Sudesh L. Wijesinghe, Daniel J. Blackwood"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_311.pdf",
      "name": "Evaluation of corrosion control on carbon steel using amino phosphonic acids",
      "authors": "Larissa A. Carvalho, Victor Hugo Cardozo, Bruno Barbosa Castro, Ladimir Carvalho, Simone L. Delarue"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_312.pdf",
      "name": "Corrosion of dental implants made of Ti-6Al-4V connected to 316L SS in agressive environment",
      "authors": "Larissa O. Berbel, Bárbara V. G. de Viveiros, Ana Lígia Micelli, Frederico Nigro, Jesualdo L. Rossi, Isolda Costa"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_316.pdf",
      "name": "Morphological and structural study of anodized titanium grade 2, using HCl in aqueous solution",
      "authors": "A. Maytorena-Sánchez, R. Orozco-Cruz, J. Hernández-Torres, F. López-Huerta, L. Zamora-Peredo, M. Pacio-Castillo, L. E. Serrano-de la Rosa, L. García-González"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_317.pdf",
      "name": "The interaction of chloride and thiosulfate with Alloy 800 in aerated and deaerated conditions",
      "authors": "Domingues, L. S., de Melo, H. G."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_319.pdf",
      "name": "Atmospheric corrosion of a bronze sculpture: the case study of an equestrian monument",
      "authors": "Leila Es Sebar, Leonardo Iannucci, Marco Parvis, Sabrina Grassini, Emma Angelini"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_320.pdf",
      "name": "Bio-based epoxy coatings for corrosion protection",
      "authors": "Leonardo Iannucci, Camilla Noè, Samuel Malburet, Alain Graillot, Marco Sangermano, Sabrina Grassini"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_321.pdf",
      "name": "Influence of the use of additives on the corrosion resistance efficiency of zinc rich epoxy primers, based on the cathodic protection principles",
      "authors": "Leonardo M. Moraes"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_324.pdf",
      "name": "Stabilization of hybrid organic-inorganic metal halide electrodes for Li-ion battery",
      "authors": "Liliana T. Lopez, Daniel Ramírez, Franklin Jaramillo, Jorge Calderón"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_326.pdf",
      "name": "Evaluation of carboxymethyl cellulose as ecofriendly corrosion inhibitor for mild steel in neutral NaCl medium",
      "authors": "Guilherme K. Possani, Eduardo L. Schneider, Lisete C. Scienza"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_327.pdf",
      "name": "The effect of Zr-based conversion nanoceramic treatments on the electrochemical behavior of 2024 e 7475 aluminum alloys",
      "authors": "Giovana Santos Barbosa, Gabriel Adriane Garcia, Carina Carraro, Lisete Cristine Scienza"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_328.pdf",
      "name": "Influence of Cl- and SO42- ions on the electrochemical behaviour of 3105 and 5052 aluminum alloys",
      "authors": "Monica Scarabotto, Eliena Jonko Birriel, Lisete Cristine Scienza"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_329.pdf",
      "name": "Electrochemical property of the Pd20Pt20Cu20Ni20P20 metallic glasses in sulfuric acid",
      "authors": "Yangzheng Li, Junlei Tang, Jichao Chao"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_330.pdf",
      "name": "Evaluation of a novel Schiff Base as greener corrosion inhibitor for API P110 carbon steel in acidizing fluids",
      "authors": "Luana B. Furtado, Rafaela C. Nascimento, Janaína Rocha, Peter R. Seidl, José Antônio C. P. Gomes, Maria José O. C. Guimarães, Fábio J. F. S. Henrique"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_331.pdf",
      "name": "Environmental monitoring for corrosion assessment: the Colombian case studies",
      "authors": "Luca Lombardo, Sabrina Grassini, Marco Parvis, Carlos Arroyave Posada, Emma Angelini"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_332.pdf",
      "name": "Influence of Hard TiN Inclusions of Strained Supermartensitic Stainless Steel on the Nucleation of Microcracks and Pitting Corrosion",
      "authors": "Lucas B. Souto, Luís F. P. Dick"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_336.pdf",
      "name": "Evaluation of Cr, Mo, Ti and Cu on the pitting corrosion resistance of supermartensitic stainless steels at different temperatures",
      "authors": "Lucas N. S. Prachedes, Caio V. L. Sabará, Luan C. Santos, Rhuan C. Souza, Lecino Caldeira, Gustavo L. Vaz, Jefferson R. de Oliveira, José A. C. P. Gomes, Alysson H. S. Bueno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_338.pdf",
      "name": "Corrosion of MnS inclusions of carbon steels by amines: A case of polarity reversal of the galvanic pair",
      "authors": "Luís F. P. Dick, Natalia F. Lopes, Rafaela B. Marques"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_339.pdf",
      "name": "On-site Passivation Assessment of Type 316L Aseptic Storage Tanks with Electrochemical Techniques",
      "authors": "Luis Henrique Guilherme, Assis Vicente Benedetti, Cecílio Sadao Fugivara, Dirk Engelberg"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_343.pdf",
      "name": "CORROSION AND MECHANICAL DESTRUCTION OF PIPELINE STEELS UNDER THE INFLUENSE OF INCREASED TEMPERATURE AND CARBON DIOXIDE PRESSURE",
      "authors": "Yu. Maksishkoa, M. Khoma, V. Vynar, B. Datsko, V. Ivashkiv, M. Chuchmana, S. Korniy"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_345.pdf",
      "name": "External Cathodic Protection System of a Tank Composed by Deep Anode Beds",
      "authors": "Marcelo A. Lopes, João Hipolito L. O., Irwin G. Alves"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_346.pdf",
      "name": "Evaluation of mate leaf extract as corrosion inhibitor for SAE 1020 carbon steel in HCl",
      "authors": "Marcelo G. de Lima, Evelyn de O. Brazil, Fernando de O. Ferreira, Lumena C. Gomes, Nicolle A. Weiner, Maria de Lourdes M. Magalhães, Cícero V Abreu"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_347.pdf",
      "name": "Control and mitigation of mic by silver species hosted in microporous materials",
      "authors": "Andrea M. Pereyra, Mario S. Moreno, Marcia T. Soares Lutterbach, Elena I. Basaldella"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_348.pdf",
      "name": "Rouge Development in 316L Stainless Steel Used in Pharmaceutical Water Purification Systems, Impacts on Pharmaceutical Industries",
      "authors": "Marcos A. Lopes, Luiz R. M. de Miranda, Jose Ladimir J. de C. and Renato Portugal."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_350.pdf",
      "name": "Electrochemical characterization of anodized TiNi and Ti surfaces",
      "authors": "Marcos Vinícius Fonseca Ferreira, Pedro Damas Resende, Vicente Tadeu Lopes Buono, Dalila Chaves Sicupira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_351.pdf",
      "name": "Effect of high temperature and acid environment on epoxy-Nb2O5 anticorrosive resistance",
      "authors": "Maria E. D. Serenario, Bernardo A. F. Santos, Luiz R. M. de Miranda, Alysson H. S. Bueno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_355.pdf",
      "name": "Study of two different aluminum alloys for subsea longterm application",
      "authors": "Mariana G. M. Carvalho, Gustavo Brandolin, Luanna de C. Souza, Flávio V. V. de Sousa, Isabel C. P. Margarit-Mattos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_357.pdf",
      "name": "Development of PMMA-CeO2 anticorrosive hybrid coatings with self-healing property",
      "authors": "Mariana S. Rodrigues, Thiago A. C. Souza, Samarah V. Harb, Andressa Trentin, Peter Hammer"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_358.pdf",
      "name": "Corrosion behavior in galvanized Wind Fence structures",
      "authors": "Mariana T. Souza, Hector A. Muñoz, Ariane Gaspari O. Souza"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_361.pdf",
      "name": "Incorporation of alternative conductive particles in zinc-rich paints and its influence on mechanical properties and corrosion protection",
      "authors": "Marília Santos Menossi Mortari, Danae Lopes Francisco, Paloma Vieira dos Santos, Alessandra Nery Goncalves Motta, Kleber LanigraGuimarães, Adriano Marim de Oliveira, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_362.pdf",
      "name": "Reuse of espresso spent coffee ground waste as a green corrosion inhibitor",
      "authors": "Marina A. J. L. da Costa, Jefferson S. de Gois, Isabela M. Toaldo, Ana Clara Favilla Bauerfeldt, Diego B. Batista, Marilde T. Bordignon-Luiz, Dalva C. B. do Lago, Aderval S. Luna, Lilian F.de Senna"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_363.pdf",
      "name": "Evaluation of graphene’s influence over an anticorrosive polymer coating applied on carbon steel",
      "authors": "Mario N.Barbosa, Jeferson L Oliveira, Elisa J. Kassab, João Arthur F. L. Batalha, I. S. Bott"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_364.pdf",
      "name": "Anti-corrosion properties of an epoxy coating nano filled with reduced graphene oxide",
      "authors": "Mario N. Barbosa, Elisa Kassab, Mariana N. Silva, Jeferson L. Oliveira, Joaquim Quintela, I. S.Bott"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_365.pdf",
      "name": "A smart protocol to protect AA2024 against corrosion",
      "authors": "M. Páez, L. Muñoz, M. Sancy, L. Tamayo, M. Azocar, M. Urzua"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_366.pdf",
      "name": "Importance of the enzymatic activity present in microbiologically influenced corrosion (MIC) assisted by Pseudomona aureginosa in alloys of Aluminum-2024 and Aluminum-6063.",
      "authors": "Javier Espinoza-Vergara, Maritza Páez, Miguel Gulppi, Mariana Walter, Manuel Azocar."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_367.pdf",
      "name": "The influence of temperature on the performance of amine-based inhibitor in 3.5wt.% NaCl solution saturated with CO2",
      "authors": "Marjory Moreira Levy, Roberta B.Vasques, Gustavo L. Vaz, Álvaro A. O. Magalhães, Walney S. Araújo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_369.pdf",
      "name": "Corrosion prediction of steel in atmospheric environment by applying data science",
      "authors": "Masataka Omoda, Kazuhiro Nakatsuji, Daisuke Mizuno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_370.pdf",
      "name": "Literature review of low-chromium steels as reinforcement for cementitious matrices cured by carbonation",
      "authors": "Matheus H. A. Santiago, Almir Salles, Guilherme Y. Koga"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_371.pdf",
      "name": "Diffusion and trapping of hydrogen due to elastic interaction with η-Ni3Ti precipitates in Custom 465® stainless steel",
      "authors": "Mati Shmulevitsh, Sigalit Ifergane, Noam Eliaz, and Roni Z. Shneck"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_372.pdf",
      "name": "Technical and economic feasibility to use alloyed steel bars and hot-dip zinc coated bars in reinforced concrete structures with carbonation-induced corrosion",
      "authors": "Martins, Mauricio S., Panossian, Zehbour"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_374.pdf",
      "name": "Corrosion barrier properties of PMMA-silica coatings for protection of reinforcing steel",
      "authors": "Mayara C. Uvida, Andressa Trentin, Samarah V. Harb, Celso V. Santilli, Peter Hammer"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_376.pdf",
      "name": "Flexible pipes: the annular confinement effect on H2S embrittlement of armor wires",
      "authors": "Merlin Bandeira, Thiago Gutierrez, Rogaciano Moreira, Fabrício Santos and Oscar Mattos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_377.pdf",
      "name": "Indirect detection of corrosion by quantifying the Fe(II)-TEA complex",
      "authors": "Michael D. P. de Souza, Simone L. D. C. Brasil, Rodrigo S. Melo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_378.pdf",
      "name": "Development of Electrical Resistance Sensors for Corrosion Monitoring in extreme environments",
      "authors": "Miha Hren, Viljem Kuhar, Branko Belingar, Andraž Legat, Tadeja Kosec, Elina Huttunen-Saarivirta"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_382.pdf",
      "name": "The Advantage of Heat Resistant Composites",
      "authors": "Miles Buckhurst, Dr. Svein Jakob Kaspersen"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_385.pdf",
      "name": "Evaluation of composite flexible pipes’ barrier behavior with electrochemical impedance for offshore application",
      "authors": "Murilo B. Valerio, Larissa E. G. Dos Santos, Ana L. N. Da Silva, Leila Y. Reznik, Ladimir J. Carvalho"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_387.pdf",
      "name": "Analysis and elaboration of anti-corrosion coatings processes of external piping in corrosive atmospheres",
      "authors": "Elizabeth Pontes Magalhães Mendonça, Mylena de Fátima Rangel Borges, Rebeca Borges de Souza"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_388.pdf",
      "name": "Corrosion resistance of lean duplex stainless steel UNS S2304 welded by submerged arc welding with cold wire addition",
      "authors": "Neice F. Santos, Patrícia C. Azzi, Wagner R. C. Campos, Paulo J. Modenesi, Brunela P. Silva, Idalina V. Aoki, Ronaldo C. Júnior, Luiza Esteves"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_390.pdf",
      "name": "Potentiostats and electrodes: use, compounds, disadvantages and function for Electrochemical Corrosion Research",
      "authors": "N. E. de Souza"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_393.pdf",
      "name": "Effect of the hydrolysis time on the corrosion resistance of AA2024-T3 anodized in TSA and sealed with hybrid coatings",
      "authors": "Oscar M. P. Ramirez, Marie-Georges Olivier, Hercilio G. de Melo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_394.pdf",
      "name": "Investigation of a Ce post-treatment step on the corrosion behavior of AA2024-T3 anodized in TSA",
      "authors": "Oscar M. P. Ramirez, Marie-Georges Olivier, Hercilio G. de Melo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_395.pdf",
      "name": "Development of Novel Epoxy Closed-Cell Foam for Thermal Insulation & Corrosion Protection",
      "authors": "Osmay Oharriz, Diego Hita"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_396.pdf",
      "name": "Using Digital Twin and AI to predict atmospheric corrosion - A smart tool for decision makers",
      "authors": "Otavio Correa, Fulvio Silva, Jorge Luiz S. Mariano"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_397.pdf",
      "name": "Development of Coal tar free anticorrosive epoxy coatings from lignin",
      "authors": "Otilio B. F. Diógenes, Davi R. de Oliveira, Lucas R. R. da Silva, Ítalo G. Pereira, Diego Lomonaco, Walney S. Araujo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_398.pdf",
      "name": "In-situ determination of the degree of sensitization in austenitic stainless steels",
      "authors": "Pablo Altamirano, Mariano Kappes, Martín Rodríguez"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_399.pdf",
      "name": "Improving zinc-rich epoxy primer performance with lamellar zinc incorporation",
      "authors": "Paloma Vieira dos Santos, Alessandra Nery Goncalves Motta, Marília Santos Menossi, Danae Lopes Francisco, Neusvaldo Lira de Almeida, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_402.pdf",
      "name": "Study of a quaternary imidazoline-based corrosion inhibitor for oil and gas industry under high temperature and pressure conditions",
      "authors": "Paulo E. Silva Jr., Idalina V. Aoki"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_406.pdf",
      "name": "Detection and enumeration of diverse sulfate-reducing and thiosulfate reducing microbial species using thin-film culture device technology",
      "authors": "Evan Brutinel, Benjamin Wilson, Sergio Filho, Peter Gorman"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_408.pdf",
      "name": "Corrosion Protection of fixed bed offshore wind power plants in North and Baltic Sea",
      "authors": "Peter Plagemann, Oliver Kranz"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_411.pdf",
      "name": "A Cerium-based nanocoating for corrosion protection of Clad on AA 2024-T3 Alloy",
      "authors": "Rafael E. Klumpp, Caruline de S. C. Machado, João V. de S. Araujo, Renato Altobelli Antunes, Marina Magnani, Isolda Costa"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_414.pdf",
      "name": "Effect of process cleanliness on the corrosion reliability of electronics",
      "authors": "Rajan Ambat, Kamila Piotrowska"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_417.pdf",
      "name": "Green Energy- Transport of current from off-shore to onshore",
      "authors": "Ralf Feser, Julian Oltze, Volker Waschk"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_420.pdf",
      "name": "Electrochemical Behavior of Weathering Steel Submitted to Simulated Industrial Environment",
      "authors": "Raphael F. Assumpção, Amanda P. Silva, Rogério A. Carneiro, Vanessa F. C. Lins, Dalila C. Sicupira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_421.pdf",
      "name": "Effect of aging time on intergranular corrosion of duplex stainless steel UNS S32205",
      "authors": "Raphael F. Assumpção, Ricardo A. Júnior, Dagoberto B. Santos, Dalila C. Sicupira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_422.pdf",
      "name": "High temperature materials for light water reactors accident tolerant fuels",
      "authors": "Raul B. Rebak"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_424.pdf",
      "name": "The influence of rotating cage geometry and baffles on the wall shear stress in sweet/sour corrosion environments",
      "authors": "Rhuan C. Souza, Bernardo Coelho, Jefferson R. Oliveira, Gustavo L. Vaz, André L. C. Bonfim, José Antônio C. P. Gomes, Guillermo Vilalta-Alonso, Alysson H. S. Bueno"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_425.pdf",
      "name": "Contributions of scanning electrochemical microscopy to the characterization of metal-inhibitor and metal-coating systems for corrosion protection",
      "authors": "Ricardo M. Souto, Javier Izquierdo, Juan J. Santana"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_426.pdf",
      "name": "Innovation of conservation treatment in metallic cultural heritage: SiO2-rust converter hybrid film",
      "authors": "D. E. Arceo-Gómez, R. Galván-Martínez, J. Reyes-Trujeque, R. Orozco-Cruz"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_427.pdf",
      "name": "Electrochemical analysis of the protective capability of a SiO2 nanolayer over a copper sulphate patina as an alternative of conservation of historical metallic heritage",
      "authors": "Tania de J. Jiméneza,b, R. Galván-Martíneza, Gerardo Fajardoc, R. Orozco-Cruz"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_430.pdf",
      "name": "Evaluation of Anticorrosive Coatings by Nonlinear Electrochemical Impedance Spectroscopy",
      "authors": "Patrik M. Assunção, Rodrigo S. Melo, Ladimir J. de Carvalho, Simone L. D. C. Brasil"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_432.pdf",
      "name": "Alternative dynamic test method to evaluate the susceptibility to Sulfide Stress Corrosion Cracking in High Strength OCTG Steels",
      "authors": "Rodrigo Landim, Javier Alejandro, Luciana Lima, Sergio Souto, Ricardo Nolasco"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_433.pdf",
      "name": "Evaluation of Flow Accelerated Corrosion in Typical Recovery Boiler Environments of Energy Production Industries",
      "authors": "Rogaciano M. Moreira, Tatiana C. Almeida, Merlin C. E. Bandeira, Felipe R. S. Assunção, Juliana F. A. Carvalho, João V. T. Verbicario, Oscar R. Mattos, Jefferson R. Oliveira, André L. C. Bonfim, André R. Novgorodecev"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_437.pdf",
      "name": "Accelerated Corrosion tests of epoxy-based coatings containing zinc tannates",
      "authors": "Antonella Hadzich, Manuel Gonzales, Santiago Flores"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_439.pdf",
      "name": "Inhibition of 1018 carbon steel corrosion in aerated and CO2-saturated salin solution by using Histidine",
      "authors": "MAGALHÃES, SAULO J.M., MOURA, MILENA J.S., ARAÚJO, WALNEY S."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_442.pdf",
      "name": "Corrosion failure analysis of a superduplex stainless steel flange",
      "authors": "Arthur de P. Kölblinger, Joaquim S. Corte, André R. Pimenta, Sérgio S.M. Tavares"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_443.pdf",
      "name": "Intergranular corrosion of surface cemented layer of supermartensitic stainless steel used for mandrels for chemical products injection in the wheel",
      "authors": "H.L.A. Keide, P. Soucassoux, S.S.M. Tavares, J.A.C. Velasco, J.M. Pardal, I. Palmieri Baptista"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_444.pdf",
      "name": "Microstructure and preferential pitting corrosion of a 17%Cr mutli-phase stainlesss steel for oil country tubular goods (OCTG)",
      "authors": "I. Palmieri Baptista, A. F. Lázaro, S.S.M. Tavares, J.A. Velasco"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_445.pdf",
      "name": "Effect of 3.5 wt.% NaCl and cathodic charging on the deformation behavior of interruptedly aged AA 7050 alloy",
      "authors": "Shweta Shukla, Rahul Agrawal, B. Nagmani Jaya, V S Raja"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_446.pdf",
      "name": "Corrosion in carbon steel and galvanized carbon steel pipes used to transport drinking and industrial water",
      "authors": "Sidney Oswaldo Pagotto Júnior, Neusvaldo L. de Almeida"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_447.pdf",
      "name": "Development of new qualification standards for painting schemes for Sabesp, a state water and waste management company",
      "authors": "Sidney Oswaldo Pagotto Júnior, Neusvaldo Lira de Almeida, Allan Saddi Arnesen, Marco Aurélio Lima Barbosa"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_451.pdf",
      "name": "Growing Role of Education within Corrosion Management in Industry",
      "authors": "Agatha Swierczynski"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_453.pdf",
      "name": "A novel electronic bridge for electrochemical measurements for high pressure electrochemical measures",
      "authors": "Pimentel, T.H.C, Silva, C.A., Nevesc, D.R., Panossian, Z."
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_457.pdf",
      "name": "Effect of oxygen content in back purging gas on localized corrosion resistance of Nickel-Alloy Cladding Welded Joints",
      "authors": "Tatiana C. Almeida, Rogaciano M. Moreira, Susana Díaz, Elaine F. Silva, Drielly Souza, Rafaela A. Posse, Merlin C.E. Bandeira, Marcelo F. Motta, Hélio C. Miranda, Flávia M.F.Guedes, Ilson P. Baptista, Aline L.S. Gomes, Ricardo R. Marinho, Petrônio Z. Junior, Oscar R. Mattos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_461.pdf",
      "name": "Austenitic-Stainless-Steel behavior on pitting corrosion resistance due to different levels of strain hardening",
      "authors": "Tiago S. Lima, Willian S. Labiapari, Lucio Bailo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_464.pdf",
      "name": "Corrosion inhibition of carbon steel by palm oil fiber powder in carbonated concrete pore solution in presence of chloride ions",
      "authors": "Davi Ribeiro Silva, Luís Gustavo Costa Nimo Santos, Vera Rosa Capelossi, Vanessa de Freitas Cunha Lins"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_465.pdf",
      "name": "Corrosion inhibition of carbon steel by palm oil fiber powder in carbonated concrete pore solution in presence of chloride ions",
      "authors": "Davi Ribeiro Silva, Luís Gustavo Costa Nimo Santos, Vera Rosa Capelossi, Vanessa de Freitas Cunha Lins"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_466.pdf",
      "name": "Characterization of Interference Nanofilms Grown on Austenitic and Ferritic Stainless Steel Surface by Alternate Pulse Current",
      "authors": "Jaqueline L. da Silva, Geovane M. Castro, Vanessa de F. C. Lins, Rosa M. R. Junqueira"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_467.pdf",
      "name": "Corrosion testing of Steel 9% Ni for cryogenic applications in 3.5% NaCl",
      "authors": "P. Titas, M. Delagrammatikas, O. Papadopoulou and P. Vassiliou"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_468.pdf",
      "name": "Water-based polyaniline/graphene-epoxy anti-corrosion coating applied in carbon steel",
      "authors": "Verônica A. Veloso, Elisângela Silva, Jéssica P. Del'Boccio, Juliana de F. da S. Xavier, Clascídia A. Furtado, Adelina P. Santos"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_469.pdf",
      "name": "Experimental design for improvement of electrodeposited silane/zinc coating",
      "authors": "Victor Hugo C. A. Rego, Gabriel B. Leoni, Simone L. D. C. Brasil"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_470.pdf",
      "name": "Scaling control on stainless steel using an amino phosphonic acid",
      "authors": "Victor Hugo C. A. Rego, Larissa A. Carvalho, Bruno B. Castro, Ladimir J. Carvalho, Simone L. D.C. Brasil"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_471.pdf",
      "name": "Corrosion time initiation modelling in nonhomogeneous concrete structures using the transient Boundary Element approach",
      "authors": "Vinícius de B. Souza, Edson D. Leonel"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_472.pdf",
      "name": "Parametric study of the corrosion-erosion kinetics of Zr in the presence of a two-phase jet",
      "authors": "Vinicius Soares Teixeira, Benoit Gwinner, Tojonirina Randriamanantena, Sophie Charton, Eric Schaer"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_473.pdf",
      "name": "Resistance to pitting in different chloride concentrations of AISI 316L steels modified with niobium additions",
      "authors": "Vitor S. Rolin, Carlos A. Picone, Juno Gallego, Sergio A. Spinola Machado, Germano Tremiliosi Filho Luiz C. Casteletti"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_474.pdf",
      "name": "Comparative study of pitting resistance between different austenitic, ferritic and duplex stainless steels",
      "authors": "Vitor S. Rolin, Carlos A. Picone, Juno Gallego, Sergio A. Spinola Machado, Germano Tremiliosi Filho Luiz C. Casteletti"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_475.pdf",
      "name": "Corrosion and pitting resistance of aisi 316l stainless steel modified with niobium",
      "authors": "Vitor S. Rolin, Carlos A. Picone, Juno Gallego, Douglas Silva, Sergio A. Spinola Machado, Germano Tremiliosi Filho, Luiz C. Casteletti"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_476.pdf",
      "name": "Voltammetry of Microparticles (VMP) in the analysis of metals and alloys",
      "authors": "Vitoria H. F. de Menezes, Hercílio G. de Melo"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_477.pdf",
      "name": "Anticorrosive properties of centrifugation residue from the grape juice industry as green corrosion inhibitors for carbon steel in 0.1 mol/L Na2SO4 medium",
      "authors": "Vitória Marujo, Dalva C. B. do Lago, Lilian F.de Senna, Mariana M. Marquesa"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_481.pdf",
      "name": "Influence of heat treatment time at 675 °C in localized corrosion of AISI 304 and 304 L stainless steels",
      "authors": "Warlen Alves Monfardini, Beatriz Garcia Bossatto, Henrique Corteletti Bolonha, Davi Pereira Garcia, Leandro Bitti Santa Anna"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_482.pdf",
      "name": "The Laboratory Simulation and Electrochemical Monitor Techniques for Corrosion Under Insulation (CUI)",
      "authors": "Wei J. Li, Cheng Y. Tsai"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_483.pdf",
      "name": "Real-time corrosion prediction of carbon steel in diluted and concentrated ammonium chloride solutions",
      "authors": "Tom W.M. Bos, Priyanka Agrawal, Yogesh Nakhate, Joy Phophichitra, Abitha Ramesh, G. Leendert Bezemer"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_488.pdf",
      "name": "Photocathodic Protection of MOFs Modified TiO2 Films on 304 Stainless Steel",
      "authors": "Lifeng Chi, Xiutong Wang, Siyao Guo, Youbo Nan, Hui Xu, Barong Hou"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_492.pdf",
      "name": "In-vivo investigation of electrochemical reactions of Ti in human synovial fluids",
      "authors": "Yueyue Bao, Anna Igual Muñoz, Claes-Olof A. Olsson, Brigitte Jolles, Stefano Mischler"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_496.pdf",
      "name": "Application of Transpassive Dissolution to Quantify Deleterious Phases in Super Duplex Stainless Steel",
      "authors": "Marcelo T. G. de Sampaio, Anderson B. Furtado, Sergio S. M. Tavares, Juan M. Pardal, Marcelo C. S. de Macêdo, Javier A. C. Velasco, André S. Araujo and Eduardo A. Ponzio"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_497.pdf",
      "name": "Phyllogorgia dilatata: a novel natural corrosion inhibitor for mild steel in acid medium",
      "authors": "Caio M. Fernandes, Thayssa da S. F. Fagundes, Nazir E. dos Santos, Alessandra L. Valverde, Javier A. C. Velasco, Eduardo A. Ponzio"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_498.pdf",
      "name": "Corrosion and corrosion protection properties of binary Fe-Al alloys-intermetallics",
      "authors": "Burcu Eroglu, Erkan Kacar, Mustafa Ürgen"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_499.pdf",
      "name": "Study of the complexing agents effect in the copper non-cyanide baths",
      "authors": "Fabián Andree Cerda Pastrián, Isabelle Mayara Fraga Fontes, Caroline Ferreira da Silva, Celia Aparecida Lino dos Santos, Neusvaldo Lira de Almeida, Zehbour Panossian"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_500.pdf",
      "name": "Chemical Cleaning of pre-operational Boilers (new), and operational Boilers",
      "authors": "Asdrubal Alvim, Lucas Neder"
    }, {
      "url": "https://intercorr.com.br/anais/2021/ICC_INTERCORR_WCO_2021_501.pdf",
      "name": "Evaluation of an Organic Green Corrosion Inhibitor in Synthetic Concrete Pore Solution to Protect Steel Rebars",
      "authors": "Loreto J. Pamatmat Dacio, E.I.T., Leonardo X. Alvarez, Ph.D., Oladis Troconis de Rincon, Ph.D., Homero Castaneda-Lopez, Ph.D., and Brendy Rincon Troconis, Ph.D."
    }
  ],
  "message": `<p class="mt-4">Todos os trabalhos técnicos que serão apresentados após a realização do Intercorr 2020, no período de  10 a 14 de maio, serão disponibilizados nesta página.</p>
  <p>Site do evento: <a href="https://abraco.org.br/icc-congress2021/">https://abraco.org.br/icc-congress2021/</a></p>

<h3>Confira a programação preliminar</h3>
<p>
<a href="https://icc-congress2020.com.br/src/uploads/2020/03/preliminary_program_11may-1.pdf" target="_blank"
class="btn btn-sm btn-primary">11 de maio</a>
<a href="https://icc-congress2020.com.br/src/uploads/2020/03/preliminary_program_12may-1.pdf" target="_blank" class="btn btn-sm btn-primary">12 de maio</a>
<a href="https://icc-congress2020.com.br/src/uploads/2020/03/preliminary_program_13may-1.pdf" target="_blank" class="btn btn-sm btn-primary">13 de maio</a>
<a href="https://icc-congress2020.com.br/src/uploads/2020/03/preliminary_program_14may-1.pdf" target="_blank" class="btn btn-sm btn-primary">14 de maio</a>
</p>`
}

const messages = {
    pt: {
        translations: {

            home: {
              header: "<p>O INTERCORR é o maior evento internacional de corrosão realizado no Brasil que reúne a comunidade técnica e científica de universidades, institutos de pesquisas, empresas e profissionais da área de corrosão, proporcionando um maior intercâmbio de conhecimentos e experiências. Com o objetivo principal de incentivar o intercâmbio de informações e divulgar conhecimentos recentemente adquiridos com os estudos da corrosão e suas mais variadas formas de controle e prevenção, com um público que reúne: especialistas, gerentes, consultores, engenheiros, técnicos, pesquisadores e acadêmicos envolvidos em diversos setores ligados, direta ou indiretamente, ao combate da corrosão e suas medidas preventivas.</p><p>O INTERCORR vem se destacando e tornando-se uma referência para o desenvolvimento do setor industrial, sendo um excelente cenário para empresas de diversos segmentos apresentarem suas tecnologias, divulgarem sua marca e darem visibilidade aos seus negócios, ampliando relacionamento e conhecimento.</p><p>O conteúdo dos artigos aqui publicados é de inteira responsabilidade dos seus autores.</p>",
              subtitulo: "O conteúdo dos artigos aqui publicados são de inteira responsabilidade dos seus autores.",
              tituloEdicao: "Websites oficiais das edições",
              edicao: "Edição"
            },

            edicoes: {
                titulo: 'Edições',
                tituloSingular: "Edição",
                periodo: "Periodicidade do evento:",
                resPeriodo: "a cada dois anos",
                area: "Área de conhecimento:",
                resArea: "Corrosão",
                edicao: "Edição",
                trabalhos: "trabalhos",
                fotos: "Fotos",
                anais: "Anais",
                datas: {
                    2008: "12 a 16 de maio",
                    2010: "24 a 28 de maio",
                    2012: "14 a 18 de maio",
                    2014: "19 a 23 de maio",
                    2016: "16 a 20 de maio",
                    2018: "14 a 18 de maio",
                    2021: "20 a 23 de julho"
                }

            },


            comissao: {
                titulo: "Comissão Científica",
                comiteExecutivo: "Comitê Executivo",
                comiteOrganizador: "Comitê Organizador Local",
                comiteTecnico: "Comitê Técnico-cientifico",
                membros: "Membros",
                instituicao: "Instituição",
                pais: "País",
                presidente: "Presidente"

            },

            editorial: {
                titulo: "Equipe Editorial",
                coordenacao: "Coordenação Editorial",
                organizacao: "Organização e Editoração Eletrônica"

            },

            expediente: {
                titulo: "Expediente",
                comissaoOrganizadora: "Comissão Organizadora",
                int: "Institulo Nacional de Tecnologia",
                ipt: "Instituto de Pesquisas Tecnológicas",
                presidente: "presidente",
                periodicidade: "Periodicidade da publicação",
                resPeriodicidade: "Evento bienal",
                idioma: "Idioma(s) que serão aceitos os artigos",
                inglesEportugues: "Português e Inglês",
                dadosEditora: "Dados da Editora",
                nome: "Nome",
                endereco: "Endereço",
                emailEtelefone: "E-mail e Telefone",
                siteDaEditora: "Site da Editora"
            }

        }
    }
}

export {messages}

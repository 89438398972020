import React from 'react';

const Anais = ({anais}) => {
  return (
    <article className="anais">
      <p className="title"><a href={anais.url}>{anais.name}</a></p>
      <p className="authors">{anais.authors}</p>
    </article>
  );
};

export default Anais;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from "react-bootstrap";
import '../theme/Comissao.scss';
import { i18n } from '../translations/i18n';

const Comissao = () => {
  return (
    <div data-comp-comissao>
      <div className="pre-title" />
      <h1>{i18n.t("comissao.titulo")}</h1>
{/*
      <code>
        2020: https://icc-congress2020.com.br/comites/ <br />
        2018: http://www.abraco.org.br/intercorr2018/comites.php <br />
        2016: http://www.abraco.org.br/intercorr2016/comite.php <br />
        2014: http://www.abraco.org.br/intercorr2014/comite.php <br />
        2012: http://www.abraco.org.br/intercorr2012/comites.php <br />
        2010: http://www.abraco.org.br/intercorr2010/comites.php          <br />
        2008: http://www.abraco.org.br/intercorr2008/comites.htm          <br />
      </code> */}
      {/* Implementar Accordion daqui */}
      <h2><span>2021</span></h2>
      <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}: </span> Prof. Dra. Zehbour Panossian</td>
            <td>ABRACO/IPT</td>
            <td>BRASIL</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold ">1º Vice-{i18n.t('comissao.presidente')}:</span> Prof. Dr. Günter Schmitt</td>
            <td>ICC</td>
            <td>ALEMANHA</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold">2º Vice-{i18n.t('comissao.presidente')}:</span> Prof. Emma Angelini</td>
            <td>ICC</td>
            <td>ITÁLIA</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold">3º Vice-{i18n.t('comissao.presidente')}:</span> Dra. Olga Baptista Ferraz</td>
            <td>ABRACO/INT</td>
            <td>BRASIL</td>
          </tr>
        </tbody>
      </Table>
      <h3>{i18n.t('comissao.comiteOrganizador')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th></th>
            <th>{i18n.t('comissao.instituicao')}</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Adauto Riva</td><td></td><td>Tintas Renner</td></tr>
          <tr><td>Carlos Alexandre Martins da Silva</td><td></td><td>TRANSPETRO</td></tr>
          <tr><td>Carlos Roberto Patrício </td><td></td><td>B. Bosch Galvanização do Brasil</td></tr>
          <tr><td>Danilo Natalio Sanches  </td><td></td><td>Zincoligas Indústria e Comércio Ltda</td></tr>
          <tr><td>Denise Souza de Freitas </td><td></td><td>Instituto Nacional de Tecnologia</td></tr>
          <tr><td>Diego Gonzalo Hita  </td><td></td><td>Hita Comércio e Serviços Ltda</td></tr>
          <tr><td>Hercílio Gomes de Melo  </td><td></td><td>Universidade de São Paulo</td></tr>
          <tr><td>Idalina Vieira Aoki </td><td></td><td>Universidade de São Paulo</td></tr>
          <tr><td>Isabel Cristina P. Margarit-Mattos  </td><td></td><td>Universidade Federal do Rio de Janeiro</td></tr>
          <tr><td>Isolda Costa</td><td></td><td>Instituto de Pesquisas Energéticas e Nucleares</td></tr>
          <tr><td>Javier Alejandro Carreno Velasco</td><td></td><td>Instituto Nacional de Tecnologia</td></tr>
          <tr><td>Jesualdo Luiz Rossi </td><td></td><td>Instituto de Pesquisas Energéticas e Nucleares</td></tr>
          <tr><td>Laerce de Paula Nunes   </td><td></td><td>IEC – Instalações e Engenharia de Corrosão LTDA</td></tr>
          <tr><td>Luís Frederico P. Dick  </td><td></td><td>Universidade Federal do Rio Grande do Sul</td></tr>
          <tr><td>Neusvaldo Lira de Almeida </td><td></td><td>Instituto de Pesquisas Tecnológicas do Estado de São Paulo</td></tr>
          <tr><td>Oscar Rosa Mattos   </td><td></td><td>Universidade Federal do Rio de Janeiro</td></tr>
          <tr><td>Simone Louise Delarue Cezar Brasil  </td><td></td><td>Universidade Federal do Rio de Janeiro</td></tr>
          <tr><td>Walney Silva Araújo </td><td></td><td>Universidade Federal do Ceará</td></tr>
          <tr><td>Zehbour Panossian</td><td></td><td>Instituto de Pesquisas Tecnológicas do Estado de São Paulo</td></tr>

        </tbody>
      </Table>
      <h3>{i18n.t('comissao.comiteTecnico')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th></th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>Adriana de Araujo</td><td></td><td>BRASIL</td></tr>
          <tr><td>Aldo Cordeiro</td><td></td><td>BRASIL</td></tr>
          <tr><td>Alexandra Banu</td><td></td><td>ROMÊNIA</td></tr>
          <tr><td>Álvaro Augusto</td><td></td><td>BRASIL</td></tr>
          <tr><td>Anna Ramus</td><td></td><td>BRASIL</td></tr>
          <tr><td>Carlos Alexandre</td><td></td><td>BRASIL</td></tr>
          <tr><td>Celia Aparecida</td><td></td><td>BRASIL</td></tr>
          <tr><td>Daniel John</td><td></td><td>SINGAPURA</td></tr>
          <tr><td>Denise Souza</td><td></td><td>BRASIL</td></tr>
          <tr><td>Emma Angelini</td><td></td><td>ITÁLIA</td></tr>
          <tr><td>Erik Barbosa</td><td></td><td>BRASIL</td></tr>
          <tr><td>Flavia Maciel</td><td></td><td>BRASIL</td></tr>
          <tr><td>Günter Schmitt</td><td></td><td>ALEMANHA</td></tr>
          <tr><td>Guilherme Koga</td><td></td><td>BRASIL</td></tr>
          <tr><td>Gustavo Duffó</td><td></td><td>ARGENTINA</td></tr>
          <tr><td>Gutemberg Pimenta</td><td></td><td>BRASIL</td></tr>
          <tr><td>Iba F. Al-Adel</td><td></td><td>ARÁBIA SAUDITA</td></tr>
          <tr><td>Idalina Vieira</td><td></td><td>BRASIL</td></tr>
          <tr><td>Ilson Palmieri</td><td></td><td>BRASIL</td></tr>
          <tr><td>Isolda Costa</td><td></td><td>BRASIL</td></tr>
          <tr><td>Jane Zoppas</td><td></td><td>BRASIL</td></tr>
          <tr><td>Jesualdo Rossi</td><td></td><td>BRASIL</td></tr>
          <tr><td>João Paulo</td><td></td><td>BRASIL</td></tr>
          <tr><td>Joseph Raj</td><td></td><td>SEYCHELLES</td></tr>
          <tr><td>Juliana Cardoso</td><td></td><td>BRASIL</td></tr>
          <tr><td>Lisete Cristine</td><td></td><td>BRASIL</td></tr>
          <tr><td>Marcia Lutterbach</td><td></td><td>BRASIL</td></tr>
          <tr><td>Maritza Páez</td><td></td><td>CHILE</td></tr>
          <tr><td>Mauro Chaves</td><td></td><td>BRASIL</td></tr>
          <tr><td>Mustafa Urgen</td><td></td><td>TURQUIA</td></tr>
          <tr><td>Neusvaldo Lira</td><td></td><td>BRASIL</td></tr>
          <tr><td>Noam Eliaz</td><td></td><td>ISRAEL</td></tr>
          <tr><td>Pedro Altoé</td><td></td><td>BRASIL</td></tr>
          <tr><td>Ricardo Cruz</td><td></td><td>MÉXICO</td></tr>
          <tr><td>Simone Louise</td><td></td><td>BRASIL</td></tr>
          <tr><td>V.S. Raja</td><td></td><td>ÍNDIA</td></tr>
          <tr><td>Zehbour Panossian</td><td></td><td>BRASIL</td></tr>
        </tbody>
      </Table>
      {/* Implementar Accordion até aqui */}

      <h2><span>2018</span></h2>
      <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>{/*  Linha 1 */}
            <td>Fábio Kränkel</td>
            <td>WEG</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Neusvaldo Lira de Almeida</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Simone Louise Delarue Cezar Brasil</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Zehbour Panossian</td>
            <td>IPT</td>
            <td>...</td>
          </tr>
        </tbody>
      </Table>

      <h3>{i18n.t('comissao.comiteTecnico')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>Coordenação</th>
            <th></th>
            <th>{i18n.t('comissao.instituicao')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Neusvaldo Lira de Almeida</td>
            <td></td>
            <td>IPT</td>
          </tr>
          <tr>
            <td>Simone Louise Delarue Cezar Brasil</td>
            <td></td>
            <td>UFRJ</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th></th>
            <th>{i18n.t('comissao.instituicao')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Aldo Cordeiro Dutra</td>
            <td></td>
            <td>ABRACO</td>
          </tr>
          <tr>
            <td>Álvaro Augusto O. Magalhães</td>
            <td></td>
            <td>PETROBRAS</td>
          </tr>
          <tr>
            <td>Carlos Alexandre Martins da Silva</td>
            <td></td>
            <td>TRANSPETRO</td>
          </tr>
          <tr>
            <td>Denise Souza de Freitas</td>
            <td></td>
            <td>INT</td>
          </tr>
          <tr>
            <td>Fernando de Loureiro Fragata</td>
            <td></td>
            <td>AUTÔNOMO</td>
          </tr>
          <tr>
            <td>Flávia Guedes</td>
            <td></td>
            <td>PETROBRAS</td>
          </tr>
          <tr>
            <td>Gutemberg de Souza Pimenta</td>
            <td></td>
            <td>AUTÔNOMO</td>
          </tr>
          <tr>
            <td>Hercílio Gomes de Melo</td>
            <td></td>
            <td>USP</td>
          </tr>
          <tr>
            <td>Idalina Vieira Aoki</td>
            <td></td>
            <td>USP</td>
          </tr>
          <tr>
            <td>Ilson Palmieri</td>
            <td></td>
            <td>PETROBRAS</td>
          </tr>
          <tr>
            <td>Isolda Costa</td>
            <td></td>
            <td>IPEN</td>
          </tr>
          <tr>
            <td>Ivan Napoleão Bastos</td>
            <td></td>
            <td>UERJ</td>
          </tr>
          <tr>
            <td>João Paulo Klausing </td>
            <td></td>
            <td>PETROBRAS</td>
          </tr>
          <tr>
            <td>Ladimir José de Carvalho</td>
            <td></td>
            <td>UFRJ</td>
          </tr>
          <tr>
            <td>Márcia Teresa Soares Lutterbach</td>
            <td></td>
            <td>INT</td>
          </tr>
          <tr>
            <td>Mauro Barreto</td>
            <td></td>
            <td>IEC</td>
          </tr>
          <tr>
            <td>Pedro Altoé</td>
            <td></td>
            <td>PETROBRAS</td>
          </tr>
          <tr>
            <td>Walter Cravo Junior</td>
            <td></td>
            <td>INT</td>
          </tr>
        </tbody>
      </Table>

      <h2><span>2016</span></h2>
      <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}:</span> Denise Souza de Freitas</td>
            <td>Int</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold">Vice-{i18n.t('comissao.presidente')}:</span> Gutemberg de Souza Pimenta</td>
            <td>Consultor</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Aécio Castelo Branco Teixeira</td>
            <td>TINTAS JUMBO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ana Paula Erthal Moreira</td>
            <td>A&amp;Z ANÁLISES QUÍMICAS LTDA.</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Fernando de Loureiro Fragata</td>
            <td>Consultor</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Laerce de Paula Nunes</td>
            <td>Iec</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Maria Carolina Rodrigues Silva</td>
            <td>Eletronuclear S.A</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Pedro Paulo Barbosa Leite</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Segehal Matsumoto</td>
            <td>Consultor</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Zehbour Panossian</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>
      <h3>{i18n.t('comissao.comiteTecnico')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>Instiuição</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}: </span>Simone Louise Delarue Cezar Brasil</td>
            <td>EQ/UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold">Vice-{i18n.t('comissao.presidente')}: </span>Neusvaldo L. Almeida </td>
            <td>Ipt</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Álvaro Augusto O. Magalhães</td>
            <td>Petrobras/Cenpes</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>André Koebsch</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Carlos Alexandre Martins da Silva</td>
            <td>Transpetro</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Célia Aparecida Lino dos Santos</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Cyntdia Andrade</td>
            <td>PETROBRAS/CENPES</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Fernando de Loureiro Fragata</td>
            <td>Consultor</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ilson Palmiere</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ivan Napoleão Bastos</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>João Paulo Klausing</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Joaquim Quintela</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Jorge Fernando Pereira Coelho</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>José Antônio da Cunha Ponciano Gomes</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ladimir José de Carvalho</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Mauro Barreto</td>
            <td>Iec</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Walter Cravo Junior</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Atdayde Araújo Tell Ribeiro</td>
            <td>Abraco</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Jaqueline Rodrigues</td>
            <td>Abraco</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>

      <h2><span>2014</span></h2>
      <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}:</span> Neusvaldo Lira de Almeida</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold">Vice-{i18n.t('comissao.presidente')}:</span> Rosileia Mantovani</td>
            <td>Jotun</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>A&eacute;cio Castelo Branco</td>
            <td>Tintas Jumbo</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Aldo Cordeira Dutra</td>
            <td>INMETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Cesar Carlos de Souza</td>
            <td>Weg Tintas</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Denise Souza de Freitas</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Gutemberg de Souza Pimenta</td>
            <td>Petrobras/CENPES</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Isidoro Barbiero</td>
            <td>Smartcoat</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Marcos Morete</td>
            <td>ABRACO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Pedro Paulo Barbosa Leite</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Simone Brasil</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Simone Maciel</td>
            <td>ABRACO</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>
      <h3>{i18n.t('comissao.comiteTecnico')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody><tr>
          <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}:</span> Simone Louise Delarue Cezar Brasil</td>
          <td>EQ/UFRJ</td>
          <td>Brasil</td>
        </tr>
          <tr>
            <td><span className="font-weight-bold">Vice-{i18n.t('comissao.presidente')}:</span> Denise Souza de Freitas </td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Andr&eacute; Koebsch</td>
            <td>PETROBRAS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Alda Simoes</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>&Aacute;lvaro Augusto O. Magalh&atilde;es</td>
            <td>Petrobras/CENPES</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Anna Ramus</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Carlos Alexandre Martins da    Silva</td>
            <td>TRANSPETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Carlos Enrique Arroyave    Posada</td>
            <td>UA</td>
            <td>Colômbia</td>
          </tr>
          <tr>
            <td>C&eacute;lia Aparecida Lino dos    Santos</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Dalva Cristina Baptista do    Lago</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Daniel    de La Fuente </td>
            <td>CENIM/CSIC</td>
            <td>Espanha</td>
          </tr>
          <tr>
            <td>Fernando de Loureiro Fragata</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Francisca Pessoa de Fran&ccedil;a</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Idalina Vieira Aoki</td>
            <td>EPUSP </td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ieda Nadja Silva Montenegro</td>
            <td>NUTEC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Isabel Correia Guedes</td>
            <td>USP</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Isolda Costa</td>
            <td>IPEN</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ivan Napole&atilde;o Bastos</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Jo&atilde;o Carlos Salvador Fernandes </td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Jo&atilde;o Hip&oacute;lito de Lima Oliver</td>
            <td>TRANSPETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Jorge Fernando Pereira Coelho</td>
            <td>PETROBRAS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Jos&eacute; Antonio da Cunha Ponciano Gomes</td>
            <td>COPPE/UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ladimir Jos&eacute; de Carvalho</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Lilian Ferreira de Senna</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Manuel Morcillo Linares</td>
            <td>CENIM</td>
            <td>Espanha</td>
          </tr>
          <tr>
            <td>M&aacute;rcia Teresa Soares Lutterbach</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Maria Alice A. Gomes de Lima</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Maria de F&aacute;tima Grilo Costa    Montemor</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>M&aacute;rio Guerreiro Silva    Ferreira</td>
            <td>UA</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Matilde Fernandez de Romero</td>
            <td>Univ. del Zulia</td>
            <td>Venezuela</td>
          </tr>
          <tr>
            <td>Mauro Zanini Sebr&atilde;o</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Oladis Troc&oacute;nis de Rinc&oacute;n </td>
            <td>CEC</td>
            <td>Venezuela</td>
          </tr>
          <tr>
            <td>Olga Ferraz</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Pedro Alto&eacute;</td>
            <td>Petrobras</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Pedro de Lima Neto</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Ricardo Pereira Nogueira</td>
            <td>Univ. Grenoble</td>
            <td>Fran&ccedil;a</td>
          </tr>
          <tr>
            <td>Teresa Diamantino</td>
            <td>INETI</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Walney Silva Ara&uacute;jo</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Zehbour Panossian</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>

      <h2><span>2012</span></h2>
      <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}:</span> Gutemberg de Souza Pimenta</td>
            <td>PETROBRAS/CENPES</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold">Vice-{i18n.t('comissao.presidente')}:</span> Marcos Morete</td>
            <td>ABRACO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Adauto Carlos Colussi Riva</td>
            <td>RENNER</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Aldo Cordeira Dutra</td>
            <td>INMETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Fernando de Loureiro Fragata</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>João Hipolito de Lima Oliver</td>
            <td>TRANSPETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Marco Aurelio Ferreira Silveira</td>
            <td>WEG</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Olga Baptista Ferraz</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Rosileia Mantovani</td>
            <td>JOTUN</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Simone Maciel</td>
            <td>ABRACO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Zehbour Panossian</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>
      <h3>{i18n.t('comissao.comiteTecnico')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}: </span>Neusvaldo Lira de Almeida</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td><span className="font-weight-bold">Vice-{i18n.t('comissao.presidente')}: </span>Simone Louise Delarue Cezar Brasil</td>
            <td>EQ/UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>André Koebsch</td>
            <td>PETROBRAS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Anna Ramus</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Carlos Alexandre Martins da Silva</td>
            <td>TRANSPETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Carlos Enrique Arroyave Posada</td>
            <td>UA</td>
            <td>Colômbia</td>
          </tr>
          <tr>
            <td>Célia Aparecida Lino dos Santos</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Cesar Vitorio Franco</td>
            <td>UFSC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Dalva Cristina Baptista do Lago</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Daniel de La Fuente</td>
            <td>CENIM/CSIC</td>
            <td>Espanha</td>
          </tr>
          <tr>
            <td>Denise Shermann Azambuja</td>
            <td>UFRS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Denise Souza de Freitas</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Djalma Ribeiro da Silva</td>
            <td>UFRN</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Francisca Pessoa de França</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Gutemberg de Souza Pimenta</td>
            <td>PETROBRAS/CENPES</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Idalina Vieira Aoki</td>
            <td>EPUSP</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Isabel Correia Guedes</td>
            <td>USP</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Isolda Costa</td>
            <td>IPEN</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ivan Napoleão Bastos</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>João Carlos Salvador Fernandes</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Jorge Fernando Pereira Coelho</td>
            <td>PETROBRAS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>José Antonio da Cunha Ponciano Gomes</td>
            <td>COPPE/UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Lilian Ferreira de Senna</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Luis Frederico P. Dick</td>
            <td>ELECTROCON/UFRGS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Manuel Morcillo Linares</td>
            <td>CENIM</td>
            <td>Espanha</td>
          </tr>
          <tr>
            <td>Márcia Teresa Soares Lutterbach</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Maria Alice Gomes de Andrade Lima</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Maria de Fátima Grilo Costa Montemor</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Mário Guerreiro Silva Ferreira</td>
            <td>UA</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Matilde Fernandez de Romero</td>
            <td>Univ. del Zulia</td>
            <td>Venezuela</td>
          </tr>
          <tr>
            <td>Mauro Zanini Sebrão</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Oladis Trocónis de Rincón</td>
            <td>CEC</td>
            <td>Venezuela</td>
          </tr>
          <tr>
            <td>Pedro de Lima Neto</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ricardo Pereira Nogueira</td>
            <td>Univ. Grenoble</td>
            <td>França</td>
          </tr>
          <tr>
            <td>Severino Urtiga Filho</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Teresa Diamantino</td>
            <td>INETI</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Walney Silva Araújo</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Yêda Medeiros Bastos de Almeida</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>

      <h2><span>2010</span></h2>
      <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}: </span>Laerce de Paula Nunes</td>
            <td>IEC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Fernando Benedicto Manier</td>
            <td>UFF</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Fernando de Loureiro Fragata</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ieda Nadja Silva Montenegro</td>
            <td>NUTEC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>João Hipólito de Lima Oliver</td>
            <td>TRANSPETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Mauro José Deretti</td>
            <td>WEG</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Neusvaldo Lira de Almeida</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Olga Baptista Ferraz</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Rosileia Mantovani</td>
            <td>Akzo Nobel</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Simone Louise Delarue Cezar Brasil</td>
            <td>EQ/UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Simone Maciel</td>
            <td>ABRACO</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>
      <h3>{i18n.t('comissao.comiteTecnico')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}: </span>Zehbour Panossian</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Alda Maria Pereira Simões</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Aldo Cordeiro Dutra</td>
            <td>INMETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Anna Ramus Moreira</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Carlos Alexandre Martins da Silva</td>
            <td>TRANSPETRO</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Carlos Enrique Arroyave Posada</td>
            <td>UA</td>
            <td>Colômbia</td>
          </tr>
          <tr>
            <td>Célia Aparecida Lino dos Santos</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Cesar Vitório Franco</td>
            <td>UFSC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Dalva Cristina Baptista do Lago</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Daniel de La Fuente</td>
            <td>CENIM/CSIC</td>
            <td>Espanha</td>
          </tr>
          <tr>
            <td>Denise Shermann Azambuja</td>
            <td>UFRS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Denise Souza de Freitas</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Djalma Ribeiro da Silva</td>
            <td>UFRN</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Francisca Pessoa de França</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Hector Augusto Videla</td>
            <td>UTN/NACE</td>
            <td>Argentina</td>
          </tr>
          <tr>
            <td>Idalina Vieira Aoki</td>
            <td>EPUSP</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Isabel Correia Guedes</td>
            <td>USP</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Isolda Costa</td>
            <td>IPEN</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Ivan Napoleão Bastos</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>João Carlos Salvador Fernandes</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Jorge Fernando Pereira Coelho</td>
            <td>PETROBRAS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>José Antonio da Cunha Ponciano Gomes</td>
            <td>COPPE-UFRJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Jose Carlos Cardoso Filho</td>
            <td>DEQAL/UFPA</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Juan M. Bastidas</td>
            <td>CENIM</td>
            <td>Espanha</td>
          </tr>
          <tr>
            <td>Lilian Ferreira de Senna</td>
            <td>UERJ</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Luis Frederico P. Dick</td>
            <td>ELECTROCON/ UFRGS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Manuel Morcillo Linares</td>
            <td>CENIM</td>
            <td>Espanha</td>
          </tr>
          <tr>
            <td>Márcia Teresa Soares Lutterbach</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Maria Alice Gomes de Andrade Lima</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Maria de Fátima Grilo Costa Montemor</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Mário Guerreiro Silva Ferreira</td>
            <td>UA</td>
            <td>Portugal</td>
          </tr>
          <tr>
            <td>Matilde Fernandez de Romero</td>
            <td></td>
            <td>Venezuela</td>
          </tr>
          <tr>
            <td>Mauro Zanini Sebrão</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Oladis Trocónis de Rincón </td>
            <td>CEC</td>
            <td>Venezuela</td>
          </tr>
          <tr>
            <td>Pedro de Lima Neto</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Severino Urtiga Filho</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Sinésio D. Franco</td>
            <td>UFU</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Telmo Roberto Strohaecker</td>
            <td>PPGEM / UFRGS</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Walney Silva Araujo</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Yêda Medeiros Bastos de Almeida</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>

      <h2><span>2008</span></h2>
      <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('comissao.presidente')}: Pedro Paulo Barbosa Leite</td>
            <td>PETROBRAS/ENGENHARIA/NORTE</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Aldo Cordeiro Dutra</td>
            <td>INMETRO</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Gutemberg de Souza Pimenta</td>
            <td>PETROBRAS/CENPES</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Jeferson da Silva</td>
            <td>Akzo Nobel</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Laerce de Paula Nunes</td>
            <td>IEC</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Olga Baptista Ferraz</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Simone Maciel</td>
            <td>ABRACO</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Zehbour Panossian</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>
      <h3>{i18n.t('comissao.comiteTecnico')}</h3>
      <Table responsive striped hover size="sm">
        <thead>
          <tr>
            <th>{i18n.t('comissao.membros')}</th>
            <th>{i18n.t('comissao.instituicao')}</th>
            <th>{i18n.t('comissao.pais')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{i18n.t('comissao.presidente')}: Neusvaldo Lira de Almeida</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>
          <tr>
            <td>Alda Maria Pereira Simões</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>

          <tr>
            <td>Carlos Alexandre Martins da Silva</td>
            <td>PETROBRAS</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Célia Aparecida Lino dos Santos</td>
            <td>IPT</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Cesar Vitório Franco</td>
            <td>UFSC</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Cezar Henrique Gonzalez</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Daniel de La Fuente</td>
            <td>CENIM/CSIC</td>
            <td>Espanha</td>
          </tr>

          <tr>
            <td>Denise Shermann Azambuja</td>
            <td>UFRS</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Denise Souza de Freitas</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Djalma Ribeiro da Silva</td>
            <td>UFRN</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Eduardo Homem De Siqueira Cavalcanti</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Elaine Dalledone Kenny</td>
            <td>UFPR/LACTEC</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Fernando de Loureiro Fragata</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Flamarion Borges Diniz</td>
            <td>DQF/UFPE</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Francisca Pessoa de França</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Glória Maria Vinhas</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Idalina Vieira Aoki</td>
            <td>EPUSP</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Isabel Correia Guedes</td>
            <td>USP</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Isolda Costa</td>
            <td>IPEN</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>João Carlos Salvador Fernandes</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>

          <tr>
            <td>Jorge Fernando Pereira Coelho</td>
            <td>PETROBRAS</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>José Antonio da C. Ponciano Gomes</td>
            <td>COPPE/UFRJ</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Jose Carlos Cardoso Filho</td>
            <td>DEQAL/UFPA</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Juan M. Bastidas</td>
            <td>CENIM</td>
            <td>Espanha</td>
          </tr>

          <tr>
            <td>Luis Frederico P. Dick</td>
            <td>ELECTROCON/ UFRGS</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Márcia Teresa Soares Lutterbach</td>
            <td>INT</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Maria Alice Gomes de Andrade Lima</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Maria de Fátima Grilo Costa Montemor</td>
            <td>IST</td>
            <td>Portugal</td>
          </tr>

          <tr>
            <td>Mário Guerreiro Silva Ferreira</td>
            <td>UA</td>
            <td>Portugal</td>
          </tr>

          <tr>
            <td>Mauro Chaves Barreto</td>
            <td>IEC</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Mauro Zanini Sebrão</td>
            <td>CEPEL</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Oscar Rosa Mattos</td>
            <td>COPPE/UFRJ</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Pedro de Lima Neto</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Sara Horácio de Oliveira</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Severino Urtiga Filho</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Simone Louise Delarue Cezar Brasil</td>
            <td>UFRJ</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Walney Silva Araujo</td>
            <td>UFC</td>
            <td>Brasil</td>
          </tr>

          <tr>
            <td>Yêda Medeiros Bastos de Almeida</td>
            <td>UFPE</td>
            <td>Brasil</td>
          </tr>
        </tbody>
      </Table>

    </div>
  );
}

// class Comissao extends Component {
//   render() {
//     return (
//       <div data-comp-comissao>
//         <div className="pre-title" />
//         <h1>Comissão Científica</h1>
// {/*
//         <code>
//           2020: https://icc-congress2020.com.br/comites/ <br />
//           2018: http://www.abraco.org.br/intercorr2018/comites.php <br />
//           2016: http://www.abraco.org.br/intercorr2016/comite.php <br />
//           2014: http://www.abraco.org.br/intercorr2014/comite.php <br />
//           2012: http://www.abraco.org.br/intercorr2012/comites.php <br />
//           2010: http://www.abraco.org.br/intercorr2010/comites.php          <br />
//           2008: http://www.abraco.org.br/intercorr2008/comites.htm          <br />
//         </code> */}
//         {/* Implementar Accordion daqui */}
//         <h2><span>2021</span></h2>
//         <h3>{i18n.t('comissao.comiteExecutivo')}</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>{i18n.t('comissao.membros')}</th>
//               <th>{i18n.t('comissao.instituicao')}</th>
//               <th>{i18n.t('comissao.pais')}</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">{i18n.t('comissao.presidente')}: </span> Prof. Dra. Zehbour Panossian</td>
//               <td>ABRACO/IPT</td>
//               <td>BRASIL</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold ">1º Vice-Presidente:</span> Prof. Dr. Günter Schmitt</td>
//               <td>ICC</td>
//               <td>ALEMANHA</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold">2º Vice-Presidente:</span> Prof. Emma Angelini</td>
//               <td>ICC</td>
//               <td>ITÁLIA</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold">3º Vice-Presidente:</span> Dra. Olga Baptista Ferraz</td>
//               <td>ABRACO/INT</td>
//               <td>BRASIL</td>
//             </tr>
//           </tbody>
//         </Table>
//         <h3>Comitê Organizador Local</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th></th>
//               <th>Instituição</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr><td>Adauto Riva</td><td></td><td>Tintas Renner</td></tr>
//             <tr><td>Carlos Alexandre Martins da Silva</td><td></td><td>TRANSPETRO</td></tr>
//             <tr><td>Carlos Roberto Patrício </td><td></td><td>B. Bosch Galvanização do Brasil</td></tr>
//             <tr><td>Danilo Natalio Sanches  </td><td></td><td>Zincoligas Indústria e Comércio Ltda</td></tr>
//             <tr><td>Denise Souza de Freitas </td><td></td><td>Instituto Nacional de Tecnologia</td></tr>
//             <tr><td>Diego Gonzalo Hita  </td><td></td><td>Hita Comércio e Serviços Ltda</td></tr>
//             <tr><td>Hercílio Gomes de Melo  </td><td></td><td>Universidade de São Paulo</td></tr>
//             <tr><td>Idalina Vieira Aoki </td><td></td><td>Universidade de São Paulo</td></tr>
//             <tr><td>Isabel Cristina P. Margarit-Mattos  </td><td></td><td>Universidade Federal do Rio de Janeiro</td></tr>
//             <tr><td>Isolda Costa</td><td></td><td>Instituto de Pesquisas Energéticas e Nucleares</td></tr>
//             <tr><td>Javier Alejandro Carreno Velasco</td><td></td><td>Instituto Nacional de Tecnologia</td></tr>
//             <tr><td>Jesualdo Luiz Rossi </td><td></td><td>Instituto de Pesquisas Energéticas e Nucleares</td></tr>
//             <tr><td>Laerce de Paula Nunes   </td><td></td><td>IEC – Instalações e Engenharia de Corrosão LTDA</td></tr>
//             <tr><td>Luís Frederico P. Dick  </td><td></td><td>Universidade Federal do Rio Grande do Sul</td></tr>
//             <tr><td>Neusvaldo Lira de Almeida </td><td></td><td>Instituto de Pesquisas Tecnológicas do Estado de São Paulo</td></tr>
//             <tr><td>Oscar Rosa Mattos   </td><td></td><td>Universidade Federal do Rio de Janeiro</td></tr>
//             <tr><td>Simone Louise Delarue Cezar Brasil  </td><td></td><td>Universidade Federal do Rio de Janeiro</td></tr>
//             <tr><td>Walney Silva Araújo </td><td></td><td>Universidade Federal do Ceará</td></tr>
//             <tr><td>Zehbour Panossian</td><td></td><td>Instituto de Pesquisas Tecnológicas do Estado de São Paulo</td></tr>

//           </tbody>
//         </Table>
//         <h3>{i18n.t('comissao.comiteTecnico')}</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th></th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr><td>Adriana de Araujo</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Aldo Cordeiro</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Alexandra Banu</td><td></td><td>ROMÊNIA</td></tr>
//             <tr><td>Álvaro Augusto</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Anna Ramus</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Carlos Alexandre</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Celia Aparecida</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Daniel John</td><td></td><td>SINGAPURA</td></tr>
//             <tr><td>Denise Souza</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Emma Angelini</td><td></td><td>ITÁLIA</td></tr>
//             <tr><td>Erik Barbosa</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Flavia Maciel</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Günter Schmitt</td><td></td><td>ALEMANHA</td></tr>
//             <tr><td>Guilherme Koga</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Gustavo Duffó</td><td></td><td>ARGENTINA</td></tr>
//             <tr><td>Gutemberg Pimenta</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Iba F. Al-Adel</td><td></td><td>ARÁBIA SAUDITA</td></tr>
//             <tr><td>Idalina Vieira</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Ilson Palmieri</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Isolda Costa</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Jane Zoppas</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Jesualdo Rossi</td><td></td><td>BRASIL</td></tr>
//             <tr><td>João Paulo</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Joseph Raj</td><td></td><td>SEYCHELLES</td></tr>
//             <tr><td>Juliana Cardoso</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Lisete Cristine</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Marcia Lutterbach</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Maritza Páez</td><td></td><td>CHILE</td></tr>
//             <tr><td>Mauro Chaves</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Mustafa Urgen</td><td></td><td>TURQUIA</td></tr>
//             <tr><td>Neusvaldo Lira</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Noam Eliaz</td><td></td><td>ISRAEL</td></tr>
//             <tr><td>Pedro Altoé</td><td></td><td>BRASIL</td></tr>
//             <tr><td>Ricardo Cruz</td><td></td><td>MÉXICO</td></tr>
//             <tr><td>Simone Louise</td><td></td><td>BRASIL</td></tr>
//             <tr><td>V.S. Raja</td><td></td><td>ÍNDIA</td></tr>
//             <tr><td>Zehbour Panossian</td><td></td><td>BRASIL</td></tr>
//           </tbody>
//         </Table>
//         {/* Implementar Accordion até aqui */}

//         <h2><span>2018</span></h2>
//         <h3>Comitê Executivo</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>{/*  Linha 1 */}
//               <td>Fábio Kränkel</td>
//               <td>WEG</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Neusvaldo Lira de Almeida</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Simone Louise Delarue Cezar Brasil</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Zehbour Panossian</td>
//               <td>IPT</td>
//               <td>...</td>
//             </tr>
//           </tbody>
//         </Table>

//         <h3>Comitê Técnico-científico</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Coordenação</th>
//               <th></th>
//               <th>Instituição</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Neusvaldo Lira de Almeida</td>
//               <td></td>
//               <td>IPT</td>
//             </tr>
//             <tr>
//               <td>Simone Louise Delarue Cezar Brasil</td>
//               <td></td>
//               <td>UFRJ</td>
//             </tr>
//           </tbody>
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th></th>
//               <th>Instituição</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Aldo Cordeiro Dutra</td>
//               <td></td>
//               <td>ABRACO</td>
//             </tr>
//             <tr>
//               <td>Álvaro Augusto O. Magalhães</td>
//               <td></td>
//               <td>PETROBRAS</td>
//             </tr>
//             <tr>
//               <td>Carlos Alexandre Martins da Silva</td>
//               <td></td>
//               <td>TRANSPETRO</td>
//             </tr>
//             <tr>
//               <td>Denise Souza de Freitas</td>
//               <td></td>
//               <td>INT</td>
//             </tr>
//             <tr>
//               <td>Fernando de Loureiro Fragata</td>
//               <td></td>
//               <td>AUTÔNOMO</td>
//             </tr>
//             <tr>
//               <td>Flávia Guedes</td>
//               <td></td>
//               <td>PETROBRAS</td>
//             </tr>
//             <tr>
//               <td>Gutemberg de Souza Pimenta</td>
//               <td></td>
//               <td>AUTÔNOMO</td>
//             </tr>
//             <tr>
//               <td>Hercílio Gomes de Melo</td>
//               <td></td>
//               <td>USP</td>
//             </tr>
//             <tr>
//               <td>Idalina Vieira Aoki</td>
//               <td></td>
//               <td>USP</td>
//             </tr>
//             <tr>
//               <td>Ilson Palmieri</td>
//               <td></td>
//               <td>PETROBRAS</td>
//             </tr>
//             <tr>
//               <td>Isolda Costa</td>
//               <td></td>
//               <td>IPEN</td>
//             </tr>
//             <tr>
//               <td>Ivan Napoleão Bastos</td>
//               <td></td>
//               <td>UERJ</td>
//             </tr>
//             <tr>
//               <td>João Paulo Klausing </td>
//               <td></td>
//               <td>PETROBRAS</td>
//             </tr>
//             <tr>
//               <td>Ladimir José de Carvalho</td>
//               <td></td>
//               <td>UFRJ</td>
//             </tr>
//             <tr>
//               <td>Márcia Teresa Soares Lutterbach</td>
//               <td></td>
//               <td>INT</td>
//             </tr>
//             <tr>
//               <td>Mauro Barreto</td>
//               <td></td>
//               <td>IEC</td>
//             </tr>
//             <tr>
//               <td>Pedro Altoé</td>
//               <td></td>
//               <td>PETROBRAS</td>
//             </tr>
//             <tr>
//               <td>Walter Cravo Junior</td>
//               <td></td>
//               <td>INT</td>
//             </tr>
//           </tbody>
//         </Table>

//         <h2><span>2016</span></h2>
//         <h3>Comitê Executivo</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">Presidente:</span> Denise Souza de Freitas</td>
//               <td>Int</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold">Vice-Presidente:</span> Gutemberg de Souza Pimenta</td>
//               <td>Consultor</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Aécio Castelo Branco Teixeira</td>
//               <td>TINTAS JUMBO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ana Paula Erthal Moreira</td>
//               <td>A&amp;Z ANÁLISES QUÍMICAS LTDA.</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Fernando de Loureiro Fragata</td>
//               <td>Consultor</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Laerce de Paula Nunes</td>
//               <td>Iec</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Maria Carolina Rodrigues Silva</td>
//               <td>Eletronuclear S.A</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Pedro Paulo Barbosa Leite</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Segehal Matsumoto</td>
//               <td>Consultor</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Zehbour Panossian</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>
//         <h3>Comitê Técnico-científico</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instiuição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">Presidente: </span>Simone Louise Delarue Cezar Brasil</td>
//               <td>EQ/UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold">Vice-Presidente: </span>Neusvaldo L. Almeida </td>
//               <td>Ipt</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Álvaro Augusto O. Magalhães</td>
//               <td>Petrobras/Cenpes</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>André Koebsch</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Carlos Alexandre Martins da Silva</td>
//               <td>Transpetro</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Célia Aparecida Lino dos Santos</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Cyntdia Andrade</td>
//               <td>PETROBRAS/CENPES</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Fernando de Loureiro Fragata</td>
//               <td>Consultor</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ilson Palmiere</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ivan Napoleão Bastos</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>João Paulo Klausing</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Joaquim Quintela</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Jorge Fernando Pereira Coelho</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>José Antônio da Cunha Ponciano Gomes</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ladimir José de Carvalho</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Mauro Barreto</td>
//               <td>Iec</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Walter Cravo Junior</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Atdayde Araújo Tell Ribeiro</td>
//               <td>Abraco</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Jaqueline Rodrigues</td>
//               <td>Abraco</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>

//         <h2><span>2014</span></h2>
//         <h3>Comitê Executivo</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">Presidente:</span> Neusvaldo Lira de Almeida</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold">Vice-presidente:</span> Rosileia Mantovani</td>
//               <td>Jotun</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>A&eacute;cio Castelo Branco</td>
//               <td>Tintas Jumbo</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Aldo Cordeira Dutra</td>
//               <td>INMETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Cesar Carlos de Souza</td>
//               <td>Weg Tintas</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Denise Souza de Freitas</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Gutemberg de Souza Pimenta</td>
//               <td>Petrobras/CENPES</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Isidoro Barbiero</td>
//               <td>Smartcoat</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Marcos Morete</td>
//               <td>ABRACO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Pedro Paulo Barbosa Leite</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Simone Brasil</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Simone Maciel</td>
//               <td>ABRACO</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>
//         <h3>Comitê Técnico-científico</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody><tr>
//             <td><span className="font-weight-bold">Presidente:</span> Simone Louise Delarue Cezar Brasil</td>
//             <td>EQ/UFRJ</td>
//             <td>Brasil</td>
//           </tr>
//             <tr>
//               <td><span className="font-weight-bold">Vice-presidente:</span> Denise Souza de Freitas </td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Andr&eacute; Koebsch</td>
//               <td>PETROBRAS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Alda Simoes</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>&Aacute;lvaro Augusto O. Magalh&atilde;es</td>
//               <td>Petrobras/CENPES</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Anna Ramus</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Carlos Alexandre Martins da    Silva</td>
//               <td>TRANSPETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Carlos Enrique Arroyave    Posada</td>
//               <td>UA</td>
//               <td>Colômbia</td>
//             </tr>
//             <tr>
//               <td>C&eacute;lia Aparecida Lino dos    Santos</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Dalva Cristina Baptista do    Lago</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Daniel    de La Fuente </td>
//               <td>CENIM/CSIC</td>
//               <td>Espanha</td>
//             </tr>
//             <tr>
//               <td>Fernando de Loureiro Fragata</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Francisca Pessoa de Fran&ccedil;a</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Idalina Vieira Aoki</td>
//               <td>EPUSP </td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ieda Nadja Silva Montenegro</td>
//               <td>NUTEC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Isabel Correia Guedes</td>
//               <td>USP</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Isolda Costa</td>
//               <td>IPEN</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ivan Napole&atilde;o Bastos</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Jo&atilde;o Carlos Salvador Fernandes </td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Jo&atilde;o Hip&oacute;lito de Lima Oliver</td>
//               <td>TRANSPETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Jorge Fernando Pereira Coelho</td>
//               <td>PETROBRAS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Jos&eacute; Antonio da Cunha Ponciano Gomes</td>
//               <td>COPPE/UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ladimir Jos&eacute; de Carvalho</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Lilian Ferreira de Senna</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Manuel Morcillo Linares</td>
//               <td>CENIM</td>
//               <td>Espanha</td>
//             </tr>
//             <tr>
//               <td>M&aacute;rcia Teresa Soares Lutterbach</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Maria Alice A. Gomes de Lima</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Maria de F&aacute;tima Grilo Costa    Montemor</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>M&aacute;rio Guerreiro Silva    Ferreira</td>
//               <td>UA</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Matilde Fernandez de Romero</td>
//               <td>Univ. del Zulia</td>
//               <td>Venezuela</td>
//             </tr>
//             <tr>
//               <td>Mauro Zanini Sebr&atilde;o</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Oladis Troc&oacute;nis de Rinc&oacute;n </td>
//               <td>CEC</td>
//               <td>Venezuela</td>
//             </tr>
//             <tr>
//               <td>Olga Ferraz</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Pedro Alto&eacute;</td>
//               <td>Petrobras</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Pedro de Lima Neto</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Ricardo Pereira Nogueira</td>
//               <td>Univ. Grenoble</td>
//               <td>Fran&ccedil;a</td>
//             </tr>
//             <tr>
//               <td>Teresa Diamantino</td>
//               <td>INETI</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Walney Silva Ara&uacute;jo</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Zehbour Panossian</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>

//         <h2><span>2012</span></h2>
//         <h3>Comitê Executivo</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">Presidente:</span> Gutemberg de Souza Pimenta</td>
//               <td>PETROBRAS/CENPES</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold">Vice-Presidente:</span> Marcos Morete</td>
//               <td>ABRACO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Adauto Carlos Colussi Riva</td>
//               <td>RENNER</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Aldo Cordeira Dutra</td>
//               <td>INMETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Fernando de Loureiro Fragata</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>João Hipolito de Lima Oliver</td>
//               <td>TRANSPETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Marco Aurelio Ferreira Silveira</td>
//               <td>WEG</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Olga Baptista Ferraz</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Rosileia Mantovani</td>
//               <td>JOTUN</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Simone Maciel</td>
//               <td>ABRACO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Zehbour Panossian</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>
//         <h3>Comitê Técnico-científico</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">Presidente: </span>Neusvaldo Lira de Almeida</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td><span className="font-weight-bold">Vice-Presidente: </span>Simone Louise Delarue Cezar Brasil</td>
//               <td>EQ/UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>André Koebsch</td>
//               <td>PETROBRAS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Anna Ramus</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Carlos Alexandre Martins da Silva</td>
//               <td>TRANSPETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Carlos Enrique Arroyave Posada</td>
//               <td>UA</td>
//               <td>Colômbia</td>
//             </tr>
//             <tr>
//               <td>Célia Aparecida Lino dos Santos</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Cesar Vitorio Franco</td>
//               <td>UFSC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Dalva Cristina Baptista do Lago</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Daniel de La Fuente</td>
//               <td>CENIM/CSIC</td>
//               <td>Espanha</td>
//             </tr>
//             <tr>
//               <td>Denise Shermann Azambuja</td>
//               <td>UFRS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Denise Souza de Freitas</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Djalma Ribeiro da Silva</td>
//               <td>UFRN</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Francisca Pessoa de França</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Gutemberg de Souza Pimenta</td>
//               <td>PETROBRAS/CENPES</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Idalina Vieira Aoki</td>
//               <td>EPUSP</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Isabel Correia Guedes</td>
//               <td>USP</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Isolda Costa</td>
//               <td>IPEN</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ivan Napoleão Bastos</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>João Carlos Salvador Fernandes</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Jorge Fernando Pereira Coelho</td>
//               <td>PETROBRAS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>José Antonio da Cunha Ponciano Gomes</td>
//               <td>COPPE/UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Lilian Ferreira de Senna</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Luis Frederico P. Dick</td>
//               <td>ELECTROCON/UFRGS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Manuel Morcillo Linares</td>
//               <td>CENIM</td>
//               <td>Espanha</td>
//             </tr>
//             <tr>
//               <td>Márcia Teresa Soares Lutterbach</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Maria Alice Gomes de Andrade Lima</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Maria de Fátima Grilo Costa Montemor</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Mário Guerreiro Silva Ferreira</td>
//               <td>UA</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Matilde Fernandez de Romero</td>
//               <td>Univ. del Zulia</td>
//               <td>Venezuela</td>
//             </tr>
//             <tr>
//               <td>Mauro Zanini Sebrão</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Oladis Trocónis de Rincón</td>
//               <td>CEC</td>
//               <td>Venezuela</td>
//             </tr>
//             <tr>
//               <td>Pedro de Lima Neto</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ricardo Pereira Nogueira</td>
//               <td>Univ. Grenoble</td>
//               <td>França</td>
//             </tr>
//             <tr>
//               <td>Severino Urtiga Filho</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Teresa Diamantino</td>
//               <td>INETI</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Walney Silva Araújo</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Yêda Medeiros Bastos de Almeida</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>

//         <h2><span>2010</span></h2>
//         <h3>Comitê Executivo</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">Presidente: </span>Laerce de Paula Nunes</td>
//               <td>IEC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Fernando Benedicto Manier</td>
//               <td>UFF</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Fernando de Loureiro Fragata</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ieda Nadja Silva Montenegro</td>
//               <td>NUTEC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>João Hipólito de Lima Oliver</td>
//               <td>TRANSPETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Mauro José Deretti</td>
//               <td>WEG</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Neusvaldo Lira de Almeida</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Olga Baptista Ferraz</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Rosileia Mantovani</td>
//               <td>Akzo Nobel</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Simone Louise Delarue Cezar Brasil</td>
//               <td>EQ/UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Simone Maciel</td>
//               <td>ABRACO</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>
//         <h3>Comitê Técnico-científico</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td><span className="font-weight-bold">Presidente: </span>Zehbour Panossian</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Alda Maria Pereira Simões</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Aldo Cordeiro Dutra</td>
//               <td>INMETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Anna Ramus Moreira</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Carlos Alexandre Martins da Silva</td>
//               <td>TRANSPETRO</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Carlos Enrique Arroyave Posada</td>
//               <td>UA</td>
//               <td>Colômbia</td>
//             </tr>
//             <tr>
//               <td>Célia Aparecida Lino dos Santos</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Cesar Vitório Franco</td>
//               <td>UFSC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Dalva Cristina Baptista do Lago</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Daniel de La Fuente</td>
//               <td>CENIM/CSIC</td>
//               <td>Espanha</td>
//             </tr>
//             <tr>
//               <td>Denise Shermann Azambuja</td>
//               <td>UFRS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Denise Souza de Freitas</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Djalma Ribeiro da Silva</td>
//               <td>UFRN</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Francisca Pessoa de França</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Hector Augusto Videla</td>
//               <td>UTN/NACE</td>
//               <td>Argentina</td>
//             </tr>
//             <tr>
//               <td>Idalina Vieira Aoki</td>
//               <td>EPUSP</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Isabel Correia Guedes</td>
//               <td>USP</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Isolda Costa</td>
//               <td>IPEN</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Ivan Napoleão Bastos</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>João Carlos Salvador Fernandes</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Jorge Fernando Pereira Coelho</td>
//               <td>PETROBRAS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>José Antonio da Cunha Ponciano Gomes</td>
//               <td>COPPE-UFRJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Jose Carlos Cardoso Filho</td>
//               <td>DEQAL/UFPA</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Juan M. Bastidas</td>
//               <td>CENIM</td>
//               <td>Espanha</td>
//             </tr>
//             <tr>
//               <td>Lilian Ferreira de Senna</td>
//               <td>UERJ</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Luis Frederico P. Dick</td>
//               <td>ELECTROCON/ UFRGS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Manuel Morcillo Linares</td>
//               <td>CENIM</td>
//               <td>Espanha</td>
//             </tr>
//             <tr>
//               <td>Márcia Teresa Soares Lutterbach</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Maria Alice Gomes de Andrade Lima</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Maria de Fátima Grilo Costa Montemor</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Mário Guerreiro Silva Ferreira</td>
//               <td>UA</td>
//               <td>Portugal</td>
//             </tr>
//             <tr>
//               <td>Matilde Fernandez de Romero</td>
//               <td></td>
//               <td>Venezuela</td>
//             </tr>
//             <tr>
//               <td>Mauro Zanini Sebrão</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Oladis Trocónis de Rincón </td>
//               <td>CEC</td>
//               <td>Venezuela</td>
//             </tr>
//             <tr>
//               <td>Pedro de Lima Neto</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Severino Urtiga Filho</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Sinésio D. Franco</td>
//               <td>UFU</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Telmo Roberto Strohaecker</td>
//               <td>PPGEM / UFRGS</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Walney Silva Araujo</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Yêda Medeiros Bastos de Almeida</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>

//         <h2><span>2008</span></h2>
//         <h3>Comitê Executivo</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Presidente: Pedro Paulo Barbosa Leite</td>
//               <td>PETROBRAS/ENGENHARIA/NORTE</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Aldo Cordeiro Dutra</td>
//               <td>INMETRO</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Gutemberg de Souza Pimenta</td>
//               <td>PETROBRAS/CENPES</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Jeferson da Silva</td>
//               <td>Akzo Nobel</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Laerce de Paula Nunes</td>
//               <td>IEC</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Olga Baptista Ferraz</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Simone Maciel</td>
//               <td>ABRACO</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Zehbour Panossian</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>
//         <h3>Comitê Técnico-científico</h3>
//         <Table responsive striped hover size="sm">
//           <thead>
//             <tr>
//               <th>Membros</th>
//               <th>Instituição</th>
//               <th>País</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>Presidente: Neusvaldo Lira de Almeida</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>
//             <tr>
//               <td>Alda Maria Pereira Simões</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>

//             <tr>
//               <td>Carlos Alexandre Martins da Silva</td>
//               <td>PETROBRAS</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Célia Aparecida Lino dos Santos</td>
//               <td>IPT</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Cesar Vitório Franco</td>
//               <td>UFSC</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Cezar Henrique Gonzalez</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Daniel de La Fuente</td>
//               <td>CENIM/CSIC</td>
//               <td>Espanha</td>
//             </tr>

//             <tr>
//               <td>Denise Shermann Azambuja</td>
//               <td>UFRS</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Denise Souza de Freitas</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Djalma Ribeiro da Silva</td>
//               <td>UFRN</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Eduardo Homem De Siqueira Cavalcanti</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Elaine Dalledone Kenny</td>
//               <td>UFPR/LACTEC</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Fernando de Loureiro Fragata</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Flamarion Borges Diniz</td>
//               <td>DQF/UFPE</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Francisca Pessoa de França</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Glória Maria Vinhas</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Idalina Vieira Aoki</td>
//               <td>EPUSP</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Isabel Correia Guedes</td>
//               <td>USP</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Isolda Costa</td>
//               <td>IPEN</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>João Carlos Salvador Fernandes</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>

//             <tr>
//               <td>Jorge Fernando Pereira Coelho</td>
//               <td>PETROBRAS</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>José Antonio da C. Ponciano Gomes</td>
//               <td>COPPE/UFRJ</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Jose Carlos Cardoso Filho</td>
//               <td>DEQAL/UFPA</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Juan M. Bastidas</td>
//               <td>CENIM</td>
//               <td>Espanha</td>
//             </tr>

//             <tr>
//               <td>Luis Frederico P. Dick</td>
//               <td>ELECTROCON/ UFRGS</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Márcia Teresa Soares Lutterbach</td>
//               <td>INT</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Maria Alice Gomes de Andrade Lima</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Maria de Fátima Grilo Costa Montemor</td>
//               <td>IST</td>
//               <td>Portugal</td>
//             </tr>

//             <tr>
//               <td>Mário Guerreiro Silva Ferreira</td>
//               <td>UA</td>
//               <td>Portugal</td>
//             </tr>

//             <tr>
//               <td>Mauro Chaves Barreto</td>
//               <td>IEC</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Mauro Zanini Sebrão</td>
//               <td>CEPEL</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Oscar Rosa Mattos</td>
//               <td>COPPE/UFRJ</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Pedro de Lima Neto</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Sara Horácio de Oliveira</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Severino Urtiga Filho</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Simone Louise Delarue Cezar Brasil</td>
//               <td>UFRJ</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Walney Silva Araujo</td>
//               <td>UFC</td>
//               <td>Brasil</td>
//             </tr>

//             <tr>
//               <td>Yêda Medeiros Bastos de Almeida</td>
//               <td>UFPE</td>
//               <td>Brasil</td>
//             </tr>
//           </tbody>
//         </Table>

//       </div>
//     );
//   }
// }

function mapStateToProps(state) {
  return {};
}

export default connect(
  mapStateToProps,
)(Comissao);

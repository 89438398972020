import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";


const ScrollToTop = (props) => {
  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
  }, [props.location])
  return props.children
}

export default withRouter(ScrollToTop)

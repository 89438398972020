import {createStore, applyMiddleware, compose} from 'redux'
import reducer from './reducers/basicRootReducer'
import thunk from 'redux-thunk';

const initialState = {};

let composeEnhancers = compose;
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}


export default () => {
  return createStore(
    reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk)
    )
  )
}
